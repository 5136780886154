import React, { useEffect, useState } from 'react';
import { getMOADBStates } from 'api/moadb.service';
import { MultiSelect } from '../MultiSelect';
import { Select } from '../Select';

export const MOADBDropdownStates = ({
  className = '',
  disabled = false,
  multiSelect = true,
  onChange,
  region = [],
  value = [],
}: {
  className?: string;
  disabled?: boolean;
  multiSelect?: boolean;
  onChange?: any;
  region?: any[];
  value?: any[];
}) => {
  const [states, setStates] = useState<any[]>([]);

  useEffect(() => {
    getMOADBStates().then(response => setStates(response.data));
  }, []);

  return multiSelect ? (
    <MultiSelect
      className={className}
      disabled={disabled}
      value={value}
      onChange={onChange}
      placeholder="Select a State"
    >
      {(states ?? [])
        .filter(
          state =>
            !region?.[0] || region[0] === '' || region.includes(state.region),
        )
        .map(state => (
          <option key={state.state_abbr} value={state.state_abbr}>
            {state.state}
          </option>
        ))}
    </MultiSelect>
  ) : (
    <Select
      className={className}
      disabled={disabled}
      value={value}
      onChange={onChange}
      placeholder="Select a State"
    >
      {(states ?? [])
        .filter(
          state =>
            !region?.[0] || region[0] === '' || region.includes(state.region),
        )
        .map(state => (
          <option key={state.state_abbr} value={state.state_abbr}>
            {state.state}
          </option>
        ))}
    </Select>
  );
};
