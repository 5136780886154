import * as React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import viewCustomFieldContentHandler from '../viewCustomFieldContentHandler';

const PlanViewRow = ({
  appEnabled,
  cellTemplateList,
  columnOptionList,
  donors,
  idField,
  options,
  range,
  resetRange,
  retrieveTemplatesAndSMTP,
  row,
  rowHeight,
  rowIndex,
  rowIndexLabel,
  rowVisibility,
  sameAsPost,
  sameAsPre,
  setDonors,
  setFormsMedium,
  setFormsRow,
  setRemoveRow,
  setRemoveRowList,
  setShowFormsModal,
  setShowOptionsModal,
  smtpCredentials,
  smtpFormEnabled,
  templates,
  uniqueFields,
  updateRange,
  userLogged,
}) => {
  const [menuXY, setMenuXY] = React.useState({
    display: 'none',
    left: 0,
    top: 0,
  });
  const trRef = React.useRef<HTMLTableRowElement>(null);
  const tdClassName = !sameAsPost ? 'moadbMatchLast' : '';
  const visibility = rowVisibility?.[rowIndexLabel];
  const selectRowIndexes = Object.keys(
    '0'.repeat(range.max - range.min + 1).split(''),
  ).map(key => range.min + parseInt(key, 10));
  const rowSelected = selectRowIndexes.includes(rowIndex);

  const onBlur = React.useCallback(e => {
    if (['contextmenu'].includes(e.type)) e.preventDefault();
    setMenuXY({ display: 'none', left: e.pageX, top: e.pageY });
  }, []);

  const onContextMenu = React.useCallback(e => {
    e.preventDefault();
    setMenuXY({ display: 'block', left: e.pageX, top: e.pageY });
  }, []);

  React.useEffect(() => {
    if (!!trRef?.current) {
      trRef.current.addEventListener('contextmenu', onContextMenu);
      trRef.current.addEventListener('mouseleave', onBlur);
      trRef.current.addEventListener('mouseup', onBlur);
      trRef.current.addEventListener('keydown', onBlur);
    }
  }, [onBlur, onContextMenu]);

  return (
    <tr
      id={`plan-view-row-${row?.[idField]}`}
      data-id={row?.[idField]}
      data-index={rowIndexLabel}
      data-row={true}
      data-visible={visibility}
      ref={trRef}
      className={`${
        rowIndex >= range.min && rowIndex <= range.max ? 'table-primary' : ''
      }`}
      onMouseDown={e => {
        const tagName = (e.target as HTMLElement).tagName.toLowerCase();

        if (
          ['table', 'td', 'tr'].includes(tagName) ||
          rowIndex < range.min ||
          rowIndex > range.max
        )
          resetRange(rowIndex);
      }}
      onMouseMove={() => updateRange(rowIndex, true)}
      onMouseUp={() => updateRange(rowIndex, false)}
      {...(!visibility ? { style: { height: `${rowHeight}px` } } : {})}
    >
      {visibility && (
        <td className={tdClassName}>
          <small>{rowIndexLabel + 1}</small>

          {!!row?.Uploaded && (
            <ListGroup className="contextMenu" style={menuXY}>
              <ListGroup.Item action onClick={() => setRemoveRow(row)}>
                Delete Row
              </ListGroup.Item>
              <ListGroup.Item action onClick={() => setRemoveRowList(row)}>
                Delete List
              </ListGroup.Item>
            </ListGroup>
          )}
        </td>
      )}

      {visibility &&
        !row?.deletedAt &&
        columnOptionList.map(columnOptions => {
          const cellTemplate = cellTemplateList.filter(
            a => a.field === columnOptions.field,
          )?.[0];

          return (
            columnOptions?.isVisible &&
            cellTemplate?.showInDesktop && (
              <td
                className={`${tdClassName} ${
                  uniqueFields.includes(cellTemplate.field)
                    ? 'moadbMatchUnique'
                    : ''
                }`}
                key={`row-${row?.[idField]}-${cellTemplate.field}`}
                style={{
                  maxWidth:
                    columnOptions?.width ?? cellTemplate.width ?? 'fit-content',
                  minWidth:
                    columnOptions?.width ?? cellTemplate.width ?? 'fit-content',
                  width: columnOptions?.width ?? 'fit-content',
                  whiteSpace: !!columnOptions?.width ? undefined : 'nowrap',
                }}
              >
                {(uniqueFields.includes(cellTemplate.field) ||
                  !cellTemplate.isStatic) && (
                  <small className={rowSelected ? '' : 'disabled'}>
                    {!!cellTemplate?.custom
                      ? viewCustomFieldContentHandler(
                          row,
                          cellTemplate,
                          columnOptions,
                          selectRowIndexes,
                          donors,
                          setDonors,
                        )
                      : cellTemplate.contentHandler(
                          row,
                          templates,
                          cellTemplate,
                          columnOptions,
                          selectRowIndexes,
                          donors,
                          setDonors,
                          appEnabled,
                          userLogged,
                          smtpCredentials,
                          smtpFormEnabled,
                          setFormsMedium,
                          setFormsRow,
                          setShowFormsModal,
                          setShowOptionsModal,
                          retrieveTemplatesAndSMTP,
                          options,
                        )}
                  </small>
                )}
              </td>
            )
          );
        })}

      {visibility && row?.deletedAt && (
        <td
          className="bg-danger"
          colSpan={
            columnOptionList.filter(columnOptions => {
              const cellTemplate = cellTemplateList.filter(
                a => a.field === columnOptions.field,
              )?.[0];

              return columnOptions.isVisible && cellTemplate?.showInDesktop;
            }).length
          }
        ></td>
      )}
    </tr>
  );
};

export default PlanViewRow;
