import constants from 'app/config/constants';
import axiosInstance from 'utils/api';

export const getP2PMessageQueue = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/p2pMessageQueue/`;

  return await axiosInstance().get(url, { params: body });
};

export const insertP2PMessageQueueRecords = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/p2pMessageQueue/insertOrUpdateRecords`;

  return await axiosInstance().post(url, body);
};
