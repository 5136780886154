import { Box } from '@mui/system';
import { getDonorListCSV } from 'api/donorlist.service';
import dayjs from 'dayjs';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { CustomButton } from '../../components/LeftSidebar/index';
import { selectSearchDonorData } from '../SearchPage/slice/selectors';
const SucceededPage = () => {
  const navigate = useNavigate();

  const searchData = useSelector(selectSearchDonorData);
  const userLogged = useSelector(getAuthUser);

  const cart = useMemo(() => {
    return searchData.filter(donor => donor.selected);
  }, [searchData]);

  useEffect(() => {
    if (cart && cart.length) {
      const dreIds = cart.map(cartItem => cartItem.DRE_ID);
      sessionStorage.setItem('LAST_PURCHASED', JSON.stringify(dreIds));
    }
  }, [cart]);

  const downloadCSV = async () => {
    try {
      const dreIds = JSON.parse(
        sessionStorage.getItem('LAST_PURCHASED') || '[]',
      ) as number[];
      const email = userLogged!.email;
      const test = await getDonorListCSV(dreIds, email);

      const blob = new Blob([test.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `data${dayjs().format(' YYYY-MM-DD HH.mm.ss')}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h1 className="heading-10">A great big thank you!</h1>
      <p>
        Welcome to the DRE family! We'll take it from here. We are compiling
        your information and sending it through to your email address.
      </p>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <CustomButton sx={{ width: '150px' }} onClick={() => navigate('/')}>
          Dashboard
        </CustomButton>
        <br />
        <CustomButton sx={{ width: '200px' }} onClick={downloadCSV}>
          Download CSV
        </CustomButton>
      </Box>
    </Box>
  );
};

export default SucceededPage;
