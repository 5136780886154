import constants from 'app/config/constants';
import axiosInstance from 'utils/api';
import { websocketRequest } from 'utils/wsAPI';

export const getProspectTrackerAssignees = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/prospectTracker/assignees`;

  return await axiosInstance().post(url, body);
};

export const getProspectTrackerList = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/prospectTrackerView/records`;

  return await axiosInstance().post(url, body);
};

export const getProspectTrackerGrouping = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/prospectTrackerView/grouping`;

  return await axiosInstance().post(url, body);
};

export const getProspectTrackerListWS = async (
  body,
  callback,
  callbackStatus: any = null,
) => {
  const endpoint = constants.BASE_ENDPOINT_WS();
  const url = `${endpoint}/api/dre/prospectTrackerView/records`;

  websocketRequest(url, body, callback, callbackStatus);
};

export const insertProspectTrackerRecords = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/prospectTracker/insertOrUpdateRecords`;

  return await axiosInstance().post(url, body);
};
