// App.tsx
import constants from 'app/config/constants';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  getProspectTrackerList,
  getProspectTrackerListWS,
  insertProspectTrackerRecords,
} from 'api/prospect.tracker.service';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import PlanView from './PlanView';
import OptionsModal from '../OptionsModal';
import { useMediaQuery } from '@mui/material';
import { getProspectTrackerTemplateList } from 'api/prospect.tracker.template.service';
import {
  deleteProspectTrackerOptions,
  insertProspectTrackerOptionsRecords,
} from 'api/prospect.tracker.options.service';
import PlanViewStatusBar from './PlanView/PlanViewStatusbar';
import ContactView from './ContactView';
import {
  compare,
  enumerate,
  getPageHeight,
  getRowHeight,
  isElementVisible,
} from 'app/components/Prospect-Tracker/utility';
import { getProspectTrackerOptions } from 'api/prospect.tracker.options.service';
import { getSMTPCredentialsList } from 'api/smtp.credentials.service';
import GeneralConfirmationModal from 'app/components/GeneralConfirmationModal/GeneralConfirmationModal';
import {
  removeRecord,
  removeRecordLists,
} from 'api/prospect.ingestion.service';
import {
  getProspectSegments,
  insertProspectSegmentRecords,
} from 'api/prospect.segment.service';
import OptionsCanvas from '../OptionsCanvas';
import NewSegmentModal from '../NewSegmentModal';
import RenameSegmentModal from '../RenameSegmentModal';
import { toast } from 'react-toastify';
import LoadingOverlay from '../../../LoadingOverlay';
import CustomFieldModal from '../CustomFieldModal';
import { viewCustomFieldContentHandlerCSV } from './viewCustomFieldContentHandler';
import FormsModal from '../FormsModal';

const View = ({
  cellTemplateList,
  forms,
  idField = '_id',
  options,
  setCellTemplateList,
  setOptions,
}) => {
  const userLogged = useSelector(getAuthUser);

  const [activePage, setActivePage] = React.useState(0);
  const [appEnabled, setAppEnabled] = React.useState(false);
  const [columnOptionList, setColumnOptionList] = React.useState<any[]>([]);
  const [count, setCount] = React.useState(-1);
  const [donors, setDonors] = React.useState([{}]);
  const [includeUploads, setIncludeUploads] = React.useState(true);
  const [formsMedium, setFormsMedium] = React.useState<any>(null);
  const [formsRow, setFormsRow] = React.useState<any>(null);
  const interval = React.useRef<any>(null);
  const isMobile = useMediaQuery(`( max-width: 1224px )`);
  const loading = React.useRef(false);
  const [loadingStatus, setLoadingStatus] = React.useState<any>(null);
  const [orderContact, setOrderContact] = React.useState([-1]);
  const [orderPlan, setOrderPlan] = React.useState(-1);
  const [pageCount, setPageCount] = React.useState(0);
  const [pageHeight, setPageHeight] = React.useState<any>(null);
  const [pagesLoaded, setPagesLoaded] = React.useState({});
  const [pageVisibility, setPageVisibility] = React.useState<any>({});
  const [queryContact] = React.useState([
    {
      'prospectTracker.assignee': [userLogged?._id],
    },
    null,
  ]);
  const [queryPlan, setQueryPlan] = React.useState({});
  const [range, setRange] = React.useState({ min: -1, max: -1 });
  const [showResetSettingsModal, setShowResetSettingsModal] =
    React.useState<any>(null);
  const [removeRow, setRemoveRow] = React.useState<any>(null);
  const [removeRowList, setRemoveRowList] = React.useState<any>(null);
  const [renameSegment, setRenameSegment] = React.useState<any>(null);
  const [rowHeight, setRowHeight] = React.useState<any>(null);
  const [rowVisibility, setRowVisibilty] = React.useState<any>({});
  const [saveBatch, setSaveBatch] = React.useState<any>(null);
  const [saveQueue, setSaveQueue] = React.useState<any[]>([]);
  const [segments, setSegments] = React.useState<any[]>([]);
  const [sessionId] = React.useState(uuidv4());
  const [shiftDown, setShiftDown] = React.useState(false);
  const [showCustomFieldModal, setShowCustomFieldModal] = React.useState(false);
  const [showLoadingOverlay, setShowLoadingOverlay] = React.useState(false);
  const [showFormsModal, setShowFormsModal] = React.useState<any>(null);
  const [showNewSegmentModal, setShowNewSegmentModal] = React.useState(false);
  const [showOptionsModal, setShowOptionsModal] = React.useState(false);
  const [smtpFormEnabled, setSMTPFormEnabled] = React.useState(
    userLogged?.canAccessMOADBQuery,
  );
  const [sortPlan, setSortPlan] = React.useState('donationCapacity');
  const [sortContact, setSortContact] = React.useState([
    'prospectTracker.priority',
  ]);
  const [templates, setTemplates] = React.useState<any[]>([]);
  const [smtpCredentials, setSMTPCredentials] = React.useState<any[]>([]);
  const [updateSegment, setUpdateSegment] = React.useState<any>(null);
  const updatingRowVisibility = React.useRef(false);
  const [viewMode, setViewMode] = React.useState(
    isMobile ? 'mobile' : 'desktop',
  );
  const workspaceRef = React.useRef<HTMLDivElement>(null);
  const workspacePaddingRef = React.useRef<HTMLDivElement>(null);

  const downloadCSV = async () => {
    try {
      setLoadingStatus(null);
      setShowLoadingOverlay(true);

      getProspectTrackerListWS(
        {
          email: userLogged!.email,
          sessionId: uuidv4(),
          all: true,
          ...getQueryAndSortBasedOnViewMode(),
          orgAdminContact:
            userLogged?.role === 'orgAdmin' && viewMode !== 'desktop',
        },
        response => {
          const header = `"${columnOptionList
            .map(columnOptions => {
              const cellTemplate = cellTemplateList.filter(
                a => a.field === columnOptions.field,
              )?.[0];

              return !cellTemplate
                ? null
                : columnOptions.isVisible &&
                    (cellTemplate.showInCSV || !!cellTemplate.custom) &&
                    cellTemplate.field
                      .replace('prospectTracker.', '')
                      .replace('custom.', '');
            })
            .filter(a => a)
            .join('","')}"`;

          const rows = response.map(row => {
            return `"${columnOptionList
              .map(columnOptions => {
                const cellTemplate = cellTemplateList.filter(
                  a => a.field === columnOptions.field,
                )?.[0];

                if (!cellTemplate) return null;
                else if (
                  !(
                    columnOptions.isVisible &&
                    (cellTemplate.showInCSV || !!cellTemplate.custom)
                  )
                )
                  return null;
                else if (!!cellTemplate?.contentHandlerCSV)
                  return (
                    cellTemplate
                      .contentHandlerCSV(row, cellTemplate)
                      ?.toString()
                      ?.replace(/"/g, '') ?? ''
                  );
                else if (!!cellTemplate?.custom)
                  return (
                    viewCustomFieldContentHandlerCSV(row, cellTemplate)
                      ?.toString()
                      ?.replace(/"/g, '') ?? ''
                  );
                else
                  return (
                    cellTemplate
                      .contentHandler(
                        row,
                        templates,
                        cellTemplate,
                        columnOptions,
                        [],
                        donors,
                        setDonors,
                        appEnabled,
                        userLogged,
                        smtpCredentials,
                      )
                      ?.toString()
                      ?.replace(/"/g, '') ?? ''
                  );
              })
              .filter(a => !!a || a === '')
              .join('","')}"`;
          });

          const blob = new Blob([[header, ...rows].join('\n')], {
            type: 'text/csv;charset=utf-8',
          });
          const blobURL = URL.createObjectURL(blob);
          window.open(blobURL, '_blank');

          setShowLoadingOverlay(false);
        },
        status => {
          const count = parseInt(status.count, 10);
          const current = parseInt(status.current, 10);
          const percentage = ((current / count) * 100).toFixed(2);

          setLoadingStatus(
            `${percentage.toLocaleString()}% (${current.toLocaleString(
              'en-US',
            )}/${count.toLocaleString('en-US')})`,
          );
        },
      );
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  const getPageInsertIndex = React.useCallback(
    page =>
      Object.keys(pagesLoaded).filter(key => parseInt(key, 10) < page).length *
      constants.DPT_LOAD_LIMIT(),
    [pagesLoaded],
  );

  const getQueryAndSortBasedOnViewMode = React.useCallback(() => {
    return {
      query: {
        ...(includeUploads ? null : { uploaded: { $exists: false } }),
        ...(viewMode === 'desktop'
          ? queryPlan
          : queryContact[userLogged?.role === 'orgAdmin' ? 1 : 0]),
      },
      sort:
        viewMode === 'desktop'
          ? [[sortPlan, orderPlan]]
          : sortContact.map((sort, index) => [sort, orderContact[index]]),
    };
  }, [
    includeUploads,
    orderContact,
    orderPlan,
    queryContact,
    queryPlan,
    sortContact,
    sortPlan,
    userLogged?.role,
    viewMode,
  ]);

  const goToPage = pageIndex => {
    scrollToPage(pageIndex);
  };

  const loadRecords = React.useCallback(
    pageIndex => {
      const truePageIndex = !!pageCount
        ? Math.min(pageCount - 1, parseInt(pageIndex, 10))
        : parseInt(pageIndex, 10);

      if (!pagesLoaded[truePageIndex] && !loading.current) {
        loading.current = true;

        getProspectTrackerList({
          email: userLogged!.email,
          sessionId,
          limit: constants.DPT_LOAD_LIMIT(),
          page: truePageIndex,
          ...getQueryAndSortBasedOnViewMode(),
          orgAdminContact:
            userLogged?.role === 'orgAdmin' && viewMode !== 'desktop',
        }).then(response => {
          const insertIndex = getPageInsertIndex(truePageIndex);

          setCount(parseInt(response?.data?.count, 10));
          setPageCount(
            Math.ceil(
              parseInt(response?.data?.count, 10) / constants.DPT_LOAD_LIMIT(),
            ),
          );

          setDonors([
            ...donors.slice(0, insertIndex),
            ...response.data.donors,
            ...donors.slice(insertIndex),
          ]);
          setPagesLoaded({ ...pagesLoaded, [truePageIndex]: true });
          loading.current = false;
        });
      }
    },
    [
      donors,
      getPageInsertIndex,
      getQueryAndSortBasedOnViewMode,
      pageCount,
      pagesLoaded,
      sessionId,
      userLogged,
      viewMode,
    ],
  );

  const loadSegment = segment => {
    setQueryPlan(segment.Query ?? {});
    setOrderPlan(segment.Order);
    setSortPlan(segment.Sort);
  };

  const markRowRemoved = row => {
    setRemoveRow(null);
    setLoadingStatus(null);
    setShowLoadingOverlay(true);

    removeRecord(row).then(() => {
      row.deletedAt = Date.now();
      setShowLoadingOverlay(false);
      resetDataLoad();
    });
  };

  const markRowListRemoved = row => {
    setRemoveRowList(null);
    setLoadingStatus(null);
    setShowLoadingOverlay(true);

    removeRecordLists(row).then(() => {
      const deletedAt = Date.now();
      donors.forEach((donor: any) => {
        if (
          (donor.prospectList ?? [])
            .map(a => a?._id)
            .some(a => (row.prospectList ?? []).map(a => a?._id).includes(a))
        )
          donor.deletedAt = deletedAt;
      });

      setDonors([...donors]);
      setShowLoadingOverlay(false);
      resetDataLoad();
    });
  };

  const onScroll = React.useCallback(() => {
    if (
      !!workspaceRef.current &&
      !!workspacePaddingRef.current &&
      !updatingRowVisibility.current
    ) {
      updatingRowVisibility.current = true;

      //calculate row, page, & window height
      const calcRowHeight = rowHeight ? rowHeight : getRowHeight();
      const calcPageHeight = pageHeight ? pageHeight : getPageHeight();
      const newRowHeight = calcRowHeight ?? 100;
      const newPageHeight =
        viewMode === 'desktop' || isMobile
          ? newRowHeight * constants.DPT_LOAD_LIMIT()
          : calcPageHeight ?? 2000;
      const windowHeight = Math.max(
        window.innerHeight,
        document.documentElement.clientHeight,
        document.body.clientHeight,
      );

      //calculate applicable pages
      const top = workspaceRef.current.getBoundingClientRect().top;
      const pageIndexStart = Math.max(0, Math.floor((0 - top) / newPageHeight));
      const pageIndexEnd = Math.ceil((windowHeight - top) / newPageHeight);
      const pageIndex = enumerate(pageIndexEnd - pageIndexStart + 1).map(
        index => index + pageIndexStart,
      );
      const newPageVisibility = pageIndex
        .map(index => ({ [index]: true }))
        .reduce((p, c) => ({ ...p, ...c }), {});

      if (!compare(newPageVisibility, pageVisibility))
        setPageVisibility(newPageVisibility);

      if (!!calcRowHeight) setRowHeight(calcRowHeight);
      setPageHeight(newPageHeight);
      setActivePage(pageIndexStart);

      //calculate applicable rows
      if (viewMode === 'desktop') {
        const rowIndexStart = Math.max(0, Math.floor((0 - top) / newRowHeight));
        const rowIndexEnd = Math.ceil((windowHeight - top) / newRowHeight);
        const rowIndex = enumerate(rowIndexEnd - rowIndexStart + 1).map(
          index => index + rowIndexStart,
        );
        const newRowVisibility = rowIndex
          .map(index => ({ [index]: true }))
          .reduce((p, c) => ({ ...p, ...c }), {});

        if (!compare(newRowVisibility, rowVisibility))
          setRowVisibilty(newRowVisibility);
      } else {
        const newRowVisibility = Array.from(
          document.querySelectorAll(`[data-row="true"]`),
        )
          .filter(row => isElementVisible(row))
          .map(row => ({ [row.getAttribute('data-index') ?? '']: true }))
          .reduce((p, c) => ({ ...p, ...c }), {});

        if (!compare(newRowVisibility, rowVisibility))
          setRowVisibilty(newRowVisibility);
      }

      //load first, unloaded page
      if (!loading.current) {
        let loadedNewPage = false;

        pageIndex.forEach(index => {
          if (!loadedNewPage && !pagesLoaded[index]) {
            loadRecords(index);
            loadedNewPage = true;
          }
        });
      }

      //update workspace and padding
      if (!!workspaceRef.current && !!workspacePaddingRef.current) {
        const pages =
          Array.from(document.querySelectorAll(`[data-page="true"]`)) ?? [];

        const firstVisiblePageIndex = Math.min(
          ...pages.map(page =>
            parseInt(page.getAttribute('data-page-index') ?? '0', 10),
          ),
        );

        const workspacePadding = firstVisiblePageIndex * newPageHeight;
        workspaceRef.current.style.height = `${newPageHeight * pageCount}px`;
        workspaceRef.current.style.top = `${workspacePadding}px`;
        workspacePaddingRef.current.style.display = `block`;
        workspacePaddingRef.current.style.height = `${workspacePadding}px`;
      }

      updatingRowVisibility.current = false;
    }
  }, [
    isMobile,
    loadRecords,
    pageCount,
    pageHeight,
    pageVisibility,
    pagesLoaded,
    rowHeight,
    rowVisibility,
    viewMode,
  ]);

  const resetDataLoad = () => {
    window.scrollTo({ top: 0 });
    setDonors([{}]);
    setPagesLoaded({});
    loading.current = false;
    loadRecords(0);
  };

  const resetSettings = () => {
    deleteProspectTrackerOptions({
      email: userLogged!.email,
    }).then(() => {
      toast.success('Settings reset!');
      setShowResetSettingsModal(false);
      setShowOptionsModal(false);
    });
  };

  const retrieveTemplatesAndSMTP = React.useCallback(
    (showToast = false) => {
      getProspectTrackerTemplateList({
        email: userLogged!.email,
      }).then(response => {
        setTemplates(response.data.templates);
      });

      getSMTPCredentialsList({
        email: userLogged!.email,
      }).then(response => {
        setSMTPCredentials(response.data.credentials);
        if (showToast) toast.success('Templates updated!');
      });
    },
    [userLogged],
  );

  const saveNewSegment = name => {
    saveSegments([
      ...segments,
      {
        Name: name,
        Query: queryPlan,
        Order: orderPlan,
        Sort: sortPlan,
      },
    ]);

    setShowNewSegmentModal(false);
  };

  const saveOptions = (
    updatedColumnsOptionList,
    updatedCustomFields = null,
    udatedSMTPFormEnabled = null,
    updatedActiveEmailTemplate = null,
    updatedActiveSMSTemplate = null,
    updatedActiveSMTPCredentials = null,
    callback: any = null,
  ) => {
    insertProspectTrackerOptionsRecords({
      email: userLogged!.email,
      activeEmailTemplate: updatedActiveEmailTemplate,
      activeSMSTemplate: updatedActiveSMSTemplate,
      activeSMTPCredentials: updatedActiveSMTPCredentials,
      columns: updatedColumnsOptionList ?? columnOptionList,
      customFields: updatedCustomFields,
      smtpFormEnabled: udatedSMTPFormEnabled ?? smtpFormEnabled,
      storeOrganizationId: !!updatedCustomFields,
    }).then(response => {
      if (!!callback) callback(response);
    });
  };

  const saveRenamedSegment = name => {
    renameSegment.Name = name;
    saveSegments([...segments]);
    setRenameSegment(null);
  };

  const saveSegments = updatedSegments => {
    setSegments(updatedSegments);

    insertProspectSegmentRecords({
      email: userLogged!.email,
      segments: updatedSegments,
    }).then(response => setSegments(response.data.segments));
  };

  const scrollToPage = React.useCallback(
    pageIndex => {
      window.scrollTo(
        0,
        (parseInt(pageIndex, 10) +
          (parseInt(pageIndex, 10) > activePage ? 1 : 0)) *
          pageHeight,
      );
    },
    [activePage, pageHeight],
  );

  const updateDonors = delta => {
    setSaveQueue([
      ...saveQueue,
      ...delta.map(item => ({
        InternalReference_ID: item.data?.InternalReference_ID ?? item.data._id,
        update: {
          ...item.data.prospectTracker,
          prospectId: item.data._id,
          orgId: userLogged?.organizationId,
        },
      })),
    ]);

    delta.forEach(item => (donors[item.index] = item.data));
    setDonors([...donors]);
  };

  const updateAndSaveSegment = () => {
    saveSegments(
      segments.map(segment =>
        segment._id !== updateSegment._id
          ? segment
          : {
              ...segment,
              Query: queryPlan,
              Order: orderPlan,
              Sort: sortPlan,
            },
      ),
    );

    setUpdateSegment(null);
  };

  const updateViewMode = newViewMode => {
    setDonors([{}]);
    setPageHeight(null);
    setPagesLoaded({});
    setPageVisibility({});
    setRowHeight(null);
    setRowVisibilty({});
    setViewMode(newViewMode);
  };

  React.useEffect(() => {
    setActivePage(0);
    setCount(-1);
    setDonors([{}]);
    setPagesLoaded({});
  }, [
    includeUploads,
    orderContact,
    orderPlan,
    queryContact,
    queryPlan,
    sortContact,
    sortPlan,
    viewMode,
  ]);

  React.useEffect(() => {
    interval.current = setInterval(onScroll, 100);
    return () => {
      clearInterval(interval.current);
    };
  }, [onScroll]);

  React.useEffect(() => {
    if (saveQueue.length >= 1 && !saveBatch) {
      const batch = saveQueue.splice(0, constants.DPT_SAVE_LIMIT());

      setSaveBatch(batch);
      setSaveQueue([...saveQueue]);

      insertProspectTrackerRecords(batch).then(() => setSaveBatch(null));
    }
  }, [saveBatch, saveQueue]);

  React.useEffect(() => {
    retrieveTemplatesAndSMTP();

    getProspectSegments({
      email: userLogged!.email,
    }).then(response => {
      setSegments(response.data.segments);
    });
  }, [retrieveTemplatesAndSMTP, userLogged]);

  React.useEffect(() => {
    if (!columnOptionList?.length) {
      setColumnOptionList(
        cellTemplateList.map((cellTemplate, index) => ({
          field: cellTemplate.field,
          index,
          isVisible: true,
        })),
      );

      getProspectTrackerOptions({
        email: userLogged!.email,
      }).then(response => {
        const options = response.data?.options
          .map(options =>
            Object.keys(options)
              .filter(key => options[key])
              .reduce((p, c) => ({ ...p, ...{ [c]: options[c] } }), {}),
          )
          .reduce((p, c) => ({ ...p, ...c }), {});

        const columns = options.columns ?? [];
        const columnsFields = columns.map(column => column.field);
        const customFields = options.customFields ?? [];

        columns.push(
          ...cellTemplateList
            .filter(cellTemplate => cellTemplate.showInDesktop)
            .filter(cellTemplate => !columnsFields.includes(cellTemplate.field))
            .map((cellTemplate, index) => ({
              field: cellTemplate.field,
              index: columns?.length + index,
              isVisible: true,
            })),
        );

        columns.push(
          ...customFields
            .filter(customField => !columnsFields.includes(customField.field))
            .map((customField, index) => ({
              field: customField.field,
              index: columns?.length + index,
              isVisible: true,
            })),
        );

        response.data?.options.forEach(item => {
          if ('smtpFormEnabled' in item)
            setSMTPFormEnabled(item.smtpFormEnabled);

          if ('activeEmailTemplate' in item)
            setOptions({
              ...options,
              activeEmailTemplate: item.activeEmailTemplate,
            });

          if ('activeSMSTemplate' in item)
            setOptions({
              ...options,
              activeSMSTemplate: item.activeSMSTemplate,
            });

          if ('activeSMTPCredentials' in item)
            setOptions({
              ...options,
              activeSMTPCredentials: item.activeSMTPCredentials,
            });
        });

        setCellTemplateList([...cellTemplateList, ...customFields]);
        setColumnOptionList(columns);
      });
    }
  }, [
    cellTemplateList,
    columnOptionList?.length,
    setCellTemplateList,
    userLogged,
  ]);

  return (
    <div
      ref={workspaceRef}
      className="pb-5"
      onKeyDown={e => {
        if (e.key.toLowerCase() === 'escape') {
          setShiftDown(false);
          setRange({ min: -1, max: -1 });
        } else if (e.shiftKey) {
          const tagName = (e.target as HTMLElement).tagName.toLowerCase();
          if (['table', 'td', 'tr'].includes(tagName)) setShiftDown(true);
        }
      }}
      onKeyUp={e => setShiftDown(e.shiftKey)}
      onMouseUp={() => setShiftDown(false)}
    >
      <LoadingOverlay show={showLoadingOverlay} status={loadingStatus} />

      <GeneralConfirmationModal
        show={!!removeRow}
        title="Delete"
        message="This will mark the prospect as soft-deleted. The prospect will not actually be deleted, but will appear so. This task may take a while. After this task is completed, the data will reload. Are you sure you want to continue?"
        onCancel={() => setRemoveRow(null)}
        onHide={() => setRemoveRow(null)}
        onOK={() => markRowRemoved(removeRow)}
      />

      <GeneralConfirmationModal
        show={!!removeRowList}
        title="Delete Entire List"
        message={`This will mark the list${
          removeRowList?.prospectList?.length > 1 ? 's' : ''
        } "${removeRowList?.prospectList
          .map(a => a.Name)
          .join(
            '" and "',
          )}" and all associated prospects as soft-deleted. They will not actually be deleted, but will appear so. This task may take a while. After this task is completed, the data will reload. Are you sure you want to continue?`}
        onCancel={() => setRemoveRowList(null)}
        onHide={() => setRemoveRowList(null)}
        onOK={() => markRowListRemoved(removeRowList)}
      />

      <GeneralConfirmationModal
        show={!!updateSegment}
        title="Update"
        message={`Are you certain you want to update segment "${updateSegment?.Name}". This action cannot be undone?`}
        onCancel={() => setUpdateSegment(null)}
        onHide={() => setUpdateSegment(null)}
        onOK={() => updateAndSaveSegment()}
      />

      <GeneralConfirmationModal
        show={!!showResetSettingsModal}
        title="Reset Settings"
        message={`Are you certain you want to reset your settings? This action cannot be undone?`}
        onCancel={() => setShowResetSettingsModal(false)}
        onHide={() => setShowResetSettingsModal(false)}
        onOK={() => resetSettings()}
      />

      <CustomFieldModal
        columnOptionList={columnOptionList}
        cellTemplateList={cellTemplateList}
        onHide={() => setShowCustomFieldModal(false)}
        saveOptions={saveOptions}
        setCellTemplateList={setCellTemplateList}
        setColumnOptionList={setColumnOptionList}
        show={showCustomFieldModal}
      />

      <FormsModal
        appEnabled={appEnabled}
        forms={forms}
        medium={formsMedium}
        onHide={() => setShowFormsModal(null)}
        row={formsRow}
        show={showFormsModal}
        smtpCredentials={smtpCredentials}
        smtpFormEnabled={smtpFormEnabled}
        userLogged={userLogged}
      />

      <OptionsModal
        canAccessMOADBQuery={userLogged?.canAccessMOADBQuery}
        onHide={() => setShowOptionsModal(false)}
        options={options}
        retrieveTemplatesAndSMTP={retrieveTemplatesAndSMTP}
        saveOptions={saveOptions}
        setOptions={setOptions}
        setShowResetSettingsModal={setShowResetSettingsModal}
        setSMTPFormEnabled={setSMTPFormEnabled}
        setViewMode={updateViewMode}
        show={showOptionsModal}
        smtpCredentials={smtpCredentials}
        smtpFormEnabled={smtpFormEnabled}
        templates={templates}
        viewMode={viewMode}
      />

      <NewSegmentModal
        onHide={() => setShowNewSegmentModal(false)}
        onSave={saveNewSegment}
        show={showNewSegmentModal}
      />

      <RenameSegmentModal
        onHide={() => setRenameSegment(null)}
        onSave={saveRenamedSegment}
        segment={renameSegment}
        show={!!renameSegment}
      />

      {viewMode === 'desktop' ? (
        <>
          <PlanView
            appEnabled={appEnabled}
            cellTemplateList={cellTemplateList}
            columnOptionList={columnOptionList}
            count={count}
            donors={donors}
            getPageInsertIndex={getPageInsertIndex}
            idField={idField}
            options={options}
            pageHeight={pageHeight}
            pagesLoaded={pagesLoaded}
            pageVisibility={pageVisibility}
            order={orderPlan}
            query={queryPlan}
            range={range}
            retrieveTemplatesAndSMTP={retrieveTemplatesAndSMTP}
            rowHeight={rowHeight}
            rowVisibility={rowVisibility}
            saveOptions={saveOptions}
            setColumnOptionList={setColumnOptionList}
            setDonors={updateDonors}
            setFormsMedium={setFormsMedium}
            setFormsRow={setFormsRow}
            setShowOptionsModal={setShowOptionsModal}
            setOrder={setOrderPlan}
            setQuery={setQueryPlan}
            setRange={setRange}
            setRemoveRow={setRemoveRow}
            setRemoveRowList={setRemoveRowList}
            setShowFormsModal={setShowFormsModal}
            setShiftDown={setShiftDown}
            setSort={setSortPlan}
            shiftDown={shiftDown}
            smtpCredentials={smtpCredentials}
            smtpFormEnabled={smtpFormEnabled}
            sort={sortPlan}
            templates={templates}
            userLogged={userLogged}
            workspacePaddingRef={workspacePaddingRef}
          />
          <OptionsCanvas
            columnOptionList={columnOptionList}
            cellTemplateList={cellTemplateList}
            loadSegment={loadSegment}
            saveOptions={saveOptions}
            segments={segments}
            setColumnOptionList={setColumnOptionList}
            setShowCustomFieldModal={setShowCustomFieldModal}
            setRenameSegment={setRenameSegment}
            setShowNewSegmentModal={setShowNewSegmentModal}
            setUpdateSegment={setUpdateSegment}
          />
        </>
      ) : (
        <ContactView
          cellTemplateList={cellTemplateList}
          columnOptionList={columnOptionList}
          count={count}
          donors={donors}
          getPageInsertIndex={getPageInsertIndex}
          idField={idField}
          order={orderContact}
          pageHeight={pageHeight}
          pagesLoaded={pagesLoaded}
          pageVisibility={pageVisibility}
          rowHeight={rowHeight}
          rowVisibility={rowVisibility}
          setDonors={updateDonors}
          setOrder={setOrderContact}
          setSort={setSortContact}
          setShowOptionsModal={setShowOptionsModal}
          sort={sortContact}
          templates={templates}
          workspacePaddingRef={workspacePaddingRef}
        />
      )}

      <PlanViewStatusBar
        appEnabled={appEnabled}
        activePage={activePage}
        canAccessMOADBQuery={userLogged?.canAccessMOADBQuery}
        count={count}
        downloadCSV={downloadCSV}
        getQueryAndSortBasedOnViewMode={getQueryAndSortBasedOnViewMode}
        goToPage={goToPage}
        includeUploads={includeUploads}
        setAppEnabled={setAppEnabled}
        setIncludeUploads={setIncludeUploads}
        userLogged={userLogged}
      />
    </div>
  );
};

export default View;
