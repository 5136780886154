import React from 'react';
import View from 'app/components/Prospect-Tracker/components/View';
import { getProspectTrackerAssignees } from 'api/prospect.tracker.service';
import { getProspectTrackerOptions } from 'api/prospect.tracker.options.service';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { useSelector } from 'react-redux';
import './style.css';
import { getLists } from 'api/prospect.list.service';
import { getOneOrganization } from 'api/organization.service';
import { getForms } from 'api/formsservice';
import newCellTemplateList from '../../newCellTemplateList';

const MainPage = () => {
  const userLogged = useSelector(getAuthUser);
  const [assignees, setAssignees] = React.useState([
    { _id: '', firstName: '', lastName: '' },
  ]);
  const [cellTemplateList, setCellTemplateList] = React.useState<any>(null);
  const [list, setList] = React.useState<any>(null);
  const [forms, setForms] = React.useState<any>(null);
  const [options, setOptions] = React.useState<any>(null);

  React.useEffect(() => {
    getProspectTrackerAssignees({
      email: userLogged!.email,
    }).then(response => {
      setAssignees(response.data.assignees);
    });

    getProspectTrackerOptions({
      email: userLogged!.email,
    }).then(response => {
      let newOptions = {};

      response.data?.options.forEach(item => {
        if ('activeEmailTemplate' in item)
          newOptions = {
            ...newOptions,
            activeEmailTemplate: item.activeEmailTemplate,
          };

        if ('activeSMSTemplate' in item)
          newOptions = {
            ...newOptions,
            activeSMSTemplate: item.activeSMSTemplate,
          };

        if ('activeSMTPCredentials' in item)
          newOptions = {
            ...newOptions,
            activeSMTPCredentials: item.activeSMTPCredentials,
          };
      });

      setOptions(newOptions);
    });

    getLists(userLogged?.email).then(response => {
      setList(response.data);
    });

    getOneOrganization({ Organization_ID: userLogged?.organizationId }).then(
      response =>
        getForms(response.petitionAccountToken).then(response =>
          setForms(response.data.json.forms),
        ),
    );
  }, [userLogged]);

  React.useEffect(() => {
    if (list && assignees && options)
      setCellTemplateList(
        newCellTemplateList({ assignees, options, list, userLogged }),
      );
  }, [assignees, list, options, userLogged]);

  if (!cellTemplateList || !options) return <></>;
  return (
    <View
      cellTemplateList={cellTemplateList}
      forms={forms}
      options={options}
      setCellTemplateList={setCellTemplateList}
      setOptions={setOptions}
    />
  );
};

export default MainPage;
