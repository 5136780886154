import React from 'react';
import ToolTip from 'app/components/ToolTip';
import DonatesToAnimalWelfare from '../../assets/icons/paw-color-icon.png';
import DonatesToArtsAndCulture from '../../assets/icons/painting-icon.png';
import DonatesToChildrensCauses from '../../assets/icons/doll-icon.png';
import DonatesToConservativeCauses from '../../assets/icons/map-direction-navigation-right-red-icon.png';
import DonatesToHealthcare from '../../assets/icons/first-aid-icon.png';
import DonatesToInternationalAidCauses from '../../assets/icons/globe-network-color-icon.png';
import DonatesToLiberalCauses from '../../assets/icons/map-direction-navigation-left-blue-icon.png';
import DonatesToLocalCommunity from '../../assets/icons/diversity-icon.png';
import DonatesToPoliticalCausesIcon from '../../assets/icons/checkmark-box-green-icon.png';
import DonatesToVeteransCauses from '../../assets/icons/medal-color-icon.png';
import DonatesToWildlifePreservation from '../../assets/icons/lion-face-color-icon.png';

import { Col, Image } from 'react-bootstrap';

const getRowColumn = (filteredColumns, row, column) =>
  filteredColumns.indexOf(column.column) >= 0 ? row[column.column] : null;

const IntersetsIcons = ({
  columnProperties = { className: 'align-self-center', lg: 1 },
  prospect,
  filteredColumns = [],
  withinColumn = true,
}) => {
  return (
    <>
      {[
        {
          column: 'donates_to_animal_welfare',
          Icon: DonatesToAnimalWelfare,
        },
        {
          column: 'donates_to_arts_and_culture',
          Icon: DonatesToArtsAndCulture,
        },
        {
          column: 'donates_to_childrens_causes',
          Icon: DonatesToChildrensCauses,
        },
        {
          column: 'donates_to_conservative_causes',
          Icon: DonatesToConservativeCauses,
        },
        {
          column: 'donates_to_healthcare',
          Icon: DonatesToHealthcare,
        },
        {
          column: 'donates_to_international_aid_causes',
          Icon: DonatesToInternationalAidCauses,
        },
        {
          column: 'donates_to_liberal_causes',
          Icon: DonatesToLiberalCauses,
        },
        {
          column: 'donates_to_local_community',
          Icon: DonatesToLocalCommunity,
        },
        {
          column: 'donates_to_political_causes',
          Icon: DonatesToPoliticalCausesIcon,
        },
        {
          column: 'donates_to_veterans_causes',
          Icon: DonatesToVeteransCauses,
        },
        {
          column: 'donates_to_wildlife_preservation',
          Icon: DonatesToWildlifePreservation,
        },
      ]
        .filter(interest =>
          ['1', 'YES'].includes(
            (
              getRowColumn(filteredColumns, prospect, interest) ?? ''
            ).toUpperCase(),
          ),
        )
        .map(interest =>
          withinColumn ? (
            <Col {...(columnProperties ?? {})}>
              <ToolTip
                title={interest.column
                  .toLowerCase()
                  .replace(/_/g, ' ')
                  .replace(/\b([a-z])/g, a => a.toUpperCase())}
              >
                <Image src={interest.Icon} />
              </ToolTip>
            </Col>
          ) : (
            <ToolTip
              title={interest.column
                .toLowerCase()
                .replace(/_/g, ' ')
                .replace(/\b([a-z])/g, a => a.toUpperCase())}
            >
              <Image src={interest.Icon} />
            </ToolTip>
          ),
        )}
    </>
  );
};

export default IntersetsIcons;
