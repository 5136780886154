import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrganization, PaginationResultFlatten } from 'api/interfaces';
import { aggregateOrganizations } from 'api/organization.service';
import { PipelineStage } from 'mongoose';
import { useInjectReducer } from 'redux-injectors';
import { OrgPaginationSlice } from './types';

export const initialState: OrgPaginationSlice = {
  skip: 0,
  limit: 5,
  pageNumber: 0,
  isLoading: false,
  error: '',
  sort: { Organization_ID: 1 },
  paginationResult: {
    data: [],
    filteredPagination: 0,
    totalPagination: 0,
  },
};

export const fetchOrgPagination = createAsyncThunk(
  'orgPaginationSlice/fetchContent',
  async (aggregateQuery: PipelineStage[] = []) => {
    const response = await aggregateOrganizations(aggregateQuery);
    if (response && response.length) {
      const [firstResponse] = response;
      const paginationResultflatten: PaginationResultFlatten<IOrganization> = {
        data: firstResponse.data,
        filteredPagination:
          firstResponse.filteredPagination.pop()?.totalCount || 0,
        totalPagination: firstResponse.totalPagination.pop()?.totalCount || 0,
      };
      return paginationResultflatten;
    }
    return initialState.paginationResult;
  },
);

const orgPaginationSlice = createSlice({
  name: 'orgPaginationSlice',
  initialState,
  reducers: {
    resetState: state => {
      state.skip = initialState.skip;
      state.limit = initialState.limit;
      state.pageNumber = initialState.pageNumber;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
      state.skip = action.payload * state.limit;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setSort: (state, action: PayloadAction<{ [key: string]: number }>) => {
      state.sort = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchOrgPagination.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrgPagination.fulfilled, (state, action) => {
      state.isLoading = false;
      state.paginationResult = action.payload;
    });
    builder.addCase(fetchOrgPagination.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || '';
    });
  },
});

export const useOrgPaginationSlice = () => {
  useInjectReducer({
    key: orgPaginationSlice.name,
    reducer: orgPaginationSlice.reducer,
  });
  return { actions: orgPaginationSlice.actions, fetchOrgPagination };
};
