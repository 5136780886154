import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import { deepMergeObjects, newFacets } from '../utils';

const OpenModal = ({ facetedSearches, onHide, onOpen, show }) => {
  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Open Faceted Search</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ButtonGroup vertical className="w-100">
          {!!facetedSearches?.[0] &&
            facetedSearches
              .sort((a, b) => a.Name.localeCompare(b.name))
              .map(facetedSearch => (
                <Button
                  variant="light"
                  className="py-3"
                  onClick={() =>
                    onOpen(deepMergeObjects(newFacets(), facetedSearch))
                  }
                >
                  <div className="fs-5 fw-bold text-start">
                    {facetedSearch.Name}
                    {!!facetedSearch.IsTemplate && (
                      <>
                        &nbsp;<Badge>Template</Badge>
                      </>
                    )}
                  </div>
                  <div className="text-start">{facetedSearch.Description}</div>
                </Button>
              ))}
        </ButtonGroup>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="light" onClick={onHide}>
            Cancel
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default OpenModal;
