import React from 'react';
import './styles.css';

export const IdeologyMeter = ({ value = '3' }: { value?: string }) => {
  return (
    <div className="ideology">
      <div className="handle blue"></div>
      <div className={`indicator i${value}`}></div>
      <div className="bar"></div>
      <div className="handle red"></div>
    </div>
  );
};
