import constants from 'app/config/constants';
import axiosInstance from 'utils/api';

export const getProspectSegments = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/prospectSegment/`;

  return await axiosInstance().get(url, { params: body });
};

export const insertProspectSegmentRecords = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/prospectSegment/insertOrUpdateRecords`;

  return await axiosInstance().post(url, body);
};
