import constants from 'app/config/constants';
import axiosInstance from 'utils/api';

export const deleteProspectTrackerOptions = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/prospectTrackerOptions/delete`;

  return await axiosInstance().get(url, { params: body });
};

export const getProspectTrackerOptions = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/prospectTrackerOptions/`;

  return await axiosInstance().get(url, { params: body });
};

export const insertProspectTrackerOptionsRecords = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/prospectTrackerOptions/insertOrUpdateRecords`;

  return await axiosInstance().post(url, body);
};
