import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import EnhancedTableHead from './EnhancedTableHead';
import { ReusableTableProps } from './types';
import { motion } from 'framer-motion';
import { grey } from '@mui/material/colors';

const ReusableTable: FC<ReusableTableProps> = ({
  headers,
  orderBy,
  order,
  rows,
  handleClick,
  paginationProps,
  renderRow,
  handleRequestSort,
  isLoading,
}) => {
  const noResults = !rows.length;

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-labelledby="tableTitle" size={'medium'}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={headers}
          />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell align="center" colSpan={headers.length}>
                  <motion.div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignSelf: 'center',
                      padding: '0px 40px',
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <CircularProgress />
                  </motion.div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {rows.map((row, rowIndex) => (
                  <TableRow
                    hover
                    onClick={event => {
                      handleClick(event, row);
                    }}
                    role="checkbox"
                    tabIndex={-1}
                    // key={row.name}
                    key={`${row.name}-row${rowIndex}`}
                    sx={{ cursor: 'pointer' }}
                  >
                    {headers.map((header, index) => (
                      <TableCell
                        key={`${index}-cell${header.id}`}
                        align={header.align}
                      >
                        {renderRow ? renderRow(row, header.id) : row[header.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            )}
            {noResults && !isLoading && (
              <TableRow>
                <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                  <Typography color={grey[600]}>No records found.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={args =>
          paginationProps.setRowsPerPage(Number(args.target.value))
        }
        component="div"
        rowsPerPage={paginationProps.rowsPerPage}
        count={paginationProps.count}
        page={paginationProps.page}
        onPageChange={paginationProps.handleChangePage}
      />
    </>
  );
};

export default ReusableTable;
