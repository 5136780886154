import React from 'react';
import './styles.css';

export const InsetLabel = ({
  children,
  label,
}: {
  children?: any;
  label?: string;
}) => {
  return (
    <>
      {!!label && <span className="insetLabel">{label}</span>}
      {children}
    </>
  );
};
