// App.tsx
import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PlanViewRow from './PlanViewRow';
import { enumerate } from 'app/components/Prospect-Tracker/utility';
import constants from 'app/config/constants';
import objectDelta from 'utils/objectDelta';

const PlanViewPage = ({
  appEnabled,
  cellTemplateList,
  columnOptionList,
  donors,
  getPageInsertIndex,
  idField,
  options,
  pageHeight,
  pageIndex,
  pagesLoaded,
  pageVisibility,
  range,
  resetRange,
  retrieveTemplatesAndSMTP,
  rowHeight,
  rowVisibility,
  setDonors,
  setFormsMedium,
  setFormsRow,
  setRemoveRow,
  setRemoveRowList,
  setShowFormsModal,
  setShowOptionsModal,
  smtpCredentials,
  smtpFormEnabled,
  templates,
  updateRange,
  userLogged,
}) => {
  const tbodyRef = React.useRef<HTMLTableSectionElement>(null);
  const visibility = pageVisibility[pageIndex];

  return (
    visibility && (
      <tbody
        ref={tbodyRef}
        id={`plan-view-page-${pageIndex}-${!!pagesLoaded[pageIndex]}`}
        data-page={true}
        data-page-index={pageIndex}
        data-page-isloaded={!!pagesLoaded[pageIndex]}
        data-visible={visibility}
        {...(!visibility ? { style: { height: `${pageHeight}px` } } : {})}
      >
        {pagesLoaded[pageIndex] &&
          enumerate(constants.DPT_LOAD_LIMIT()).map(pageRowIndex => {
            const rowIndex = getPageInsertIndex(pageIndex) + pageRowIndex;
            const row = donors?.[rowIndex];

            let groupStartIndex = rowIndex;
            for (let i = rowIndex; i >= 0; i--) {
              if (
                donors?.[i]?.InternalReference_ID === row?.InternalReference_ID
              )
                groupStartIndex = i;
              else break;
            }

            let groupEndIndex = rowIndex;
            for (let i = rowIndex; i < donors?.length; i++) {
              if (
                donors?.[i]?.InternalReference_ID === row?.InternalReference_ID
              )
                groupEndIndex = i;
              else break;
            }

            const uniqueFields = objectDelta(
              row,
              donors.slice(groupStartIndex, rowIndex + 1),
            );

            const sameAsPost = rowIndex !== groupEndIndex;
            const sameAsPre = rowIndex !== groupStartIndex;

            return !!row?.[idField] ? (
              <PlanViewRow
                key={`plan-view-row-${row?.[idField]}`}
                appEnabled={appEnabled}
                cellTemplateList={cellTemplateList}
                columnOptionList={columnOptionList}
                donors={donors}
                idField={idField}
                options={options}
                range={range}
                resetRange={resetRange}
                retrieveTemplatesAndSMTP={retrieveTemplatesAndSMTP}
                row={row}
                rowHeight={rowHeight}
                rowIndex={rowIndex}
                rowIndexLabel={
                  pageIndex * constants.DPT_LOAD_LIMIT() + pageRowIndex
                }
                rowVisibility={rowVisibility}
                sameAsPost={sameAsPost}
                sameAsPre={sameAsPre}
                setDonors={setDonors}
                setFormsMedium={setFormsMedium}
                setFormsRow={setFormsRow}
                setRemoveRow={setRemoveRow}
                setRemoveRowList={setRemoveRowList}
                setShowFormsModal={setShowFormsModal}
                setShowOptionsModal={setShowOptionsModal}
                smtpCredentials={smtpCredentials}
                smtpFormEnabled={smtpFormEnabled}
                templates={templates}
                uniqueFields={uniqueFields}
                updateRange={updateRange}
                userLogged={userLogged}
              />
            ) : null;
          })}
      </tbody>
    )
  );
};

export default PlanViewPage;
