import React, { useRef, useState } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { Hint } from 'react-bootstrap-typeahead';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Papa from 'papaparse';
import { MOADBDropdownCustomers } from 'app/components/MOADBDropdownCustomers';
import { markAsPurchased } from 'api/moadb.service';
import { toast } from 'react-toastify';
import LoadingOverlay from 'app/components/LoadingOverlay';

const UpdloadAndMarkAsPurchsedModal = ({ onHide, show }) => {
  const [customerId, setCustomerId] = useState(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileRef = useRef<any>();

  const onFileSelect = e => {
    setFile(e?.target?.files[0]);
  };

  const onUpload = () => {
    setIsLoading(true);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: results => {
        const dt_ids = results.data.map(row => row['id']);
        markAsPurchased({
          customer_id: customerId,
          dt_ids,
        }).then(response => {
          setIsLoading(false);
          if (!!response?.data?.purchase_record_id) {
            toast.info(`Prospects marked as purchased successfully`);
            setCustomerId(null);
            setFile(null);
            onHide();
          }
        });
      },
    });
  };

  return (
    <>
      <LoadingOverlay show={isLoading} />

      <Modal size="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Upload & Mark As Purchsed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup controlId="formFile">
            <MOADBDropdownCustomers
              className="mb-3"
              value={customerId}
              onChange={e => setCustomerId(e.target.value)}
            />
          </FormGroup>

          <FormGroup controlId="formFile">
            <FormControl
              ref={fileRef}
              type="file"
              onChange={onFileSelect}
              accept=".csv"
            />
            <Hint>Supported extensions include: .csv</Hint>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button variant="light" onClick={onHide}>
              Cancel
            </Button>
            <Button onClick={onUpload} disabled={!customerId || !file}>
              Mark As Licensed
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdloadAndMarkAsPurchsedModal;
