const Elements = {
  Block: [
    {
      name: 'and',
      label: 'And',
      description: 'Block wherein all statements must prove true',
    },
    {
      name: 'not',
      label: 'Not',
      description:
        'Block wherein the reesults of all statements must prove untrue',
    },
    {
      name: 'or',
      label: 'Or',
      description: 'Block wherein at least one statement must prove true',
    },
  ],
  General: [
    {
      name: '=',
      label: '=',
      description: 'Field must match value',
      placeholder: {
        text: "'abc-DEF' (including the single quotes)",
        number: '1234',
      },
    },
    {
      name: '<=',
      label: '<=',
      description: 'Field must be equal to or lesser than value',
      placeholder: {
        text: "'abc-DEF' (including the single quotes)",
        number: '1234',
      },
    },
    {
      name: '>=',
      label: '>=',
      description: 'Field must be equal to or greater than value',
      placeholder: {
        text: "'abc-DEF' (including the single quotes)",
        number: '1234',
      },
    },
    {
      name: 'in',
      label: 'In',
      description: 'Field must match any value listed',
      placeholder: {
        text: "'abc', 'def', 'ghi', '123'",
        number: '123, 456, 789',
      },
      textOnly: true,
    },
    {
      name: 'is null',
      label: 'IS NULL',
      description: 'Field must be null',
      noValue: true,
    },
    {
      name: 'is not null',
      label: 'IS NOT NULL',
      description: 'Field must not be null',
      noValue: true,
    },
  ],
};

const getElementByName: any = name => {
  if (!name) return null;

  let match = null;

  Object.keys(Elements).forEach(section => {
    Elements[section].forEach(element => {
      if (element.name === name) match = element;
    });
  });

  return match;
};

const getIsBlock = name => {
  if (!name) return null;

  let isBlock = false;

  Object.keys(Elements).forEach(section => {
    Elements[section].forEach(element => {
      if (section === 'Block' && element.name === name) isBlock = true;
    });
  });

  return isBlock;
};

export default Elements;

export { getElementByName, getIsBlock };
