import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import {
  selectHasPurchases,
  selectHasIngested,
} from 'app/pages/SearchPage/slice/selectors';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { useSearchSlice } from 'app/pages/SearchPage/slice';
import { getDonorsPurchasedCount } from 'api/donorlist.service';
import { DownloadPurchasedButton } from '../DownloadPurchasedButton';
import { ProspectTrackerButton } from '../ProspectTrackerButton';
import { ProspectImporterButton } from '../ProspectImporterButton';
import { MOADBSearchButton } from '../MOADBSearchButton';
import { P2PMessageQueueButton } from '../P2PMessageQueueButton';
import { ProcessMonitorButton } from '../ProcessMonitorButton';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { List } from 'react-bootstrap-icons';
import { ReportsButton } from '../ReportsButton';
import { ScraperScriptButton } from '../ScraperScriptButton';
import { SettingsButton } from '../SettingsButton';

export const NavBarPurchaseButtons = () => {
  const dispatch = useDispatch();
  const { actions } = useSearchSlice();
  const userLogged = useSelector(getAuthUser);
  const hasPurchases = useSelector(selectHasPurchases);
  const hasIngested = useSelector(selectHasIngested);
  const [showModal, setShowModal] = useState(false);
  const isMobile = useMediaQuery(`( max-width: 1224px )`);

  useEffect(() => {
    if (userLogged?.organizationId) {
      getDonorsPurchasedCount(userLogged!.email).then(result => {
        dispatch(
          actions.setHasPurchasesIngested({
            hasPurchases:
              parseInt((result?.data as any)?.purchasedCount, 10) >= 1,
            hasIngested:
              parseInt((result?.data as any)?.ingestedCount, 10) >= 1,
          }),
        );
      });
    }
  }, [actions, dispatch, userLogged]);

  return (
    <>
      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ListGroup variant="flush">
            {hasPurchases && (
              <ListGroup.Item>
                <DownloadPurchasedButton isMobile={isMobile} />
              </ListGroup.Item>
            )}
            {false && userLogged?.canAccessMOADBQuery && (
              <ListGroup.Item>
                <P2PMessageQueueButton isMobile={isMobile} />
              </ListGroup.Item>
            )}
            {userLogged?.canAccessMOADBQuery && (
              <ListGroup.Item>
                <ProcessMonitorButton isMobile={isMobile} />
              </ListGroup.Item>
            )}
            {userLogged?.canAccessMOADBQuery && (
              <ListGroup.Item>
                <ProspectImporterButton isMobile={isMobile} />
              </ListGroup.Item>
            )}
            <ListGroup.Item>
              {userLogged?.role !== 'admin' &&
                (hasPurchases || hasIngested) && (
                  <ProspectTrackerButton isMobile={isMobile} />
                )}
            </ListGroup.Item>
            <ListGroup.Item>
              {userLogged?.canAccessMOADBQuery && (
                <MOADBSearchButton isMobile={isMobile} />
              )}
            </ListGroup.Item>
            {userLogged?.canAccessMOADBQuery && (
              <ListGroup.Item>
                <ReportsButton isMobile={isMobile} />
              </ListGroup.Item>
            )}
            {userLogged?.canAccessMOADBQuery && (
              <ListGroup.Item>
                <ScraperScriptButton isMobile={isMobile} />
              </ListGroup.Item>
            )}

            <ListGroup.Item>
              <SettingsButton isMobile={isMobile} />
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
      </Modal>

      {isMobile && (
        <Button
          variant="link"
          className="text-light"
          onClick={() => setShowModal(true)}
        >
          <List />
        </Button>
      )}

      {!isMobile && userLogged?.canAccessMOADBQuery && (
        <MOADBSearchButton isMobile={isMobile} />
      )}
      {!isMobile && hasPurchases && (
        <DownloadPurchasedButton isMobile={isMobile} />
      )}
      {!isMobile &&
        userLogged?.role !== 'admin' &&
        (hasPurchases || hasIngested) && (
          <ProspectTrackerButton isMobile={isMobile} />
        )}
      {!isMobile &&
        userLogged?.role !== 'admin' &&
        (hasPurchases || hasIngested) && <ReportsButton isMobile={isMobile} />}
      {!isMobile && userLogged?.canAccessMOADBQuery && (
        <ProspectImporterButton isMobile={isMobile} />
      )}
      {false && !isMobile && userLogged?.canAccessMOADBQuery && (
        <P2PMessageQueueButton isMobile={isMobile} />
      )}
      {!isMobile && userLogged?.canAccessMOADBQuery && (
        <ProcessMonitorButton isMobile={isMobile} />
      )}
      {!isMobile && userLogged?.canAccessMOADBQuery && (
        <ScraperScriptButton isMobile={isMobile} />
      )}
      {!isMobile && <SettingsButton isMobile={isMobile} />}
    </>
  );
};
