import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getSMTPCredentialsList } from 'api/smtp.credentials.service';
import { getAuthUser } from 'store/authentication/authentication.selector';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import HTMLEditor from '../HTMLEditor';
import { useSelector } from 'react-redux';
import GeneralConfirmationModal from '../GeneralConfirmationModal/GeneralConfirmationModal';
import { sendMail } from 'api/mail.service';
import { toast } from 'react-toastify';

const SMTPForm = () => {
  const userLogged = useSelector(getAuthUser);
  const [searchParams] = useSearchParams();
  const [body, setBody] = useState<any>(searchParams.get('html') ?? null);
  const [discard, setDiscard] = useState<boolean>(false);
  const [from, setFrom] = useState<any>(null);
  const [subject, setSubject] = useState<any>(
    searchParams.get('subject') ?? null,
  );
  const [to, setTo] = useState<any>(searchParams.get('to') ?? null);
  const [smtpCredentials, setSMTPCredentials] = React.useState<any[]>([]);

  const getActiveSMTPCredentialIndex = useCallback(
    () =>
      smtpCredentials
        .map((credential, index) => (credential.isActive ? index : null))
        .filter(a => a !== null)?.[0] ?? -1,
    [smtpCredentials],
  );

  const onUpdate = (_, html) => {
    setBody(html);
  };

  const send = async () => {
    await sendMail({
      smtpId: smtpCredentials[from]?._id,
      to,
      subject,
      html: body,
    });

    toast.success('Email sent!', {
      autoClose: 3000,
      onClose: props => {
        window.close();
      },
    });
  };

  useEffect(() => {
    getSMTPCredentialsList({
      email: userLogged!.email,
    }).then(response => {
      setSMTPCredentials(response.data.credentials);
    });
  }, [userLogged]);

  useEffect(() => {
    setFrom(getActiveSMTPCredentialIndex());
  }, [getActiveSMTPCredentialIndex]);

  return (
    <>
      <GeneralConfirmationModal
        show={discard}
        title="Discard"
        message="Discard email?"
        onCancel={() => setDiscard(false)}
        onHide={() => setDiscard(false)}
        onOK={() => window.close()}
      />

      <Container className="mt-3">
        <Form>
          <FloatingLabel label="From" className="mb-3">
            <Form.Select
              size="sm"
              value={from}
              onChange={e => setFrom(parseInt(e.target.value, 10))}
            >
              <option value="">-- Select a credential --</option>
              {smtpCredentials
                .map((credential, index) => (
                  <option value={index}>{credential.email}</option>
                ))
                .filter(a => a)}
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel label="To" className="mb-3">
            <Form.Control
              value={to ?? ''}
              onChange={e => setTo(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel label="Subject" className="mb-3">
            <Form.Control
              value={subject ?? ''}
              onChange={e => setSubject(e.target.value)}
            />
          </FloatingLabel>
          <HTMLEditor defaultContent={body} onUpdate={onUpdate} />
          <div className="mt-3 text-end">
            <ButtonGroup>
              <Button variant="secondary" onClick={() => setDiscard(true)}>
                discard
              </Button>
              <Button onClick={send}>Send</Button>
            </ButtonGroup>
          </div>
        </Form>
      </Container>
    </>
  );
};

export default SMTPForm;
