import React, { useEffect, useState } from 'react';
import { getMOADBJurisdictions, getMOADBStates } from 'api/moadb.service';
import { MultiSelect } from '../MultiSelect';
import { Select } from '../Select';

export const MOADBDropdownCounties = ({
  className = '',
  disabled = false,
  multiSelect = true,
  onChange,
  state = null,
  value = [],
}: {
  className?: string;
  disabled?: boolean;
  multiSelect?: boolean;
  onChange?: any;
  state?: any;
  value?: any[];
}) => {
  const [counties, setCounties] = useState<any[]>([]);

  useEffect(() => {
    getMOADBJurisdictions(state).then(response => setCounties(response.data));
  }, [state]);

  return multiSelect ? (
    <MultiSelect
      className={className}
      disabled={disabled}
      value={value}
      onChange={onChange}
      placeholder="Select a County"
    >
      {(counties ?? []).map(county => (
        <option key={county.jurisdiction_name} value={county.jurisdiction_name}>
          {county.jurisdiction_name}
        </option>
      ))}
    </MultiSelect>
  ) : (
    <Select
      className={className}
      disabled={disabled}
      value={value}
      onChange={onChange}
      placeholder="Select a State"
    >
      {(counties ?? []).map(county => (
        <option key={county.jurisdiction_name} value={county.jurisdiction_name}>
          {county.jurisdiction_name}
        </option>
      ))}
    </Select>
  );
};
