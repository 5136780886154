import { Box, Container, Stack } from '@mui/system';
import Footer from 'app/components/Footer';
import LeftSidebar from 'app/components/LeftSidebar';
import { MenuTab } from 'app/components/LeftSidebar/types';
import NavBar from 'app/components/NavBar';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import AdminOrgs from '../AdminOrgs';
import { useSearchSlice } from '../SearchPage/slice';
import { selectActiveTab } from '../SearchPage/slice/selectors';
import UsersPage from '../UsersPage';
import { ADMIN_ROUTES } from './adminRoutes';

const menuTabs: MenuTab[] = [
  {
    label: 'Organizations',
    logo: '',
    url: ADMIN_ROUTES.ORGS_ROUTE,
  },
  {
    label: 'Users',
    logo: '',
    url: `${ADMIN_ROUTES.USERS_ROUTE}?organizationId=""`,
  },
];
const AdminHome = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { actions } = useSearchSlice();
  const navigate = useNavigate();
  const activeTab = useSelector(selectActiveTab);

  useEffect(() => {
    switch (location.pathname) {
      case ADMIN_ROUTES.USERS_ROUTE:
        dispatch(actions.setActiveTab('Users'));
        break;
      case ADMIN_ROUTES.ORGS_ROUTE:
        dispatch(actions.setActiveTab('Organizations'));
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const element = useRoutes([
    {
      path: ADMIN_ROUTES.ORGS_ROUTE,
      element: <AdminOrgs />,
    },
    {
      path: ADMIN_ROUTES.USERS_ROUTE,
      element: <UsersPage />,
    },
    {
      path: '*',
      //Temporarily redirecting to AdminOrgs. So that the user doesn't see a 404
      element: <AdminOrgs />, //<NotFoundPage />,
    },
  ]);
  if (!element) return null;

  const handleSetActive = (tab: MenuTab) => {
    dispatch(actions.setActiveTab(tab.label));
    console.log('tab', tab);
    const urlTab = tab.url;
    console.log('urlTab', urlTab);
    if (urlTab) {
      navigate(urlTab);
    }
  };

  return (
    <Stack justifyContent="space-between" alignItems="center" height="100vh">
      <NavBar />
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: '48px',
          paddingBottom: '60px',
          overflowX: 'scroll',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 5.5fr',
            gridColumnGap: '40px',
            alignItems: 'start',
            gridRowGap: '24px',
            gridTemplateRows: 'auto',
          }}
        >
          <LeftSidebar
            activeTab={activeTab}
            setActiveTab={handleSetActive}
            menuTabs={menuTabs}
          />
          <Box
            sx={{
              maxWidth: '940px',
              display: 'block',
            }}
          >
            <AnimatePresence mode="wait">
              {React.cloneElement(element, { key: location.pathname })}
            </AnimatePresence>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Stack>
  );
};

export default AdminHome;
