import { cloneDeep } from 'lodash';

export const maskObject = (object, mask) => {
  const maskedObject = cloneDeep(object);
  const objectKeys = Object.keys(object);
  const maskKeys = Object.keys(mask);

  objectKeys.forEach(key => {
    if (!maskKeys.includes(key)) delete maskedObject[key];
    else if (
      typeof maskedObject[key] === 'object' &&
      !Array.isArray(maskedObject[key])
    )
      maskedObject[key] = maskObject(maskedObject[key], mask[key]);
  });

  return maskedObject;
};
