import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import SidebarButton from '../SidebarButton';
import { styled } from '@mui/material/styles';
import pricing_card_logo from '../../assets/pricing-card-icon-dashflow-webflow-template.png';
import feature_icon from '../../assets/feature-icon-left-dashflow-webflow-template.svg';
import button_arrow_icon from '../../assets/primary-button-icon-right-dashflow-webflow-template.svg';
import { LeftSidebarProps } from './types';

export const CustomButton = styled(Button)({
  padding: '8px 16px',
  border: '1px solid #36e961',
  borderRadius: '76px',
  backgroundImage: 'linear-gradient(#36e961, #64ee85)',
  height: '40px',
  color: '#fff',
  fontSize: '14px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 500,
  lineHeight: '1.286em',
  textAlign: 'center',
  boxShadow: '0 1px 4px rgba(74, 58, 255, .26)',
  textTransform: 'none',
  transition: 'all .3s ease',
  '&:hover': {
    boxShadow: '0px 0px 4px #3898ec',
    transform: 'scale(1.015)',
  },
});

const SubscriptionFeature = ({ feature }) => (
  <Box
    sx={{
      display: 'flex',
      gridColumnGap: '6px',
      alignItems: 'flex-start',
    }}
  >
    <img
      src={feature_icon}
      style={{
        position: 'relative',
        top: '2px',
      }}
      alt="feature-icon"
    />
    <Typography
      sx={{
        color: '#454e66',
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '1.286em',
        '&:hover': {
          cursor: 'pointer',
          color: '#36e961',
        },
      }}
    >
      {feature}
    </Typography>
  </Box>
);

export const SubscriptionBanner = () => {
  const features = [
    'Look up individual donor information',
    'Upload your own list',
    'Search for donors matching specific criteria',
    'Save and favorite your top donor prospects',
    'Find the top donors in your area',
  ];
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(39, 189, 178, .05)',
        padding: '24px 18px 28px',
        display: 'block',
        border: '1px solid #36e961',
        borderRadius: '12px',
        boxShadow: '0 2px 7px rgba(20, 20, 43, .09)',
        minWidth: '177.781px',
      }}
    >
      <Box
        sx={{
          flexDirection: 'column-reverse',
          display: 'flex',
          gap: '16px',
          '@media (max-width: 992px)': {
            display: `flex`,
            flexDirection: `row`,
            gap: '16px',
            alignItems: 'flex-end',
          },
        }}
      >
        <Box>
          <Typography
            sx={{
              color: '#454e66',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '22px',
              '&:hover': {
                cursor: 'pointer',
                color: '#36e961',
              },
            }}
          >
            Upgrade
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              gap: '4px',
            }}
          >
            <Typography
              sx={{
                backgroundImage: 'linear-gradient(135deg, #36e961, #08b331)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                fontFamily: 'Inter, sans-serif',
                fontSize: '26px',
                fontWeight: 700,
                lineHeight: '1.133em',
              }}
            >
              $99
            </Typography>
            <Typography
              sx={{
                color: '#828aa3',
                fontFamily: 'Inter, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '18px',
                '&:hover': {
                  cursor: 'pointer',
                  color: '#36e961',
                },
                position: 'relative',
                bottom: '3px',
              }}
            >
              /month
            </Typography>
          </Box>
        </Box>
        <Box>
          <img
            src={pricing_card_logo}
            style={{
              maxWidth: '40px',
              filter: 'hue-rotate(270deg)',
              borderStyle: 'none',
              borderColor: '#36e961',
              borderRadius: '8px',
              boxShadow: '0 1px 2px #ffbec2',
            }}
            alt="princing-card-logo"
          />
        </Box>
      </Box>
      <hr
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          color: '#e6e9f1',
          minHeight: '1.5px',
          opacity: 0.2,
        }}
      />
      <Typography
        sx={{
          color: '#828aa3',
          fontFamily: 'Inter, sans-serif',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '22px',
          marginBottom: '20px',
        }}
      >
        Upgrade today to get total access to DRE and start accessing our entire
        suit of features
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridRowGap: '12px',
          gridTemplateRows: 'auto',
          gridTemplateColumns: '1fr',
          gridAutoColumns: '1fr',
          marginBottom: '24px',
        }}
      >
        {features.map((feature, index) => (
          <SubscriptionFeature feature={feature} key={index} />
        ))}
      </Box>
      <CustomButton>
        Get started&nbsp;
        <img src={button_arrow_icon} alt="button-arrow-icon" />
      </CustomButton>
    </Box>
  );
};

export default function LeftSidebar({
  title,
  activeTab,
  menuTabs,
  setActiveTab,
}: LeftSidebarProps) {
  return (
    <Box
      sx={{
        // bgcolor: '#cfe8fc',
        // height: '100vh',
        top: '24px',
        position: 'sticky',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '1.333em',
          letterSpacing: '0.09em',
          marginBottom: '18px',
          marginLeft: '10px',
          fontFamily: 'Inter, sans-serif',
          color: 'rgb(130, 138, 163)',
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          gridTemplateRows: 'auto',
          gridTemplateColumns: '1fr',
          gridAutoColumns: '1fr',
          display: 'grid',
          gridRowGap: '4px',
          marginBottom: '48px',
        }}
      >
        {menuTabs.map(menuTab => (
          <SidebarButton
            key={menuTab.label}
            menuTab={menuTab}
            active={activeTab}
            setActive={setActiveTab}
          />
        ))}
      </Box>
      {/* <SubscriptionBanner /> */}
    </Box>
  );
}
