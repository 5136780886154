import ToolTip from 'app/components/ToolTip';
import React, { useRef, useState } from 'react';
import {
  ChatLeftDots,
  CheckCircleFill,
  EnvelopeAt,
  ExclamationCircleFill,
  InfoCircleFill,
  XCircle,
} from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { getActiveSMTPCredentialsID, getSMSURL } from '../../utility';
import { insertP2PMessageQueueRecords } from 'api/p2p.message.queue.service';
import { toast } from 'react-toastify';
import 'draft-js/dist/Draft.css';
import HTMLEditor from 'app/components/HTMLEditor';

const HorizontalMarkettingModal = ({
  appEnabled,
  onHide,
  row,
  show,
  smtpCredentials,
  smtpFormEnabled,
  userLogged,
}) => {
  const [messageHTML, setMessageHTML] = useState<any>(null);
  const [messageText, setMessageText] = useState<any>(null);
  const [sms, setSMS] = useState<any>(null);
  const editorRef = useRef<any>(null);

  const cancel = () => {
    setMessageHTML(null);
    setMessageText(null);
    onHide();
  };

  const focusEditor = () => editorRef?.current?.focus();

  const onUpdate = (text, html) => {
    setMessageText(text);
    setMessageHTML(html);
    setSMS(text.match(/(.{1,150}\s)/)?.[0]);
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Send Personalized SMS/Email</Modal.Title>
      </Modal.Header>
      <Modal.Body onClick={focusEditor}>
        <HTMLEditor
          onUpdate={onUpdate}
          height={250}
          hint={
            <Form.Text className="mt-3">
              <InfoCircleFill /> <b>SMS/Email Subject:</b> {sms}
            </Form.Text>
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="light" onClick={cancel}>
            Cancel
          </Button>
          <ToolTip
            title={
              appEnabled
                ? 'Add SMS to P2P queue'
                : 'Open app associated with SMS'
            }
          >
            <Button
              disabled={!row?.CellPhone_Full || !sms}
              variant={appEnabled ? 'primary' : 'dark'}
              className="fs-5"
              href={appEnabled ? '#' : getSMSURL(row?.CellPhone_Full, sms)}
              target={'_blank'}
              onClick={
                !appEnabled
                  ? () => {}
                  : async e => {
                      e.preventDefault();

                      await insertP2PMessageQueueRecords({
                        type: 'sms',
                        email: userLogged?.email,
                        message: sms,
                        prospectId: row?._id,
                        recipient: row?.CellPhone_Full,
                      });

                      toast.info('SMS Queued');
                    }
              }
            >
              {row?.CellPhone_Full ? <ChatLeftDots /> : <XCircle />}
              {['Replied', 'Sent'].includes(
                row?.prospectTracker?.textResult,
              ) && <CheckCircleFill className="buttonIcon green" />}
              {['Do Not Text'].includes(row?.prospectTracker?.textResult) && (
                <ExclamationCircleFill className="buttonIcon orange" />
              )}
            </Button>
          </ToolTip>
          <ToolTip
            title={
              appEnabled
                ? 'Add email to P2P queue'
                : 'Open app associated with email'
            }
          >
            <Button
              disabled={!row?.Email || !messageText}
              variant={appEnabled ? 'primary' : 'dark'}
              className="fs-5"
              href={
                appEnabled
                  ? '#'
                  : smtpFormEnabled
                  ? `/smtp-form?from=${encodeURIComponent(
                      getActiveSMTPCredentialsID(smtpCredentials),
                    )}&to=${encodeURIComponent(
                      row?.Email,
                    )}&subject=${encodeURIComponent(
                      sms,
                    )}&html=${encodeURIComponent(
                      messageHTML,
                    )}&text=${encodeURIComponent(messageText)}`
                  : `mailto:${row?.Email}?subject=${encodeURIComponent(
                      sms,
                    )}&body=${encodeURIComponent(messageText)}`
              }
              target="_blank"
              onClick={
                !appEnabled
                  ? () => {}
                  : async e => {
                      e.preventDefault();

                      insertP2PMessageQueueRecords({
                        type: 'email',
                        email: userLogged?.email,
                        messageHTML: messageHTML,
                        messageText: messageText,
                        prospectId: row?._id,
                        recipient: row?.Email,
                        smtpCredentials_ID:
                          getActiveSMTPCredentialsID(smtpCredentials),
                        subject: sms,
                      });

                      toast.info('Email Queued');
                    }
              }
            >
              {row?.Email ? <EnvelopeAt /> : <XCircle />}
              {['Opened', 'Replied', 'Sent'].includes(
                row?.prospectTracker?.emailResult,
              ) && <CheckCircleFill className="buttonIcon green" />}
              {['Bounced', 'Do Not Email'].includes(
                row?.prospectTracker?.emailResult,
              ) && <ExclamationCircleFill className="buttonIcon orange" />}
            </Button>
          </ToolTip>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default HorizontalMarkettingModal;
