import constants from 'app/config/constants';
import { PipelineStage } from 'mongoose';
import axiosInstance from 'utils/api';
import { IUser, PaginationResult } from './interfaces';

export const aggregateUsers = async (aggregateQuery: PipelineStage[] = []) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/collections/users/aggregate`;
  return await axiosInstance()
    .post<PaginationResult<IUser>[]>(url, {
      aggregateQuery,
    })
    .then(response => response.data);
};

export const updateUser = async (data: Partial<IUser>, query) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/collections/users`;
  return await axiosInstance()
    .put<Partial<IUser>>(url, { data, query })
    .then(response => response.data);
};

export const getUsersByCondition = async (query, sessionToken = null) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/collections/users/filter`;
  return await axiosInstance(sessionToken)
    .post<IUser[]>(url, { query })
    .then(response => response.data);
};
