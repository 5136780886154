import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { searchSliceSaga } from './saga';
import {
  SearchSliceState,
  SingleSearchRequest,
  SingleSearchQuery,
  SearchData,
  BulkSearchRequest,
  PurchasedDonorsRequest,
} from './types';
import {
  bulkSearchPostRequest,
  singleSearchPostRequest,
  purchasedDonorsPostRequest,
} from './api';

export const initialState: SearchSliceState = {
  searchType: '',
  error: null,
  loading: false,
  searchData: [],
  searchCount: 0,
  selectedDonor: undefined,
  activeTab: 'Home',
  recentSingleSearchQuery: {},
  filterQuery: {},
  currentPage: 1,
  rowsPerPage: 10,
  cart: [],
  sorting: [],
  showPurchased: false,
  loadingPurchased: false,
  purchasedRecords: null,
  hasPurchases: false,
  hasIngested: false,
};

export const singleSearchDataAsync = createAsyncThunk(
  'data/singleSearchData',
  async (payload: SingleSearchRequest) => {
    const response = await singleSearchPostRequest(payload);
    return response?.data;
  },
);

export const bulkSearchDataAsync = createAsyncThunk(
  'data/bulkSearchData',
  async (payload: BulkSearchRequest) => {
    const response = await bulkSearchPostRequest(payload);
    return (response?.data || []) as SearchData[];
  },
);

export const purchasedDonorsDataAsync = createAsyncThunk(
  'data/purchasedDonorsData',
  async (payload: PurchasedDonorsRequest) => {
    const response = await purchasedDonorsPostRequest(payload);
    return response?.data;
  },
);

const slice = createSlice({
  name: 'searchSlice',
  initialState,
  reducers: {
    setSearchType(state, action: PayloadAction<string>) {
      state.searchType = action.payload;
    },
    setSelectedDonor(state, action: PayloadAction<SearchData>) {
      state.selectedDonor = action.payload;
    },
    setActiveTab(state, action: PayloadAction<string>) {
      state.activeTab = action.payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<number>) {
      state.rowsPerPage = action.payload;
    },
    setRecentSingleSearchQuery(
      state,
      action: PayloadAction<SingleSearchQuery>,
    ) {
      state.recentSingleSearchQuery = action.payload;
    },
    setFilterQuery(state, action: PayloadAction<SingleSearchQuery | null>) {
      state.filterQuery = action.payload;
    },
    resetSearchSlice(state, action) {
      state.activeTab = initialState.activeTab;
      state.error = initialState.error;
      state.loading = initialState.loading;
      state.recentSingleSearchQuery = initialState.recentSingleSearchQuery;
      state.searchData = initialState.searchData;
      state.searchType = initialState.searchType;
      state.selectedDonor = initialState.selectedDonor;
      state.cart = initialState.cart;
    },
    addToCart(state, action: PayloadAction<SearchData>) {
      state.cart.push(action.payload);
    },
    removeFromCart(state, action: PayloadAction<SearchData>) {
      state.cart = state.cart.filter(
        cartObj => cartObj._id !== action?.payload?._id,
      );
    },
    removeRowsFromCart(state, action: PayloadAction<SearchData[]>) {
      state.cart = state.cart.reduce((updatedCart: Array<SearchData>, item) => {
        if (
          !action?.payload?.some(newCartItem => newCartItem._id === item._id)
        ) {
          updatedCart.push(item);
        }
        return updatedCart;
      }, []);
    },
    emptyCart(state) {
      state.cart = initialState.cart;
    },
    addRowsToCart(state, action: PayloadAction<SearchData[]>) {
      const uniqueDonors = new Set(state.cart.map(item => item._id));
      state.cart = action?.payload?.reduce(
        (updatedCart, item) => {
          if (!uniqueDonors.has(item._id)) {
            updatedCart.push(item);
            uniqueDonors.add(item._id);
          }
          return updatedCart;
        },
        [...state.cart],
      );
    },
    updateCart(state, action: PayloadAction<SearchData[]>) {
      state.cart = action.payload;
    },
    setSorting(state, action: PayloadAction<[]>) {
      state.sorting = action.payload;
    },
    setShowPurchased(state, action: PayloadAction<boolean>) {
      state.showPurchased = action.payload;
    },
    setPurchasedRecords(state, action: PayloadAction<any>) {
      state.loadingPurchased = false;
      state.purchasedRecords = action.payload;
    },
    setHasPurchases(state, action: PayloadAction<boolean>) {
      state.hasPurchases = action.payload;
    },
    setHasPurchasesIngested(state, action: PayloadAction<any>) {
      state.hasPurchases = action?.payload?.hasPurchases;
      state.hasIngested = action?.payload?.hasIngested;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(singleSearchDataAsync.pending, state => {
        state.loading = true;
        state.error = null;
        state.searchData = [];
      })
      .addCase(singleSearchDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.searchData = action?.payload?.donors;
        state.searchCount = action?.payload?.count;
      })
      .addCase(singleSearchDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'An error occurred';
        state.searchData = [];
      })
      .addCase(bulkSearchDataAsync.pending, state => {
        state.loading = true;
        state.error = null;
        state.searchData = [];
      })
      .addCase(bulkSearchDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.searchData = action.payload;
      })
      .addCase(bulkSearchDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'An error occurred';
        state.searchData = [];
      })
      .addCase(purchasedDonorsDataAsync.pending, state => {
        state.loadingPurchased = true;
        state.error = null;
        state.purchasedRecords = [];
      })
      .addCase(purchasedDonorsDataAsync.fulfilled, (state, action) => {
        state.loadingPurchased = false;
        state.error = null;
        state.purchasedRecords = action.payload?.donors;
      })
      .addCase(purchasedDonorsDataAsync.rejected, (state, action) => {
        state.loadingPurchased = false;
        state.error = action.error.message ?? 'An error occurred';
        state.purchasedRecords = [];
      });
  },
});

export const { actions: searchSliceActions } = slice;

export const useSearchSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: searchSliceSaga });
  return { actions: slice.actions };
};

export const { setHasPurchases, addToCart, removeFromCart } = slice.actions;
export const searchSliceReducer = slice.reducer;
/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSearchSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
