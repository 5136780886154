import LoginPage from 'app/pages/LoginPage';
import ResetPasswordPage from 'app/pages/ResetPasswordPage';
import SignUpPage from 'app/pages/SignUpPage';
import { LandingPage } from 'app/pages/LandingPage/Loadable';
import { useSearchParams } from 'react-router-dom';

import React, { Navigate, Route, Routes, Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import googleSignIn from 'app/helpers/google/googleSignIn';
import { useDispatch } from 'react-redux';
import { useAuthSliceSlice } from 'store/authentication/authentication';
import { useCookies } from 'react-cookie';

const AuthenticationRouter = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const dispatch = useDispatch();
  const { actions } = useAuthSliceSlice();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  useEffect(() => {
    if (!!code)
      googleSignIn({
        data: { code },
        dispatch,
        actions,
        onSuccess: response => {
          setCookie('user', {
            email: response.email,
            expiration: response.expiration,
            sessionToken: response.sessionToken,
            verify: response.verify,
          });
        },
      });
  }, [actions, code, dispatch, setCookie]);

  return (
    <Routes>
      <Route path="/">
        <Route element={<Outlet />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/reset" element={<ResetPasswordPage />} />
          <Route index element={<LandingPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AuthenticationRouter;
