import { standardWealthFetchButton, standardWealthFetchForm } from './utility';

const source = {
  realtor: standardWealthFetchButton({
    page1: 'https://www.realtor.com',
    page2: 'https://www.realtor.com/realestateandhomes-detail',
    click:
      '/html/body/div[2]/div/div[1]/div[3]/div[1]/div/div[1]/div[2]/div/div/div/div/header/div/button[1]',
    button:
      '/html/body/div[6]/div/div/div/div[1]/div/div[2]/div/div/div/button[2]',
    input: '/html/body/div[6]/div/div/div/div[1]/div/div[2]/div/div/input',
    value: [
      '/html/body/div[1]/div/div[1]/div[6]/div[4]/div/div[2]/div[4]/div[1]/div[1]/div[1]/div',
      '/html/body/div[1]/div/div[1]/div[6]/div[3]/div[1]/div[5]/div[1]/ul/li[3]/div/div[2]',
    ],
  }),
  /*redfin: standardWealthFetch({
        page1: 'https://www.redfin.com',
        page2: /https:\/\/www.redfin.com.+\/home/g,
        form: '/html/body/div[1]/div[6]/div[2]/div/section/div/div/div/div/div/div/div/div[2]/div/div/form',
        input:
          '/html/body/div[1]/div[6]/div[2]/div/section/div/div/div/div/div/div/div/div[2]/div/div/form/div[1]/div/input',
        value:
          '/html/body/div[1]/div[12]/div[2]/div[1]/div/div/div/div[1]/div[2]/div/div/div/div/div[1]/div',
      }),*/
  /*zillow: standardWealthFetchForm({
    page1: 'https://www.zillow.com',
    page2: 'https://www.zillow.com/homes',
    form: '/html/body/div[1]/div[2]/div[1]/div[1]/div/div[2]/div/div/div/div/form',
    input:
      '/html/body/div[1]/div[2]/div[1]/div[1]/div/div[2]/div/div/div/div/form/div/div/input',
    value:
      '/html/body/div[1]/div/div[3]/div/div[1]/div[2]/div[2]/div[1]/div/div[1]/span/span[2]/span/span',
  }),*/

  /*zillow: {
    vars: {
      form: '/html/body/div[1]/div[2]/div[1]/div[1]/div/div[2]/div/div/div/div/form',
      input:
        '/html/body/div[1]/div[2]/div[1]/div[1]/div/div[2]/div/div/div/div/form/div/div/input',
      value:
        '/html/body/div[1]/div/div[3]/div/div[1]/div[2]/div[2]/div[1]/div/div[1]/span/span[2]/span/span',
    },
    steps: [
      loadPage('https://www.zillow.com'),
      confirmPageLoaded('https://www.zillow.com'),
      updateInputWithField('input', 'fullAddress'),
      submitForm('form'),
      confirmPageLoaded('https://www.zillow.com/homes'),
      getInnerText('value'),
    ],
  },*/
};

export default source;
