import React, { useState } from 'react';
import {
  CaretLeftFill,
  CaretRightFill,
  Gear,
  Plus,
} from 'react-bootstrap-icons';
import Accordion from 'react-bootstrap/Accordion';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import './styles.css';
import { useMediaQuery } from '@mui/material';

const OptionsCanvas = ({
  cellTemplateList,
  columnOptionList,
  loadSegment,
  saveOptions,
  segments,
  setColumnOptionList,
  setShowCustomFieldModal,
  setShowNewSegmentModal,
  setRenameSegment,
  setUpdateSegment,
}) => {
  const isMobile = useMediaQuery(`( max-width: 1224px )`);
  const [show, setShow] = useState(false);

  const toggleColumn = columnOptions => {
    columnOptions.isVisible = !(columnOptions?.isVisible ?? false);
    setColumnOptionList([...columnOptionList]);
    saveOptions(columnOptionList);
  };

  return (
    <div
      className="optionsCanvas"
      style={{
        height: 'auto',
        minHeight: '100vh',
        overflow: show ? 'auto' : 'hidden',
        position: 'fixed',
        right: '0px',
        top: '0px',
        transition: 'width 1s',
        width: show ? (isMobile ? '100vw' : '25vw') : '40px',
        zIndex: '2000',
      }}
    >
      <Button
        onClick={() => setShow(!show)}
        style={{
          height: '40px',
          position: 'absolute',
          top: 'calc(50vh - 100px)',
          width: '40px',
        }}
      >
        {!show && <CaretLeftFill />}
        {show && <CaretRightFill />}
      </Button>
      <div
        style={{
          backgroundColor: 'white',
          height: 'auto',
          minHeight: '100vh',
          padding: '1rem',
          position: 'absolute',
          left: '40px',
          textAlign: 'end',
          top: '0px',
          width: `calc(${isMobile ? '100vw' : '25vw'} - 40px)`,
        }}
      >
        <Accordion flush defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Segments</Accordion.Header>
            <Accordion.Body>
              <div>
                <Button onClick={() => setShowNewSegmentModal(true)}>
                  <Plus />
                </Button>
              </div>

              {segments.map(segment => (
                <Dropdown className="mt-3 w-100" as={ButtonGroup} align="end">
                  <Button
                    variant="dark"
                    className="w-100"
                    onClick={() => loadSegment(segment)}
                  >
                    {segment.Name}
                  </Button>

                  <Dropdown.Toggle variant="dark" split />

                  <Dropdown.Menu className="w-100">
                    <Dropdown.Item
                      as={Button}
                      onClick={() => setUpdateSegment(segment)}
                    >
                      Update
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Button}
                      onClick={() => setRenameSegment(segment)}
                    >
                      Rename
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ))}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Columns</Accordion.Header>
            <Accordion.Body>
              <Button
                variant="link"
                className="text-decoration-none text-end w-100"
                onClick={() => setShowCustomFieldModal(true)}
              >
                <Gear /> Manage Custom Fields
              </Button>

              {columnOptionList.map(columnOptions => {
                const cellTemplate = cellTemplateList.filter(
                  a => a.field === columnOptions.field,
                )?.[0];

                return (
                  cellTemplate?.showInDesktop && (
                    <Button
                      className="w-100 mt-1"
                      variant={columnOptions?.isVisible ? 'primary' : 'light'}
                      onClick={() => toggleColumn(columnOptions)}
                    >
                      {cellTemplate.header}
                    </Button>
                  )
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default OptionsCanvas;
