import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  getMOADBQueries,
  generateSQL,
  importMOABQueryResults,
  insertMOADBQueryRecords,
  queryMOADB,
} from 'api/moadb.service';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import sqlLanguage from 'react-syntax-highlighter/dist/esm/languages/prism/sql';
import dark from 'react-syntax-highlighter/dist/esm/styles/prism/atom-dark';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import './styles.css';
import { SQLElement } from './SQLElement';
import { v4 as uuidv4 } from 'uuid';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { useSelector } from 'react-redux';
import {
  ArrowDownSquare,
  ArrowDownSquareFill,
  ArrowLeftRight,
  ArrowUpSquare,
  InfoSquare,
  PlayCircle,
  PlusCircle,
  Square,
} from 'react-bootstrap-icons';
import ToolTip from '../ToolTip';
import SaveModal from './SaveModal';
import OpenModal from './OpenModal';
import { toast } from 'react-toastify';
import AddModal from './AddModal';
import Fields from './Fields';
import FileDetailsModal from './FileDetailsModal';
import NewModal from './NewModal';
import { Alert, InputGroup } from 'react-bootstrap';
import Elements, { getIsBlock } from './Elements';
import ReplaceModal from './ReplaceModal';
import ImportModal from './ImportModal';
import { getLists } from 'api/prospect.list.service';

export const MOADBQuery = ({ setUseQuery }) => {
  const userLogged = useSelector(getAuthUser);
  const [activeList, setActiveList] = useState<any>(null);
  const [activeReplaceSection, setActiveReplaceSection] = useState<string>('');
  const [activeSQLElementId, setActiveSQLElementId] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<string>('Query');
  const [addWithin, setAddWithin] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [list, setList] = useState<any>([]);
  const [limit, setLimit] = useState<any>(1000);
  const [moadbQueries, setMOADBQueries] = useState<any>([]);
  const [query, setQuery] = useState<any>({});
  const [replaceElementId, setReplaceElementId] = useState<any>(null);
  const [results, setResults] = useState<any>(null);
  const [resultsAreLoading, setResultsAreLoading] = useState<boolean>(false);
  const resultsAreLoadingRef = useRef<boolean>(false);
  const [resultsLoadingEndTime, setResultsLoadingEndTime] = useState<any>(null);
  const [resultsLoadingStartTime, setResultsLoadingStartTime] =
    useState<any>(null);
  const [savingAs, setSavingAs] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showAllResults, setShowAllResults] = useState<boolean>(false);
  const [showFileDetailsModal, setShowFileDetailsModal] =
    useState<boolean>(false);
  const [showImportModal, setShowImportModal] = useState<boolean>(false);
  const [showNewModal, setShowNewModal] = useState<boolean>(false);
  const [showOpenModel, setShowOpenModal] = useState<boolean>(false);
  const [showReplaceModal, setShowReplaceModal] = useState<boolean>(false);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [sql, setSQL] = useState<string>('');
  const [sqlElements, setSQLElements] = useState<any>([]);
  const listGroupRef = useRef<any>(null);

  SyntaxHighlighter.registerLanguage('sql', sqlLanguage);

  const compileSQLElements = () => {
    const result = [...sqlElements.map(element => ({ ...element }))];
    const children: any[] = [...result];

    while (children.length) {
      const nextChild = children.pop();
      if (!!nextChild.children) nextChild.childen = [...nextChild.children];

      if (nextChild.type === 'ref') {
        nextChild.children = [
          ...moadbQueries.queries.filter(
            query => query._id === nextChild.referenceId,
          )?.[0]?.Query,
        ];
      }

      if (!!nextChild.children) children.push(...nextChild.children);
    }

    return result;
  };

  const copySQLToClipboard = () => {
    navigator.clipboard.writeText(sql);
    toast.info('SQL copied to clipboard');
  };

  const createSQLElement = (type, referenceId: any = null) => {
    const newElement = {
      id: uuidv4(),
      type,
      referenceId,
    };

    if (!activeSQLElementId) setSQLElements([...sqlElements, newElement]);
    else {
      if (addWithin) {
        const activeSQLElement = findSQLElementById(activeSQLElementId);
        activeSQLElement.children = [
          ...(activeSQLElement.children || []),
          newElement,
        ];
      } else {
        const parent: any = findSQLElementParentById(activeSQLElementId);

        if (!!parent)
          parent.children = [...(parent.children || []), newElement];
        else sqlElements.push(newElement);
      }

      setSQLElements([...sqlElements]);
    }
  };

  const deleteActiveSQLElement = () => {
    const children: any[] = [
      ...sqlElements.map(element => ({ ...element, array: sqlElements })),
    ];
    const childrenDictionary = {};

    while (children.length) {
      const nextChild = children.pop();

      childrenDictionary[nextChild.id] = nextChild;

      if (!!nextChild.children)
        children.push(
          ...nextChild.children.map(element => ({
            ...element,
            array: nextChild.children,
          })),
        );
    }

    const activeSQLElement = findSQLElementById(activeSQLElementId);
    const array = childrenDictionary[activeSQLElementId].array;

    array.splice(array.indexOf(activeSQLElement), 1);

    setSQLElements([...sqlElements]);
  };

  const executeQuery = () => {
    if (!resultsAreLoading) {
      if (!validateFields())
        toast.error('Please ensure all statements have an assigned field');
      else {
        setResults(null);
        setError(null);
        setShowAllResults(false);

        const compiledSQLElements = compileSQLElements();

        generateSQL({
          sqlElements: compiledSQLElements,
          limit,
        }).then(response => {
          setSQL(response.data);
          setActiveTab('Results');
          resultsAreLoadingRef.current = true;
          setResultsAreLoading(true);
          setResultsLoadingStartTime(Date.now());
          updateEndTime();

          queryMOADB(
            {
              sqlElements: compiledSQLElements,
              limit,
            },
            response => {
              const error = response?.[0]?.__error;

              if (!!error)
                setError(`MOADB Returned Error: ${decodeURIComponent(error)}`);
              else {
                setResults(response);
                setError(null);
              }

              setResultsAreLoading(false);
              resultsAreLoadingRef.current = false;
            },
          );
        });
      }
    }
  };

  const findSQLElementById = id => {
    const children: any[] = [...sqlElements];
    const childrenDictionary = {};

    while (children.length) {
      const nextChild = children.pop();

      childrenDictionary[nextChild.id] = nextChild;

      if (!!nextChild.children) children.push(...nextChild.children);
    }

    return childrenDictionary[id];
  };

  const findSQLElementParentById = id => {
    const children: any[] = [...sqlElements];

    while (children.length) {
      const nextChild = children.pop();

      if (!!nextChild.children) {
        nextChild.children.forEach(element => {
          if (element.id === id) return nextChild;
        });

        children.push(...nextChild.children);
      }
    }

    return null;
  };

  const generateCSV = () => {
    const lines = [
      `"${Object.keys(Fields)
        .map(section => Fields[section].map(column => column.name))
        .reduce((p, c) => [...p, ...c], [])
        .join('","')}"\r\n`,
      ...results.map(
        row =>
          `"${Object.keys(Fields)
            .map(section => Fields[section].map(column => row[column.name]))
            .reduce((p, c) => [...p, ...c], [])
            .join('","')}"\r\n`,
      ),
    ];

    const blob = new Blob(lines, { type: 'text/csv;charset=utf-8' });
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL, '_blank');
  };

  const importQueryResults = () => {
    if (!resultsAreLoading) {
      if (!validateFields())
        toast.error('Please ensure all statements have an assigned field');
      else {
        const compiledSQLElements = compileSQLElements();

        resultsAreLoadingRef.current = true;
        setResultsAreLoading(true);
        setResultsLoadingStartTime(Date.now());
        updateEndTime();

        importMOABQueryResults(
          {
            email: userLogged?.email,
            limit,
            list: activeList,
            sqlElements: compiledSQLElements,
          },
          () => {
            toast.info('Import Successful!');
            setResultsAreLoading(false);
            resultsAreLoadingRef.current = false;
          },
        );
      }
    }
  };

  const loadQueries = useCallback(() => {
    getMOADBQueries({
      email: userLogged!.email,
    }).then(response => {
      setMOADBQueries(response.data);
    });
  }, [userLogged]);

  const openQuery = newQuery => {
    setActiveSQLElementId(null);
    setQuery(newQuery);
    setSQLElements(JSON.parse(JSON.stringify(newQuery?.Query) ?? '[]'));
    setShowNewModal(false);
    setShowOpenModal(false);
    setActiveTab('Query');
  };

  const openReplaceModal = (elementId, type) => {
    const section =
      type === 'ref'
        ? 'Reference'
        : Object.keys(Elements).filter(
            section =>
              Elements[section].filter(element => element.name === type)
                .length >= 1,
          )?.[0];

    setReplaceElementId(elementId);
    setActiveReplaceSection(section);
    setShowReplaceModal(true);
  };

  const replaceElementTypeById = (type, referenceId) => {
    const element = document.getElementById(replaceElementId);
    element?.setAttribute('data-type', type);
    element?.setAttribute('data-referenceid', referenceId);
    updateSQLElements();
  };

  const saveQuery = (newQuery: any = null) => {
    const saveQuery = newQuery ?? query;

    if (savingAs && !!saveQuery?._id) delete saveQuery._id;

    insertMOADBQueryRecords({
      email: userLogged!.email,
      query: {
        ...saveQuery,
        Query: sqlElements,
      },
    }).then(response => {
      setQuery(response.data.query);
      setShowSaveModal(false);
      setSavingAs(false);
      loadQueries();
      toast.info(`"${saveQuery.Name}" saved successfully`);
    });
  };

  const saveQueryAs = () => {
    setSavingAs(true);
    setShowSaveModal(true);
  };

  const saveQueryOrSaveQueryAs = () => {
    if (!!query?._id) saveQuery();
    else setShowSaveModal(true);
  };

  const updateQuery = newQuery => {
    setQuery({
      ...newQuery,
    });
  };

  const updateSingleSQLElement = (id, field, value) => {
    const element = findSQLElementById(id);
    element.field = field;
    element.value = value;
    setSQLElements([...sqlElements]);
  };

  const updateSQLElements = () => {
    const topLevelElements = [...listGroupRef?.current?.childNodes].map(
      item => ({
        branch: item,
        id: item.id,
        subBranch: null,
        type: !!item?.getAttribute ? item?.getAttribute('data-type') : null,
        referenceId: !!item?.getAttribute
          ? item?.getAttribute('data-referenceid')
          : null,
        field: !!item?.getAttribute ? item?.getAttribute('data-field') : null,
        value: !!item?.getAttribute ? item?.getAttribute('data-value') : null,
        children: [],
      }),
    );
    const tree: any[] = topLevelElements;
    const activeBranch: any[] = [...tree];
    const elementsDictionary = {};

    while (activeBranch?.length) {
      const branch = activeBranch.pop();
      const subBranch = [...branch.branch.childNodes].map(item => ({
        branch: item,
        id: item.id,
        subBranch: null,
        type: !!item?.getAttribute ? item?.getAttribute('data-type') : null,
        children: [],
      }));

      branch.subBranch = subBranch;
      activeBranch.push(...subBranch);

      if (!!branch.branch?.id) elementsDictionary[branch.branch.id] = branch;

      if (!!branch?.branch?.closest) {
        const closestElement =
          elementsDictionary[branch.branch.closest('[id]').id];

        closestElement.children.push(
          ...subBranch.filter(item => !!item?.branch?.id),
        );
      }
    }

    Object.keys(elementsDictionary).forEach(key => {
      elementsDictionary[key].id = uuidv4();
      delete elementsDictionary[key].branch;
      delete elementsDictionary[key].subBranch;
    });

    setSQLElements(topLevelElements);
  };

  const updateEndTime = () => {
    setResultsLoadingEndTime(Date.now());
    if (resultsAreLoadingRef.current) setTimeout(updateEndTime, 1000);
  };

  const validateFields = () => {
    const children: any[] = [...sqlElements];

    while (children.length) {
      const nextChild = children.pop();
      const isBlock = getIsBlock(nextChild.type);

      if (!isBlock && nextChild.type !== 'ref' && !nextChild?.field)
        return false;

      if (!!nextChild.children) children.push(...nextChild.children);
    }

    return true;
  };

  useEffect(() => {
    loadQueries();
  }, [loadQueries, userLogged]);

  useEffect(() => {
    getLists(userLogged?.email).then(response => {
      setList(response.data);
    });
  }, [userLogged?.email]);

  return (
    <>
      <AddModal
        createSQLElement={createSQLElement}
        moadbQueries={moadbQueries}
        onHide={() => setShowAddModal(false)}
        show={showAddModal}
      />

      <FileDetailsModal
        onHide={() => setShowFileDetailsModal(false)}
        query={query}
        updateQuery={updateQuery}
        show={showFileDetailsModal}
      />

      <ImportModal
        activeList={activeList}
        importQueryResults={importQueryResults}
        list={list}
        onHide={() => setShowImportModal(false)}
        setActiveList={setActiveList}
        show={showImportModal}
      />

      <NewModal
        moadbQueries={moadbQueries}
        onHide={() => setShowNewModal(false)}
        onOpen={openQuery}
        show={showNewModal}
      />

      <OpenModal
        moadbQueries={moadbQueries}
        onHide={() => setShowOpenModal(false)}
        onOpen={openQuery}
        show={showOpenModel}
      />

      <ReplaceModal
        activeSection={activeReplaceSection}
        replaceSqlElement={replaceElementTypeById}
        moadbQueries={moadbQueries}
        onHide={() => setShowReplaceModal(false)}
        show={showReplaceModal}
      />

      <SaveModal
        onHide={() => setShowSaveModal(false)}
        onSave={saveQuery}
        query={query}
        updateQuery={updateQuery}
        show={showSaveModal}
      />

      <Navbar expand="lg" bg="dark" data-bs-theme="dark">
        <Container fluid>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="me-auto">
              <Nav.Item>
                <ButtonGroup>
                  <ToolTip title="New">
                    <Button
                      variant="dark"
                      onClick={() => setShowNewModal(true)}
                    >
                      <Square />
                    </Button>
                  </ToolTip>
                  <ToolTip title="Open">
                    <Button
                      variant="dark"
                      onClick={() => setShowOpenModal(true)}
                    >
                      <ArrowUpSquare />
                    </Button>
                  </ToolTip>
                  <ToolTip title="Save">
                    <Button
                      variant="dark"
                      onClick={() => saveQueryOrSaveQueryAs()}
                    >
                      <ArrowDownSquare />
                    </Button>
                  </ToolTip>
                  <ToolTip title="Save As">
                    <Button variant="dark" onClick={() => saveQueryAs()}>
                      <ArrowDownSquareFill />
                    </Button>
                  </ToolTip>
                  {!!query?._id && (
                    <ToolTip title="Edit File Info">
                      <Button
                        variant="dark"
                        onClick={() => setShowFileDetailsModal(true)}
                      >
                        <InfoSquare />
                      </Button>
                    </ToolTip>
                  )}
                </ButtonGroup>
              </Nav.Item>

              <Nav.Item>
                <ButtonGroup>
                  <ToolTip title="Add new element">
                    <Button
                      variant="dark"
                      onClick={() => setShowAddModal(true)}
                    >
                      <PlusCircle />
                    </Button>
                  </ToolTip>
                </ButtonGroup>
              </Nav.Item>
            </Nav>

            <Nav>
              <Nav.Item>
                <ButtonGroup>
                  <ToolTip title="Switch to Faceted Search">
                    <Button variant="dark" onClick={() => setUseQuery(false)}>
                      <ArrowLeftRight />
                    </Button>
                  </ToolTip>
                </ButtonGroup>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container fluid className="mt-3">
        <Row>
          <Col lg={1}></Col>
          <Col lg={10}>
            <Tabs
              activeKey={activeTab}
              onSelect={k => setActiveTab(k ?? 'Query')}
            >
              <Tab eventKey="Query" title="Query">
                <ListGroup
                  key={sqlElements.map(item => item.id).join('-')}
                  ref={listGroupRef}
                >
                  {sqlElements.map((element, index) => (
                    <SQLElement
                      activeSQLElementId={activeSQLElementId}
                      deleteActiveSQLElement={deleteActiveSQLElement}
                      isLast={index === sqlElements.length - 1}
                      key={element.id}
                      moadbQueries={moadbQueries}
                      openReplaceModal={openReplaceModal}
                      setActiveSQLElementId={setActiveSQLElementId}
                      setAddWithin={setAddWithin}
                      setShowAddModal={setShowAddModal}
                      updateSingleSQLElement={updateSingleSQLElement}
                      updateSQLElements={updateSQLElements}
                      {...element}
                    />
                  ))}
                </ListGroup>
                {!!sqlElements?.length && (
                  <Row>
                    <Col lg={9}></Col>
                    <Col>
                      <InputGroup size="lg" className="float-end">
                        <Form.FloatingLabel label="Limit">
                          <Form.Control
                            type="number"
                            value={limit}
                            onChange={e => setLimit(e.target.value)}
                          />
                        </Form.FloatingLabel>

                        <ToolTip title="Run Query">
                          <Button
                            onClick={executeQuery}
                            disabled={resultsAreLoading}
                          >
                            <PlayCircle />
                            &nbsp;Run Query
                          </Button>
                        </ToolTip>
                      </InputGroup>
                    </Col>
                  </Row>
                )}
              </Tab>
              <Tab eventKey="SQL" title="SQL" className="mt-3">
                <SyntaxHighlighter
                  language="sql"
                  style={dark}
                  showLineNumbers
                  onClick={copySQLToClipboard}
                >
                  {sql}
                </SyntaxHighlighter>
              </Tab>
              <Tab eventKey="Results" title="Results" className="mt-3">
                {resultsAreLoading && (
                  <>
                    <Spinner />
                    &nbsp;Executing Query...&nbsp;
                    {(resultsLoadingEndTime - resultsLoadingStartTime) / 1000}
                    &nbsp; seconds.
                  </>
                )}
                {!!error && (
                  <Alert variant="danger" className="mt-3">
                    {error}
                  </Alert>
                )}
                {!!results && (
                  <>
                    <div className="mb-3">
                      Execution Time:&nbsp;
                      {(resultsLoadingEndTime - resultsLoadingStartTime) / 1000}
                      &nbsp; seconds,&nbsp;Rows:&nbsp;{results.length - 1}
                      <ButtonGroup className="float-end">
                        <Button
                          size="sm"
                          variant="dark"
                          onClick={() => setShowAllResults(!showAllResults)}
                        >
                          Show {showAllResults ? 'Preview' : 'All'}
                        </Button>
                        <Button size="sm" variant="dark" onClick={generateCSV}>
                          Download CSV
                        </Button>
                        <Button
                          size="sm"
                          onClick={() => setShowImportModal(true)}
                        >
                          Import
                        </Button>
                      </ButtonGroup>
                    </div>
                    <Table striped bordered responsive size="sm">
                      <thead>
                        <tr>
                          {Object.keys(results[0]).map(column => (
                            <th>{column}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {(showAllResults ? results : results.slice(0, 10)).map(
                          row => (
                            <tr>
                              {Object.keys(results[0]).map(column => (
                                <td style={{ whiteSpace: 'nowrap' }}>
                                  {row[column]}
                                </td>
                              ))}
                            </tr>
                          ),
                        )}
                      </tbody>
                    </Table>
                  </>
                )}
              </Tab>
            </Tabs>
          </Col>
          <Col lg={1}></Col>
        </Row>
      </Container>
    </>
  );
};
