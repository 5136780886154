export const reduceObject = object =>
  Object.keys(object ?? {})
    .map(key => {
      if (
        !!object[key] &&
        !Array.isArray(object[key]) &&
        typeof object[key] === 'object' &&
        !Object.keys(object[key]).length
      )
        return null;
      else if (
        !!object[key] &&
        !Array.isArray(object[key]) &&
        typeof object[key] === 'object'
      )
        return { [key]: JSON.stringify(reduceObject(object[key])) };
      else if (
        !!object[key] &&
        Array.isArray(object[key]) &&
        !object[key].length
      )
        return null;
      else if (!!object[key] && Array.isArray(object[key]))
        return { [key]: JSON.stringify(object[key]) };
      else if (typeof object[key] === 'string' && !object[key].length)
        return null;
      else return { [key]: object[key] };
    })
    .filter(a => a)
    .reduce((p, c) => ({ ...p, ...c }), {});
