const clickButton = xPath => ({
  command: (vars, data) =>
    `${getXPathScript(
      vars[xPath],
    )}.dispatchEvent(new Event("click", {bubbles: true}));`,
});

const confirmElementExists = xPath => ({
  awaitResponse: true,
  command: (vars, data) =>
    `(() => ${(Array.isArray(xPath) ? xPath : [xPath])
      .map(item => `${getXPathScript(vars[item])}`)
      .join(' ?? ')})();`,
});

const confirmPageLoaded = url =>
  url instanceof RegExp
    ? {
        awaitResponse: true,
        command: (vars, data) =>
          `(() => window?.__DRE_LOADED?.match(${url}))();`,
      }
    : {
        awaitResponse: true,
        command: (vars, data) =>
          `(() => window?.__DRE_LOADED?.startsWith("${url}"))();`,
      };

const getInnerText = xPath => ({
  awaitResponse: true,
  command: (vars, data) =>
    `(() => ${(Array.isArray(vars[xPath]) ? vars[xPath] : [vars[xPath]])
      .map(item => `${getXPathScript(item)}?.innerText`)
      .join(' ?? ')})();`,
});

const getXPathScript = path =>
  `document.evaluate("${path}", document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue`;

const loadPage = url => ({
  command: (vars, data) => `window.location.href = "${url}";`,
});

const standardWealthFetchButton = ({
  button,
  click,
  input,
  page1,
  page2,
  value,
}) => ({
  vars: { button, click, input, value },
  steps: [
    loadPage(page1),
    confirmPageLoaded(page1),
    !!click ? clickButton('click') : null,
    updateInputWithField('input', 'fullAddress'),
    {
      command: (vars, data) => `${getXPathScript(vars['button'])}`,
    },
    clickButton('button'),
    confirmPageLoaded(page2),
    getInnerText('value'),
  ].filter(a => a),
});

const standardWealthFetchForm = ({ form, input, page1, page2, value }) => ({
  vars: { form, input, value },
  steps: [
    loadPage(page1),
    confirmPageLoaded(page1),
    updateInputWithField('input', 'fullAddress'),
    submitForm('form'),
    confirmPageLoaded(page2),
    getInnerText('value'),
  ],
});

const submitForm = xPath => ({
  command: (vars, data) => `${getXPathScript(vars[xPath])}.requestSubmit();`,
});

const updateInputWithField = (xPath, field) => ({
  command: (vars, data) =>
    [
      `Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set.call(${getXPathScript(
        vars[xPath],
      )}, "${data[field]}");`,
      `${getXPathScript(
        vars[xPath],
      )}.dispatchEvent(new Event("input", {bubbles: true, cancelable: true}));`,
    ].join(''),
});

export {
  clickButton,
  confirmElementExists,
  confirmPageLoaded,
  getInnerText,
  getXPathScript,
  loadPage,
  standardWealthFetchButton,
  standardWealthFetchForm,
  submitForm,
  updateInputWithField,
};
