import { Expression, PipelineStage } from 'mongoose';

export const boxModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const aggregationQueryUsers = (
  skip: number,
  limit: number,
  sort: { [key: string]: number },
  matchQuery,
): PipelineStage[] => {
  const hasSortValue = !!Object.keys(sort).length;
  const $sort: unknown[] = [];
  if (hasSortValue) {
    $sort.push({ $sort: sort as any });
  }
  return [
    {
      $facet: {
        totalPagination: [{ $count: 'totalCount' }],
        filteredPagination: [
          ...matchQuery,
          {
            $count: 'totalCount',
          },
        ],
        data: [
          ...matchQuery,
          { $sort: sort as Record<string, 1 | Expression.Meta | -1> },
          {
            $skip: skip,
          },
          {
            $limit: limit,
          },
        ],
      },
    },
  ];
};
