/**
 * Asynchronously loads the component for ResultsPage
 */

import { lazyLoad } from 'utils/loadable';

export const ResultsPage = lazyLoad(
  () => import('./index'),
  module => module.ResultsPage,
);
