import React from 'react';
import { ListGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
  getEmailHRef,
  getEmailOnClick,
  getEmailTarget,
  getSMSHref,
  getSMSOnClick,
} from '../../utility';
import { Eye } from 'react-bootstrap-icons';

const FormsModal = ({
  appEnabled,
  forms,
  medium,
  onHide,
  row,
  show,
  smtpCredentials,
  smtpFormEnabled,
  userLogged,
}) => {
  const FormListGroup = ({ filteredForms }) => {
    return (
      <ListGroup className="mt-3">
        {filteredForms.map(form => {
          const isEmail = medium === 'email';
          const image = `https://static.ourfight.online/upload/${form.image}`;
          const link = `https://ourfight.online/${form.id}`;
          const text = `${form.title}\r\n\r\n${link}`;
          const html = `<h3>${form.title}</h3></br></br><a target="_blank" href="${link}"><img src="${image}" width="100%" /></br>${link}</a>`;
          const subject = `${form.title}`;

          return (
            <ListGroup.Item
              action
              href={
                isEmail
                  ? getEmailHRef(
                      appEnabled,
                      text,
                      html,
                      subject,
                      smtpCredentials,
                      smtpFormEnabled,
                      row.Email,
                    )
                  : getSMSHref(appEnabled, row, link)
              }
              target={getEmailTarget(
                appEnabled,
                isEmail ? link : null,
                smtpCredentials,
              )}
              onClick={() => {
                if (isEmail)
                  getEmailOnClick(
                    appEnabled,
                    html,
                    row,
                    smtpCredentials,
                    userLogged,
                  );
                else getSMSOnClick(appEnabled, row, link, userLogged);
              }}
            >
              {form.title}
              <Button
                variant="dark"
                className="float-end"
                onClick={e => onButtonClick(e, form)}
              >
                <Eye />
              </Button>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  const onButtonClick = (e, form) => {
    e.preventDefault();
    e.stopPropagation();

    window.open(`https://ourfight.online/${form.id}`, '_blank');
  };

  if (!forms) return null;
  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Forms</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey={show}>
          <Tab eventKey="polls" title="Polls">
            <FormListGroup
              filteredForms={forms.filter(form => form.hasPolls)}
            />
          </Tab>
          <Tab eventKey="petitions" title="Petitions">
            <FormListGroup
              filteredForms={forms.filter(form => !form.hasPolls)}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default FormsModal;
