const websocketRequest = (url, body, callback, statusCallback = null) => {
  const ws = new WebSocket(url);
  const response = [];

  ws.addEventListener('open', e => {
    ws.send(JSON.stringify(body));
  });

  ws.addEventListener('message', e => {
    const data = e.data.toString();

    if (data.startsWith('{"$keepalive":'))
      ws.send(`{"$keepalive": ${Date.now()}}`);
    else if (data.startsWith('{"$status":')) {
      if (!!statusCallback) statusCallback(JSON.parse(data)['$status']);
    } else if (data === `{"$end": true}`) {
      callback(JSON.parse(response.join('')));
      ws.close();
    } else response.push(data);
  });
};

export { websocketRequest };
