import React, { useEffect, useState } from 'react';
import Catalog from '../Stripe';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { getOneOrganization } from 'api/organization.service';

const Settings = () => {
  const userLogged = useSelector(getAuthUser);
  const [organization, setOrganization] = useState<any>(null);

  const appendPurchaseHistory = payment => {
    setOrganization({
      ...organization,
      paymentHistory: [...organization.paymentHistory, payment],
    });
  };

  useEffect(() => {
    if (!!userLogged?.organizationId)
      getOneOrganization({ Organization_ID: userLogged?.organizationId }).then(
        response => {
          setOrganization(response);
        },
      );
  }, [userLogged?.organizationId]);

  return (
    <Catalog organization={organization} onPurchase={appendPurchaseHistory} />
  );
};

export default Settings;
