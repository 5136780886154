import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';

const NewModal = ({ moadbQueries, onHide, onOpen, show }) => {
  const createQueryCopy = query => {
    const newQuery = { ...query };

    delete newQuery._id;
    delete newQuery.Name;
    delete newQuery.Description;
    delete newQuery.IsTemplate;

    onOpen(newQuery);
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>New Query</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ButtonGroup vertical className="w-100">
          <Button variant="dark" className="py-3" onClick={() => onOpen({})}>
            <div className="fs-5 fw-bold text-start">Blank</div>
            <div className="text-start">Create new query</div>
          </Button>

          {!!moadbQueries?.queries &&
            moadbQueries.queries
              .sort((a, b) => a.Name.localeCompare(b.name))
              .filter(a => !!a.IsTemplate)
              .map(query => (
                <Button
                  variant="light"
                  className="py-3"
                  onClick={() => createQueryCopy(query)}
                >
                  <div className="fs-5 fw-bold text-start">
                    {query.Name}
                    {!!query.IsTemplate && (
                      <>
                        &nbsp;<Badge>Template</Badge>
                      </>
                    )}
                  </div>
                  <div className="text-start">{query.Description}</div>
                </Button>
              ))}
        </ButtonGroup>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="light" onClick={onHide}>
            Cancel
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default NewModal;
