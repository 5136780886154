import store from './store';

/* eslint-disable import/no-anonymous-default-export */
/*
  The following 3 Google constants are currently not environment specific.
  Thus, they are currently not provided by Nullstone.
  It is possible these may be enviorment specific and/or provided by Nullstone in the future.
  
*/

const GOOGLE_SCOPES = [
  'openid',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
].join(' ');
const GOOGLE_OAUTH_ACCESS_TOKEN_URL =
  'https://www.googleapis.com/oauth2/v4/token';
const GOOGLE_OAUTH_TOKEN_VALIDATION_URL =
  'https://www.googleapis.com/oauth2/v3/tokeninfo';

export default {
  ANALYTICS_CLIENT_ID: () => store?.getState()?.envJSON?.ANALYTICS_CLIENT_ID,
  ANALYTICS_ENV: () => store?.getState()?.envJSON?.ANALYTICS_ENV,
  ANALYTICS_MEASUREMENT_ID: () =>
    store?.getState()?.envJSON?.ANALYTICS_MEASUREMENT_ID,
  ANALYTICS_SECRET: () => store?.getState()?.envJSON?.ANALYTICS_SECRET,
  DPT_LOAD_LIMIT: () => store?.getState()?.envJSON?.DPT_LOAD_LIMIT ?? 20,
  DPT_SAVE_LIMIT: () => store?.getState()?.envJSON?.DPT_SAVE_LIMIT ?? 20,
  GOOGLE_CLIENT_ID: () => store?.getState()?.envJSON?.GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET: () => store?.getState()?.envJSON?.GOOGLE_CLIENT_SECRET,
  GOOGLE_SCOPES,
  GOOGLE_REDIRECT_URI: () => store?.getState()?.envJSON?.GOOGLE_REDIRECT_URI,
  GOOGLE_OAUTH_ACCESS_TOKEN_URL,
  GOOGLE_OAUTH_TOKEN_VALIDATION_URL,
  BASE_ENDPOINT: () => store?.getState()?.envJSON?.BASE_ENDPOINT,
  BASE_ENDPOINT_WS: () => store?.getState()?.envJSON?.BASE_ENDPOINT_WS,
  FEDERATED_USERS_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/auth/federatedSignin`,
  SESSION_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/auth/session`,
  SIGNIN_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/auth/login`,
  RESET_PASSWORD_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/auth/resetPassword`,
  RESET_TEMP_PASSWORD_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/auth/resetTempPassword`,
  SIGNUP_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/auth/signup`,
  STRIPE_PUBLIC_KEY: () => store?.getState()?.envJSON?.STRIPE_PUBLIC_KEY,
  STRIPE_PRICE_BASIC_ACCESS: () =>
    store?.getState()?.envJSON?.STRIPE_PRICE_BASIC_ACCESS,
  STRIPE_PRICE_BROWSER_SIDEKICK: () =>
    store?.getState()?.envJSON?.STRIPE_PRICE_BROWSER_SIDEKICK,
  STRIPE_PRICE_CREDIT: () => store?.getState()?.envJSON?.STRIPE_PRICE_CREDIT,
};
