import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import { InputGroup } from 'react-bootstrap';
import { ArrowClockwise, Gear } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

const ActiveTemplateManager = ({
  canAccessMOADBQuery,
  onHide,
  options,
  retrieveTemplatesAndSMTP,
  saveOptions,
  setOptions,
  smtpCredentials,
  templates,
}) => {
  const [updatedOptions, setUpdatedOptions] = useState<any>({ ...options });

  const save = (close = false) => {
    setOptions({ ...options, ...updatedOptions });

    saveOptions(
      null,
      null,
      null,
      updatedOptions.activeEmailTemplate,
      updatedOptions.activeSMSTemplate,
      updatedOptions.activeSMTPCredentials,
      () => {
        toast.success('Options saved successfully!');
        if (close) onHide();
      },
    );
  };

  const saveAndClose = () => {
    save(true);
  };

  return (
    <div>
      <Form.Label>Active Email Template</Form.Label>
      <InputGroup className="mb-3">
        <Form.Select
          size="sm"
          value={updatedOptions.activeEmailTemplate ?? ''}
          onChange={e =>
            setUpdatedOptions({
              ...updatedOptions,
              activeEmailTemplate: e.target.value,
            })
          }
        >
          <option value="">-- Select a template --</option>
          {templates
            .map(template => {
              return template.use === 'email' ? (
                <option value={template._id.toString()}>{template.name}</option>
              ) : null;
            })
            .filter(a => a)}
        </Form.Select>
        <Button onClick={() => retrieveTemplatesAndSMTP()}>
          <ArrowClockwise />
        </Button>
        <Button href="/template-manager" target="_blank">
          <Gear />
        </Button>
      </InputGroup>

      <Form.Label>Active SMS Template</Form.Label>
      <InputGroup className="mb-3">
        <Form.Select
          size="sm"
          value={updatedOptions.activeSMSTemplate ?? ''}
          onChange={e =>
            setUpdatedOptions({
              ...updatedOptions,
              activeSMSTemplate: e.target.value,
            })
          }
        >
          <option value="">-- Select a template --</option>
          {templates
            .map(template => {
              return template.use === 'sms' ? (
                <option value={template._id.toString()}>{template.name}</option>
              ) : null;
            })
            .filter(a => a)}
        </Form.Select>
        <Button onClick={() => retrieveTemplatesAndSMTP()}>
          <ArrowClockwise />
        </Button>
        <Button href="/template-manager" target="_blank">
          <Gear />
        </Button>
      </InputGroup>

      {canAccessMOADBQuery && (
        <>
          <Form.Label>Active SMTP Credentials</Form.Label>
          <InputGroup className="mb-3">
            <Form.Select
              size="sm"
              value={updatedOptions.activeSMTPCredentials ?? ''}
              onChange={e =>
                setUpdatedOptions({
                  ...updatedOptions,
                  activeSMTPCredentials: e.target.value,
                })
              }
            >
              <option value="">-- Select a credential --</option>
              {smtpCredentials
                .map(credential => (
                  <option value={credential._id.toString()}>
                    {credential.email}
                  </option>
                ))
                .filter(a => a)}
            </Form.Select>
            <Button onClick={() => retrieveTemplatesAndSMTP()}>
              <ArrowClockwise />
            </Button>
            <Button href="/smtp-manager" target="_blank">
              <Gear />
            </Button>
          </InputGroup>
        </>
      )}

      <ButtonGroup className="float-end">
        <Button variant="dark" onClick={() => save()}>
          Save
        </Button>
        <Button onClick={saveAndClose}>Save & Close</Button>
      </ButtonGroup>
    </div>
  );
};

export default ActiveTemplateManager;
