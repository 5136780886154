import React from 'react';
import Button from 'react-bootstrap/Button';
import content from './DREUserAgreementTermsConditions';

const TOS = ({ onAgreement }) => {
  return (
    <div className="p-5">
      <div dangerouslySetInnerHTML={{ __html: content }} />

      <Button className="bg-dark w-100 sticky-bottom" onClick={onAgreement}>
        I Agree
      </Button>
    </div>
  );
};

export default TOS;
