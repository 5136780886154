import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  getProspectTrackerAssignees,
  getProspectTrackerGrouping,
} from 'api/prospect.tracker.service';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/authentication/authentication.selector';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Container, Form } from 'react-bootstrap';
import metrics from './metrics';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const Reports = () => {
  const userLogged = useSelector(getAuthUser);
  const [activeMetric, setActiveMetric] = useState(0);
  const [assignees, setAssignees] = React.useState<any>(null);
  const colors = useMemo(
    () => [
      'rgba(255, 99, 132, 0.5)',
      'rgba(53, 162, 235, 0.5)',
      'rgb(75, 192, 192)',
      'rgba(235, 162, 235, 0.5)',
      'rgb(75, 75, 192)',
    ],
    [],
  );
  const [data, setData] = useState<any>(null);
  const loading = useRef(false);

  const processReport = useCallback(() => {
    if (!loading.current) {
      loading.current = true;

      getProspectTrackerGrouping({
        email: userLogged!.email,
        metricFilter: metrics[activeMetric].filter,
        metricMatch: metrics[activeMetric].match,
        metricSort: metrics[activeMetric].sort,
      }).then(response => {
        const reportDates = response.data
          .map(day => day.date)
          .filter((item, index, array) => index === array.indexOf(item));
        const reportAssignees = response.data
          .map(day => day.assignee)
          .filter((item, index, array) => index === array.indexOf(item));

        const reportDataset = reportAssignees.map((assignee, index) => ({
          label:
            assignees
              .filter(
                item => item._id.toString() === (assignee ?? '').toString(),
              )
              .map(item => `${item.firstName}`)?.[0] ?? 'Unassigned',
          backgroundColor: colors[index],
          data: reportDates.map(date => {
            return response.data
              .filter(day => day.date === date && day.assignee === assignee)
              .map(day => parseInt(day.tally))?.[0];
          }),
        }));

        setData({
          chart: {
            labels: reportDates.map(date =>
              date.match(/[0-9]+-[0-9]+-[0-9]+/g),
            ),
            datasets: reportDataset,
          },
        });

        loading.current = false;
      });
    }
  }, [activeMetric, assignees, colors, userLogged]);

  useEffect(() => {
    if (!!userLogged?.email)
      getProspectTrackerAssignees({
        email: userLogged!.email,
      }).then(response => {
        setAssignees(response.data.assignees);
      });
  }, [userLogged]);

  useEffect(() => {
    if (!!assignees) processReport();
  }, [assignees, processReport]);

  useEffect(() => {
    console.log('debug', activeMetric);
    if (!!assignees) processReport();
  }, [assignees, processReport, activeMetric]);

  if (!assignees || !data) return null;
  return (
    <Container>
      <div className="my-3">
        <Form.Select
          value={activeMetric}
          onChange={e => setActiveMetric(parseInt(e.target.value, 10))}
        >
          {metrics.map((metric, index) => (
            <option value={index}>{metric.label}</option>
          ))}
        </Form.Select>
      </div>
      <Bar className="mb-5" options={options as any} data={data.chart} />
    </Container>
  );
};

export default Reports;
