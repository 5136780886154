import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Link } from '@mui/material';
import DRE_logo from '../../assets/Color logo - no background.svg';

const linkStyle = {
  '&:hover': {
    color: '#36e961',
  },
  cursor: 'pointer',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '18px',
  fontFamily: 'Inter, sans-serif',
  transition: 'all 0.3s',
  textDecoration: 'none',
};

const footerLinks = [
  {
    text: 'Home',
    path: '/',
  },
  {
    text: 'Terms',
    path: 'https://www.dredata.ai/terms',
  },
  {
    text: 'Privacy',
    path: 'https://www.dredata.ai/privacy',
  },
];

export default function Footer() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: '#0a3042',
        backgroundImage: 'radial-gradient(circle at 0 100%, #0a3042, #11506e)',
        width: '100%',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '54px 0px',
            borderBottom: '1px solid #eff1f6',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
            }}
          >
            <img
              src={DRE_logo}
              onClick={() => navigate(`/`)}
              style={{
                height: '50px',
                cursor: 'pointer',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '24px',
            }}
          >
            {footerLinks.map((footerItem, index) =>
              footerItem.text === 'Home' ? (
                <Typography
                  onClick={() => navigate(footerItem.path)}
                  sx={linkStyle}
                  key={index}
                >
                  {footerItem.text}
                </Typography>
              ) : (
                <Link href={footerItem.path} sx={linkStyle} key={index}>
                  {footerItem.text}
                </Link>
              ),
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'space-between',
            padding: '32px 0px',
          }}
        >
          <Typography
            sx={{
              color: '#828aa3',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '1.571em',
            }}
          >
            Copyright © DRE Data Service LLC 2023
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

Footer.propTypes = {};
