import axios from 'axios';
import constants from '../../config/constants';

// Using Google's authorization code we can get an access and refresh token
const getTokens = auth_code =>
  axios
    .post(constants.GOOGLE_OAUTH_ACCESS_TOKEN_URL, {
      code: auth_code,
      client_id: constants.GOOGLE_CLIENT_ID(),
      client_secret: constants.GOOGLE_CLIENT_SECRET(),
      grant_type: 'authorization_code',
      redirect_uri: constants.GOOGLE_REDIRECT_URI(),
    })
    .then(body => {
      const idToken = body.data.id_token;
      const refreshToken = body.data.refresh_token;
      return { idToken, refreshToken };
    })
    .catch(err => {
      console.error(`Error getting refresh token: ${err.message}`);
    });

export default getTokens;
