import constants from 'app/config/constants';
import { orgPaymentHistory } from './orgPaymentHistory';

export const orgCredits = organization => {
  console.log(organization);

  return (
    orgPaymentHistory(organization)
      .filter(payment =>
        [
          constants.STRIPE_PRICE_BASIC_ACCESS(),
          constants.STRIPE_PRICE_BROWSER_SIDEKICK(),
          constants.STRIPE_PRICE_CREDIT(),
        ].includes(payment.price),
      )
      .map(payment => payment.total)
      .reduce((p, c) => p + c, 0) /
      100 -
    ((organization.prospects_purchased ?? [])
      .map(purchase => purchase.credits)
      .reduce((p, c) => p + c, 0) +
      (organization.searchHistory ?? []).length)
  );
};
