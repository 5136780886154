import React from 'react';
import ToolTip from '../ToolTip';
import { Search } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';

export const MOADBSearchButton = ({ isMobile }) => {
  return (
    <ToolTip title="Query MOADB" placement="bottom">
      <Button
        variant="link"
        href="/moadb-search"
        className={`fs-3 p-0 text-decoration-none text-start w-100 ${
          isMobile ? 'text-dark ' : 'text-light px-2'
        }`}
      >
        <Search />
        {isMobile && <>&nbsp; Query MOADB</>}
      </Button>
    </ToolTip>
  );
};
