import moment from 'moment';
import { orgPaymentHistory } from './orgPaymentHistory';

export const orgHasAccess = (organization, item) => {
  let results = true;

  if (!item.cost) return true;

  if (
    !!item.requiresPriceId &&
    orgPaymentHistory(organization).filter(
      payment =>
        [item.requiresPriceId].includes(payment.price) &&
        moment(Date.now()).isBetween(
          moment.unix(payment.period.start),
          moment.unix(payment.period.end),
        ),
    ).length === 0
  )
    results = false;

  if (
    !!item.oneTimePurchase &&
    orgPaymentHistory(organization).filter(
      payment =>
        [item.priceId].includes(payment.price) &&
        moment(Date.now()).isBetween(
          moment.unix(payment.period.start),
          moment.unix(payment.period.end),
        ),
    ).length !== 1
  )
    results = false;

  return results;
};
