import constants from 'app/config/constants';
import axionsInstance from 'utils/api';

export const markDonorRecordsPurchased = (dreIds: number[], email: string) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/subscription/mark_donor_records_purchased`;
  return axionsInstance()<any>({
    url,
    method: 'POST',
    data: { dreIds, email },
  }).then(response => response.data);
};
