// App.tsx
import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
  MdOutlineMenu,
} from 'react-icons/md';
import ContactViewModal from './ContactViewModal';
import ContactViewPage from './ContactViewPage';

const ContactView = ({
  cellTemplateList,
  columnOptionList,
  count,
  donors,
  getPageInsertIndex,
  idField,
  order,
  pageHeight,
  pagesLoaded,
  pageVisibility,
  rowHeight,
  rowVisibility,
  setDonors,
  setOrder,
  setShowOptionsModal,
  setSort,
  sort,
  templates,
  workspacePaddingRef,
}) => {
  const [editableFields, setEditableFields] = React.useState<string[]>([]);
  const [indexList, setIndexList] = React.useState<number[]>([]);
  const [formRow, setFormRow] = React.useState({});
  const [showFormModal, setShowFormModal] = React.useState(false);

  const showModal = (row, cellTemplate, rowIndex) => {
    setIndexList([rowIndex]);
    setEditableFields(cellTemplate.editableMobileFields);
    setFormRow(row);
    setShowFormModal(true);
  };

  const toggleOrder = () => {
    setOrder([order?.[0] === 1 ? -1 : 1]);
  };

  const updateSort = e => {
    setSort([e.target.value]);
  };

  return (
    <Container className="mt-3" fluid>
      <ContactViewModal
        cellTemplateList={cellTemplateList}
        columnOptionList={columnOptionList}
        donors={donors}
        editableFields={editableFields}
        onHide={() => setShowFormModal(false)}
        idField={idField}
        indexList={indexList}
        row={formRow}
        setDonors={setDonors}
        show={showFormModal}
        templates={templates}
      />

      <Row className="sticky-top top-0 bg-light">
        <Col xs={2} md="auto">
          <Button variant="dark" onClick={() => setShowOptionsModal(true)}>
            <MdOutlineMenu />
          </Button>
        </Col>
        <Col xs={10} lg={2}>
          <InputGroup>
            <Form.Select value={sort?.[0]} onChange={updateSort}>
              {[...cellTemplateList]
                .sort((a, b) =>
                  a.header < b.header ? -1 : a.header > b.header ? 1 : 0,
                )
                .map(
                  (cellTemplate, index) =>
                    cellTemplate?.showInDesktop && (
                      <option value={cellTemplate.field}>
                        {cellTemplate.header}
                      </option>
                    ),
                )}
            </Form.Select>
            <Button variant="dark" onClick={toggleOrder}>
              {order?.[0] === 1 && <MdKeyboardDoubleArrowUp />}
              {order?.[0] === -1 && <MdKeyboardDoubleArrowDown />}
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row ref={workspacePaddingRef} />
      {Object.keys(pageVisibility).map(pageIndex => (
        <ContactViewPage
          cellTemplateList={cellTemplateList}
          donors={donors}
          getPageInsertIndex={getPageInsertIndex}
          idField={idField}
          pageHeight={pageHeight}
          pageIndex={pageIndex}
          pagesLoaded={pagesLoaded}
          pageVisibility={pageVisibility}
          rowHeight={rowHeight}
          rowVisibility={rowVisibility}
          showModal={showModal}
          templates={templates}
        />
      ))}
    </Container>
  );
};

export default ContactView;
