import { Expression, PipelineStage } from 'mongoose';

export const aggregationQueryOrgs = (
  skip: number,
  limit: number,
  sort: { [key: string]: number },
): PipelineStage[] => {
  const hasSortValue = !!Object.keys(sort).length;
  const $sort: unknown[] = [];
  if (hasSortValue) {
    $sort.push({ $sort: sort as any });
  }
  return [
    {
      $facet: {
        totalPagination: [{ $count: 'totalCount' }],
        filteredPagination: [
          {
            $count: 'totalCount',
          },
        ],
        data: [
          {
            $project: {
              _id: 1,
              Organization_ID: 1,
              OrganizationName: 1,
              lastDownloadedDate: 1,
              donors_purchased: 1,
              donors_purchased_count: {
                $size: { $ifNull: ['$donors_purchased', []] },
              },
            },
          },
          { $sort: sort as Record<string, 1 | Expression.Meta | -1> },
          {
            $skip: skip,
          },
          {
            $limit: limit,
          },
        ],
      },
    },
  ];
};
