import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdWarning } from 'react-icons/md';

const RangeGapModal = ({ onHide, show }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <MdWarning /> Gap In Selection
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Your selection overlaps data that has not yet been loaded. Please ensure
        your selection only includes data that has been loaded.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RangeGapModal;
