import { Box, Button, Grid, Typography } from '@mui/material';
import { Container, Stack } from '@mui/system';
import { registerUser } from 'api/auth.service';
import GoogleLoginButton from '../LoginPage/GoogleButton';
import { toast } from 'react-toastify';
import axios from 'axios';
import DRE_logo from 'app/assets/dre_logo_green_blue.png';
import FormTextField from 'app/components/Form/FormTextField';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Link, useNavigate } from 'react-router-dom';
import './styles.css';
import styled from 'styled-components';
import { authFormStyle } from 'app/components/Form/FormTextField/config';
import { FormCheck } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const Anchor = ({ children, className = '', href, rel, target }) => (
  <a className={className} href={href} rel={rel} target={target}>
    {children}
  </a>
);

const GeneralLink = styled(Anchor)`
  color: #27bdb2;
  transition: all 0.3s ease;
  &:hover,
  &:focus {
    color: red;
  }
`;

const LoginLink = styled(Link)`
  color: #27bdb2;
  transition: all 0.3s ease;
  &:hover,
  &:focus {
    color: red;
  }
`;

interface FormData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
}

const initialValues: FormData = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  password: '',
};
const SignUpPage = () => {
  const [certification, setCertification] = useState<any>(null);
  const navigate = useNavigate();
  const onSubmit = async ({
    firstName,
    lastName,
    email,
    password,
    phone,
  }: FormData) => {
    registerUser({
      firstName,
      lastName,
      email,
      password,
      phone,
      active: false,
      isFederated: false,
      organizationId: 0,
      certification,
    })
      .then(response => {
        if (response) {
          toast.success('Check your email for the temporary password.');
          navigate('/login');
        }
      })
      .catch(error => {
        if (axios.isAxiosError(error)) {
          toast.error(error.response?.data.error);
        }
      })
      .finally(() => {});
  };

  async function validate(values: FormData) {
    const { email } = values;
    if (!email) {
      return { email: 'Email is required.' };
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        alignItems: 'center',
        background: '#0a3042',
        backgroundImage: 'radial-gradient(circle at 0%, #0a3042 62%, #155d81)',
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <Container maxWidth="xl">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', height: '85vh' }}
          >
            <Box sx={{ width: '100%' }} className="form-container">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  // alignItems: 'baseline',
                  // gap: 1,
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={DRE_logo}
                  alt="logo"
                  style={{ borderRadius: '13px', width: '60px' }}
                />
                <Typography
                  sx={{
                    fontSize: '28px',
                    fontWeight: 700,
                    lineHeight: '1.571em',
                    color: '#fff',
                    fontFamily: 'Inter, sans-serif',
                    marginBottom: '7px',
                  }}
                >
                  Create an Account
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <Box
                  width={'100%'}
                  sx={{
                    textAlign: 'center',
                    marginBottom: 2,
                  }}
                >
                  <GoogleLoginButton
                    behaviorType="SignUp"
                    buttonText="Sign up with Google"
                  />
                  <p
                    style={{
                      color: '#828aa3',
                      fontFamily: 'Inter, sans-serif',
                    }}
                  >
                    or
                  </p>
                </Box>

                <Form
                  initialValues={initialValues}
                  validate={validate}
                  onSubmit={onSubmit}
                  render={({ handleSubmit, submitting }) => (
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                      style={{ width: '100%' }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                          flexDirection: 'column',
                          gap: 1,
                          width: '100%',
                        }}
                      >
                        <Field name="email" type="email">
                          {props => (
                            <FormTextField
                              {...props}
                              placeholder="Email"
                              sx={authFormStyle}
                            />
                          )}
                        </Field>
                      </Box>

                      <Grid container justifyContent="flex-end" mt={'0.6rem'}>
                        <Button
                          sx={{
                            marginBottom: '7px',
                            textTransform: 'none',
                            width: '100%',
                            backgroundImage:
                              'linear-gradient(#36e961, #64ee85)',
                            border: '1px solid #36e961',
                            borderRadius: '5px',
                            color: '#fff',
                            transition: 'all 0.3s',
                            '&:hover': {
                              transform: 'scale(1.02)',
                            },
                          }}
                          type="submit"
                          variant="contained"
                          disabled={!certification || submitting}
                          // className="form-button"
                        >
                          Create account
                        </Button>
                      </Grid>
                    </form>
                  )}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  color: 'white',
                  fontFamily: 'Inter, sans-serif',
                  marginBottom: '20px',
                }}
              >
                <FormCheck
                  checked={!!certification}
                  onChange={() => {
                    const newUUID = uuidv4();
                    setCertification(!!certification ? null : newUUID);
                  }}
                  label={
                    <>
                      I certify that I have read and agree with DRE’s{' '}
                      <GeneralLink
                        target="_blank"
                        href="https://www.dredata.ai/privacy"
                        rel="noreferrer"
                      >
                        privacy policy
                      </GeneralLink>{' '}
                      and{' '}
                      <GeneralLink
                        target="_blank"
                        href="https://www.dredata.ai/terms"
                        rel="noreferrer"
                      >
                        terms of service
                      </GeneralLink>
                      .
                    </>
                  }
                />
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  color: '#828aa3',
                  fontFamily: 'Inter, sans-serif',
                }}
              >
                Already have an account?&nbsp;
                <LoginLink to="/login">Log-in</LoginLink>
              </Typography>
            </Box>
          </Stack>
        </Container>
      </motion.div>
    </Box>
  );
};

export default SignUpPage;
