import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const SaveModal = ({ facets, onHide, onSave, show, updateFacets }) => {
  const [error, setError] = useState<string>('');
  const [newFacets, setNewFacets] = useState<any>({ ...facets });

  const cancel = () => {
    setNewFacets({ ...facets });
    onHide();
  };

  const validate = () => {
    if (!newFacets?.Name) setError('Please provide a file name');
    else {
      updateFacets(newFacets);
      onSave(newFacets);
    }
  };

  const updateNewFacetedSearch = (field, value) => {
    setNewFacets({
      ...newFacets,
      [field]: value,
    });
  };

  useEffect(() => {
    setNewFacets({ ...facets });
  }, [facets]);

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Save Faceted Search</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          value={newFacets?.Name ?? ''}
          placeholder="File name"
          onChange={e => updateNewFacetedSearch('Name', e.target.value)}
        />
        <Form.Control
          className="mt-3"
          value={newFacets?.Description ?? ''}
          placeholder="Description"
          onChange={e => updateNewFacetedSearch('Description', e.target.value)}
        />
        <Form.Check
          className="mt-3"
          checked={!!newFacets?.IsTemplate}
          label="Is a template"
          onChange={e => updateNewFacetedSearch('IsTemplate', e.target.checked)}
        />
        {!!error && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="light" onClick={cancel}>
            Cancel
          </Button>
          <Button onClick={validate}>Save</Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveModal;
