const fieldMapOptions = {
  AgeRange: {
    label: 'Age Range',
    isRequired: false,
    alias: ['age'],
  },
  CellPhone_AreaCode: {
    label: 'Cell Phone Area Code',
    isNumber: true,
    isRequired: false,
    alias: ['cell area', 'mobile area'],
  },
  CellPhone_Number: {
    label: 'Cell Phone',
    isNumber: true,
    isRequired: false,
    alias: ['cell', 'mobile'],
  },
  CountyName: {
    label: 'County',
    isRequired: false,
    alias: ['county', 'district', 'presinct'],
  },
  Email: {
    label: 'Email',
    isRequired: false,
    alias: ['email'],
  },
  FirstName: {
    label: 'First Name',
    isRequired: true,
    alias: ['first', 'first name'],
  },
  LandLine_AreaCode: {
    label: 'Phone Area Code',
    isNumber: true,
    isRequired: false,
    alias: ['area code', 'phone area'],
  },
  LandLine_Number: {
    label: 'Phone Number',
    isNumber: true,
    isRequired: false,
    alias: ['number', 'phone'],
  },
  LastName: {
    label: 'Last Name',
    isRequired: true,
    alias: ['last'],
  },
  MiddleName: {
    label: 'Middle Name',
    isRequired: false,
    alias: ['middle'],
  },
  RegistrationAddress1: {
    label: 'Address 1',
    isRequired: true,
    alias: ['address'],
  },
  RegistrationAddress2: {
    label: 'Address 2',
    isRequired: false,
    alias: ['address 2'],
  },
  RegistrationAddressCity: {
    label: 'City',
    isRequired: true,
    alias: ['city'],
  },
  RegistrationAddressState: {
    label: 'State',
    isRequired: false,
    alias: ['state'],
  },
  RegistrationAddressZip5: {
    label: 'Zip',
    isNumber: true,
    isRequired: true,
    alias: ['postal', 'zip'],
  },
  Suffix: {
    label: 'Suffix',
    isRequired: false,
    alias: ['suffix'],
  },
  VoterID: {
    label: 'Voter ID',
    isRequired: false,
    alias: ['voter'],
  },
};

export default fieldMapOptions;
