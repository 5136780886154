import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { FC } from 'react';
import { AlertDialogProps } from './types';

const AlertDialog: FC<AlertDialogProps> = ({
  dialogTitle,
  okText,
  cancelText,
  open,
  dialogContent,
  handleConfirmDialog,
  handleCloseDialog,
}) => {
  const handleClose = () => {
    handleCloseDialog();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleConfirmDialog();
            }}
          >
            {okText}
          </Button>
          <Button
            onClick={() => {
              handleCloseDialog();
            }}
            autoFocus
          >
            {cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
