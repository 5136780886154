import { cloneDeep, mergeWith } from 'lodash';
import Papa from 'papaparse';
const brain = require('brain.js');

const cleanValue = value =>
  !!value ? value.toString().replace(/[^a-zA-Z0-9]/g, '_') : value;

export const denormalizeData = ({
  data,
  dictionary,
  threshold = 0.7,
}: {
  data?: any;
  dictionary?: any;
  threshold?: number;
}) => {
  const dataDenormalized = {};

  Object.keys(data).forEach(key => {
    const entry = dictionary[key];

    if (data[key] >= threshold) {
      if (!(entry.key in dataDenormalized)) dataDenormalized[entry.key] = [];
      dataDenormalized[entry.key].push(entry.value);
    }
  });

  return dataDenormalized;
};

export const enumerate = count => {
  if (count === Infinity) return [];
  return '0'
    .repeat(count)
    .split('')
    .map((_, index) => index);
};

export const filterData = ({
  data,
  threshold = 0.7,
  weight = 1,
}: {
  data?: any;
  threshold?: number;
  weight?: number;
}) => {
  const dataFiltered = { ...data };

  Object.keys(dataFiltered).forEach(key => {
    if (dataFiltered[key] < threshold) delete dataFiltered[key];
    else dataFiltered[key] = weight;
  });

  return dataFiltered;
};

export const deepMergeObjects = (priorValue, newValue) => {
  const customizer = (priorValue, newValue) =>
    Array.isArray(newValue) ? newValue : undefined;

  return mergeWith(cloneDeep(priorValue), cloneDeep(newValue), customizer);
};

export const getRandomizedObject = data => {
  const indexUsed: any = [];
  //console.log('debug', data);

  return Object.keys(data[0]).map(key => {
    const indexes = enumerate(data.length).filter(a => !indexUsed.includes(a));

    let value = null;

    while (!value) {
      const index = indexes.splice(
        Math.round(Math.random() * (indexes.length - 1)),
        1,
      )[0];

      //console.log('debug', indexes, index, data[index]);

      if (!index) break;
      value = data[index][key];
      if (!!value) indexUsed.push(index);
    }

    return value;
  });
};

export const getRandomizedObjectFieldValues = (data, count = 3) => {
  return Object.keys(data[0]).map(key => {
    const indexUsed: any = [];

    return enumerate(count).map(c => {
      const indexes = enumerate(data.length).filter(
        a => !indexUsed.includes(a),
      );

      let value: any = null;
      let index: any = null;

      while (!value) {
        index = indexes.splice(
          Math.round(Math.random() * (indexes.length - 1)),
          1,
        )[0];

        //console.log('debug', indexes, index, data[index]);

        if (!index) break;
        else if (
          !!data[index][key] &&
          data[index][key].toString().length >= 1
        ) {
          value = data[index][key];
          indexUsed.push(index);
        } else value = null;
      }
      return value;
    });
  });
};

export const historyMask = () => ({
  _id: true,
  Description: true,
  Name: true,
  orgId: true,
  Facets: {
    address: true,
    county: true,
    customerId: true,
    distance: true,
    hasEmail: true,
    hasPhone: true,
    interests: true,
    is4YrGrad: true,
    limit: true,
    minDonationCapacity: true,
    minEstimatedHomeValue: true,
    minEstimatedIncome: true,
    name: true,
    order: true,
    ideology: true,
    religion: true,
    region: true,
    sort: true,
    state: true,
  },
});

export const newFacets = (preset = {}) => ({
  Facets: {
    address: '',
    county: [],
    customerId: '',
    distance: 10,
    hasEmail: false,
    hasPhone: false,
    interests: [],
    is4YrGrad: false,
    latLon: null,
    limit: 1000,
    minDonationCapacity: 0,
    minEstimatedHomeValue: 0,
    minEstimatedIncome: 0,
    name: '',
    order: 'desc',
    ideology: [],
    religion: [],
    region: [],
    sort: 'donation_capacity',
    state: [],
    ...preset,
  },
});

export const normalizeData = ({
  data,
  dictionary,
  keys,
  onValue,
  weight = 1,
}: {
  data?: any;
  dictionary?: any;
  keys?: any;
  onValue?: any;
  weight?: number;
}) => {
  const dataNormalized = {};
  const keysSafe = keys ?? Object.keys(data);

  keysSafe.forEach(key => {
    const value = !!onValue ? onValue(key, data[key]) : data[key];
    if (typeof value === 'undefined') return;

    const keyFormatted = cleanValue(key);
    const valueFormated = cleanValue(value);
    const entry = `${keyFormatted.toLowerCase()}_${valueFormated.toLowerCase()}`;
    dictionary[entry] = { key, value };
    dataNormalized[entry] = weight;
  });

  return dataNormalized;
};

export const mlRun = async ({
  callback = () => {},
  file,
}: {
  callback?: any;
  file: any;
}) => {
  const dictionary = {};

  console.clear();

  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: async results => {
      const data = results.data; //.splice(0, 1);

      const net = new brain.NeuralNetwork({
        iterations: 100,
        errorThresh: 0.005,
        learningRate: 0.01,
        momentum: 0.01,
      });

      net.train(
        data.map(prospect => {
          const normalizedProspect = normalizeData({
            data: prospect,
            dictionary,
            weight: 1,
            onValue: (key, value) => {
              if (key === 'donation capacity')
                return (Math.floor(parseFloat(value) / 5000) * 5000).toString();
              else if (key === 'general election turnout')
                return parseFloat(value).toFixed(1).toString();
              else if (!!value) return value;
              else return undefined;
            },
            keys: [
              'donates to animal welfare',
              'donates to arts and culture',
              'donates to childrens causes',
              'donates to conservative causes',
              'donates to healthcare',
              'donates to international aid causes',
              'donates to liberal causes',
              'donates to local community',
              'donates to political causes',
              'donates to veterans causes',
              'donates to wildlife preservation',
              'donation capacity',
              'is 4yr graduate',
              'modeled religion',
              'party score',
              'postal code',
              'state',
            ],
          });

          return {
            input: normalizedProspect,
            output: normalizedProspect,
          };
        }),
      );

      console.log('debug', 'dictionary', dictionary);

      let output = {};
      let outputPrior = {};
      let threshold = 0.7;
      for (let c = 1; c <= 10; c++) {
        const data = await net.run(output);
        output = filterData({ data, threshold });
        console.log('debug', 'output', c, threshold.toFixed(2), data, output);
        if (JSON.stringify(output) === JSON.stringify(outputPrior)) break;
        outputPrior = { ...output };
        threshold -= 0.05;
      }

      callback(denormalizeData({ data: output, dictionary }));
    },
  });
};

export const splitArray = (array, chunkSize = 100, preserve = true) => {
  const chunks: any = [];
  const source: any = preserve ? [...(array ?? [])] : array;

  while (source?.length) chunks.push(source.splice(0, chunkSize));

  return chunks;
};

export const calculateFieldAccuracy = ({ data }: { data?: any }) => {
  const dataAccuracy = {};

  data.forEach(item => {
    Object.keys(item).forEach(key => {
      if (!!item[key]) dataAccuracy[key] = (dataAccuracy[key] ?? 0) + 1;
    });
  });

  Object.keys(dataAccuracy).forEach(key => (dataAccuracy[key] /= data.length));
  return dataAccuracy;
};

export const abreviateNumber = value => {
  let dividend = 1;
  let suffix = '';

  if (value >= 1000000) {
    dividend = 1000000;
    suffix = 'M';
  } else if (value >= 1000) {
    dividend = 1000;
    suffix = 'K';
  }

  return `${(value / dividend)
    .toFixed(1)
    .toString()
    .replace(/\.0/gi, '')}${suffix}`;
};

export const convertToRange = (
  value,
  abreviatedNumbers = true,
  currency = true,
) => {
  value = parseFloat(value);
  const span = value * 0.15;
  const dollarSign = currency ? '$' : '';
  const min = value - span;
  const max = value + span;

  return `${dollarSign}${
    abreviatedNumbers ? abreviateNumber(min) : min.toLocaleString()
  } - ${dollarSign}${
    abreviatedNumbers ? abreviateNumber(max) : max.toLocaleString()
  }`;
};
