import React, { useEffect, useRef, useState } from 'react';
import { FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import { InsetLabel } from '../InsetLabel';

export const AddressLookup = ({
  className = '',
  disabled = false,
  onChange = e => {},
  placeholder = 'Enter address',
  showValueDistance = true,
  useInsetLabel = true,
  value = '',
  valueDistance = 10,
}: {
  className?: string;
  disabled?: boolean;
  onChange?: any;
  placeholder?: string;
  showValueDistance?: boolean;
  useInsetLabel?: boolean;
  value?: any;
  valueDistance?: number;
}) => {
  const [address, setAddress] = useState<any>(value);
  const addressPrior = useRef<any>(null);
  const [distance, setDistance] = useState<number>(valueDistance);
  const interval = useRef<any>(null);
  const intervalSpan = 500;
  const loading = useRef<boolean>(false);
  const lastUpdated = useRef<any>(null);
  const [latLon, setLatLong] = useState<any>(null);
  const LabelControl = useInsetLabel ? InsetLabel : FloatingLabel;

  const updateDistance = e => {
    setDistance(e.target.value);
    onChange({
      target: {
        value: { address, distance: e.target.value, latLon: latLon },
      },
    });
  };

  useEffect(() => {
    setAddress(value ?? '');
  }, [value]);

  useEffect(() => {
    interval.current = setInterval(async () => {
      if (
        !loading.current &&
        !!address &&
        address !== addressPrior.current &&
        Date.now() - lastUpdated.current >= intervalSpan
      ) {
        loading.current = true;
        addressPrior.current = address;

        const response = await (
          await fetch(
            `https://nominatim.openstreetmap.org/search?&format=json&polygon=1&addressdetails=1&q=${address}`,
          )
        ).json();

        const newLatLon =
          !!response?.[0] && !!response?.[0]?.lat
            ? {
                lat: parseFloat(response[0].lat),
                lon: parseFloat(response[0].lon),
              }
            : null;
        setLatLong(newLatLon);
        onChange({
          target: {
            value: { address, distance, latLon: newLatLon },
            valueAddress: response[0].address,
          },
        });

        loading.current = false;
      } else if (
        address !== addressPrior.current &&
        Date.now() - lastUpdated.current >= intervalSpan
      ) {
        addressPrior.current = address;
        const newLatLon = null;
        setLatLong(newLatLon);
        onChange({
          target: { value: { address, distance, latLon: newLatLon } },
        });
      }
    }, intervalSpan);
    return () => clearInterval(interval.current);
  }, [address, distance, onChange]);

  return (
    <div className={`${className}`}>
      <InputGroup>
        <LabelControl label="Address">
          <Form.Control
            disabled={disabled}
            placeholder={placeholder}
            value={address}
            onChange={e => setAddress(e.target.value)}
            onKeyUp={() => (lastUpdated.current = Date.now())}
          />
        </LabelControl>
        {!!showValueDistance && !!latLon?.lat && (
          <LabelControl label="Distance">
            <Form.Control
              disabled={disabled}
              type="number"
              value={distance}
              onChange={updateDistance}
            />
          </LabelControl>
        )}
      </InputGroup>
      {!!showValueDistance && !!latLon?.lat && (
        <Form.Text muted>
          {distance} miles around{' '}
          {`${latLon.lat.toFixed(2)}, ${latLon.lon.toFixed(2)}`}
        </Form.Text>
      )}
    </div>
  );
};
