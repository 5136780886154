import React, { useCallback, useEffect, useState } from 'react';
import { updateProspectIngestionHomeValues } from 'api/prospect.ingestion.service';
import { getProspectTrackerListWS } from 'api/prospect.tracker.service';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-bootstrap/Modal';
import { ProgressBar } from 'react-bootstrap';
import source from './source';

const ScrapeModal = ({
  getQueryAndSortBasedOnViewMode,
  onHide,
  show,
  userLogged,
}) => {
  const _DRE = React.useMemo(() => (window as any)._DRE, []);
  const [currentAddress, setCurrentAddress] = useState<any>();
  const [addresses, setAddresses] = useState<any>(null);
  const [processedAddress, setProcessedAddress] = useState<any[]>([]);
  const [totalProcessed, setTotalProcessed] = useState(0);
  const [total, setTotal] = useState(0);
  const threadResponse = {};

  (window as any).setThreadResponse = async (threadId, completed, response) => {
    console.log('--- setThreadResponse', threadId, completed, response);

    if (completed) {
      const estimatedHomeValue = parseInt(response.replace(/[^0-9]/g, ''), 10);
      const estimatedHouseholdIncome = Math.floor(estimatedHomeValue * 0.25);
      const donationCapacity = Math.floor(estimatedHomeValue * 0.001);

      await updateProspectIngestionHomeValues({
        ...currentAddress,
        email: userLogged?.email,
        estimatedHomeValue: `$${estimatedHomeValue.toLocaleString('en-US')}`,
        estimatedHouseholdIncome: `$${estimatedHouseholdIncome.toLocaleString(
          'en-US',
        )}`,
        donationCapacity: `$${donationCapacity.toLocaleString('en-US')}`,
      });
    }

    setProcessedAddress([...processedAddress, currentAddress.checksum]);
    setTotalProcessed(totalProcessed + 1);
    setCurrentAddress(addresses.shift());

    threadResponse[threadId] = { completed, response };
  };

  const getHomeValue = useCallback(async () => {
    if (processedAddress.includes(currentAddress.checksum))
      setCurrentAddress(null);
    else {
      const wealthSource = source[currentAddress.wealthSource];
      const steps = JSON.stringify(
        wealthSource.steps.map(step => ({
          ...step,
          command: step.command(wealthSource.vars, currentAddress),
        })),
      );

      await _DRE.postMessage(
        JSON.stringify({
          type: 'headless',
          steps,
          threadId: 'thread1',
        }),
      );
    }
  }, [_DRE, currentAddress, processedAddress]);

  useEffect(() => {
    if (show && !addresses)
      getProspectTrackerListWS(
        {
          email: userLogged!.email,
          sessionId: uuidv4(),
          all: true,
          query: {
            ...getQueryAndSortBasedOnViewMode().query,
            donationScore: [0],
          },
        },
        response => {
          const sources = Object.keys(source);

          const addresses = response.map(item => ({
            RegistrationAddress1: item.RegistrationAddress1,
            RegistrationAddress2: item.RegistrationAddress2,
            RegistrationAddressCity: item.RegistrationAddressCity,
            RegistrationAddressState: item.RegistrationAddressState,
            RegistrationAddressZip5: item.RegistrationAddressZip5,
            wealthSource: sources[Math.floor(Math.random() * sources.length)],
            fullAddress: `${(item.RegistrationAddress1 ?? '')
              .toString()
              .trim()} ${(item.RegistrationAddress2 ?? '')
              .toString()
              .trim()} ${(item.RegistrationAddressCity ?? '')
              .toString()
              .trim()} ${(item.RegistrationAddressState ?? '')
              .toString()
              .trim()} ${(item.RegistrationAddressZip5 ?? '')
              .toString()
              .trim()}`
              .toUpperCase()
              .replace(/\s+/g, ' '),
            checksum: `${(item.RegistrationAddress1 ?? '')
              .toString()
              .trim()}-${(item.RegistrationAddress2 ?? '')
              .toString()
              .trim()}-${(item.RegistrationAddressCity ?? '')
              .toString()
              .trim()}-${(item.RegistrationAddressState ?? '')
              .toString()
              .trim()}-${(item.RegistrationAddressZip5 ?? '')
              .toString()
              .trim()}`
              .toUpperCase()
              .replace(/\s/g, '-'),
          }));

          const checksums = addresses.map(a => a.checksum);

          const uniqueAddresses = addresses
            .filter(item => !!item.RegistrationAddress1)
            .filter(
              (item, index) => index === checksums.indexOf(item.checksum),
            );

          setTotal(uniqueAddresses.length);
          if (!!uniqueAddresses?.[0])
            setCurrentAddress(uniqueAddresses.shift());
          setAddresses(uniqueAddresses);
        },
      );
  }, [_DRE, addresses, getQueryAndSortBasedOnViewMode, show, userLogged]);

  useEffect(() => {
    if (!!currentAddress) getHomeValue();
  }, [currentAddress, getHomeValue]);

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Wealth Scraper</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!total && (
          <ProgressBar
            now={totalProcessed}
            max={total}
            label={`${totalProcessed}/${total} (${
              (totalProcessed / total) * 100
            }%)`}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ScrapeModal;
