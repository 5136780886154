import constants from 'app/config/constants';
import axiosInstance from 'utils/api';

export const insertProspectIngestionRecords = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/prospectIngestion/insertMany`;

  return await axiosInstance().post(url, body);
};

export const removeRecord = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/prospectIngestion/${body._id}/${
    body?.InternalReference_ID ?? body._id
  }`;

  return await axiosInstance().delete(url);
};

export const removeRecordLists = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/prospectIngestion/list/${body._id}`;

  return await axiosInstance().delete(url);
};

export const updateProspectIngestionHomeValues = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/prospectIngestion/updateHomeValues`;

  return await axiosInstance().post(url, body);
};
