import { configureAppStore } from 'store/configureStore';

/*
    Moved redux store to its own module.
    Doing so made it global

*/

const store = configureAppStore();

export default store;
