import React from 'react';
import ToolTip from '../ToolTip';
import { BarChart } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';

export const ReportsButton = ({ isMobile }) => {
  return (
    <ToolTip title="Reports" placement="bottom">
      <Button
        variant="link"
        href="/reports"
        className={`fs-3 p-0 text-decoration-none text-start w-100 ${
          isMobile ? 'text-dark ' : 'text-light px-2'
        }`}
      >
        <BarChart />
        {isMobile && <>&nbsp; Reports</>}
      </Button>
    </ToolTip>
  );
};
