import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const ContactViewRecord = ({
  cellTemplateList,
  idField,
  row,
  rowHeight,
  rowIndex,
  rowIndexLabel,
  rowVisibility,
  showModal,
  templates,
}) => {
  const colRef = React.useRef<HTMLElement>(null);
  const visibility = rowVisibility?.[rowIndexLabel];

  return (
    <Col
      ref={colRef}
      data-id={row?.[idField]}
      data-index={rowIndexLabel}
      data-row={true}
      data-visible={visibility}
      lg={3}
      xs={12}
      md={6}
      className="mt-3"
      {...(!visibility ? { style: { height: `${rowHeight}px` } } : {})}
    >
      <Card style={visibility ? {} : { minHeight: '150px' }}>
        {visibility && (
          <>
            <Card.Header>
              <Row xs="auto">
                {cellTemplateList.map(
                  (cellTemplate, index) =>
                    cellTemplate?.showInMobileHeader && (
                      <Col
                        className="pe-0"
                        key={`row-${row?.[idField]}-${cellTemplate.field}-${index}`}
                      >
                        <small>
                          {cellTemplate.contentHandler(
                            row,
                            cellTemplate,
                            rowIndex,
                            showModal,
                          )}
                        </small>
                      </Col>
                    ),
                )}
              </Row>
            </Card.Header>
            <Card.Body>
              <Row xs="auto">
                {cellTemplateList.map(
                  (cellTemplate, index) =>
                    cellTemplate?.showInMobileBody && (
                      <Col
                        key={`row-${row?.[idField]}-${cellTemplate.field}-${index}`}
                      >
                        <small>
                          {cellTemplate.contentHandler(
                            row,
                            templates,
                            cellTemplate,
                            rowIndex,
                            showModal,
                          )}
                        </small>
                      </Col>
                    ),
                )}
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row xs="auto">
                {cellTemplateList.map(
                  (cellTemplate, index) =>
                    cellTemplate?.showInMobileFooter && (
                      <Col
                        key={`row-${row?.[idField]}-${cellTemplate.field}-${index}`}
                      >
                        <small>
                          {cellTemplate.contentHandler(
                            row,
                            templates,
                            cellTemplate,
                            rowIndex,
                            showModal,
                          )}
                        </small>
                      </Col>
                    ),
                )}
              </Row>
            </Card.Footer>
          </>
        )}
      </Card>
    </Col>
  );
};

export default ContactViewRecord;
