import Badge from 'react-bootstrap/Badge';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { TbCircleFilled } from 'react-icons/tb';

import farm_flag_icon from '../../assets/FarmFlag.png';
import local_elections_icon from '../../assets/localelection.png';
import pro_2a_icon from '../../assets/2ndamendment.png';
import prochoice_icon from '../../assets/prochoice.png';
import prolife_icon from '../../assets/prolife.png';
import sportsmen_icon from '../../assets/sportsmen.png';
import veterans_icon from '../../assets/veterans.png';
import v_donates_to_animal_welfare_icon from '../../assets/animalflag.png';
import v_donates_to_arts_and_culture_icon from '../../assets/artsandcultureflag.png';
import v_donates_to_childrens_causes_icon from '../../assets/childrens causes.png';
import v_donates_to_healthcare_icon from '../../assets/healthcareflag.png';
import v_donates_to_international_aid_causes_icon from '../../assets/internationalaidflag.png';
import v_donates_to_liberal_causes_icon from '../../assets/liberal causes.png';
import v_donates_to_local_community_icon from '../../assets/local community flag.png';
import v_donates_to_wildlife_preservation_icon_ from '../../assets/wildlifepresservationflag.png';
import { toast } from 'react-toastify';
import { stripHtml } from 'string-strip-html';
import { postToApp } from 'api/app.service';

const bluePill = {
  color: '#1d88fe',
  backgroundColor: '#eaf4ff',
};

const greyPill = {
  filter: 'grayscale()',
  color: '#ff5a65',
  backgroundColor: '#ffeff0',
};

const greenPill = {
  color: '#14ca74',
  backgroundColor: '#def2e6',
};

const redPill = {
  color: '#ff5a65',
  backgroundColor: '#ffeff0',
};

const yellowPill = {
  color: '#ff9e2c',
  backgroundColor: '#fff3e4',
};

const compare = (a, b) => JSON.stringify(a) === JSON.stringify(b);

const convertCurrency = currency => {
  if (!!currency) {
    if (typeof currency === 'number')
      return parseFloat(currency).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    else return currency;
  } else return '';
};

const convertSuffixToValue = suffix => {
  if (suffix === 'M+') return 1000000;
  if (suffix === 'M') return 1000000;
  if (suffix === 'K+') return 1000;
  if (suffix === 'K') return 1000;
  else return 1;
};

const convertValueToPillColor = (text, value, expectSmallDollor = false) => {
  if (expectSmallDollor) {
    if (value >= 5000) return greenPill;
    else if (value >= 500) return bluePill;
    else if (value >= 250) return yellowPill;
    else if (text.toUpperCase() === 'UNKNOWN') return greyPill;
    else return redPill;
  } else {
    if (value >= 1000000) return greenPill;
    else if (value >= 50000) return bluePill;
    else if (value >= 30000) return yellowPill;
    else if (text.toUpperCase() === 'UNKNOWN') return greyPill;
    else return redPill;
  }
};

const displayInterests = interests => {
  if (!interests) return null;

  const interestIndex = {
    FarmFlag: {
      image: farm_flag_icon,
      tooltip: 'This donor supports farming.',
    },
    LocalElectionFlag: {
      image: local_elections_icon,
      tooltip: 'This donor frequently votes in local elections.',
    },
    ProChoiceFlag: {
      image: prochoice_icon,
      tooltip: 'This donor is prochoice.',
    },
    ProLifeFlag: {
      image: prolife_icon,
      tooltip: 'This donor is prolife.',
    },
    SecondAmendmentSupporterFlag: {
      image: pro_2a_icon,
      tooltip: 'This donor is a 2nd Amendment supporter.',
    },
    SportsmenFlag: {
      image: sportsmen_icon,
      tooltip: 'This donor is an avid sportsman.',
    },
    VeteransFlag: {
      image: veterans_icon,
      tooltip: 'This donor supports veterans.',
    },
    V_DONATES_TO_ANIMAL_WELFARE: {
      image: v_donates_to_animal_welfare_icon,
      tooltip: 'This donor supports animal welfare.',
    },
    V_DONATES_TO_ARTS_AND_CULTURE: {
      image: v_donates_to_arts_and_culture_icon,
      tooltip: 'This donor supports arts and culture.',
    },
    V_DONATES_TO_CHILDRENS_CAUSES: {
      image: v_donates_to_childrens_causes_icon,
      tooltip: "This donor supports children's causes.",
    },
    V_DONATES_TO_HEALTHCARE: {
      image: v_donates_to_healthcare_icon,
      tooltip: 'This donor supports healthcare.',
    },
    V_DONATES_TO_INTERNATIONAL_AID_CAUSES: {
      image: v_donates_to_international_aid_causes_icon,
      tooltip: 'This donor supports international aid causes.',
    },
    V_DONATES_TO_LIBERAL_CAUSES: {
      image: v_donates_to_liberal_causes_icon,
      tooltip: 'This donor supports liberal causes.',
    },
    V_DONATES_TO_LOCAL_COMMUNITY: {
      image: v_donates_to_local_community_icon,
      tooltip: 'This donor supports the local community.',
    },
    V_DONATES_TO_WILDLIFE_PRESERVATION: {
      image: v_donates_to_wildlife_preservation_icon_,
      tooltip: 'This donor supports wildlife preservation.',
    },
  };

  return (
    <div style={{ overflow: 'hidden', width: 'fit-content' }}>
      {interests
        .map(
          interest =>
            !!interestIndex?.[interest] && (
              <OverlayTrigger
                overlay={
                  <Tooltip>{interestIndex?.[interest]?.tooltip}</Tooltip>
                }
              >
                <Image
                  alt=""
                  style={{ width: '25px' }}
                  src={interestIndex?.[interest]?.image}
                />
              </OverlayTrigger>
            ),
        )
        .filter(a => a)}
    </div>
  );
};

const enumerate = count => {
  if (count === Infinity) return [];
  return '0'
    .repeat(count)
    .split('')
    .map((_, index) => index);
};

const getActiveSMTPCredentialsID = smtpCredentials => {
  return smtpCredentials.filter(a => a.isActive)?.[0]?._id;
};

const getClosestElement = (selector, indexAttribute = 'data-page-index') => {
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  const nearest = Array.from(document.querySelectorAll(selector))
    .map(element => {
      const top = element.getBoundingClientRect().top ?? 0;
      const bottom = element.getBoundingClientRect().bottom ?? 0;
      const distance = Math.min(
        Math.abs(0 - top),
        Math.abs(windowHeight - bottom),
      );

      return {
        distance,
        index: element.getAttribute(indexAttribute) ?? 0,
      };
    })
    .sort((a, b) => a.distance - b.distance)?.[0];

  return !!nearest && nearest?.distance <= windowHeight ? nearest : null;
};

const getColorBasedOnPropensity = propensity => {
  if (propensity >= 0.9) return '5';
  else if (propensity >= 0.75) return '4';
  else if (propensity >= 0.5) return '3';
  else if (propensity >= 0.25) return '2';
  else return '1';
};

const getEmailHRef = (
  appEnabled,
  body,
  html,
  subject,
  smtpCredentials,
  smtpFormEnabled,
  to,
) =>
  appEnabled
    ? '#'
    : smtpFormEnabled
    ? `/smtp-form?from=${getActiveSMTPCredentialsID(
        smtpCredentials,
      )}&to=${to}&html=${html}&subject=${subject}`
    : `mailto:${to}?body=${body}&subject=${subject}`;

const getEmailOnClick = (
  appEnabled,
  message,
  row,
  smtpCredentials,
  userLogged,
  options,
) =>
  !appEnabled
    ? null
    : async e => {
        e.preventDefault();

        const activeSMTPCredentials = smtpCredentials.filter(
          a => a._id === options.activeSMTPCredentials,
        )?.[0];

        const subject = message
          .match(/([a-z0-9]+)|([^a-z0-9]+)/gi)
          .slice(0, 20)
          .join('');

        await postToApp(userLogged?.appIp, {
          action: 'email',
          message,
          recipient: row?.Email,
          smtpEmail: activeSMTPCredentials.email,
          smtpPassword: activeSMTPCredentials.password,
          subject,
        });

        toast.info('Email Sent to DRE App');
      };

const getEmailTarget = (appEnabled, href, smtpFormEnabled) =>
  (!!href && !appEnabled) || smtpFormEnabled ? '_blank' : '';

const getFinalStep = path => {
  const steps = path.split('.');
  return steps.slice(-1)?.[0];
};

const getIdeologyLabel = ideology => {
  if (!ideology) return '';
  else
    return [
      'Conservative',
      'Conservative Leaning',
      'Unaffiliated / Moderate',
      'Liberal Leaning',
      'Liberal',
    ][ideology - 1];
};

const getObjectValue = (object, path) => {
  const subObject = getSubObject(object, path);

  if (Array.isArray(subObject))
    return subObject.map(item => item[getFinalStep(path)]) ?? null;
  else return subObject[getFinalStep(path)] ?? null;
};

const getObjectDateValue = (object, path) => {
  const value = getObjectValue(object, path);

  if (!!value) return new Date(value).toISOString().split('T')[0];
};

const getPageHeight = () => {
  const pages =
    Array.from(
      document.querySelectorAll(
        `[data-page="true"][data-page-isloaded="true"][data-visible="true"]`,
      ),
    ) ?? [];

  return pages?.length
    ? Math.max(...pages.map(page => page.offsetHeight))
    : null;
};

const getPriorityIcon = priority => {
  const meta = {
    C_High: { color: 'danger', tooltip: 'Priority High' },
    B_Medium: { color: 'warning', tooltip: 'Priority Medium' },
    A_Low: { color: 'success', tooltip: 'Priority Low' },
    undefined: { color: 'dark', tooltip: 'Priority Undefined' },
  };

  return (
    <OverlayTrigger overlay={<Tooltip>{meta?.[priority]?.tooltip}</Tooltip>}>
      <span>
        <TbCircleFilled className={`text-${meta?.[priority]?.color}`} />
      </span>
    </OverlayTrigger>
  );
};

const getProcessedTemplate = (templates, options, use, row, noHTML = false) => {
  const useField =
    use === 'email' ? 'activeEmailTemplate' : 'activeSMSTemplate';

  let content =
    templates
      .map(template =>
        template.use === use && template._id === (options?.[useField] ?? '')
          ? template.content
          : null,
      )
      .filter(a => a !== null)?.[0] ?? '';

  Object.keys(row).forEach(key => {
    const regex = new RegExp(`\\[\\[${key}\\]\\]`, 'gi');
    const value = ['FirstName', 'LastName'].includes(key)
      ? properCase(row[key])
      : row[key];
    content = content.replace(regex, value);
  });

  return encodeURIComponent(
    noHTML ? stripHtml(content.replaceAll('<br>', '\r\n')).result : content,
  );
};

const getProcessedTemplateSubject = (templates, options, use, row) => {
  const useField =
    use === 'email' ? 'activeEmailTemplate' : 'activeSMSTemplate';

  let subject =
    templates
      .map(template =>
        template.use === use && template._id === (options?.[useField] ?? '')
          ? template.subject
          : null,
      )
      .filter(a => a !== null)?.[0] ?? '';

  Object.keys(row).forEach(key => {
    const regex = new RegExp(`\\[\\[${key}\\]\\]`, 'gi');
    const value = ['FirstName', 'LastName'].includes(key)
      ? properCase(row[key])
      : row[key];
    subject = subject.replace(regex, value);
  });

  return encodeURIComponent(subject);
};

const getRowHeight = () => {
  const rows =
    Array.from(
      document.querySelectorAll(`[data-row="true"][data-visible="true"]`),
    ) ?? [];
  return rows?.length ? Math.max(...rows.map(row => row.offsetHeight)) : null;
};

const getSMSHref = (appEnabled, row, message) =>
  appEnabled ? '#' : getSMSURL(row?.CellPhone_Full, message);

const getSMSOnClick = (appEnabled, row, message, userLogged) =>
  !appEnabled
    ? null
    : async e => {
        e.preventDefault();

        await postToApp(userLogged?.appIp, {
          action: 'sms',
          message,
          recipient: row?.CellPhone_Full,
        });

        toast.info('SMS Sent to App');
      };

const getSMSURL = (phone, message) => `sms:${phone};?&body=${message}`;

const getStatusPill = status => {
  const meta = {
    inProgress: {
      bgColor: 'primary',
      fontColor: 'light',
      label: 'In Progress',
      tooltip: 'In Progress',
    },
    lost: {
      bgColor: 'danger',
      fontColor: 'light',
      label: 'Lost',
      tooltip: 'Lost',
    },
    uncontacted: {
      bgColor: 'secondary',
      fontColor: 'light',
      label: 'Uncontacted',
      tooltip: 'Uncontacted',
    },
    won: {
      bgColor: 'success',
      fontColor: 'light',
      label: 'Won',
      tooltip: 'Won',
    },
    undefined: {
      bgColor: 'secondary',
      fontColor: 'light',
      label: 'Undefined Status',
      tooltip: 'Undefined Status',
    },
  };

  return (
    <OverlayTrigger overlay={<Tooltip>{meta?.[status]?.tooltip}</Tooltip>}>
      <Badge bg={meta?.[status]?.bgColor} text={meta?.[status]?.fontColor}>
        {meta?.[status]?.label}
      </Badge>
    </OverlayTrigger>
  );
};

const getSubObject = (object, path) => {
  const steps = path.split('.');
  let subObject = object;

  while (steps.length > 1) {
    const step = steps.shift();

    if (!(step in subObject)) subObject[step] = {};
    subObject = subObject[step];
  }

  return subObject;
};

const getTallestPageHeight = () => {
  return Array.from(document.querySelectorAll(`[data-page="true"]`))
    .map(page => page.offsetHeight)
    .sort()
    .reverse()?.[0];
};

const isElementVisible = element => {
  const top = element?.getBoundingClientRect().top ?? 0;
  const bottom = element?.getBoundingClientRect().bottom ?? 0;
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  return top < windowHeight && bottom > 0;
};

const isElementRefVisible = ref => isElementVisible(ref.current);

const isGapInRange = (data, idField, min, max) => {
  let priorRowIndex = -1;
  let gapFound = false;

  for (let c = min; c <= max; c++) {
    const rowIndex = Number(
      document
        .getElementById(`plan-view-row-${data?.[c]?.[idField]}`)
        ?.getAttribute('data-index'),
    );

    if (c > min && rowIndex - priorRowIndex > 1) gapFound = true;

    priorRowIndex = rowIndex;
  }

  return gapFound;
};

const properCase = value =>
  (value ?? '')
    .replace(/V_/gi, '')
    .replace(/_/g, ' ')
    .replaceAll(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .replace(/(\b[a-z])/g, v => v.toUpperCase());

const splitArray = (array, chunkSize = 100, preserve = true) => {
  const chunks = [];
  const source = preserve ? [...(array ?? [])] : array;

  while (source?.length) chunks.push(source.splice(0, chunkSize));

  return chunks;
};

const syncPageHeights = pagesLoaded => {
  const tallestHeight = getTallestPageHeight();

  const pages = document.querySelectorAll(`[data-page="true"]`);

  Array.from(pages).forEach((page, index) => {
    if (!pagesLoaded?.[index]) page.style.height = `${tallestHeight}px`;
    else page.style.height = 'auto';
  });
};

export {
  compare,
  convertCurrency,
  convertSuffixToValue,
  convertValueToPillColor,
  displayInterests,
  enumerate,
  getActiveSMTPCredentialsID,
  getClosestElement,
  getColorBasedOnPropensity,
  getEmailHRef,
  getEmailOnClick,
  getEmailTarget,
  getFinalStep,
  getIdeologyLabel,
  getObjectValue,
  getObjectDateValue,
  getPageHeight,
  getPriorityIcon,
  getProcessedTemplate,
  getProcessedTemplateSubject,
  getRowHeight,
  getSMSHref,
  getSMSOnClick,
  getSMSURL,
  getStatusPill,
  getSubObject,
  getTallestPageHeight,
  isElementVisible,
  isElementRefVisible,
  isGapInRange,
  properCase,
  splitArray,
  syncPageHeights,
};
