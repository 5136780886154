import React, { useEffect, useState } from 'react';
import { getMOADBRegions } from 'api/moadb.service';
import { MultiSelect } from '../MultiSelect';

export const MOADBDropdownRegions = ({
  className = '',
  disabled = false,
  value,
  onChange,
}) => {
  const [regions, setRegions] = useState<any[]>([]);

  useEffect(() => {
    getMOADBRegions().then(response => setRegions(response.data));
  }, []);

  return (
    <MultiSelect
      className={className}
      disabled={disabled}
      value={value}
      onChange={onChange}
      placeholder="Select a Region"
    >
      {(regions ?? []).map(region => (
        <option key={region} value={region}>
          {region}
        </option>
      ))}
    </MultiSelect>
  );
};
