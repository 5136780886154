import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import {
  convertCurrency,
  convertSuffixToValue,
  convertValueToPillColor,
  getEmailHRef,
  getEmailOnClick,
  getEmailTarget,
  getFinalStep,
  getObjectDateValue,
  getObjectValue,
  getProcessedTemplate,
  getProcessedTemplateSubject,
  getSMSHref,
  getSMSOnClick,
  getSubObject,
} from 'app/components/Prospect-Tracker/utility';
import {
  ChatLeftDots,
  CheckCircleFill,
  EnvelopeAt,
  ExclamationCircleFill,
  Telephone,
  XCircle,
} from 'react-bootstrap-icons';
import ToolTip from 'app/components/ToolTip';
import HorizontalMarkettingButton from '../HorizontalMarkettingButton';

const viewStandardFilterValueHandler = (data, fieldValue, fieldLabel) => {
  const values = data.reduce((p, c) => [...p, ...(c ?? [])], []);

  const ids = values.map(item => item?._id);

  const unique = values
    .map((value, index) => (ids.indexOf(value._id) === index ? value : null))
    .filter(a => a)
    .map(value => ({
      value: value?.[fieldValue],
      label: value?.[fieldLabel],
    }))
    .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));

  return unique;
};

const viewStandardOnChange = (cellTemplate, indexList, data, setData) => {
  return e => {
    const delta = indexList.map(index => {
      const object = data[index];
      const field = `${
        !!cellTemplate?.custom ? 'prospectTracker.custom.' : ''
      }${cellTemplate.field}`;

      const subObject = getSubObject(object, field);
      subObject[getFinalStep(field)] = e.target.value ?? e.currentTarget.value;

      return { index, data: object };
    });

    setData(delta);
  };
};

const viewStandardCurrencyContentHandler = (
  row,
  cellTemplate,
  columnOptions,
  indexList,
  data,
  setData,
) => {
  const objectValue = getObjectValue(row, cellTemplate.field) ?? 'Unknown';
  const suffix = objectValue
    .match(/[^0-9]+$/g)?.[0]
    ?.replace(/[\s]+/, '')
    .toUpperCase();
  const value =
    parseFloat(objectValue.replace(/-.*/g, '').replace(/[^0-9]+/g, '')) *
    convertSuffixToValue(suffix);

  return (
    <span
      className="px-2 py-1 rounded-pill"
      style={convertValueToPillColor(
        objectValue,
        value,
        cellTemplate?.expectSmallDollar,
      )}
    >
      {convertCurrency(objectValue)}
    </span>
  );
};

const viewStandardDateContentHandler = (
  row,
  cellTemplate,
  columnOptions,
  indexList,
  data,
  setData,
  disabled = false,
) => {
  return (
    <Form.Control
      disabled={disabled}
      size="sm"
      type="date"
      value={getObjectDateValue(row, cellTemplate.field) ?? ''}
      onChange={viewStandardOnChange(cellTemplate, indexList, data, setData)}
    />
  );
};

const viewStandardMobileButtonContentHandler = (
  medium,
  row,
  templates,
  cellTemplate,
  rowIndex,
  showModal,
  appEnabled,
  disabled = false,
) => {
  return (
    <Button
      disabled={disabled}
      variant="light"
      className="fs-1"
      href={!!cellTemplate?.href && cellTemplate.href(row, templates)}
      target={!!cellTemplate?.href ? '_blank' : ''}
      onClick={() => showModal(row, cellTemplate, rowIndex)}
      style={{ position: 'relative' }}
    >
      {medium === 'sms' &&
        ['Replied', 'Sent'].includes(row?.prospectTracker?.textResult) && (
          <CheckCircleFill className="buttonIcon green" />
        )}

      {medium === 'sms' &&
        ['Do Not Text'].includes(row?.prospectTracker?.textResult) && (
          <ExclamationCircleFill className="buttonIcon orange" />
        )}

      {medium === 'tel' &&
        [
          'Made Contact - Interested',
          'Made Contact - Not Now',
          'No Answer',
          'Other (See Notes)',
          'VM - Left Message',
          'VM - No Message',
        ].includes(row?.prospectTracker?.emailResult) && (
          <CheckCircleFill className="buttonIcon green" />
        )}
      {medium === 'tel' &&
        [
          'Bad Number / Wrong Person',
          'Do Not Call',
          'Made Contact - Not Interested',
        ].includes(row?.prospectTracker?.emailResult) && (
          <ExclamationCircleFill className="buttonIcon orange" />
        )}

      {medium === 'email' &&
        ['Opened', 'Replied', 'Sent'].includes(
          row?.prospectTracker?.emailResult,
        ) && <CheckCircleFill className="buttonIcon green" />}
      {medium === 'email' &&
        ['Bounced', 'Do Not Email'].includes(
          row?.prospectTracker?.emailResult,
        ) && <ExclamationCircleFill className="buttonIcon orange" />}

      {!disabled ? cellTemplate.icon : <XCircle />}
    </Button>
  );
};

const viewStandardOutboundContentHandler = (
  row,
  templates,
  cellTemplate,
  appEnabled,
  userLogged,
  smtpCredentials,
  smtpFormEnabled,
  setFormsMedium,
  setFormsRow,
  setShowFormsModal,
  setShowOptionsModal,
  retrieveTemplatesAndSMTP,
  options,
) => {
  return (
    <ButtonGroup>
      <ToolTip
        placement="top"
        title={
          appEnabled
            ? 'Send SMS through DRE App'
            : 'Open app associated with SMS'
        }
      >
        <Dropdown as={ButtonGroup}>
          <Button
            disabled={!row?.CellPhone_Full}
            variant={appEnabled ? 'primary' : 'dark'}
            className="fs-5"
            href={getSMSHref(
              appEnabled,
              row,
              getProcessedTemplate(templates, options, 'sms', row),
            )}
            target={!!cellTemplate?.href ? '_blank' : undefined}
            onClick={getSMSOnClick(
              appEnabled,
              row,
              getProcessedTemplate(templates, options, 'sms', row),
              userLogged,
            )}
          >
            {row?.CellPhone_Full ? <ChatLeftDots /> : <XCircle />}
            {['Replied', 'Sent'].includes(row?.prospectTracker?.textResult) && (
              <CheckCircleFill className="buttonIcon green" />
            )}
            {['Do Not Text'].includes(row?.prospectTracker?.textResult) && (
              <ExclamationCircleFill className="buttonIcon orange" />
            )}
          </Button>

          <Dropdown.Toggle
            variant={appEnabled ? 'primary' : 'dark'}
            disabled={!row?.CellPhone_Full}
          />

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setFormsMedium('sms');
                setFormsRow(row);
                setShowFormsModal('polls');
              }}
            >
              Send Poll
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setFormsMedium('sms');
                setFormsRow(row);
                setShowFormsModal('petitions');
              }}
            >
              Send Petition
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item target="_blank" href="/template-manager">
              Manage Templates
            </Dropdown.Item>
            <Dropdown.Item onClick={retrieveTemplatesAndSMTP}>
              Refresh Templates
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setShowOptionsModal(true)}>
              Set Active SMS Template
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ToolTip>
      <ToolTip
        placement="top"
        title={appEnabled ? null : 'Open app associated with calls'}
      >
        <Button
          disabled={!row?.CellPhone_Full || appEnabled}
          variant={appEnabled ? 'primary' : 'dark'}
          className="fs-5"
          href={`tel: ${row?.CellPhone_Full}`}
          target={!!cellTemplate?.href ? '_blank' : ''}
        >
          {row?.CellPhone_Full ? <Telephone /> : <XCircle />}
          {[
            'Made Contact - Interested',
            'Made Contact - Not Now',
            'No Answer',
            'Other (See Notes)',
            'VM - Left Message',
            'VM - No Message',
          ].includes(row?.prospectTracker?.emailResult) && (
            <CheckCircleFill className="buttonIcon green" />
          )}
          {[
            'Bad Number / Wrong Person',
            'Do Not Call',
            'Made Contact - Not Interested',
          ].includes(row?.prospectTracker?.emailResult) && (
            <ExclamationCircleFill className="buttonIcon orange" />
          )}
        </Button>
      </ToolTip>
      <ToolTip
        placement="top"
        title={
          appEnabled
            ? 'Add email to P2P queue'
            : 'Open app associated with email'
        }
      >
        <Dropdown as={ButtonGroup}>
          <Button
            disabled={!row?.Email}
            variant={appEnabled ? 'primary' : 'dark'}
            className="fs-5"
            href={getEmailHRef(
              appEnabled,
              getProcessedTemplate(templates, options, 'email', row, true),
              getProcessedTemplate(templates, options, 'email', row),
              getProcessedTemplateSubject(templates, options, 'email', row),
              smtpCredentials,
              smtpFormEnabled,
              row?.Email,
            )}
            target={getEmailTarget(
              appEnabled,
              cellTemplate?.href,
              smtpFormEnabled,
            )}
            onClick={getEmailOnClick(
              appEnabled,
              decodeURIComponent(
                getProcessedTemplate(templates, options, 'sms', row),
              ),
              row,
              smtpCredentials,
              userLogged,
              options,
            )}
          >
            {row?.Email ? <EnvelopeAt /> : <XCircle />}
            {['Opened', 'Replied', 'Sent'].includes(
              row?.prospectTracker?.emailResult,
            ) && <CheckCircleFill className="buttonIcon green" />}
            {['Bounced', 'Do Not Email'].includes(
              row?.prospectTracker?.emailResult,
            ) && <ExclamationCircleFill className="buttonIcon orange" />}
          </Button>

          <Dropdown.Toggle
            variant={appEnabled ? 'primary' : 'dark'}
            disabled={!row?.Email}
          />

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setFormsMedium('email');
                setFormsRow(row);
                setShowFormsModal('polls');
              }}
            >
              Send Poll
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setFormsMedium('email');
                setFormsRow(row);
                setShowFormsModal('petitions');
              }}
            >
              Send Petition
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item target="_blank" href="/smtp-manager">
              Manage SMTP
            </Dropdown.Item>
            <Dropdown.Item target="_blank" href="/template-manager">
              Manage Templates
            </Dropdown.Item>
            <Dropdown.Item onClick={retrieveTemplatesAndSMTP}>
              Refresh Templates
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setShowOptionsModal(true)}>
              Set Active Email Template
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ToolTip>
      <HorizontalMarkettingButton
        appEnabled={appEnabled}
        row={row}
        smtpCredentials={smtpCredentials}
        smtpFormEnabled={smtpFormEnabled}
        userLogged={userLogged}
      />
    </ButtonGroup>
  );
};

const viewStandardNumberContentHandler = (
  row,
  cellTemplate,
  columnOptions,
  indexList,
  data,
  setData,
) => {
  return (
    <Form.Control
      size="sm"
      type="number"
      value={getObjectValue(row, cellTemplate.field) ?? ''}
      onChange={viewStandardOnChange(cellTemplate, indexList, data, setData)}
    />
  );
};

const viewStandardSelectContentHandler = (
  row,
  cellTemplate,
  columnOptions,
  indexList,
  data,
  setData,
  options,
  disabled = false,
) => {
  return (
    <Form.Select
      disabled={disabled}
      size="sm"
      value={getObjectValue(row, cellTemplate.field) ?? ''}
      onChange={viewStandardOnChange(cellTemplate, indexList, data, setData)}
      style={{
        maxWidth: columnOptions?.width ?? 'fit-content',
        minWidth: columnOptions?.width ?? 'fit-content',
        width: columnOptions?.width ?? 'fit-content',
      }}
    >
      {options.map(item => (
        <option
          key={`${row._id}-${cellTemplate.field}-${item.props.value}`}
          value={item.props.value}
        >
          {item.props.children}
        </option>
      ))}
    </Form.Select>
  );
};

const viewStandardTextContentHandler = (
  row,
  cellTemplate,
  columnOptions,
  indexList,
  data,
  setData,
) => {
  return (
    <Form.Control
      size="sm"
      type="text"
      value={getObjectValue(row, cellTemplate.field) ?? ''}
      onChange={viewStandardOnChange(cellTemplate, indexList, data, setData)}
    />
  );
};

export {
  viewStandardFilterValueHandler,
  viewStandardOnChange,
  viewStandardCurrencyContentHandler,
  viewStandardDateContentHandler,
  viewStandardMobileButtonContentHandler,
  viewStandardOutboundContentHandler,
  viewStandardNumberContentHandler,
  viewStandardSelectContentHandler,
  viewStandardTextContentHandler,
};
