import React from 'react';
import { createPortal } from 'react-dom';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const LoadingOverlay = ({ show, status = null }) => {
  return (
    show &&
    createPortal(
      <div
        className="text-center text-light"
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          height: '100vh',
          left: '0px',
          paddingTop: '45vh',
          position: 'fixed',
          width: '100vw',
          textAlign: 'center',
          top: '0px',
          zIndex: 2000,
        }}
      >
        <Spinner />
        <div>{status}</div>
      </div>,
      document.body,
    )
  );
};

LoadingOverlay.propTypes = {
  show: PropTypes.bool,
};

LoadingOverlay.defaultProps = {};

export default LoadingOverlay;
