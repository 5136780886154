const Fields = {
  General: [
    {
      name: 'PREFIX_NAME',
      label: 'Prefix',
      type: 'text',
    },
    {
      name: 'FIRST_NAME',
      label: 'First Name',
      type: 'text',
    },
    {
      name: 'LAST_NAME',
      label: 'Last Name',
      type: 'text',
    },
    {
      name: 'MIDDLE_INITIAL',
      label: 'Middle Initital',
      type: 'text',
    },
    {
      name: 'MIDDLE_NAME',
      label: 'Middle Name',
      type: 'text',
    },
    {
      name: 'SUFFIX_NAME',
      label: 'Suffix',
      type: 'text',
    },
    {
      name: 'BIRTH_DATE',
      label: 'Birth Date',
      type: 'text',
    },
    {
      name: 'BIRTH_YEAR',
      label: 'Birth Year',
      type: 'number',
    },
    {
      name: 'PRIMARY_EMAIL',
      label: 'Primary Email',
      type: 'text',
    },
    {
      name: 'EMAIL_LIST',
      label: 'Email List',
      type: 'text',
    },
    {
      name: 'EMAIL_DELIVERABILITY_CODE_LIST',
      label: 'Email Deliverability Code List',
      type: 'text',
    },
    {
      name: 'EMAIL_MATCH_TYPE_LIST',
      label: 'Email Match Type List',
      type: 'text',
    },
    {
      name: 'CELL_PHONE_HUB224',
      label: 'Cell Phone (Hub224)',
      type: 'text',
    },
    {
      name: 'CELL_PHONE_ALESCO',
      label: 'Cell Phone (Alesco)',
      type: 'text',
    },
    {
      name: 'LANDLINE_PHONE__HUB224',
      label: 'Landline Phone (Hub224)',
      type: 'text',
    },
    {
      name: 'LANDLINE_PHONE_ALESCO',
      label: 'Landling Phone (Alesco)',
      type: 'text',
    },
    {
      name: 'OTHER_PHONE_ALESCO',
      label: 'Other Phone (Alesco)',
      type: 'text',
    },
    {
      name: 'ADDRESS_1',
      label: 'Address 1',
      type: 'text',
    },
    {
      name: 'ADDRESS_2',
      label: 'Address 2',
      type: 'text',
    },
    {
      name: 'CITY',
      label: 'City',
      type: 'text',
    },
    {
      name: 'STATE',
      label: 'State',
      type: 'text',
    },
    {
      name: 'POSTAL_CODE',
      label: 'Postal Code',
      type: 'number',
    },
    {
      name: 'JURISDICTION_NAME',
      label: 'Jurisdiction Name',
      type: 'text',
    },
    {
      name: 'US_HOUSE_DISTRICT',
      label: 'US House District',
      type: 'text',
    },
    {
      name: 'STATE_HOUSE_DISTRICT',
      label: 'State House District',
      type: 'text',
    },
    {
      name: 'STATE_SENATE_DISTRICT',
      label: 'State Senate District',
      type: 'text',
    },
    {
      name: 'DMA_MARKET_REGION',
      label: 'DMA Market Region',
      type: 'text',
    },
  ],
  Party: [
    {
      name: 'MOADB_VOTER_ID',
      label: 'Voter ID',
      type: 'text',
    },
    {
      name: 'MOADB_VOTER_ID_LEGACY',
      label: 'Voter ID Legacy',
      type: 'text',
    },
    {
      name: 'VOTER_STATUS',
      label: 'Voter Status',
      type: 'text',
    },
    {
      name: 'IDEOLOGY',
      label: 'Worldview/Values',
      type: 'number',
    },
    {
      name: 'MODELED_PARTY',
      label: 'Modeled Party',
      type: 'text',
    },
    {
      name: 'MODELED_SPECTRUM_SCORE',
      label: 'Modeled Spectrum Score',
      type: 'number',
    },
    {
      name: 'MODELED_SPECTRUM_BRACKETS',
      label: 'Modeled Spectrum Brackets',
      type: 'text',
    },
    {
      name: 'GENERAL_ELECTION_TURNOUT',
      label: 'General Election Turnout',
      type: 'number',
    },
  ],
  Wealth: [
    {
      name: 'HOUSEHOLD_INCOME_TOTAL',
      label: 'Household Income Total',
      type: 'number',
    },
    {
      name: 'HOUSEHOLD_INCOME_NET',
      label: 'Household Income Net',
      type: 'number',
    },
    {
      name: 'HOUSEHOLD_INCOME_EXPENDABLE',
      label: 'Household Expendable Income',
      type: 'number',
    },
    {
      name: 'DONATION_CAPACITY_FORMATTED',
      label: 'Donation Capacity (Formatted)',
      type: 'text',
    },
    {
      name: 'DONATION_CAPACITY',
      label: 'Donation Capacity',
      type: 'number',
    },
    {
      name: 'DONATION_CAPACITY_BRACKETS',
      label: 'Donation Capacity Brackets',
      type: 'text',
    },
    {
      name: 'ESTIMATED_HOME_VALUE',
      label: 'Estimated Home Value',
      type: 'number',
    },
    {
      name: 'ESTIMATED_RENTAL_VALUE',
      label: 'Estimated Rental Value',
      type: 'number',
    },
    {
      name: 'HOME_VALUE_ESTIMATED_AT',
      label: 'Home Valut Estimated At',
      type: 'number',
    },
  ],
  Interests: [
    {
      name: 'DONATES_TO_POLITICAL_CAUSES',
      label: 'Donates To Political Causes',
      type: 'yesno',
    },
    {
      name: 'DONATES_TO_ANIMAL_WELFARE',
      label: 'Donates To Animal Welfare',
      type: 'yesno',
    },
    {
      name: 'DONATES_TO_ARTS_AND_CULTURE',
      label: 'Donates To Arts And Culture',
      type: 'yesno',
    },
    {
      name: 'DONATES_TO_CHILDRENS_CAUSES',
      label: "Donates To Children's Causes",
      type: 'yesno',
    },
    {
      name: 'DONATES_TO_CONSERVATIVE_CAUSES',
      label: 'Donates To Conservative Causes',
      type: 'yesno',
    },
    {
      name: 'DONATES_TO_HEALTHCARE',
      label: 'Donates To Healthcare',
      type: 'yesno',
    },
    {
      name: 'DONATES_TO_INTERNATIONAL_AID_CAUSES',
      label: 'Donates To International Aid Causes',
      type: 'yesno',
    },
    {
      name: 'DONATES_TO_LIBERAL_CAUSES',
      label: 'Donates To Liberal Causes',
      type: 'yesno',
    },
    {
      name: 'DONATES_TO_LOCAL_COMMUNITY',
      label: 'Donates To Local Community',
      type: 'yesno',
    },
    {
      name: 'DONATES_TO_VETERANS_CAUSES',
      label: 'Donates To Veterans Causes',
      type: 'yesno',
    },
    {
      name: 'DONATES_TO_WILDLIFE_PRESERVATION',
      label: 'Donates To Wildlife Preservation',
      type: 'yesno',
    },
  ],
};

const getFieldByName: any = name => {
  if (!name) return null;

  let match = null;

  Object.keys(Fields).forEach(section => {
    Fields[section].forEach(element => {
      if (element.name === name) match = element;
    });
  });

  return match;
};

export default Fields;

export { getFieldByName };
