import React from 'react';

import {
  displayInterests,
  getColorBasedOnPropensity,
  getIdeologyLabel,
  getObjectValue,
  getPriorityIcon,
  getProcessedTemplate,
  getSMSURL,
  getStatusPill,
  properCase,
} from './utility';
import {
  MdOutlineCall,
  MdOutlineEdit,
  MdOutlineEmail,
  MdOutlineSms,
} from 'react-icons/md';
import {
  viewStandardCurrencyContentHandler,
  viewStandardDateContentHandler,
  viewStandardFilterValueHandler,
  viewStandardMobileButtonContentHandler,
  viewStandardNumberContentHandler,
  viewStandardOutboundContentHandler,
  viewStandardSelectContentHandler,
  viewStandardTextContentHandler,
} from './components/View/viewStandardContentHandlers';
import { OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';

const getAssigneeById = (assignees, id) =>
  assignees.filter(assignee => assignee._id === id)[0];

const newCellTemplateList = ({ assignees, list, options, userLogged }) => [
  //mobile header
  {
    showInMobileHeader: true,
    contentHandler: row => getPriorityIcon(row?.prospectTracker?.priority),
  },

  {
    showInMobileHeader: true,
    contentHandler: row =>
      `${properCase(row?.FirstName)} ${properCase(row?.LastName)}`,
  },

  {
    showInMobileHeader: true,
    contentHandler: row => (
      <small className="text-success">{row?.DonationCapacity}</small>
    ),
  },

  {
    showInMobileHeader: true,
    contentHandler: row => getStatusPill(row?.prospectTracker?.status),
  },

  //mobile body
  {
    href: (row, templates) =>
      getSMSURL(
        row?.CellPhone_Full,
        getProcessedTemplate(templates, options, 'sms', row),
      ),
    icon: <MdOutlineSms />,
    editableMobileFields: [
      'FirstName',
      'LastName',
      'CellPhone_AreaCode',
      'prospectTracker.donated',
      'prospectTracker.textResult',
      'prospectTracker.lastContacted',
      'prospectTracker.lastTexted',
      'prospectTracker.notes',
    ],
    showInMobileBody: true,
    contentHandler: (
      row,
      templates,
      cellTemplate,
      rowIndex,
      showModal,
      appEnabled,
    ) =>
      viewStandardMobileButtonContentHandler(
        'sms',
        row,
        templates,
        cellTemplate,
        rowIndex,
        showModal,
        appEnabled,
        !row?.CellPhone_Full,
      ),
  },
  {
    href: row => `tel: ${row?.CellPhone_Full}`,
    icon: <MdOutlineCall />,
    editableMobileFields: [
      'FirstName',
      'LastName',
      'CellPhone_AreaCode',
      'prospectTracker.donated',
      'prospectTracker.callResult',
      'prospectTracker.lastContacted',
      'prospectTracker.lastCalled',
      'prospectTracker.notes',
    ],
    showInMobileBody: true,
    contentHandler: (
      row,
      templates,
      cellTemplate,
      rowIndex,
      showModal,
      appEnabled,
    ) =>
      viewStandardMobileButtonContentHandler(
        'tel',
        row,
        templates,
        cellTemplate,
        rowIndex,
        showModal,
        appEnabled,
        !row?.CellPhone_Full,
      ),
  },
  {
    href: (row, templates) =>
      `mailto:${row?.Email}?body=${getProcessedTemplate(
        templates,
        options,
        'email',
        row,
      )}`,
    icon: <MdOutlineEmail />,
    editableMobileFields: [
      'FirstName',
      'LastName',
      'Email',
      'prospectTracker.donated',
      'prospectTracker.emailResult',
      'prospectTracker.lastContacted',
      'prospectTracker.lastEmailed',
      'prospectTracker.notes',
    ],
    showInMobileBody: true,
    contentHandler: (
      row,
      templates,
      cellTemplate,
      rowIndex,
      showModal,
      appEnabled,
    ) =>
      viewStandardMobileButtonContentHandler(
        'email',
        row,
        templates,
        cellTemplate,
        rowIndex,
        showModal,
        appEnabled,
        !row?.Email,
      ),
  },
  {
    icon: <MdOutlineEdit />,
    showInMobileBody: true,
    contentHandler: (row, templates, cellTemplate, rowIndex, showModal) =>
      viewStandardMobileButtonContentHandler(
        null,
        row,
        templates,
        cellTemplate,
        rowIndex,
        showModal,
      ),
  },

  //mobile footer
  {
    showInMobileFooter: true,
    contentHandler: row => displayInterests(row?.Meta),
  },

  //desktop / mobile popup
  {
    field: 'prospectList',
    header: 'List',
    isStatic: true,
    showInDesktop: true,
    showInCSV: true,
    contentHandler: row =>
      row?.prospectList
        ?.map(item => item.Name)
        .sort()
        .join(', '),
    filterValueHandler: data =>
      list
        .filter(item => !!item.Name)
        .map(item => ({ value: item._id, label: item.Name })),
    headerValueHandler: (values, data) => {
      const filteredData = viewStandardFilterValueHandler(data, '_id', 'Name');

      return values.map(
        value => filteredData.filter(item => item.value === value)?.[0]?.label,
      );
    },
  },

  {
    field: 'prospectTracker.status',
    header: 'Status',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) =>
      viewStandardSelectContentHandler(
        row,
        cellTemplate,
        columnOptions,
        indexList,
        data,
        setData,
        [
          <option value="">-- Select One---</option>,
          <option value="inProgress">In Progress</option>,
          <option value="lost">Lost</option>,
          <option value="uncontacted">Uncontacted</option>,
          <option value="won">Won</option>,
        ],
      ),
  },
  {
    field: 'prospectTracker.priority',
    header: 'Priority',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) =>
      viewStandardSelectContentHandler(
        row,
        cellTemplate,
        columnOptions,
        indexList,
        data,
        setData,
        [
          <option value="">-- Select One---</option>,
          <option value="C_High">High</option>,
          <option value="B_Medium">Medium</option>,
          <option value="A_Low">Low</option>,
        ],
      ),
  },
  {
    field: 'prospectTracker.donation',
    header: 'Donation',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) =>
      viewStandardNumberContentHandler(
        row,
        cellTemplate,
        columnOptions,
        indexList,
        data,
        setData,
      ),
  },
  {
    field: 'prospectTracker.lastContacted',
    header: 'Last Contacted',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) =>
      viewStandardDateContentHandler(
        row,
        cellTemplate,
        columnOptions,
        indexList,
        data,
        setData,
      ),
  },
  {
    field: 'FirstName',
    header: 'First',
    isStatic: true,
    showInDesktop: true,
    showInCSV: true,
    tagDescription: "Insert prospect's first name",
    contentHandler: row => properCase(row?.FirstName),
  },
  {
    field: 'LastName',
    header: 'Last',
    isStatic: true,
    showInDesktop: true,
    showInCSV: true,
    tagDescription: "Insert prospect's last name",
    contentHandler: row => properCase(row?.LastName),
  },
  {
    field: 'outbound',
    header: 'Outbound',
    hideInMobile: true,
    showInDesktop: true,
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
      appEnabled,
      userLogged,
      smtpCredentials,
      smtpFormEnabled,
      setFormsMedium,
      setFormsRow,
      setShowFormsModal,
      setShowOptionsModal,
      retrieveTemplatesAndSMTP,
      options,
    ) =>
      viewStandardOutboundContentHandler(
        row,
        templates,
        cellTemplate,
        appEnabled,
        userLogged,
        smtpCredentials,
        smtpFormEnabled,
        setFormsMedium,
        setFormsRow,
        setShowFormsModal,
        setShowOptionsModal,
        retrieveTemplatesAndSMTP,
        options,
      ),
  },
  {
    field: 'CellPhone_Full',
    header: 'Cell',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (row, templates, cellTemplate, columnOptions) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={`tel:${row?.CellPhone_Full}`}
        style={{
          display: 'inline-block',
          width: columnOptions?.width ?? null,
        }}
      >
        {row?.CellPhone_Full}
      </a>
    ),
  },
  {
    field: 'prospectTracker.lastCalled',
    header: 'Last Called',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) =>
      viewStandardDateContentHandler(
        row,
        cellTemplate,
        columnOptions,
        indexList,
        data,
        setData,
        !row?.CellPhone_Full,
      ),
  },
  {
    field: 'prospectTracker.callResult',
    header: 'Result of Call',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) =>
      viewStandardSelectContentHandler(
        row,
        cellTemplate,
        columnOptions,
        indexList,
        data,
        setData,
        [
          <option value="">-- Select One---</option>,
          <option value="Bad Number / Wrong Person">
            Bad Number / Wrong Person
          </option>,
          <option value="Do Not Call">Do Not Call</option>,
          <option value="Made Contact - Interested">
            Made Contact - Interested
          </option>,
          <option value="Made Contact - Not Interested">
            Made Contact - Not Interested
          </option>,
          <option value="Made Contact - Not Now">
            Made Contact - Not Now
          </option>,
          <option value="No Answer">No Answer</option>,
          <option value="Other (See Notes)">Other (See Notes)</option>,
          <option value="VM - Left Message">VM - Left Message</option>,
          <option value="VM - No Message">VM - No Message</option>,
        ],
        !row?.CellPhone_Full,
      ),
  },
  {
    field: 'prospectTracker.lastTexted',
    header: 'Last Texted',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) =>
      viewStandardDateContentHandler(
        row,
        cellTemplate,
        columnOptions,
        indexList,
        data,
        setData,
        !row?.CellPhone_Full,
      ),
  },
  {
    field: 'prospectTracker.textResult',
    header: 'Result of Text',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) =>
      viewStandardSelectContentHandler(
        row,
        cellTemplate,
        columnOptions,
        indexList,
        data,
        setData,
        [
          <option value="">-- Select One---</option>,
          <option value="Do Not Text">Do Not Text</option>,
          <option value="Replied">Replied</option>,
          <option value="Sent">Sent</option>,
          <option value="Unknown">Unknown</option>,
        ],
        !row?.CellPhone_Full,
      ),
  },
  {
    field: 'Email',
    header: 'Email',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (row, templates, cellTemplate, columnOptions) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={`mailto:${row?.Email}?body=${getProcessedTemplate(
          templates,
          options,
          'email',
          row,
        )}`}
        style={{
          display: 'inline-block',
          width: columnOptions?.width ?? null,
        }}
      >
        {row?.Email}
      </a>
    ),
  },
  {
    field: 'prospectTracker.lastEmailed',
    header: 'Last Emailed',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templaets,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) =>
      viewStandardDateContentHandler(
        row,
        cellTemplate,
        columnOptions,
        indexList,
        data,
        setData,
        !row?.Email,
      ),
  },
  {
    field: 'prospectTracker.emailResult',
    header: 'Result of Email',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) =>
      viewStandardSelectContentHandler(
        row,
        cellTemplate,
        columnOptions,
        indexList,
        data,
        setData,
        [
          <option value="">-- Select One---</option>,
          <option value="Bounced">Bounced</option>,
          <option value="Do Not Email">Do Not Email</option>,
          <option value="Opened">Opened</option>,
          <option value="Sent">Sent</option>,
          <option value="Unknown">Unknown</option>,
        ],
        !row?.Email,
      ),
  },
  {
    field: 'EstimatedHouseholdIncome',
    header: 'Household Income',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) =>
      viewStandardCurrencyContentHandler(
        row,
        cellTemplate,
        columnOptions,
        indexList,
        data,
        setData,
      ),
  },
  {
    field: 'DonationCapacity',
    header: 'Capacity',
    showInDesktop: true,
    showInCSV: true,
    expectSmallDollar: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) =>
      viewStandardCurrencyContentHandler(
        row,
        cellTemplate,
        columnOptions,
        indexList,
        data,
        setData,
      ),
  },
  {
    field: 'DonationScore',
    header: 'Propensity',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: row => (
      <OverlayTrigger
        overlay={
          <Tooltip>
            {parseFloat(row.DonationScore?.toFixed(2) ?? 0) * 100}%+
          </Tooltip>
        }
      >
        <ProgressBar
          className={`progressBarPropensity${getColorBasedOnPropensity(
            row?.DonationScore,
          )}`}
          now={row?.DonationScore * 100}
        />
      </OverlayTrigger>
    ),
    filterValueHandler: data => {
      const values = data.map(item => parseFloat(item?.toFixed(1)));
      const unique = values
        .map((value, index) => (values.indexOf(value) === index ? value : null))
        .filter(a => a)
        .map(value => ({ value, label: `${value * 100}%+` }))
        .sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
      return unique;
    },
    headerValueHandler: data => data.map(item => `${item * 100}%+`),
  },
  {
    field: 'MOADBPartyCalc',
    header: 'Ideology',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: row => (
      <OverlayTrigger
        overlay={
          <Tooltip>
            {getIdeologyLabel(parseInt(row.MOADBPartyCalc, 10))}
          </Tooltip>
        }
      >
        <ProgressBar
          className={`progressBarIdeology${row.MOADBPartyCalc}`}
          min={1}
          max={5}
          now={5 - (row?.MOADBPartyCalc - 1)}
        />
      </OverlayTrigger>
    ),
    filterValueHandler: data => {
      const unique = data
        .map((value, index) => (data.indexOf(value) === index ? value : null))
        .filter(a => a)
        .map(value => ({ value, label: getIdeologyLabel(value) }))
        .sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
      return unique;
    },
    headerValueHandler: data => data.map(item => getIdeologyLabel(item)),
  },
  {
    field: 'Meta',
    header: 'Interests',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field)
        ?.filter(a => !Number.isInteger(a))
        ?.join(', ') ?? '',
    contentHandler: row => displayInterests(row?.Meta),
    filterValueHandler: data => {
      const values = data.reduce((p, c) => [...p, ...(c ?? [])], []);
      const unique = values
        .map((value, index) =>
          !Number.isInteger(value) && values.indexOf(value) === index
            ? value
            : null,
        )
        .filter(a => a)
        .map(value => ({
          value,
          label: properCase(value),
        }))
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));
      return unique;
    },
    headerValueHandler: data => {
      return data.map(item => properCase(item));
    },
  },
  {
    field: 'RegistrationAddress1',
    header: 'Address 1',
    showInDesktop: true,
    showInCSV: true,
    tagDescription: 'Address 1',
    contentHandler: row => row?.RegistrationAddress1,
  },
  {
    field: 'RegistrationAddress2',
    header: 'Address 2',
    showInDesktop: true,
    showInCSV: true,
    tagDescription: 'Address 2',
    contentHandler: row => row?.RegistrationAddress2,
  },
  {
    field: 'RegistrationAddressCity',
    header: 'City',
    showInDesktop: true,
    showInCSV: true,
    tagDescription: 'City',
    contentHandler: row => row?.RegistrationAddressCity,
  },
  {
    field: 'RegistrationAddressState',
    header: 'State',
    showInDesktop: true,
    showInCSV: true,
    tagDescription: 'State',
    contentHandler: row => row?.RegistrationAddressState,
  },
  {
    field: 'RegistrationAddressZip5',
    header: 'Zip',
    showInDesktop: true,
    showInCSV: true,
    tagDescription: 'Zip',
    contentHandler: row => row?.RegistrationAddressZip5,
  },
  {
    field: 'prospectTracker.assignee',
    header: 'Assignee',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) => {
      if (userLogged?.role === 'orgAdmin') {
        return viewStandardSelectContentHandler(
          row,
          cellTemplate,
          columnOptions,
          indexList,
          data,
          setData,
          [
            <option value="">-- Select One --</option>,
            ...assignees.map(assignee => (
              <option
                value={assignee._id}
              >{`${assignee?.firstName} ${assignee?.lastName}`}</option>
            )),
          ],
        );
      } else {
        const assignee = getAssigneeById(
          assignees,
          row?.prospectTracker.assignee,
        );
        return !!assignee ? `${assignee?.firstName} ${assignee?.lastName}` : '';
      }
    },
    filterValueHandler: data => {
      const unique = data
        .map((value, index) => (data.indexOf(value) === index ? value : null))
        .filter(a => a)
        .map(value => {
          const assignee = getAssigneeById(assignees, value);
          return assignee
            ? {
                value,
                label: `${assignee.firstName} ${assignee.lastName}`,
              }
            : value;
        })
        .sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
      return unique;
    },
    headerValueHandler: data =>
      data.map(item => getAssigneeById(assignees, item._id)),
  },
  {
    field: 'prospectTracker.notes',
    header: 'Notes',
    width: '500px',
    showInDesktop: true,
    showInCSV: true,
    contentHandlerCSV: (row, cellTemplate) =>
      getObjectValue(row, cellTemplate.field) ?? '',
    contentHandler: (
      row,
      templates,
      cellTemplate,
      columnOptions,
      indexList,
      data,
      setData,
    ) =>
      viewStandardTextContentHandler(
        row,
        cellTemplate,
        columnOptions,
        indexList,
        data,
        setData,
      ),
  },
];

export default newCellTemplateList;
