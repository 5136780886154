import React, { useState } from 'react';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { applyPromotionalCode } from 'api/promotional.code.service';
import { toast } from 'react-toastify';

const PromotionalCode = ({ user }) => {
  const [code, setCode] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const applyCode = () => {
    if (!code) setError('Code required');
    else {
      setError(null);
      applyPromotionalCode(user.email, code).then(response =>
        toast.success(
          `"${response.data.promotionalCode.name}" applied succesfully!`,
        ),
      );
    }
  };

  return (
    <>
      <InputGroup>
        <Form.Control
          value={code ?? ''}
          onChange={e => setCode(e.target.value)}
        />
        <Button variant="dark" onClick={applyCode}>
          Apply
        </Button>
      </InputGroup>

      {!!error && (
        <Alert variant="danger" className="mt-3">
          {error}
        </Alert>
      )}
    </>
  );
};

PromotionalCode.propTypes = {
  user: PropTypes.object,
};

PromotionalCode.defaultProps = {};

export default PromotionalCode;
