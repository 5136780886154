import React, { useState } from 'react';
import { ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import './styles.css';
import {
  CaretRight,
  CheckSquare,
  ChevronDown,
  Plus,
  Square,
} from 'react-bootstrap-icons';

export const InputSelect = ({
  addLabel = 'Add',
  children,
  className = '',
  disabled = false,
  number = true,
  onAdd = null,
  onChange = e => {},
  placeholder = 'Select one or more',
  value = '',
}: {
  addLabel?: string;
  children?: any;
  className?: string;
  disabled?: boolean;
  number?: boolean;
  onAdd?: any;
  onChange?: any;
  placeholder?: string;
  value?: any;
}) => {
  const [addValue] = useState(`add-${Date.now()}`);
  const allChildren = children
    .map(child => (Array.isArray(child) ? child : [child]))
    .reduce((p, c) => [...p, ...c], []);

  const handleOnChange = value => {
    if (value === addValue) onAdd();
    else onChange({ target: { value } });
  };

  return (
    <Dropdown
      as={ButtonGroup}
      align="end"
      className={`inputSelect w-100 ${className}`}
    >
      <Form.Control
        className="border rounded-0 rounded-start"
        disabled={disabled}
        value={value}
        type={number ? 'number' : 'text'}
        onChange={e => handleOnChange(parseFloat(e.target.value))}
      />
      <Dropdown.Toggle
        disabled={disabled}
        className={`${disabled ? 'bg-body-secondary' : 'bg-white'} text-dark`}
      >
        <ChevronDown />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item disabled={disabled} onClick={() => handleOnChange('')}>
          <CaretRight /> {placeholder}
        </Dropdown.Item>
        {!!onAdd && (
          <Dropdown.Item
            disabled={disabled}
            onClick={() => handleOnChange(addValue)}
          >
            <Plus /> {addLabel}
          </Dropdown.Item>
        )}
        <Dropdown.Divider />

        {allChildren.map(child => {
          const selected = value === child.props?.value;

          return (
            <Dropdown.Item
              disabled={disabled}
              key={child.key}
              onClick={() => handleOnChange(child.props.value)}
            >
              {selected ? <CheckSquare /> : <Square />} {child.props.children}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
