import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import L from 'leaflet';
import { Marker, Polygon, Popup } from 'react-leaflet';
import { Button, ButtonGroup, Table } from 'react-bootstrap';
import { getBounds, getCenterOfBounds } from 'geolib';
import { convertToRange } from 'app/components/MOADBFacetedSearch/utils';
import { IdeologyMeter } from 'app/components/IdeologyMeter';
import './styles.css';
import IntersetsIcons from 'app/components/InterestIcons';

const ch = require('convex-hull');

export const Cluster = ({
  credits,
  data = [],
  defaultExpanded = false,
  fitBounds = false,
  mapRef,
  onColumnFilter,
  searchPurchased,
  setCreditsDelta,
  setMarkSingleProspect,
  setProspect,
  showContactInfo,
  threshold = 1000,
  timer = -1,
}: {
  credits: number;
  data: any;
  defaultExpanded?: boolean;
  fitBounds?: boolean;
  mapRef: any;
  onColumnFilter?: any;
  searchPurchased: boolean;
  setCreditsDelta: any;
  setMarkSingleProspect: any;
  setProspect: any;
  showContactInfo: boolean;
  threshold?: number;
  timer?: number;
}) => {
  const columns = Object.keys(data?.[0] ?? {}).sort();
  const filteredColumns = columns.filter(column =>
    !!onColumnFilter ? onColumnFilter(column) : true,
  );

  const [expanded, setExpanded] = useState(defaultExpanded);

  const dataLatLon = data.map(item => ({
    ...item,
    latitude: parseFloat(item.address_latitude),
    longitude: parseFloat(item.address_longitude),
  }));

  //const bounds = getBounds(dataLatLon);

  const center = getCenterOfBounds(dataLatLon) as any;

  const circleRef = useRef(null);

  const handleClick = useCallback(() => {
    if (fitBounds)
      mapRef.current.fitBounds((circleRef?.current as any).getBounds());
    setExpanded(true);
  }, [fitBounds, mapRef]);

  const hasInterests = prospect =>
    !![
      'donates_to_animal_welfare',
      'donates_to_arts_and_culture',
      'donates_to_childrens_causes',
      'donates_to_conservative_causes',
      'donates_to_healthcare',
      'donates_to_international_aid_causes',
      'donates_to_liberal_causes',
      'donates_to_local_community',
      'donates_to_political_causes',
      'donates_to_veterans_causes',
      'donates_to_wildlife_preservation',
    ].filter(interest =>
      ['1', 'YES'].includes((prospect?.[interest] ?? '').toUpperCase()),
    ).length;

  const splitIntoQuads = () => {
    const bounds = getBounds(dataLatLon);
    return [
      { ...bounds, maxLat: center.latitude, maxLng: center.longitude },
      { ...bounds, minLat: center.latitude, maxLng: center.longitude },
      { ...bounds, minLat: center.latitude, minLng: center.longitude },
      { ...bounds, maxLat: center.latitude, minLng: center.longitude },
    ]
      .map(quad =>
        dataLatLon.filter(
          item =>
            item.latitude >= quad.minLat &&
            item.latitude <= quad.maxLat &&
            item.longitude >= quad.minLng &&
            item.longitude <= quad.maxLng,
        ),
      )
      .filter(quad => quad.length >= 1);
  };

  const eventHandlers = useMemo(
    () => ({
      click() {
        handleClick();
      },
    }),
    [handleClick],
  );

  useEffect(() => {
    if (timer >= 0) setTimeout(() => handleClick(), timer);
  }, [handleClick, timer]);

  if (data.length > threshold && !expanded)
    /*return (
      <Rectangle
        ref={circleRef}
        bounds={[
          [bounds.minLat, bounds.minLng],
          [bounds.maxLat, bounds.maxLng],
        ]}
        eventHandlers={timer >= 0 ? undefined : eventHandlers}
        pathOptions={{ color: 'blue' }}
      >
        <Marker
          position={[center.latitude, center.longitude]}
          icon={L.divIcon({
            html: `<div style="background: none; color: white; font-size: 2rem; white-space: nowrap">${data.length}</div>`,
          })}
        />
      </Rectangle>
    );*/
    return (
      <Polygon
        ref={circleRef}
        eventHandlers={eventHandlers}
        pathOptions={{ color: 'blue' }}
        positions={ch(
          dataLatLon.map(point => [point.latitude, point.longitude]),
        )
          .map(point => [
            [dataLatLon[point[0]].latitude, dataLatLon[point[0]].longitude],
            [dataLatLon[point[1]].latitude, dataLatLon[point[1]].longitude],
          ])
          .reduce((p, c) => [...p, ...c], [])}
      >
        <Marker
          position={[center.latitude, center.longitude]}
          icon={L.divIcon({
            html: `<div style="background: none; color: white; font-size: 2rem; white-space: nowrap">${data.length}</div>`,
          })}
        />
      </Polygon>
    );

  if (data.length > threshold && expanded)
    return splitIntoQuads().map(quad => (
      <Cluster
        credits={credits}
        data={quad}
        mapRef={mapRef}
        onColumnFilter={onColumnFilter}
        searchPurchased={searchPurchased}
        setCreditsDelta={setCreditsDelta}
        setMarkSingleProspect={setMarkSingleProspect}
        setProspect={setProspect}
        showContactInfo={showContactInfo}
        threshold={threshold}
        timer={timer}
      />
    ));

  return data.map(item => {
    return (
      <Marker
        position={[
          parseFloat(item.address_latitude),
          parseFloat(item.address_longitude),
        ]}
      >
        <Popup className="p-0">
          <div className="info">
            <Table className="mb-0">
              <tr>
                <td>Name:</td>
                <td width="75%">{`${item.first_name} ${item.last_name}`}</td>
              </tr>
              <tr>
                <td>City:</td>
                <td width="75%">{item.city}</td>
              </tr>
              <tr>
                <td>State:</td>
                <td width="75%">{item.state}</td>
              </tr>
              {!!item.estimated_home_value && (
                <tr>
                  <td>Assets:</td>
                  <td width="75%">{` ${convertToRange(
                    item.estimated_home_value,
                  )}`}</td>
                </tr>
              )}
              {!!item.household_income_total && (
                <tr>
                  <td>Income:</td>
                  <td width="75%">{` ${convertToRange(
                    item.household_income_total,
                  )}`}</td>
                </tr>
              )}{' '}
              {!!item.ideology && (
                <tr>
                  <td>Views:</td>
                  <td width="75%">
                    <IdeologyMeter value={item.ideology} />
                  </td>
                </tr>
              )}
              {hasInterests(item) && (
                <tr>
                  <td>Interests:</td>
                  <td width="75%">
                    <IntersetsIcons
                      filteredColumns={filteredColumns}
                      prospect={item}
                      withinColumn={false}
                    />
                  </td>
                </tr>
              )}
            </Table>
          </div>
          <ButtonGroup className="mt-1 p-0 w-100">
            {!!showContactInfo && (
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => setProspect(item)}
              >
                View
              </Button>
            )}
            {!searchPurchased && (
              <Button
                size="sm"
                onClick={() => {
                  if (!credits) setCreditsDelta(1);
                  else setMarkSingleProspect(item);
                }}
              >
                License this Record
              </Button>
            )}
          </ButtonGroup>
        </Popup>
      </Marker>
    );
  });
};
