import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { SidebarButtonProps } from './types';

const SidebarButton: FC<SidebarButtonProps> = ({
  menuTab,
  active,
  setActive,
}) => {
  const { label, logo } = menuTab;
  return (
    <Box
      onClick={() => setActive(menuTab)}
      sx={{
        '&:hover': {
          cursor: 'pointer',
          backgroundColor:
            active === label ? undefined : 'rgba(255, 255, 255, 0.50)',
          borderColor: active === label ? undefined : '#e6e9f1',
        },
        gridColumnGap: '6px',
        border:
          active === label ? '1px solid #e6e9f1' : '1px solid rgba(0, 0, 0, 0)',
        borderRadius: '6px',
        alignItems: 'center',
        padding: '8px',
        textDecoration: 'none',
        transition: 'all .3s',
        display: 'flex',
        backgroundColor: active === label ? '#fff' : undefined,
        boxShadow:
          active === label ? '0 0 3px rgba(20, 20, 43, .09)' : undefined,
      }}
    >
      {logo && (
        <img
          alt="menutab-logo"
          src={logo}
          style={{
            width: label === 'Favorites' ? '20px' : undefined,
          }}
        />
      )}
      <Typography
        sx={{
          '&:hover': {
            color: '#36e961',
          },
          fontFamily: 'Inter, sans-serif',
          color: active === label ? '#454e66' : '#828aa3',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '1.286em',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

// const SidebarButton: FC<SidebarButtonProps> = ({
//   menuTab,
//   active,
//   setActive,
// }) => {

// };

export default SidebarButton;
