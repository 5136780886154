import React from 'react';
import { useMemo, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

export const useConfirmation = () => {
  const [message, setMessage] = useState('');
  const [okCaption, setOKCaption] = useState<any>('OK');
  const [onOK, setOnOK] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const ConfirmationModal = useMemo(
    () => (
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button variant="light" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setShowModal(false);
                onOK();
              }}
            >
              {okCaption}
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    ),
    [message, okCaption, onOK, showModal, title],
  );

  const confirm = (uTitle, uMessage, uOnOK, uOKCaption = 'OK') => {
    setTitle(uTitle);
    setMessage(uMessage);
    setOKCaption(uOKCaption);
    setOnOK(() => uOnOK);
    setShowModal(true);
  };

  return { confirm, ConfirmationModal };
};
