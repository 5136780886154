// import { RootState } from 'store/configureStore';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './authentication';

const selectSlice = (state: RootState) => state?.authentication || initialState;

export const getIsAuthenticated = createSelector(
  [selectSlice],
  state => state.isAuthenticated,
);

export const getIsAuthenticationError = createSelector(
  [selectSlice],
  state => state.isAuthenticationError,
);

export const getAuthUser = createSelector([selectSlice], state => state.user);
export const getAuthState = createSelector([selectSlice], state => state);
