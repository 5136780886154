// App.tsx
import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { enumerate } from 'app/components/Prospect-Tracker/utility';
import constants from 'app/config/constants';
import ContactViewRecord from './ContactViewRecord';
import Row from 'react-bootstrap/Row';

const ContactViewPage = ({
  cellTemplateList,
  donors,
  getPageInsertIndex,
  idField,
  pageHeight,
  pageIndex,
  pagesLoaded,
  pageVisibility,
  rowHeight,
  rowVisibility,
  showModal,
  templates,
}) => {
  const tbodyRef = React.useRef<HTMLTableSectionElement>(null);
  const visibility = pageVisibility[pageIndex];

  return (
    <Row
      ref={tbodyRef}
      id={`plan-view-page-${pageIndex}-${!!pagesLoaded[pageIndex]}`}
      data-page={true}
      data-page-index={pageIndex}
      data-page-isloaded={!!pagesLoaded[pageIndex]}
      data-visible={visibility}
      {...(!visibility ? { style: { height: `${pageHeight}px` } } : {})}
    >
      {pagesLoaded[pageIndex] &&
        enumerate(constants.DPT_LOAD_LIMIT()).map(pageRowIndex => {
          const rowIndex = getPageInsertIndex(pageIndex) + pageRowIndex;
          const row = donors?.[rowIndex];

          return !!row?.[idField] ? (
            <ContactViewRecord
              key={`contact-view-record-${row?.[idField]}`}
              cellTemplateList={cellTemplateList}
              idField={idField}
              row={row}
              rowHeight={rowHeight}
              rowIndex={rowIndex}
              rowIndexLabel={
                pageIndex * constants.DPT_LOAD_LIMIT() + pageRowIndex
              }
              rowVisibility={rowVisibility}
              showModal={showModal}
              templates={templates}
            />
          ) : null;
        })}
    </Row>
  );
};

export default ContactViewPage;
