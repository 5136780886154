import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser, PaginationResultFlatten } from 'api/interfaces';
import { aggregateUsers } from 'api/user.service';
import { PipelineStage } from 'mongoose';
import { useInjectReducer } from 'redux-injectors';
import { UserPaginationSlice } from './types';

export const initialState: UserPaginationSlice = {
  search: '',
  skip: 0,
  limit: 5,
  pageNumber: 0,
  sort: { firstName: 1 },
  isLoading: false,
  error: '',
  paginationResult: {
    data: [],
    filteredPagination: 0,
    totalPagination: 0,
  },
};

export const fetchUserPagination = createAsyncThunk(
  'userPaginationSlice/fetchContent',
  async (aggregateQuery: PipelineStage[] = []) => {
    const response = await aggregateUsers(aggregateQuery);
    if (response && response.length) {
      const [firstResponse] = response;
      const paginationResultflatten: PaginationResultFlatten<IUser> = {
        data: firstResponse.data,
        filteredPagination:
          firstResponse.filteredPagination.pop()?.totalCount || 0,
        totalPagination: firstResponse.totalPagination.pop()?.totalCount || 0,
      };
      return paginationResultflatten;
    }
    return initialState.paginationResult;
  },
);

const userPaginationSlice = createSlice({
  name: 'userPaginationSlice',
  initialState,
  reducers: {
    resetState: state => {
      state.skip = initialState.skip;
      state.limit = initialState.limit;
      state.pageNumber = initialState.pageNumber;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
      state.skip = action.payload * state.limit;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setSort: (state, action: PayloadAction<{ [key: string]: number }>) => {
      state.sort = action.payload;
    },
    setSearch: (state, action: PayloadAction<string | undefined>) => {
      state.search = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserPagination.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserPagination.fulfilled, (state, action) => {
      state.isLoading = false;
      state.paginationResult = action.payload;
    });
    builder.addCase(fetchUserPagination.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || '';
    });
  },
});

export const useUserPaginationSlice = () => {
  useInjectReducer({
    key: userPaginationSlice.name,
    reducer: userPaginationSlice.reducer,
  });
  return { actions: userPaginationSlice.actions, fetchUserPagination };
};
