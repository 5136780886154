const generateCSV = ({ data, onColumnFilter, onColumnHeader }) => {
  if (!data?.[0]) return;
  const columns = Object.keys(data?.[0] ?? {});
  const filteredColumns = columns.filter(column =>
    !!onColumnFilter ? onColumnFilter(column) : true,
  );
  const columnHeaders = filteredColumns.map(column =>
    !!onColumnHeader ? onColumnHeader(column).props.label : column,
  );

  const lines = [
    `"${columnHeaders.join('","')}"\r\n`,
    ...data.map(
      row => `"${filteredColumns.map(column => row[column]).join('","')}"\r\n`,
    ),
  ];

  const blob = new Blob(lines, { type: 'text/csv;charset=utf-8' });
  const blobURL = URL.createObjectURL(blob);
  window.open(blobURL, '_blank');
};
export default generateCSV;
