import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const FileDetailsModal = ({ onHide, facets, show, updateFacets }) => {
  const [error, setError] = useState<string>('');
  const [newQuery, setNewQuery] = useState<any>({ ...facets });

  const cancel = () => {
    setNewQuery({ ...facets });
    onHide();
  };

  const validate = () => {
    if (!newQuery?.current?.Name) setError('Please provide a file name');
    else {
      updateFacets(newQuery.current);
      setError('');
      onHide();
    }
  };

  const updateNewQuery = (field, value) => {
    setNewQuery({
      ...newQuery,
      current: {
        ...(newQuery?.current ?? {}),
        [field]: value,
      },
    });
  };

  useEffect(() => {
    setNewQuery({ ...facets });
  }, [facets]);

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Faceted Search Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          value={newQuery?.current?.Name ?? ''}
          placeholder="File name"
          onChange={e => updateNewQuery('Name', e.target.value)}
        />
        <Form.Control
          className="mt-3"
          value={newQuery?.current?.Description ?? ''}
          placeholder="Description"
          onChange={e => updateNewQuery('Description', e.target.value)}
        />
        <Form.Check
          className="mt-3"
          checked={!!newQuery?.current?.IsTemplate}
          label="Is a template"
          onChange={e => updateNewQuery('IsTemplate', e.target.checked)}
        />
        {!!error && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="light" onClick={cancel}>
            Cancel
          </Button>
          <Button onClick={validate}>Update</Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default FileDetailsModal;
