import { getP2PMessageQueue } from 'api/p2p.message.queue.service';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/authentication/authentication.selector';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { ChatLeftDots, EnvelopeAt, Pause, Play } from 'react-bootstrap-icons';
import { insertP2PMessageQueueRecords } from 'api/p2p.message.queue.service';
import { toast } from 'react-toastify';

const P2PMessageQueue = () => {
  const userLogged = useSelector(getAuthUser);
  const [currentMessage, setCurrentMessage] = useState<any>();
  const [delay, setDelay] = useState<number>(1000);
  const [isApp, setIsApp] = useState(false);
  const [messageQueue, setMessageQueue] = useState<any>([]);
  const [play, setPlay] = useState(false);
  const [processingMessage, setProcessingMessage] = useState(false);
  const [sentMessageId, setSentMessageId] = useState<any[]>([]);
  const _DRE = useMemo(() => (window as any)._DRE, []);
  const queryTimerRef = useRef<any>(null);

  (window as any).setProcessingMessage = async stillProcessing => {
    if (!stillProcessing) {
      await insertP2PMessageQueueRecords({
        ...currentMessage,
        email: userLogged?.email,
        sentAt: new Date().toISOString(),
      });

      setSentMessageId([...sentMessageId, currentMessage._id]);
      setCurrentMessage(null);
      toast.info('Message processed!');
    }

    setProcessingMessage(stillProcessing);
  };

  const loadNextMessageInQueue = useCallback(() => {
    setCurrentMessage(messageQueue.shift());
    setMessageQueue([...messageQueue]);
  }, [messageQueue]);

  const retrieveQueuedMessages = useCallback(() => {
    getP2PMessageQueue({
      email: userLogged?.email,
    }).then(response => setMessageQueue(response.data));
  }, [userLogged?.email]);

  const sendQueuedMessage = useCallback(async () => {
    if (!processingMessage && !sentMessageId.includes(currentMessage._id)) {
      _DRE.postMessage(
        JSON.stringify({
          ...currentMessage,
          delay,
        }),
      );
    }

    if (sentMessageId.includes(currentMessage._id)) setCurrentMessage(null);
  }, [_DRE, currentMessage, delay, processingMessage, sentMessageId]);

  useEffect(() => {
    retrieveQueuedMessages();
  }, [retrieveQueuedMessages]);

  useEffect(() => {
    setIsApp(!!(window as any)._DRE);
  }, []);

  useEffect(() => {
    if (
      !!messageQueue?.length &&
      play &&
      !processingMessage &&
      !currentMessage
    ) {
      loadNextMessageInQueue();
    }
  }, [
    currentMessage,
    loadNextMessageInQueue,
    messageQueue,
    play,
    processingMessage,
  ]);

  useEffect(() => {
    if (!!currentMessage) sendQueuedMessage();
  }, [currentMessage, sendQueuedMessage]);

  useEffect(() => {
    queryTimerRef.current = setInterval(retrieveQueuedMessages, 10000);
    return () => clearInterval(queryTimerRef.current);
  }, [retrieveQueuedMessages]);

  return (
    <Container fluid className="mt-3">
      <Row>
        <Col md={1} />
        <Col>
          <div className="mb-3 text-end">
            {!isApp && (
              <Dropdown>
                <Dropdown.Toggle>Android</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    target="_blank"
                    href="https://drive.google.com/file/d/1Bvi1Xv1NPoLm2wsLkzPafdiBvEHOBDJd/view?usp=drive_link"
                  >
                    Arm 64 (v8a)
                  </Dropdown.Item>
                  <Dropdown.Item
                    target="_blank"
                    href="https://drive.google.com/file/d/13AVr98mCNwMuXslusSOBCCInMo5Yh777/view?usp=drive_link"
                  >
                    Armeabi (v7a)
                  </Dropdown.Item>
                  <Dropdown.Item
                    target="_blank"
                    href="https://drive.google.com/file/d/1KdVUJfl1Sl9E8FCl1oMnoY13McS82jHK/view?usp=drive_link"
                  >
                    x86-64
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {isApp && (
              <InputGroup>
                <FloatingLabel label="Delay between messages">
                  <Form.Control
                    type="number"
                    value={delay}
                    onChange={e => setDelay(parseInt(e.target.value, 10))}
                  />
                </FloatingLabel>
                <Button>
                  {!play && <Play onClick={() => setPlay(true)} />}
                  {play && <Pause onClick={() => setPlay(false)} />}
                </Button>
              </InputGroup>
            )}
          </div>

          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Type</th>
                <th>From</th>
                <th>Recipient</th>
                <th>Message</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {messageQueue
                .filter(row => !sentMessageId.includes(row?._id))
                .map(row => (
                  <tr>
                    <td>
                      {row?.type === 'email' && <EnvelopeAt />}
                      {row?.type === 'sms' && <ChatLeftDots />}
                      &nbsp;{row?.type}
                    </td>
                    <td>{row?.smtpCredentials?.email}</td>
                    <td>{row?.recipient}</td>
                    <td>{decodeURIComponent(row?.message)}</td>
                    <td>{row?.createdAt}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
        <Col md={1} />
      </Row>
    </Container>
  );
};

export default P2PMessageQueue;
