import {
  Box,
  Paper,
  Modal,
  Typography,
  IconButton,
  TextField,
  Container,
  Button,
} from '@mui/material';
import { IOrganization, IUser } from 'api/interfaces';
import { getOrganizations } from 'api/organization.service';
import ReusableTable from 'app/components/ReusableTable';
import { HeadCell } from 'app/components/ReusableTable/types';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useUserPaginationSlice } from 'store/user-pagination/user-pagination';
import { getUserPaginationState } from 'store/user-pagination/user-pagination.selector';
import UserFormModal from './UserFormModal';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import SettingsIcon from '@mui/icons-material/Settings';
import { aggregationQueryUsers, boxModalStyle } from './config';
import { useDebounce } from '../../../custom-hooks/useDebounce';
import AddIcon from '@mui/icons-material/Add';

const headers: HeadCell[] = [
  {
    id: 'firstName',
    label: 'First Name',
    disablePadding: true,
    numeric: true,
    align: 'center',
  },
  {
    id: 'lastName',
    label: 'Last Name',
    disablePadding: true,
    numeric: true,
    align: 'center',
  },
  {
    id: 'email',
    label: 'Email',
    disablePadding: true,
    numeric: true,
    align: 'center',
  },
  {
    id: 'organizationId',
    label: 'Organization Id',
    disablePadding: true,
    numeric: true,
    align: 'center',
  },
  {
    id: 'action',
    label: '',
    disablePadding: true,
    numeric: true,
    align: 'center',
  },
];

const UsersPage = () => {
  const [searchParams] = useSearchParams();

  const organizationId = searchParams.get('organizationId');
  const { fetchUserPagination, actions } = useUserPaginationSlice();
  const dispatch = useDispatch();
  const userPaginationState = useSelector(getUserPaginationState);

  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [open, setOpen] = React.useState(false);

  const [user, setUser] = useState<IUser>();

  // Debounce callback
  const debouncedValue = useDebounce(userPaginationState.search || '', 700);

  const search = useCallback(async () => {
    paginteUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    search();
  }, [debouncedValue, search]);

  const handleOpen = (user?: IUser) => {
    setUser(user);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getOrganizations().then(organizations => setOrganizations(organizations));
    return () => {
      dispatch(actions.setSearch(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paginteUsers = () => {
    const _organizationId =
      !organizationId || organizationId === '""' ? undefined : organizationId;

    const matchQuery: any[] = [];

    const searchValue = userPaginationState.search;
    matchQuery.push({
      $match: {
        ...(_organizationId && { organizationId: Number(_organizationId) }),
        ...(searchValue && {
          $or: [
            { firstName: { $regex: searchValue, $options: 'i' } },
            { lastName: { $regex: searchValue, $options: 'i' } },
            { email: { $regex: searchValue, $options: 'i' } },
          ],
        }),
      },
    });

    dispatch(
      fetchUserPagination(
        aggregationQueryUsers(
          userPaginationState.skip,
          userPaginationState.limit,
          userPaginationState.sort,
          matchQuery,
        ),
      ),
    );
  };

  useEffect(() => {
    if (organizationId) {
      paginteUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);
  useEffect(() => {
    paginteUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userPaginationState.limit,
    userPaginationState.skip,
    userPaginationState.sort,
  ]);

  const [sortKeys, sortValues] = [
    Object.keys(userPaginationState.sort),
    Object.values(userPaginationState.sort),
  ];

  const getOrderBy = sortKeys.length ? sortKeys[0] : '';
  const getOrder = sortValues.length
    ? sortValues[0] === 1
      ? 'asc'
      : 'desc'
    : undefined;

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ borderRadius: '12px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 4,
            padding: 2,
          }}
        >
          <Typography variant="h5">Users</Typography>
          <TextField
            sx={{ width: '50%' }}
            id="standard-basic"
            label="Search"
            variant="standard"
            value={userPaginationState.search}
            onChange={(
              event:
                | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                | undefined,
            ) => dispatch(actions.setSearch(event?.target.value))}
          />
          <Button
            sx={{ alignSelf: 'end' }}
            variant="outlined"
            color="info"
            startIcon={<AddIcon />}
            onClick={() => handleOpen(undefined)}
          >
            New User
          </Button>
        </Box>
        <ReusableTable
          isLoading={userPaginationState.isLoading}
          headers={headers}
          order={getOrder}
          orderBy={getOrderBy}
          rows={userPaginationState.paginationResult.data}
          handleClick={(event, row) => {}}
          handleRequestSort={(_, property: string) => {
            const isAsc =
              userPaginationState.sort.hasOwnProperty(property) &&
              userPaginationState.sort[property] === 1;
            dispatch(actions.setSort({ [property]: isAsc ? -1 : 1 }));
          }}
          paginationProps={{
            page: userPaginationState.pageNumber,
            rowsPerPage: userPaginationState.limit,
            setRowsPerPage: (rowsPerPage: number) => {
              dispatch(actions.setLimit(rowsPerPage));
            },
            count: userPaginationState.paginationResult.filteredPagination,
            handleChangePage: (_, pageNumber: number) => {
              dispatch(actions.setPageNumber(pageNumber));
            },
          }}
          renderRow={(row, headerId) => {
            const user = row as IUser;
            switch (headerId) {
              case 'action':
                return (
                  <IconButton
                    sx={{
                      fontSize: 34,
                      color: grey[500],
                    }}
                    onClick={() => handleOpen(user)}
                  >
                    <SettingsIcon />
                  </IconButton>
                );
              default:
                return user[headerId];
            }
          }}
        />
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            User Form
          </Typography>
          <br />
          <IconButton
            sx={{
              position: 'absolute',
              right: 6,
              top: 6,
              fontSize: 34,
              color: grey[500],
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <UserFormModal
            organizations={organizations}
            user={user}
            handleClose={handleClose}
            afterUpdate={() => {
              handleClose();
              paginteUsers();
            }}
          />
        </Box>
      </Modal>
    </Container>
  );
};

export default UsersPage;
