const metrics = [
  {
    label: 'Last Contacted',
    filter: {
      date: { toDate: 'lastContacted' },
      assignee: 'assignee',
    },
    match: { date: 'notNull' },
    sort: { date: 1 },
  },
  {
    label: 'Last Emailed',
    filter: {
      date: { toDate: 'lastEmailed' },
      assignee: 'assignee',
    },
    match: { date: 'notNull' },
    sort: { date: 1 },
  },
  {
    label: 'Last Texted',
    filter: {
      date: { toDate: 'lastTexted' },
      assignee: 'assignee',
    },
    match: { date: 'notNull' },
    sort: { date: 1 },
  },
];

export default metrics;
