import React, { useState } from 'react';
import { MOADBQuery } from '../MOADBQuery';
import { MOADBFacetedSearch } from '../MOADBFacetedSearch';
import { Container } from 'react-bootstrap';

export const MOADBSearch = () => {
  const [useQuery, setUseQuery] = useState(false);

  return (
    <div>
      {useQuery ? (
        <MOADBQuery setUseQuery={setUseQuery} />
      ) : (
        <MOADBFacetedSearch setUseQuery={setUseQuery} />
      )}{' '}
    </div>
  );
};
