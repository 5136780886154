import axios from 'axios';
import getTokens from './getTokens';
import getUserProfile from './getUserProfile';
import { toast } from 'react-toastify';
import { GoogleProfile } from 'store/authentication/types';
import { signInFederatedUser } from 'api/auth.service';

const makeSignInFederatedUser = async (googleUserProfile: GoogleProfile) => {
  const username = googleUserProfile.email;
  const { idToken, refreshToken } = googleUserProfile;

  return await signInFederatedUser(username, {
    ...googleUserProfile,
    tokens: { idToken, refreshToken },
  });
};

const googleSignIn = ({ data, dispatch, actions, onSuccess }) => {
  const { finishLoginProcess, googleLogin, loginError, startLoginProcess } =
    actions;

  dispatch(startLoginProcess());
  getTokens(data.code)
    .then(responseToken =>
      getUserProfile(responseToken?.idToken, responseToken?.refreshToken),
    )
    .then(profile => makeSignInFederatedUser(profile as GoogleProfile))
    .then(response => {
      dispatch(googleLogin(response));
      onSuccess(response);
    })
    .catch(error => {
      dispatch(loginError('Cannot validate user credentials'));
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.error);
      }
    })
    .finally(() => dispatch(finishLoginProcess()));
};

export default googleSignIn;
