import ToolTip from 'app/components/ToolTip';
import React, { useState } from 'react';
import { ArrowLeftRight } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import HorizontalMarkettingModal from '../HorizontalMarkettingModal';

const HorizontalMarkettingButton = ({
  appEnabled,
  row,
  smtpCredentials,
  smtpFormEnabled,
  userLogged,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <HorizontalMarkettingModal
        appEnabled={appEnabled}
        onHide={() => setShowModal(false)}
        row={row}
        show={showModal}
        smtpCredentials={smtpCredentials}
        smtpFormEnabled={smtpFormEnabled}
        userLogged={userLogged}
      ></HorizontalMarkettingModal>

      <ToolTip
        placement="top"
        title={
          appEnabled
            ? 'Add SMS and/or email to P2P queue'
            : 'Send SMS and/or email via associated apps'
        }
      >
        <Button
          disabled={!row?.CellPhone_Full && !row?.Email}
          variant={appEnabled ? 'primary' : 'dark'}
          onClick={() => setShowModal(true)}
        >
          <ArrowLeftRight />
        </Button>
      </ToolTip>
    </>
  );
};

export default HorizontalMarkettingButton;
