import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GoogleLoginButton } from 'react-social-login-buttons';
import constants from '../../../config/constants';

import getTokens from '../../../helpers/google/getTokens';
import getUserProfile from '../../../helpers/google/getUserProfile';

import { useScript } from 'custom-hooks/useScript';
import { toast } from 'react-toastify';

import { registerUser } from 'api/auth.service';
import { useAuthSliceSlice } from 'store/authentication/authentication';
import axios from 'axios';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import googleSignIn from 'app/helpers/google/googleSignIn';
import { useCookies } from 'react-cookie';

export interface GoogleButtonProps {
  buttonText?: string;
  behaviorType: 'SignIn' | 'SignUp';
}

const GoogleButton: FC<GoogleButtonProps> = ({
  behaviorType,
  buttonText = 'Sign in with Google',
}) => {
  const [_, setCookie] = useCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { actions } = useAuthSliceSlice();

  const [oAuthClient, setOAuthClient] = useState<any>();

  const { google } = useScript(
    'https://accounts.google.com/gsi/client',
    'google',
  ) as { google: any };

  const handleGoogleSignIn = data =>
    googleSignIn({
      data,
      dispatch,
      actions,
      onSuccess: response => {
        setCookie('user', {
          acceptedToS: response.acceptedToS,
          canUpdateProspects: ((response?.permissions ?? []) as any).includes(
            'canUpdateProspects',
          ),
          email: response.email,
          expiration: response.expiration,
          sessionToken: response.sessionToken,
          verify: response.verify,
        });
      },
    });

  const handleGoogleSignUp = data => {
    // dispatch(startLoginProcess());
    getTokens(data.code)
      .then(responseToken =>
        getUserProfile(responseToken?.idToken, responseToken?.refreshToken),
      )
      .then(({ email, familyName, givenName }) => {
        registerUser({
          email,
          firstName: givenName,
          lastName: familyName,
          isFederated: true,
          active: true,
          organizationId: 0,
        }).then(response => {
          if (response) {
            toast.success('User registered successfully');
            navigate('/');
          }
        });
      })
      .catch(error => {
        if (axios.isAxiosError(error)) {
          toast.error(error.response?.data.error);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (google) {
      const oAuth2Client = google.accounts.oauth2.initCodeClient({
        client_id: constants.GOOGLE_CLIENT_ID(),
        scope: constants.GOOGLE_SCOPES,
        access_type: 'offline',
        ux_mode: !!(window as any)._DRE ? 'redirect' : 'popup',
        redirect_uri: constants.GOOGLE_REDIRECT_URI(),
        callback:
          behaviorType === 'SignIn' ? handleGoogleSignIn : handleGoogleSignUp,
      });
      setOAuthClient(oAuth2Client);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google]);

  return (
    <div>
      <GoogleLoginButton
        type="light"
        className="social-btn"
        text={buttonText}
        onClick={() => {
          oAuthClient?.requestCode();
        }}
      ></GoogleLoginButton>
    </div>
  );
};

export default GoogleButton;
