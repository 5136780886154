import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { levenshteinEditDistance } from 'levenshtein-edit-distance';
import fieldMapOptions from './fieldMapOptions';
import moment from 'moment';
import Papa from 'papaparse';
import readXlsxFile from 'read-excel-file';
import { Hint } from 'react-bootstrap-typeahead';
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  Row,
} from 'react-bootstrap';
import { insertProspectIngestionRecords } from 'api/prospect.ingestion.service';
import { getLists } from 'api/prospect.list.service';
import FieldMappingDropdown from './FieldMappingDropdown';
import GeneralConfirmationModal from '../GeneralConfirmationModal/GeneralConfirmationModal';
import GeneralModal from '../GeneralModal/GeneralModal';
import LoadingOverlay from '../LoadingOverlay';
import { ProspectListDropdown } from '../ProspectListDropdown';
import { getProspectTrackerOptions } from 'api/prospect.tracker.options.service';
import CustomFieldModal from './CustomField';
import { insertProspectTrackerOptionsRecords } from 'api/prospect.tracker.options.service';
import { toast } from 'react-toastify';
import sortObjectBySubField from 'utils/sortObjectBySubField';
import { splitArray } from '../Prospect-Tracker/utility';

const ProspectImporter = () => {
  const navigate = useNavigate();
  const userLogged = useSelector(getAuthUser);
  const fileRef = useRef<any>();
  const [activeList, setActiveList] = useState<any>(null);
  const [allFields, setAllFields] = useState<any>(fieldMapOptions);
  const [customFields, setCustomFields] = useState<any>(null);
  const [exampleData, setExampleData] = useState({});
  const [enableUploadButton, setEnableUploadButton] = useState(false);
  const [fieldMap, setFieldMap] = useState({});
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState<any>(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [hasHeaderRow, setHasHeaderRow] = useState(true);
  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<any>(null);
  const [numberOfContactsImported, setNumberOfContactsImported] =
    useState<number>(0);
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [showAutoFillModal, setShowAutoFillModal] = useState<boolean>(false);
  const [showCustomFieldModal, setShowCustomFieldModal] =
    useState<boolean>(false);
  const [showImportModal, setShowImportModal] = useState<boolean>(false);

  const autoFill = () => {
    const newFieldMap = { ...fieldMap };
    const optionTaken = Object.values(fieldMap);
    const allFieldsKeys = Object.keys(allFields).sort();

    const stringCompare = (a, b) => {
      const length = Math.min(a.length, b.length);
      return levenshteinEditDistance(
        a.substring(0, length),
        b.substring(0, length),
        true,
      );
    };

    const leastDistance = optionKey =>
      Math.min(
        ...Object.keys(fileData?.[0] ?? {}).map(fieldKey =>
          Math.min(
            ...allFields[optionKey].alias
              .filter(a => !optionTaken.includes(a))
              .map(alias => stringCompare(fieldKey, alias))
              .reduce((p, c) => [...p, c], []),
          ),
        ),
      );

    Object.keys(fileData?.[0] ?? {})
      .sort()
      .forEach(fieldKey => {
        if (!(fieldKey in newFieldMap)) {
          const fieldMapOptionDistance = allFieldsKeys
            .filter(a => !optionTaken.includes(a))
            .map(optionKey => {
              const minDistance = leastDistance(optionKey);
              const distance = Math.min(
                ...allFields[optionKey].alias
                  .map(alias => stringCompare(fieldKey, alias))
                  .reduce((p, c) => [...p, c], []),
              );

              return {
                field: optionKey,
                distance: distance === minDistance ? distance : 1000,
              };
            });

          fieldMapOptionDistance.sort((a, b) => a.distance - b.distance);

          if (
            !!fieldMapOptionDistance?.[0] &&
            fieldMapOptionDistance[0].distance >= 0
          ) {
            newFieldMap[fieldKey] = fieldMapOptionDistance[0].field;
            optionTaken.push(fieldMapOptionDistance[0].field);
          }
        }
      });

    setFieldMap(newFieldMap);
    setShowAutoFillModal(false);
  };

  const onCustomFieldsSave = updatedCustomFields => {
    insertProspectTrackerOptionsRecords({
      email: userLogged!.email,
      customFields: updatedCustomFields,
      storeOrganizationId: true,
    }).then(response => {
      const updatedCustomFields = response.data.options?.[0].customFields;

      toast.success('Custom fields saved successfully!');
      setCustomFields(
        updatedCustomFields.map(customField => ({
          ...customField,
          label: customField.header,
          isRequired: false,
          alias: [customField.header],
        })),
      );

      updateAllFields(updatedCustomFields);
    });
  };

  const onFileSelect = e => {
    setFile(e?.target?.files[0]);
    setFileExtension(
      e?.target?.files?.[0]?.name?.split('.')?.pop()?.toLowerCase(),
    );
  };

  const onUpload = async () => {
    setShowImportModal(false);

    if (validateRequiredFields(true)) {
      try {
        setLoading(true);

        let totalImported = fileData.length;
        const chunks = splitArray(fileData ?? [], 10000) as Object[][];

        for (let c = 0; c < chunks.length; c++) {
          const chunk = chunks[c];

          await insertProspectIngestionRecords({
            email: userLogged?.email,
            list: activeList,
            prospects: chunk.map(data => {
              return {
                ...Object.keys(fieldMap)
                  .map(key => {
                    const mappedKey = fieldMap[key];

                    return {
                      [mappedKey]:
                        (key === 'birthday'
                          ? moment(data[key]).format('YYYY-MM-DD')
                          : allFields[mappedKey].isNumber
                          ? (data?.[key] ?? '')
                              .toString()
                              .replace(/[^0-9]/g, '')
                          : data[key]) ??
                        (allFields[mappedKey].isRequired ? 'N/A' : ''),
                    };
                  })
                  .reduce((p, c) => {
                    return { ...p, ...c };
                  }, {}),
                ...{
                  organizationId: userLogged?.organizationId,
                  userId: userLogged?._id,
                },
              };
            }),
          })
            .then((response: any) => {
              if (response?.status !== 200)
                setErrorMessage(JSON.stringify(response));
            })
            .catch(error => {
              setErrorMessage((error as any)?.message ?? 'Unknown');
            });
        }

        setExampleData({});
        setFieldMap({});
        setFile(null);
        setFileData(null);
        setFileExtension(null);
        setHasHeaderRow(true);
        setNumberOfContactsImported(totalImported);
        fileRef!.current!.value = null;
        setLoading(false);
      } catch (error) {
        console.error(error);
        setErrorMessage((error as any)?.message ?? 'Unknown');
      }
    }
  };

  const setErrorMessage = error =>
    setMessage({
      isError: true,
      title: 'Import',
      message: (
        <>
          <div className="mb-3">
            Import encountered an error. Please contact us with the follwing
            information:
          </div>
          <Form.Control
            as="textarea"
            readOnly
            rows={5}
            value={JSON.stringify(error)}
          />
        </>
      ),
    });

  const updateAllFields = updatedCustomFields => {
    setAllFields(
      sortObjectBySubField(
        {
          ...fieldMapOptions,
          ...((updatedCustomFields as any) ?? [])
            .map(customField => ({
              [`custom.${customField.field}`]: {
                ...customField,
                label: customField.header,
                isRequired: false,
                alias: [customField.header],
              },
            }))
            .reduce((p, c) => ({ ...p, ...c }), {}),
        },
        'label',
      ),
    );
  };

  const updateFileData = newData => {
    if (newData?.[0])
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setFileData(newData.map(({ _id, ...validData }) => validData));
  };

  const validateRequiredFields = useCallback(
    (notifyUser = false) => {
      const fieldMapValues = Object.values(fieldMap);

      if (!activeList || !activeList?.Name?.length) {
        if (notifyUser)
          setMessage({
            isError: true,
            title: 'Import',
            message: `Please select a list.`,
          });

        return false;
      }
      return (
        Object.keys(allFields)
          .map(key => {
            if (allFields[key].isRequired && !fieldMapValues.includes(key)) {
              if (notifyUser)
                setMessage({
                  isError: true,
                  title: 'Import',
                  message: `${allFields[key].label} must be mapped.`,
                });

              return key;
            } else return null;
          })
          .filter(a => a).length === 0
      );
    },
    [activeList, allFields, fieldMap],
  );

  useEffect(() => {
    if (file) {
      setLoading(true);
      if (fileExtension === 'csv')
        Papa.parse(file, {
          header: hasHeaderRow,
          skipEmptyLines: true,
          complete: results => {
            if (hasHeaderRow) updateFileData(results.data);
            else {
              updateFileData(
                results.data.map(row =>
                  row
                    .map((field, index) => {
                      return {
                        [`column_${'0'.repeat(
                          3 - index.toString().length,
                        )}${index}`]: field,
                      };
                    })
                    .reduce((p, c) => {
                      return { ...p, ...c };
                    }, {}),
                ),
              );
            }

            if (results?.errors?.[0]) {
              setMessage({
                isError: true,
                title: 'Import',
                message: (
                  <>
                    <div className="mb-3">
                      CSV may have loaded with errors. Contact data may be
                      corrupt.
                    </div>
                    <div className="mb-3">
                      <strong>Row:</strong>&nbsp;
                      {results.errors[0].row}
                    </div>
                    <div className="mb-3">
                      <strong>Code:</strong>&nbsp;
                      {results.errors[0].code}
                    </div>
                    <div className="mb-3">
                      <strong>Message:</strong>&nbsp;
                      {results.errors[0].message}
                    </div>
                  </>
                ),
              });
            }

            setLoading(false);
          },
        });
      else if (fileExtension === 'xlsx')
        readXlsxFile(file, { dateFormat: 'mm/dd/yyyy' }).then(results => {
          const headerRow = hasHeaderRow
            ? results.shift()
            : '*'
                .repeat(results[0].length)
                .split('')
                .map(
                  (value, index) =>
                    `column_${'0'.repeat(3 - index.toString().length)}${index}`,
                );

          updateFileData(
            results.map(row =>
              row
                .map((field, index) => {
                  return { [headerRow?.[index] as string]: field };
                })
                .reduce((p, c) => {
                  return { ...p, ...c };
                }, {}),
            ),
          );

          setLoading(false);
        });
    }
  }, [file, fileExtension, hasHeaderRow]);

  useEffect(() => {
    if (fileData) {
      const newExampleData = {};

      Object.keys(fileData[0]).forEach(key => {
        for (let c = 0; c < Math.min(3, fileData.length); c++) {
          const isLast = c === fileData.length - 1;

          if (
            fileData?.[c]?.[key] &&
            (fileData?.[c]?.[key]?.toString()?.replace(/[0 ]/, '') || isLast)
          )
            newExampleData[key] = [
              ...(newExampleData[key] ?? []),
              fileData[c][key],
            ];
        }

        newExampleData[key] = [
          ...Array.from(new Set(newExampleData[key] || [])),
        ]
          .slice(0, 10)
          .sort();
      });

      setExampleData(newExampleData);

      setRequiredFields(
        Object.keys(allFields)
          .filter(key => allFields[key].isRequired)
          .map(key => allFields[key].label)
          .sort(),
      );
    }
  }, [allFields, fileData]);

  useEffect(() => {
    if (fileData) setEnableUploadButton(validateRequiredFields(false));
  }, [fileData, fieldMap, validateRequiredFields]);

  useEffect(() => {
    getLists(userLogged?.email).then(response => {
      setList(response.data);
    });

    getProspectTrackerOptions({
      email: userLogged!.email,
    }).then(response => {
      response.data?.options.forEach(options => {
        if (!!options?.customFields) {
          setCustomFields(
            options.customFields.map(customField => ({
              ...customField,
              label: customField.header,
              isRequired: false,
              alias: [customField.header],
            })),
          );

          updateAllFields(options.customFields);
        }
      });
    });
  }, [userLogged, userLogged?.email]);

  return (
    <Container fluid className="mt-5">
      {message && (
        <GeneralModal {...message} show onHide={() => setMessage(null)} />
      )}

      <LoadingOverlay show={loading} />

      <GeneralConfirmationModal
        show={showAutoFillModal}
        title="Import"
        message="This will attempt to auto-map fields not yet mapped. This action cannot be undone. Would you like to continue?"
        onCancel={() => setShowAutoFillModal(false)}
        onHide={() => setShowAutoFillModal(false)}
        onOK={() => autoFill()}
      />

      <GeneralConfirmationModal
        show={showImportModal}
        title="Import"
        message={`You are about to import ${fileData?.length.toLocaleString()} prospect(s). Any unmapped fields will not be imported. This action cannot be undone. Would you like to continue?`}
        onCancel={() => setShowImportModal(false)}
        onHide={() => setShowImportModal(false)}
        onOK={() => onUpload()}
      />

      <GeneralConfirmationModal
        show={!!numberOfContactsImported && !loading}
        title="Import"
        message={
          numberOfContactsImported === 1
            ? `New prospect was imported. It may take a moment before it shows in the prospect tracker.`
            : `${numberOfContactsImported.toLocaleString()} new prospects were imported. It may take a moment before they all show in the prospect tracker.`
        }
        cancelLabel="Monitor Progress"
        okLabel="Go To Prospect Tracker"
        onCancel={() => navigate('/process-monitor')}
        onHide={() => setNumberOfContactsImported(0)}
        onOK={() => navigate('/prospect-tracker')}
      />

      <CustomFieldModal
        customFields={customFields}
        onHide={() => setShowCustomFieldModal(false)}
        onSave={onCustomFieldsSave}
        show={showCustomFieldModal}
      />

      <Row className="pb-4">
        <Col className="mb-3 mb-md-0" md={3}>
          <FormGroup controlId="formFile">
            <FormControl
              ref={fileRef}
              type="file"
              onChange={onFileSelect}
              accept=".xls,.xlsx,.csv"
            />
            <Hint>Supported extensions include: .csv, .xlsx</Hint>
          </FormGroup>
        </Col>

        {fileData && (
          <Col className="mb-3 mb-md-0" md={3}>
            <ButtonGroup>
              <Button
                variant={hasHeaderRow ? 'primary' : 'light'}
                onClick={() => setHasHeaderRow(true)}
              >
                Has Header Row
              </Button>
              <Button
                variant={!hasHeaderRow ? 'primary' : 'light'}
                onClick={() => setHasHeaderRow(false)}
              >
                Does Not Have Header Row
              </Button>
            </ButtonGroup>
          </Col>
        )}

        <Col className="mb-3 mb-md-0" md={3}>
          <ProspectListDropdown
            list={list}
            activeList={activeList}
            setActiveList={setActiveList}
          />
        </Col>

        {fileData && (
          <Col md={3}>
            <Button
              className="float-end"
              onClick={() => setShowAutoFillModal(true)}
            >
              Auto Map
            </Button>
          </Col>
        )}
      </Row>

      {!!fileData && !!requiredFields.length && (
        <Row className="mb-3">
          <Col xs={12}>
            <div className="fs-1">Map Fields</div>
            <div className="fs-6">
              The following fields must be mapped to before continuing:{' '}
              {requiredFields.join(', ')}
            </div>
          </Col>
        </Row>
      )}

      {!!fileData && (
        <Row>
          {Object.keys(fileData?.[0])
            .sort((a, b) =>
              a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
            )
            .map(key => (
              <Col key={key} md={3} className="mb-3">
                <FieldMappingDropdown
                  key={JSON.stringify(customFields)}
                  customFields={customFields}
                  exampleData={exampleData}
                  field={key}
                  fieldMap={fieldMap}
                  setFieldMap={setFieldMap}
                  setShowCustomFieldModal={setShowCustomFieldModal}
                />
              </Col>
            ))}
        </Row>
      )}
      {!!fileData && (
        <Row className="mt-3">
          <Col md={11}></Col>
          <Col md={1}>
            <Button
              disabled={!enableUploadButton}
              className="w-100"
              onClick={() => setShowImportModal(true)}
            >
              Upload
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ProspectImporter;
