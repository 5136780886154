import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { insertProspectTrackerTemplateRecords } from 'api/prospect.tracker.template.service';
import { useSelector } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import newCellTemplateList from '../../newCellTemplateList';
import { getProspectTrackerTemplateList } from 'api/prospect.tracker.template.service';
import { getProspectTrackerAssignees } from 'api/prospect.tracker.service';
import { getProspectTrackerOptions } from 'api/prospect.tracker.options.service';
import { getLists } from 'api/prospect.list.service';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import HTMLEditor from 'app/components/HTMLEditor';

const TemplateManager = () => {
  const userLogged = useSelector(getAuthUser);
  const [assignees, setAssignees] = React.useState([
    { _id: '', firstName: '', lastName: '' },
  ]);
  const [list, setList] = React.useState<any>(null);
  const [cellTemplateList, setCellTemplateList] = React.useState<any>(null);
  const [options, setOptions] = React.useState<any>({});
  const [templates, setTemplates] = React.useState<any[]>([]);
  const [templateIndex, setTemplateIndex] = React.useState(-1);

  const addTemplate = () => {
    setTemplates([
      ...(templates ?? []),
      {
        use: 'Email',
        name: 'New Template',
        content: 'Hello [[FirstName]] [[LastName]]',
        subject: 'Important message for [[FirstName]] [[LastName]]',
      },
    ]);

    setTemplateIndex(templates?.length ?? 0);
  };

  const getActiveTemplateContent = () =>
    templates?.[templateIndex]?.content ?? '';

  const getActiveTemplateSubject = () =>
    templates?.[templateIndex]?.subject ?? '';

  const getActiveTemplateName = () => templates?.[templateIndex]?.name ?? '';

  const getActiveTemplateUse = () => templates?.[templateIndex]?.use ?? '';

  const getActiveTemplateId = () => templates?.[templateIndex]?._id ?? '';

  const onHTMLUpdate = (_, html) => {
    updateActiveTemplateContent(html);
  };

  const saveTemplate = () => {
    insertProspectTrackerTemplateRecords({
      email: userLogged!.email,
      templates,
    }).then(response => {
      setTemplates(response.data.templates);
      toast.success('All templates saved successfully!');
    });
  };

  const updateActiveTemplateContent = content => {
    if (!!templates[templateIndex]) {
      templates[templateIndex].content = content;
      setTemplates([...templates]);
    }
  };

  const updateActiveTemplateSubject = subject => {
    if (!!templates[templateIndex]) {
      templates[templateIndex].subject = subject;
      setTemplates([...templates]);
    }
  };

  const updateActiveTemplateName = name => {
    if (!!templates[templateIndex]) {
      templates[templateIndex].name = name;
      setTemplates([...templates]);
    }
  };

  const updateActiveTemplateUse = use => {
    if (!!templates[templateIndex]) {
      templates[templateIndex].use = use;
      setTemplates([...templates]);
    }
  };

  React.useEffect(() => {
    getProspectTrackerAssignees({
      email: userLogged!.email,
    }).then(response => {
      setAssignees(response.data.assignees);
    });

    getProspectTrackerOptions({
      email: userLogged!.email,
    }).then(response => {
      let newOptions = {};

      response.data?.options.forEach(item => {
        if ('activeEmailTemplate' in item)
          newOptions = {
            ...newOptions,
            activeEmailTemplate: item.activeEmailTemplate,
          };

        if ('activeSMSTemplate' in item)
          newOptions = {
            ...newOptions,
            activeSMSTemplate: item.activeSMSTemplate,
          };

        if ('activeSMTPCredentials' in item)
          newOptions = {
            ...newOptions,
            activeSMTPCredentials: item.activeSMTPCredentials,
          };
      });

      setOptions(newOptions);
    });

    getProspectTrackerTemplateList({
      email: userLogged!.email,
    }).then(response => {
      setTemplates(response.data.templates);
    });

    getLists(userLogged?.email).then(response => {
      setList(response.data);
    });
  }, [userLogged]);

  React.useEffect(() => {
    if (list && assignees && options)
      setCellTemplateList(
        newCellTemplateList({ assignees, list, options, userLogged }),
      );
  }, [assignees, list, options, userLogged]);

  if (!cellTemplateList) return null;
  return (
    <Container className="mt-3">
      <InputGroup size="sm" className="mb-3">
        <Form.Select
          size="sm"
          value={templateIndex}
          onChange={e => setTemplateIndex(parseInt(e.target.value, 10))}
        >
          <option value="">-- Select a template to edit --</option>
          {templates.map((template, index) => (
            <option value={index}>{template.name}</option>
          ))}
        </Form.Select>
        <Button size="sm" onClick={addTemplate}>
          <MdAdd />
        </Button>
      </InputGroup>

      <InputGroup className="mb-3">
        <Form.Select
          size="sm"
          value={getActiveTemplateUse()}
          onChange={e => updateActiveTemplateUse(e.target.value)}
        >
          <option value="">-- Select a use --</option>
          <option value="email">Email</option>
          <option value="sms">SMS</option>
        </Form.Select>
        <Form.Control
          placeholder="Enter a template name"
          value={getActiveTemplateName()}
          onChange={e => updateActiveTemplateName(e.target.value)}
        />
      </InputGroup>

      {getActiveTemplateUse() === 'sms' && (
        <small
          className={
            getActiveTemplateContent().length > 160
              ? 'text-danger'
              : getActiveTemplateContent().length > 100
              ? 'text-warning'
              : ''
          }
        >
          Char Count: {getActiveTemplateContent().length}
        </small>
      )}

      {getActiveTemplateUse() === 'email' && (
        <Form.Control
          className="mb-3"
          placeholder="Email subject"
          value={getActiveTemplateSubject()}
          onChange={e => updateActiveTemplateSubject(e.target.value)}
        />
      )}

      {getActiveTemplateUse() === 'sms' && (
        <Form.Control
          as="textarea"
          rows={10}
          placeholder="SMS Message"
          value={getActiveTemplateContent()}
          onChange={e => updateActiveTemplateContent(e.target.value)}
        />
      )}

      {getActiveTemplateUse() === 'email' && (
        <HTMLEditor
          key={getActiveTemplateId()}
          defaultContent={getActiveTemplateContent().replace(
            /(\r\n)|\r|\n/gm,
            '<br/>',
          )}
          height={500}
          onUpdate={onHTMLUpdate}
        />
      )}

      <Accordion className="mt-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Utilize the below tags to personalize your template:
          </Accordion.Header>
          <Accordion.Body>
            <Table hover striped>
              <thead>
                <tr>
                  <th>Tag</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                {cellTemplateList.map(
                  cellTemplate =>
                    !!cellTemplate?.tagDescription && (
                      <tr>
                        <td>[[{cellTemplate.field}]]</td>
                        <td>{cellTemplate.tagDescription}</td>
                      </tr>
                    ),
                )}
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <ButtonGroup className="mt-3 float-end">
        <Button variant="dark" onClick={saveTemplate}>
          Save
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default TemplateManager;
