import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

export const ProspectListDropdown = ({ activeList, list, setActiveList }) => {
  return (
    <Dropdown as={InputGroup}>
      <Form.Control
        disabled={!!activeList?._id}
        placeholder="Enter name of new list"
        value={activeList?.Name ?? ''}
        onChange={e => setActiveList({ ...activeList, Name: e.target.value })}
      />
      <Dropdown.Toggle split />
      <Dropdown.Menu className="w-100" align="end">
        <Dropdown.Item
          className={!activeList?._id ? 'bg-primary text-light' : ''}
          onClick={() => setActiveList({ Name: 'My New List' })}
        >
          Create New List
        </Dropdown.Item>
        {list.map(item => (
          <Dropdown.Item
            className={
              activeList?._id === item._id ? 'bg-primary text-light' : ''
            }
            key={item?._id}
            onClick={() => setActiveList(item)}
          >
            {item?.Name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
