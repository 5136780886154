import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { stripHtml } from 'string-strip-html';

const HTMLEditor = ({
  defaultContent = '',
  height = 500,
  hint = null,
  onUpdate = (text, html) => {},
}: {
  defaultContent?: string;
  height?: number;
  hint?: any;
  onUpdate?: any;
}) => {
  const [content, setContent] = useState<string>(defaultContent);

  const onChange = newValue => {
    setContent(newValue);
    onUpdate(stripHtml(newValue.replaceAll('<br>', '\r\n')).result, newValue);
  };

  return (
    <>
      <Editor
        tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
        value={content}
        onEditorChange={onChange}
        init={{
          height,
          branding: false,
          menubar: false,
          plugins: 'image link lists',
          toolbar:
            'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat link | image',
        }}
      />
      {!!hint && <small>{hint}</small>}
    </>
  );
};

export default HTMLEditor;
