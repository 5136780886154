import { AxiosResponse } from 'axios';
import {
  BulkSearchRequest,
  SingleSearchRequest,
  PurchasedDonorsRequest,
} from './types';
import constants from 'app/config/constants';
import axiosInstance from 'utils/api';
import { UserSession } from 'store/authentication/types';

export const singleSearchPostRequest = async (
  data: SingleSearchRequest,
): Promise<AxiosResponse> => {
  const { limit, ...rest } = data;
  try {
    const response = await axiosInstance()<UserSession>({
      url: `${constants.BASE_ENDPOINT()}/api/dre/donor/records${
        limit ? `?limit=${limit}` : ''
      }`,
      method: 'POST',
      data: rest,
    });
    return response;
  } catch (error) {
    throw new Error('Failed to fetch single donor request');
  }
};

export const bulkSearchPostRequest = async (
  data: BulkSearchRequest,
): Promise<AxiosResponse> => {
  try {
    const response = await axiosInstance()<UserSession>({
      url: `${constants.BASE_ENDPOINT()}/api/dre/donorlist/records`,
      method: 'POST',
      data,
    });
    return response;
  } catch (error) {
    throw new Error('Failed to fetch single donor request');
  }
};

export const purchasedDonorsPostRequest = async (
  data: PurchasedDonorsRequest,
): Promise<AxiosResponse> => {
  try {
    const response = await axiosInstance()<UserSession>({
      url: `${constants.BASE_ENDPOINT()}/api/dre/donorlist/getPurchased`,
      method: 'POST',
      data,
    });
    return response;
  } catch (error) {
    throw new Error('Failed to fetch purchased donors');
  }
};
