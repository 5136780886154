import React from 'react';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { getDonorListCSV } from 'api/donorlist.service';
import dayjs from 'dayjs';
import ToolTip from '../ToolTip';
import { Button } from 'react-bootstrap';

export const DownloadPurchasedButton = ({ isMobile }) => {
  const userLogged = useSelector(getAuthUser);

  const downloadCSV = async () => {
    try {
      const email = userLogged!.email;
      const test = await getDonorListCSV([], email, true);

      const blob = new Blob([test.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `data${dayjs().format(' YYYY-MM-DD HH.mm.ss')}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  return (
    <ToolTip title="Download Previously Licensed Donors" placement="bottom">
      <Button
        variant="link"
        onClick={downloadCSV}
        className={`fs-3 text-decoration-none text-start w-100 p-0 ${
          isMobile ? 'text-dark ' : 'text-light px-2'
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 640 512"
        >
          {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
          <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z" />
        </svg>
        {isMobile && <>&nbsp; Download Licensed</>}
      </Button>
    </ToolTip>
  );
};
