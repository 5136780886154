import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.searchSlice || initialState;

export const selectSearchSlice = createSelector([selectSlice], state => state);
export const selectSearchType = createSelector(
  [selectSlice],
  state => state.searchType,
);
export const selectActiveTab = createSelector(
  [selectSlice],
  state => state.activeTab,
);
export const selectCurrentPage = createSelector(
  [selectSlice],
  state => state.currentPage,
);
export const selectRowsPerPage = createSelector(
  [selectSlice],
  state => state.rowsPerPage,
);
export const selectSearchLoading = createSelector(
  [selectSlice],
  state => state.loading,
);
export const selectSearchError = createSelector(
  [selectSlice],
  state => state.error,
);
export const selectSearchDonorData = createSelector(
  [selectSlice],
  state => state.searchData,
);
export const selectSearchDonorCount = createSelector(
  [selectSlice],
  state => state.searchCount,
);
export const selectDonor = createSelector(
  [selectSlice],
  state => state.selectedDonor,
);
export const selectRecentSingleSearchQuery = createSelector(
  [selectSlice],
  state => state.recentSingleSearchQuery,
);
export const selectFilterQuery = createSelector(
  [selectSlice],
  state => state.filterQuery,
);
export const selectShowPurchased = createSelector(
  [selectSlice],
  state => state.showPurchased,
);
export const selectLoadingPurchased = createSelector(
  [selectSlice],
  state => state.loadingPurchased,
);
export const selectPurchasedRecords = createSelector(
  [selectSlice],
  ({ filterQuery, purchasedRecords }) => {
    if (filterQuery && Object.entries(filterQuery).length) {
      const filter = {
        FirstName: filterQuery!.firstName,
        LastName: filterQuery!.lastName,
        AgeRange: filterQuery!.age,
        RegistrationAddressState: filterQuery!.state,
      };
      return purchasedRecords.filter(obj =>
        Object.keys(filter).some(key => {
          if (!obj.hasOwnProperty(key)) return false;
          const objValue = obj[key];
          const filterValue = filter[key];
          if (typeof objValue === 'string' && typeof filterValue === 'string') {
            return (
              objValue.toLowerCase().includes(filterValue.toLowerCase()) ||
              filterValue.trim() === ''
            );
          }
          return (
            objValue === filterValue ||
            (filterValue === null && objValue === null) ||
            (filterValue === undefined && objValue === undefined)
          );
        }),
      );
    } else {
      return purchasedRecords;
    }
  },
);
export const selectHasPurchases = createSelector(
  [selectSlice],
  state => state.hasPurchases,
);
export const selectHasIngested = createSelector(
  [selectSlice],
  state => state.hasIngested,
);
export const selectCart = createSelector([selectSlice], state => state.cart);
export const selectSorting = createSelector(
  [selectSlice],
  state => state.sorting,
);
