export const authFormStyle = {
  '& .MuiOutlinedInput-root': {
    height: '40px',
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    lineHeight: '1.286em',
    '& fieldset': {
      textAlign: 'center',
      transition: 'all .3s',
      border: '1px solid #e6e9f1',
      borderRadius: '6px',
    },
    '&:hover fieldset': {
      borderColor: '#aab1c6',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: '#63D489',
    },
  },
  backgroundColor: '#fff',
  borderRadius: '6px',
  width: '100%',
  boxShadow: '0 0 4px rgba(20, 20, 43, .09)',
};
