import React, { useEffect, useRef, useState } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { Hint } from 'react-bootstrap-typeahead';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import {
  calculateFieldAccuracy,
  denormalizeData,
  filterData,
  getRandomizedObjectFieldValues,
  newFacets,
  normalizeData,
  splitArray,
} from '../utils';
import { matchExtractedDataToMOADB } from 'api/chatgpt.service';
import Papa from 'papaparse';
import LoadingOverlay from 'app/components/LoadingOverlay';

const DataProfileModal = ({ customerId, onComplete, onHide, show }) => {
  const [data, setData] = useState<any>(null);
  const [dataNormalized, setDataNormalized] = useState<any>(null);
  const [dictionary, setDictionary] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [lastProcessed, setLastProcessed] = useState<number>(Date.now());
  const [loading, setLoading] = useState(false);
  const [specificity, setSpecificity] = useState(2);
  const loadingRef = useRef(false);

  const onFileSelect = e => {
    setFile(e?.target?.files[0]);
  };

  useEffect(() => {
    if (!!file && !loadingRef.current) {
      //setLoading(true);
      //loadingRef.current = true;

      Papa.parse(file, {
        header: false,
        skipEmptyLines: true,
        complete: results => {
          const samples = getRandomizedObjectFieldValues(results.data, 20);

          console.clear();
          console.log('debug', samples);

          matchExtractedDataToMOADB(
            {
              customerId,
              samples,
              fileData: results.data,
            },
            response => {
              console.log('debug', response);

              setData(response);
            },
          );
        },
      });
    }
  }, [customerId, file]);

  useEffect(() => {
    if (!!data) {
      const newDictionary = {};
      setDataNormalized(
        data.map(prospect =>
          normalizeData({
            data: prospect,
            dictionary: newDictionary,
            weight: 1,
            onValue: (key, value) => {
              if (key === 'donation_capacity')
                return (Math.floor(parseFloat(value) / 5000) * 5000).toString();
              else if (!!value) return value;
              else return undefined;
            },
            keys: [
              'donates_to_animal_welfare',
              'donates_to_arts_and_culture',
              'donates_to_childrens_causes',
              'donates_to_conservative_causes',
              'donates_to_healthcare',
              'donates_to_international_aid_causes',
              'donates_to_liberal_causes',
              'donates_to_local_community',
              'donates_to_political_causes',
              'donates_to_veterans_causes',
              'donates_to_wildlife_preservation',
              'donation_capacity',
              'is_4yr_graduate',
              'modeled_religion',
              'ideology',
              'postal_code',
              'state',
            ],
          }),
        ),
      );

      setDictionary(newDictionary);
    }
  }, [data]);

  useEffect(() => {
    if (!!dataNormalized && !!dictionary) {
      let fieldAccuracy: any = null;
      let filteredRows: any = dataNormalized;

      for (let s = 1; s <= specificity; s++) {
        fieldAccuracy = calculateFieldAccuracy({ data: filteredRows });
        const filteredFields = Object.keys(filterData({ data: fieldAccuracy }));

        filteredRows = filteredRows.filter(
          prospect =>
            filteredFields.filter(field => field in prospect).length ===
            filteredFields.length,
        );

        if (!filteredRows?.length) break;
      }

      const filteredData = denormalizeData({
        data: filterData({ data: fieldAccuracy }),
        dictionary,
      });

      const facets: any = newFacets();

      Object.keys(filteredData).forEach(key => {
        const value = filteredData[key];
        console.log('debug', key, value);

        if (key === 'donates_to_animal_welfare')
          facets.Facets.interests.push('DONATES_TO_ANIMAL_WELFARE');
        else if (key === 'donates_to_arts_and_culture')
          facets.Facets.interests.push('DONATES_TO_ARTS_AND_CULTURE');
        else if (key === 'donates_to_childrens_causes')
          facets.Facets.interests.push('DONATES_TO_CHILDRENS_CAUSES');
        else if (key === 'donates_to_conservative_causes')
          facets.Facets.interests.push('DONATES_TO_CONSERVATIVE_CAUSES');
        else if (key === 'donates_to_healthcare')
          facets.Facets.interests.push('DONATES_TO_HEALTHCARE');
        else if (key === 'donates_to_international_aid_causes')
          facets.Facets.interests.push('DONATES_TO_INTERNATIONAL_AID_CAUSES');
        else if (key === 'donates_to_liberal_causes')
          facets.Facets.interests.push('DONATES_TO_LIBERAL_CAUSES');
        else if (key === 'donates_to_local_community')
          facets.Facets.interests.push('DONATES_TO_LOCAL_COMMUNITY');
        else if (key === 'donates_to_political_causes')
          facets.Facets.interests.push('DONATES_TO_POLITICAL_CAUSES');
        else if (key === 'donates_to_veterans_causes')
          facets.Facets.interests.push('DONATES_TO_VETERANS_CAUSES');
        else if (key === 'donates_to_wildlife_preservation')
          facets.Facets.interests.push('DONATES_TO_WILDLIFE_PRESERVATION');
        else if (key === 'donation_capacity')
          facets.Facets.minDonationCapacity = parseInt(value?.[0] ?? 0, 10);
        else if (key === 'is_4yr_graduate') facets.Facets.is4YrGrad = true;
        else if (key === 'modeled_religion') facets.Facets.religion = value;
        else if (key === 'ideology') facets.Facets.ideology = value;
        else if (key === 'postal_code')
          facets.Facets.address = value?.[0] ?? '';
        else if (key === 'state') facets.Facets.state = value;
      });

      onHide();
      onComplete(facets);

      setDictionary(null);
      setDataNormalized(null);
      setFile(null);
      setLastProcessed(Date.now());
      setLoading(false);
      loadingRef.current = false;

      //console.log('debug', fieldAccuracy, filteredData, facets);
    }
  }, [dataNormalized, dictionary, onComplete, onHide, specificity]);

  return (
    <>
      <LoadingOverlay show={loading} />

      <Modal size="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Create Data Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormControl
              key={lastProcessed}
              type="file"
              onChange={onFileSelect}
              accept=".xls,.xlsx,.csv"
            />
            <Hint>Supported extensions include: .csv, .xlsx</Hint>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button variant="light" onClick={onHide}>
              Cancel
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DataProfileModal;
