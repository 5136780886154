import React from 'react';
import { Accordion, Col, Row, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import { convertToRange } from 'app/components/MOADBFacetedSearch/utils';
import IconGuage from '../IconGuage';

import { ReactComponent as donationCapacityIcon } from '../../../assets/icons/helping-hands-icon.svg';
import { ReactComponent as realEstateAssetsIcon } from '../../../assets/icons/house-icon.svg';
import { ReactComponent as householdIncomeIcon } from '../../../assets/icons/buyer-pay-icon.svg';
import { ReactComponent as ideologyIcon } from '../../../assets/icons/productivity-expert-icon.svg';

import { getIdeologyLabel } from 'app/components/Prospect-Tracker/utility';
import IntersetsIcons from 'app/components/InterestIcons';

const ProspectModal = ({
  data,
  dataAll,
  onColumnFilter,
  onColumnHeader,
  onHide,
  show,
}) => {
  const columns = Object.keys(data ?? {}).sort();
  const filteredColumns = columns.filter(column =>
    !!onColumnFilter ? onColumnFilter(column) : true,
  );
  const columnHeaders = filteredColumns.map(column =>
    !!onColumnHeader ? onColumnHeader(column, false) : column,
  );

  const getRowColumn = (row, column) =>
    filteredColumns.indexOf(column) >= 0 ? row[column] : null;

  if (!data) return null;
  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {[
            data.first_name,
            data.middle_name ?? data.middle_initital,
            data.last_name,
          ].join(' ')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey="overview" flush>
          <Accordion.Item eventKey="overview">
            <Accordion.Header>Overview</Accordion.Header>
            <Accordion.Body className="px-0">
              <Table size="sm" bordered striped>
                <tbody>
                  <tr>
                    <td>Address</td>
                    <td colSpan={3}>
                      {[
                        getRowColumn(data, 'address_1'),
                        [
                          getRowColumn(data, 'city'),
                          getRowColumn(data, 'state'),
                          getRowColumn(data, 'postal_code'),
                        ].join(' '),
                      ]
                        .filter(a => a)
                        .join(', ')}
                    </td>
                  </tr>
                  <tr>
                    <td>Contact Info</td>
                    <td colSpan={3}>
                      {[
                        getRowColumn(data, 'cell_phone_alesco'),
                        getRowColumn(data, 'cell_phone_hub224'),
                        getRowColumn(data, 'landline_phone__hub224'),
                      ]
                        .filter(a => a)
                        .join(', ')}
                    </td>
                  </tr>
                  <tr>
                    <td>Birth Date</td>
                    <td>{getRowColumn(data, 'birth_date')}</td>
                    <td>Modeled Religion</td>
                    <td>{getRowColumn(data, 'modeled_religion')}</td>
                  </tr>
                </tbody>
              </Table>

              {/*<Row className="align-self-center">
                {!!getRowColumn(data, 'ideology') && (
                  <Col lg={2} className="align-self-center">
                    <IconGuage
                      colors={['blue', 'red']}
                      Icon={ideologyIcon}
                      iconOffsetX={15}
                      maxValue={100}
                      segmentCount={100}
                      value={
                        ((6 - parseInt(getRowColumn(data, 'ideology'), 10)) /
                          5) *
                        100
                      }
                      title={getIdeologyLabel(getRowColumn(data, 'ideology'))}
                      type="grafana"
                    />
                  </Col>
                )}

                {!!getRowColumn(data, 'estimated_home_value') && (
                  <Col lg={2} className="align-self-center">
                    <IconGuage
                      Icon={realEstateAssetsIcon}
                      segments={[33, 66, 100]}
                      value={
                        (Math.max(
                          0,
                          Math.min(
                            100,
                            getRowColumn(data, 'estimated_home_value') / 100000,
                          ),
                        ) /
                          100) *
                        100
                      }
                      title={`Real Estate Assets: ${convertToRange(
                        getRowColumn(data, 'estimated_home_value'),
                      )}`}
                    />
                  </Col>
                )}

                {!!getRowColumn(data, 'household_income_total') && (
                  <Col lg={2} className="align-self-center">
                    <IconGuage
                      Icon={householdIncomeIcon}
                      iconOffsetX={15}
                      height={45}
                      segments={[25, 50, 75, 100]}
                      value={
                        (Math.max(
                          0,
                          Math.min(
                            100,
                            getRowColumn(data, 'household_income_total') /
                              25000,
                          ),
                        ) /
                          100) *
                        100
                      }
                      title={`Household Income: ${convertToRange(
                        getRowColumn(data, 'household_income_total'),
                      )}`}
                    />
                  </Col>
                )}

                {!!getRowColumn(data, 'donation_capacity') && (
                  <Col lg={2} className="align-self-center">
                    <IconGuage
                      Icon={donationCapacityIcon}
                      iconOffsetX={23}
                      height={30}
                      segments={[20, 40, 60, 80, 100]}
                      value={
                        (Math.max(
                          0,
                          Math.min(
                            10,
                            getRowColumn(data, 'donation_capacity') / 1000,
                          ),
                        ) /
                          10) *
                        100
                      }
                      title={`Donation Capacity: ${convertToRange(
                        getRowColumn(data, 'donation_capacity'),
                      )}`}
                    />
                  </Col>
                )}

                <IntersetsIcons
                  prospect={data}
                  filteredColumns={filteredColumns}
                />
              </Row>*/}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="details">
            <Accordion.Header>Details</Accordion.Header>
            <Accordion.Body className="px-0">
              <Table size="sm" bordered striped>
                <tbody>
                  {filteredColumns.sort().map((key, index) => {
                    if (!data[key]) return null;
                    return (
                      <tr>
                        <td>{columnHeaders[index]}</td>
                        <td>
                          {[
                            'donation_capacity',
                            'estimated_home_value',
                            'household_income_expendable',
                            'household_income_total',
                          ].includes(key) && !!getRowColumn(data, key)
                            ? `$${parseFloat(data[key]).toLocaleString()}`
                            : ['ideology'].includes(key) &&
                              !!getRowColumn(data, key)
                            ? getIdeologyLabel(data[key])
                            : data[key]}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="light" onClick={onHide}>
            Close
          </Button>
        </ButtonGroup>
      </Modal.Footer>{' '}
    </Modal>
  );
};

export default ProspectModal;
