import constants from 'app/config/constants';
import axiosInstance from 'utils/api';

export const getDonorListCSV = async (
  dreIds: number[],
  email: string,
  pullDonorsPurchased: boolean = false,
) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/donorlist/getCSV`;

  const body = {
    query: dreIds,
    email,
    pullDonorsPurchased,
  };
  return await axiosInstance().post(url, body, {
    responseType: 'blob',
  });
};

export const getDonorsPurchasedCount = async (email: string) => {
  const url = `${constants.BASE_ENDPOINT()}/api/dre/donorlist/countPurchased`;

  return await axiosInstance().post(url, {
    email,
    limit: 1,
  });
};
