import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Elements from '../Elements';

const ReplaceModal = ({
  activeSection,
  replaceSqlElement,
  moadbQueries,
  onHide,
  show,
}) => {
  const addElement = (type, referenceId: any = null) => {
    replaceSqlElement(type, referenceId);
    onHide();
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Replace Element</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Object.keys(Elements)
          .filter(section => section === activeSection)
          .map(section => (
            <ButtonGroup vertical className="w-100">
              {Elements[section].map(element => (
                <Button
                  variant="light"
                  onClick={() => addElement(element.name)}
                >
                  <div className="fs-5 fw-bold text-start">
                    <Badge
                      bg={section === 'Block' ? 'primary' : 'dark'}
                      className="fs-5"
                    >
                      {element.name}
                    </Badge>
                  </div>
                  <div className="text-start">{element.description}</div>
                </Button>
              ))}
            </ButtonGroup>
          ))}

        {activeSection === 'Reference' && (
          <ButtonGroup vertical className="w-100">
            {!!moadbQueries?.queries &&
              moadbQueries.queries
                .sort((a, b) => a.Name.localeCompare(b.Name))
                .filter(a => !a.IsTemplate)
                .map(query => (
                  <Button
                    variant="light"
                    className="py-3"
                    onClick={() => addElement('ref', query._id)}
                  >
                    <div className="fs-5 fw-bold text-start">
                      <Badge bg={'success'} className="fs-5">
                        ref
                      </Badge>
                      &nbsp;
                      {query.Name}
                    </div>
                    <div className="text-start">{query.Description}</div>
                  </Button>
                ))}
          </ButtonGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="light" onClick={onHide}>
            Cancel
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ReplaceModal;
