import { PaymentIntent, PaymentMethod } from '@stripe/stripe-js';
import constants from 'app/config/constants';
import axiosInstance from 'utils/api';
import { CPaymentIntent } from './interfaces';

type CreateCustomer = {
  email: string;
  name: string;
  phone: string;
};
export const createCustomer = async ({
  email,
  name,
  phone,
}: CreateCustomer) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/stripe/customers`;

  return axiosInstance()<any>({
    url,
    method: 'POST',
    data: { email, name, phone },
  }).then(response => response.data);
};

type CreatePaymentIntent = {
  customer: string;
  amount: number;
  description?: string;
  customerPayload?: unknown;
  receipt_email?: string;
  metadata: { [key: string]: any };
};

type CreatePricePaymentIntent = {
  customer: string;
  description?: string;
  customerPayload?: unknown;
  item: any;
  metadata: { [key: string]: any };
  receipt_email?: string;
  quantity?: number;
};

export const createPaymentIntent = ({
  customer,
  amount,
  description,
  customerPayload,
  metadata,
  dreIds,
  organizationId,
}: CreatePaymentIntent & { dreIds?: number[]; organizationId?: number }) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/stripe/paymentIntents`;
  return axiosInstance()<CPaymentIntent>({
    url,
    method: 'POST',
    data: {
      customer,
      amount,
      description,
      customerPayload,
      metadata,
      dreIds,
      organizationId,
    },
  }).then(response => response.data);
};

export const createPricePaymentIntent = ({
  customer,
  description,
  customerPayload,
  item,
  metadata,
  organizationId,
  quantity,
}: CreatePricePaymentIntent & {
  dreIds?: number[];
  organizationId?: number;
}) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/stripe/paymentPriceIntents`;
  return axiosInstance()<CPaymentIntent>({
    url,
    method: 'POST',
    data: {
      customer,
      description,
      customerPayload,
      priceId: item.priceId,
      metadata,
      organizationId,
      quantity,
    },
  }).then(response => response.data);
};

export const getInvoice = (id: string) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/stripe/invoice/${id}`;
  return axiosInstance()<any>({
    url,
    method: 'GET',
  }).then(response => response.data);
};

export const getInvoiceFromIntent = (id: string) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/stripe/invoice/fromIntent/${id}`;
  return axiosInstance()<any>({
    url,
    method: 'GET',
  }).then(response => response.data);
};

export const getPaymentIntent = (id: string) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/stripe/paymentIntents/${id}`;
  return axiosInstance()<PaymentIntent>({
    url,
    method: 'GET',
  }).then(response => response.data);
};

export const listPaymentMethods = (
  customerId: string,
  type: { type: string },
) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/stripe/customers/listPaymentMethods`;
  return axiosInstance()<{
    data: PaymentMethod[];
    has_more: boolean;
    object: string;
    url: string;
  }>({
    url,
    method: 'POST',
    data: { customerId, type },
  }).then(response => response.data);
};

export const confirmPaymentIntent = (
  paymentIntentId: string,
  paymentMethodId: string,
) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/stripe/paymentIntents/confirm`;
  return axiosInstance()<PaymentIntent>({
    url,
    method: 'POST',
    data: { paymentIntentId, paymentMethodId },
  }).then(response => response.data);
};

export const updateOrganizationSubscription = (
  email: string,
  item: number,
  stripeCustomerID: string,
  stripeBasicMeteredSubscriptionItemId: string,
  stripeEnhancedMeteredSubscriptionItemId: string,
) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/vlog/subscription/update_organization_subscription`;
  return axiosInstance()<any>({
    url,
    method: 'POST',
    data: {
      email,
      item,
      stripeCustomerID,
      stripeBasicMeteredSubscriptionItemId,
      stripeEnhancedMeteredSubscriptionItemId,
    },
  }).then(response => response.data);
};
