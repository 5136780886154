import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';

const CreditsModal = ({ credits, delta, onHide, show }) => {
  return (
    <>
      <Modal size="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Credits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You need {delta.toLocaleString()} more credit{delta !== 1 ? 's' : ''}{' '}
          to complete this action. You currently have {credits.toLocaleString()}{' '}
          credit{credits !== 1 ? 's' : ''}.
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button variant="light" onClick={onHide}>
              Cancel
            </Button>
            <Button href="/settings">Purchase Credits</Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreditsModal;
