import React, { FC, useState } from 'react';
import { Field, Form } from 'react-final-form';
import FormTextField from 'app/components/Form/FormTextField';
import { Box, Button, MenuItem } from '@mui/material';
import { UserFormData, UserFormModalProps } from './types';
import { CustomButton } from 'app/components/LeftSidebar';
import { grey } from '@mui/material/colors';
import { updateUser } from 'api/user.service';
import { toast } from 'react-toastify';
import axios from 'axios';
import { registerUser } from 'api/auth.service';

const initialValues: UserFormData = {
  firstName: '',
  lastName: '',
  email: '',
  organizationId: 0,
  _id: '',
  role: '',
  federated: true,
};

const ROLES = [
  { _id: 'admin', label: 'Admin' },
  { _id: 'orgAdmin', label: 'Organization Admin' },
  { _id: '', label: 'Unassign role' },
];
const UserFormModal: FC<UserFormModalProps> = ({
  organizations,
  user,
  handleClose,
  afterUpdate,
}) => {
  const isEditMode = !!user;
  const [updating, setUpdating] = useState<boolean>(false);
  const onSubmit = async ({
    email,
    firstName,
    lastName,
    organizationId,
    _id,
    role = '',
    federated,
  }: UserFormData) => {
    const userPayload = { email, firstName, lastName, organizationId, role };
    const query = { _id };
    setUpdating(true);
    if (isEditMode) {
      updateUser(userPayload, query)
        .then(updated => {
          afterUpdate && afterUpdate();
          toast.success(`${firstName} ${lastName} was updated successfully`);
        })
        .catch(error => {
          let message = error?.response;
          if (axios.isAxiosError(error)) {
            message = error.response?.data.error;
          }
          toast.error(message);
        })
        .finally(() => setUpdating(false));
    } else {
      registerUser({
        firstName,
        lastName,
        email,
        password: '',
        phone: '',
        active: federated,
        isFederated: federated,
        organizationId,
      })
        .then(response => {
          if (response) {
            const message = federated
              ? 'User registered successfully'
              : 'Email sent with the temporary password.';
            toast.success(message);
            afterUpdate && afterUpdate();
          }
        })
        .catch(error => {
          if (axios.isAxiosError(error)) {
            toast.error(error.response?.data.error);
          }
        })
        .finally(() => setUpdating(false));
    }
  };

  async function validate(values: UserFormData) {
    const { firstName, lastName, email, organizationId } = values;

    if (!firstName) {
      return { firstName: 'First Name is required.' };
    }
    if (!lastName) {
      return { lastName: 'Last Name is required.' };
    }
    if (!email) {
      return { email: 'Email is required.' };
    }
    if (!organizationId) {
      return { organizationId: 'Organization is required.' };
    }
  }

  return (
    <Form
      initialValues={{
        firstName: user?.firstName || initialValues.firstName,
        lastName: user?.lastName || initialValues.lastName,
        email: user?.email || initialValues.email,
        organizationId: user?.organizationId || initialValues.organizationId,
        _id: user?._id || '',
        role: user?.role,
        federated: user?.isFederated || initialValues.federated,
      }}
      validate={validate}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '100%',
            }}
          >
            {/* Federated */}
            {!isEditMode && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 4,
                  width: '100%',
                }}
              >
                <label>Federated</label>
                <Field name="federated" type="checkbox" disabled={isEditMode}>
                  {props => (
                    <FormTextField
                      {...props}
                      placeholder="Email"
                      variant="outlined"
                      sx={{
                        width: '50px',
                        height: '30px',
                        fontSize: '10px',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 500,
                        lineHeight: '1.286em',
                        '& .MuiOutlinedInput-root': {
                          height: '40px',
                          fontSize: '14px',
                          fontFamily: 'Inter, sans-serif',
                          fontWeight: 500,
                          lineHeight: '1.286em',
                          '& fieldset': {
                            textAlign: 'center',
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderWidth: '1px',
                            borderColor: 'white',
                          },
                        },
                        border: 0,
                      }}
                    />
                  )}
                </Field>
              </Box>
            )}
            {/* firstName */}
            <Field name="firstName">
              {props => (
                <FormTextField
                  {...props}
                  placeholder="First Name"
                  variant="outlined"
                />
              )}
            </Field>
            {/* lastName */}
            <Field name="lastName">
              {props => <FormTextField {...props} placeholder="Last Name" />}
            </Field>
            {/* email */}
            <Field name="email" type="email" disabled={isEditMode}>
              {props => <FormTextField {...props} placeholder="Email" />}
            </Field>
            {/* organizationId */}
            <label>Organization</label>
            <Field name="organizationId">
              {props => (
                <FormTextField {...props} placeholder="Organization" select>
                  {organizations.map(organization => (
                    <MenuItem
                      key={organization._id}
                      value={organization.Organization_ID}
                    >
                      {organization.OrganizationName}
                    </MenuItem>
                  ))}
                </FormTextField>
              )}
            </Field>
            {/* role */}
            <label>Role</label>
            <Field name="role">
              {props => (
                <FormTextField {...props} placeholder="Role" select>
                  {ROLES.map(role => (
                    <MenuItem key={role._id} value={role._id}>
                      {role.label}
                    </MenuItem>
                  ))}
                </FormTextField>
              )}
            </Field>

            <Box sx={{ display: 'flex', gap: 4 }}>
              <CustomButton
                type="submit"
                variant="contained"
                disabled={submitting || updating}
                sx={{ width: '100%' }}
              >
                {updating ? 'SAVING...' : 'SAVE'}
              </CustomButton>
              <Button
                onClick={handleClose}
                sx={{
                  color: grey[500],
                  border: '1px solid ',
                  borderColor: grey[500],
                  borderRadius: '76px',
                  width: '100%',
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      )}
    />
  );
};

export default UserFormModal;
