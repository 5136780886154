import constants from 'app/config/constants';
import axiosInstance from 'utils/api';
import { websocketRequest } from 'utils/wsAPI';

export const getMOADBCustomers = async () => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/moadb/customers`;

  return await axiosInstance().get(url);
};

export const getMOADBQueries = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/moadb/`;

  return await axiosInstance().get(url, { params: body });
};

export const getMOADBRegions = async () => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/moadb/regions`;

  return await axiosInstance().get(url);
};

export const getMOADBStates = async () => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/moadb/states`;

  return await axiosInstance().get(url);
};

export const getMOADBJurisdictions = async state => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/moadb/jurisdictions?state=${state}`;

  return await axiosInstance().get(url);
};

export const generateSQL = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/moadb/generateSQL`;

  return await axiosInstance().post(url, body);
};

export const importMOABQueryResults = async (body, callback) => {
  const endpoint = constants.BASE_ENDPOINT_WS();
  const url = `${endpoint}/api/dre/moadb/import?method=post&Session-Token=${encodeURIComponent(
    sessionStorage.getItem('Session-Token') ?? '',
  )}`;

  websocketRequest(url, body, callback);
};

export const insertMOADBCustomer = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/moadb/customers`;

  return await axiosInstance().post(url, body);
};

export const insertMOADBQueryRecords = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/moadb/insertOrUpdateRecords`;

  return await axiosInstance().post(url, body);
};

export const markAsPurchased = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/moadb/markAsPurchased`;

  return await axiosInstance().post(url, body);
};

export const queryMOADB = async (body, callback) => {
  const endpoint = constants.BASE_ENDPOINT_WS();
  const url = `${endpoint}/api/dre/moadb/?method=post&Session-Token=${encodeURIComponent(
    sessionStorage.getItem('Session-Token') ?? '',
  )}`;

  websocketRequest(url, body, callback);
};
