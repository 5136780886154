import { ProspectListDropdown } from 'app/components/ProspectListDropdown';
import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';

const ImportModal = ({
  activeList,
  importQueryResults,
  list,
  onHide,
  setActiveList,
  show,
}) => {
  const [error, setError] = useState<any>(null);

  const validate = () => {
    if (!activeList?.Name?.length) setError('Please enter a list name');
    else if (!activeList) setError('Please select a list');
    else {
      setError(null);
      importQueryResults();
      onHide();
    }
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Import Query Results</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProspectListDropdown
          list={list}
          activeList={activeList}
          setActiveList={setActiveList}
        />
        {!!error && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="light" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={validate}>
            Import
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportModal;
