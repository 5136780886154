const sortObjectBySubField = (object: any, subField: any) => {
  const objectKeys = Object.keys(object);

  objectKeys.sort((a, b) => {
    if (object[a][subField] < object[b][subField]) return -1;
    else if (object[a][subField] > object[b][subField]) return 1;
    else return 0;
  });

  return objectKeys
    .map(key => {
      return { [key]: object[key] };
    })
    .reduce((p, c) => {
      return { ...p, ...c };
    }, {});
};

export default sortObjectBySubField;
