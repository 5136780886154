/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { amber, deepOrange, grey } from '@mui/material/colors';
import { PaletteMode } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthState } from 'store/authentication/authentication.selector';
import AuthenticationRouter from 'routers/AuthenticationRouter';
import { restoreSession } from 'api/auth.service';
import { getUsersByCondition, updateUser } from 'api/user.service';

import { HomePage } from './pages/HomePage';
import { useAuthSliceSlice } from 'store/authentication/authentication';
import { useEnvJSONSlice } from 'app/config/envJSONSlice';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminHome from './pages/AdminHome';
import { useCookies } from 'react-cookie';
import moment from 'moment';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });
const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: grey,
          divider: amber[200],
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        }
      : {
          // palette values for dark mode
          primary: deepOrange,
          divider: deepOrange[700],
          background: {
            default: deepOrange[900],
            paper: deepOrange[900],
          },
          text: {
            primary: '#fff',
            secondary: grey[500],
          },
        }),
  },
});

export function App() {
  const [cookies, , removeCookie] = useCookies();
  const authState = useSelector(getAuthState);
  const [envJSONRetrieved, setEnvJSONRetrieved] = useState(false);
  const [handledCookie, setHandledCookie] = useState(
    !cookies?.user?.expiration,
  );
  const [isResetPage] = useState(window.location.pathname.startsWith('/reset'));
  const {
    actions: { googleLogin, makeLogout, usernamePasswordLogin },
  } = useAuthSliceSlice();
  const {
    actions: { loadEnvJSON },
  } = useEnvJSONSlice();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const expiration = parseInt(
    sessionStorage.getItem('expiration') ?? cookies?.user?.expiration,
  );
  const sessionExpired = !expiration || moment(expiration) < moment();
  const sessionToken =
    sessionStorage.getItem('Session-Token') ?? cookies?.user?.sessionToken;

  const [appIp] = useState(
    new URLSearchParams(window.location.search).get('appIp'),
  );

  useEffect(() => {
    fetch('env.json')
      .then(res => res.json())
      .then(data => {
        dispatch(loadEnvJSON(data));
        setEnvJSONRetrieved(true);
      });
  }, [dispatch, loadEnvJSON]);

  useEffect(() => {
    if (!envJSONRetrieved) return;
    // check if a valid session exists & restore it
    const now = Date.now();
    const hasValidExpiration = expiration && Number(expiration) - now > 0;
    if (sessionToken && hasValidExpiration && !authState.isAuthenticated) {
      restoreSession(sessionToken).then(response =>
        dispatch(googleLogin(response)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envJSONRetrieved]);

  useEffect(() => {
    if (!!appIp && !!authState.user?._id)
      updateUser({ appIp }, { _id: authState.user._id });
  }, [appIp, authState.user]);

  useEffect(() => {
    if (!envJSONRetrieved) return;

    if (
      !!cookies?.user?.sessionToken &&
      !sessionStorage.getItem('Session-Token')
    ) {
      if (sessionExpired) {
        removeCookie('user');
        setHandledCookie(true);
        dispatch(makeLogout());
      } else {
        getUsersByCondition(
          { email: cookies.user.email },
          cookies.user.sessionToken,
        ).then(response => {
          if (!!response?.[0]) {
            dispatch(
              usernamePasswordLogin({
                ...response?.[0],
                ...cookies.user,
              } as any),
            );
            setHandledCookie(true);
          }
        });
      }
    } else setHandledCookie(true);
  }, [
    cookies.user,
    dispatch,
    envJSONRetrieved,
    makeLogout,
    removeCookie,
    sessionExpired,
    usernamePasswordLogin,
  ]);

  if (!envJSONRetrieved || !handledCookie) return null;
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Helmet
            titleTemplate="%s - Donor Recommendation Engine"
            defaultTitle="Donor Recommendation Engine"
            htmlAttributes={{ lang: i18n.language }}
          >
            <meta name="description" content="Find donors" />
          </Helmet>
          {((!authState.isAuthenticated && !sessionToken) ||
            !!sessionExpired ||
            isResetPage) && <AuthenticationRouter />}
          {authState.isAuthenticated &&
            !!sessionToken &&
            !sessionExpired &&
            !isResetPage &&
            authState.user?.role !== 'admin' && <HomePage />}
          {authState.isAuthenticated &&
            !!sessionToken &&
            !sessionExpired &&
            !isResetPage &&
            authState.user?.role === 'admin' && <AdminHome />}
          <ToastContainer />

          <GlobalStyle />
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
