import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Paper,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { IOrganization } from 'api/interfaces';
import ReusableTable from 'app/components/ReusableTable';
import { HeadCell } from 'app/components/ReusableTable/types';
import dayjs from 'dayjs';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useOrgPaginationSlice } from 'store/org-pagination/org-pagination';
import { getOrgPaginationState } from 'store/org-pagination/org-pagination.selector';
import { aggregationQueryOrgs } from './config';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { boxModalStyle } from '../UsersPage/config';
import OrganizationFormModal from './OrganizationFormModal';
import AlertDialog from 'app/components/AlertDialog';
import { getUsersByCondition } from 'api/user.service';
import { toast } from 'react-toastify';
import axios from 'axios';
import { removeOrganization } from 'api/organization.service';
dayjs.extend(LocalizedFormat);

const headers: HeadCell[] = [
  {
    id: 'Organization_ID',
    label: 'Id',
    disablePadding: true,
    numeric: true,
    align: 'center',
  },
  {
    id: 'OrganizationName',
    label: 'Name',
    disablePadding: true,
    numeric: true,
    align: 'center',
  },
  {
    id: 'donors_purchased_count',
    label: 'Donors Purchased',
    disablePadding: true,
    numeric: true,
    align: 'center',
  },
  {
    id: 'lastDownloadedDate',
    label: 'Last Downloaded Date',
    disablePadding: true,
    numeric: true,
    align: 'center',
  },
  {
    id: 'action',
    label: '',
    disablePadding: true,
    numeric: true,
    align: 'center',
  },
];
const AdminOrgs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orgPaginationState = useSelector(getOrgPaginationState);
  const { fetchOrgPagination, actions } = useOrgPaginationSlice();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [organization, setOrganization] = useState<IOrganization>();

  const handleClose = () => setOpenModal(false);
  const handleOpen = (organization?: IOrganization) => {
    setOrganization(organization);
    setOpenModal(true);
  };

  const handleOpenDialog = () => setOpenDialog(true);

  const handleConfirmDialog = async () => {
    setIsDeleting(true);
    try {
      const users = await getUsersByCondition({
        organizationId: organization?.Organization_ID,
      });
      if (users.length) {
        toast.error(
          `The organization ${organization?.OrganizationName} has users, therefore it cannot be deleted.`,
        );
        return;
      }
      await removeOrganization(organization?._id!);
      toast.success(
        `The organization ${organization?.OrganizationName} was successfully removed.`,
      );
      paginateOrgs();
    } catch (error: unknown) {
      let message = '';
      if (error instanceof Error) {
        message = error?.message;
      }
      if (axios.isAxiosError(error)) {
        message = error.response?.data.error || error?.response;
      }
      toast.error(message);
    } finally {
      setIsDeleting(false);
      handleCloseDialog();
    }
  };
  const handleCloseDialog = () => setOpenDialog(false);

  const paginateOrgs = () =>
    dispatch(
      fetchOrgPagination(
        aggregationQueryOrgs(
          orgPaginationState.skip,
          orgPaginationState.limit,
          orgPaginationState.sort,
        ),
      ),
    );

  useEffect(() => {
    paginateOrgs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orgPaginationState.limit,
    orgPaginationState.skip,
    orgPaginationState.sort,
  ]);

  const [sortKeys, sortValues] = [
    Object.keys(orgPaginationState.sort),
    Object.values(orgPaginationState.sort),
  ];

  const getOrderBy = sortKeys.length ? sortKeys[0] : '';
  const getOrder = sortValues.length
    ? sortValues[0] === 1
      ? 'asc'
      : 'desc'
    : undefined;

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ borderRadius: '12px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 4,
            padding: 2,
          }}
        >
          <Typography variant="h5">Organizations</Typography>
          <Button
            variant="outlined"
            color="info"
            startIcon={<AddIcon />}
            onClick={() => handleOpen(undefined)}
          >
            New Organization
          </Button>
        </Box>
        <ReusableTable
          isLoading={orgPaginationState.isLoading}
          headers={headers}
          orderBy={getOrderBy}
          order={getOrder}
          rows={orgPaginationState.paginationResult.data}
          handleClick={(event, row) => {
            const organization = row as IOrganization;
            navigate(`/users?organizationId=${organization.Organization_ID}`);
          }}
          handleRequestSort={(_, property: string) => {
            const isAsc =
              orgPaginationState.sort.hasOwnProperty(property) &&
              orgPaginationState.sort[property] === 1;
            dispatch(actions.setSort({ [property]: isAsc ? -1 : 1 }));
          }}
          paginationProps={{
            page: orgPaginationState.pageNumber,
            rowsPerPage: orgPaginationState.limit, //orgPaginationState.paginationResult.filteredPagination,
            setRowsPerPage: (rowsPerPage: number) => {
              dispatch(actions.setLimit(rowsPerPage));
            },
            count: orgPaginationState.paginationResult.filteredPagination,
            handleChangePage: (_, pageNumber: number) => {
              dispatch(actions.setPageNumber(pageNumber));
            },
          }}
          renderRow={(row, headerId) => {
            const organization = row as IOrganization;
            switch (headerId) {
              case 'lastDownloadedDate':
                const lastDownloadedDate = organization[headerId];
                return (
                  <small>
                    {lastDownloadedDate
                      ? dayjs(lastDownloadedDate).format('LLL')
                      : ''}
                  </small>
                );
              case 'action':
                return (
                  <Box sx={{ display: 'flex' }}>
                    <IconButton
                      sx={{
                        fontSize: 34,
                        color: grey[500],
                      }}
                      onClick={(
                        event: MouseEvent<HTMLButtonElement> | undefined,
                      ) => {
                        event?.stopPropagation();
                        handleOpen(organization);
                      }}
                    >
                      <SettingsIcon />
                    </IconButton>
                    <IconButton
                      disabled={isDeleting}
                      color="error"
                      onClick={(
                        event: MouseEvent<HTMLButtonElement> | undefined,
                      ) => {
                        event?.stopPropagation();
                        setOrganization(organization);
                        handleOpenDialog();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                );
              default:
                return organization[headerId];
            }
          }}
        />
      </Paper>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Organization Form
          </Typography>
          <br />
          <IconButton
            sx={{
              position: 'absolute',
              right: 6,
              top: 6,
              fontSize: 34,
              color: grey[500],
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <OrganizationFormModal
            organization={organization}
            handleClose={handleClose}
            afterSave={() => {
              handleClose();
              paginateOrgs();
            }}
          />
        </Box>
      </Modal>

      <AlertDialog
        dialogTitle={'Are you sure to remove the organization?'}
        open={openDialog}
        setOpen={handleOpenDialog}
        handleConfirmDialog={handleConfirmDialog}
        handleCloseDialog={handleCloseDialog}
        dialogContent={
          <Typography variant="body2">
            Please confirm the deletion of the organization{' '}
            <b>{organization?.OrganizationName}</b>
          </Typography>
        }
        okText="Confirm"
        cancelText="Cancel"
      />
    </Container>
  );
};

export default AdminOrgs;
