import React from 'react';
import { Table } from 'react-bootstrap';

export const EventBasedTable = ({
  data,
  maxRows = undefined,
  onColumnFilter,
  onColumnHeader,
  onRowColumn,
}: {
  data: any;
  maxRows?: number;
  onColumnFilter?: any;
  onColumnHeader?: any;
  onRowColumn?: any;
}) => {
  const columns = Object.keys(data?.[0] ?? {}).sort();
  const filteredColumns = columns.filter(column =>
    !!onColumnFilter ? onColumnFilter(column) : true,
  );
  const columnHeaders = filteredColumns.map(column =>
    !!onColumnHeader ? onColumnHeader(column) : column,
  );

  return (
    <Table striped bordered responsive size="sm">
      <thead>
        <tr>
          <th></th>
          {columnHeaders.map(column => (
            <th className="text-nowrap">{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(maxRows ? data.slice(0, maxRows) : data).map((row, index) => (
          <tr>
            <td>{index + 1}</td>
            {filteredColumns.map(column => (
              <td style={{ whiteSpace: 'nowrap' }}>
                {onRowColumn ? onRowColumn(row, column) : row[column]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
