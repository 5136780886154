import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { getAuthUser } from 'store/authentication/authentication.selector';
import {
  getSMTPCredentialsList,
  insertSMTPCredentialsRecords,
} from 'api/smtp.credentials.service';
import { useSelector } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';

const SMTPManager = () => {
  const userLogged = useSelector(getAuthUser);
  const [smtpCredentials, setSMTPCredentials] = React.useState<any[]>([]);
  const [smtpCredentialIndex, setSMTPCredentialIndex] = React.useState(-1);

  const addSMTPCredentials = () => {
    setSMTPCredentials([...(smtpCredentials ?? []), {}]);

    setSMTPCredentialIndex(smtpCredentials?.length ?? 0);
  };

  const getActiveCredentialEmail = () =>
    smtpCredentials?.[smtpCredentialIndex]?.email ?? '';

  const getActiveCredentialPassword = () =>
    smtpCredentials?.[smtpCredentialIndex]?.password ?? '';

  const saveSMTPCredentials = () => {
    insertSMTPCredentialsRecords({
      email: userLogged!.email,
      smtpCredentials,
    }).then(response => {
      setSMTPCredentials(response.data.credentials);
      toast.success('All SMTP credentials saved successfully!');
    });
  };

  const updateActiveCredentialEmail = email => {
    if (!!smtpCredentials[smtpCredentialIndex]) {
      smtpCredentials[smtpCredentialIndex].email = email;
      setSMTPCredentials([...smtpCredentials]);
    }
  };

  const updateActiveCredentialPassword = password => {
    if (!!smtpCredentials[smtpCredentialIndex]) {
      smtpCredentials[smtpCredentialIndex].password = password;
      setSMTPCredentials([...smtpCredentials]);
    }
  };

  React.useEffect(() => {
    getSMTPCredentialsList({
      email: userLogged!.email,
    }).then(response => {
      setSMTPCredentials(response.data.credentials);
    });
  }, [userLogged]);

  return (
    <Container className="mt-3">
      <InputGroup size="sm" className="mb-3">
        <Form.Select
          size="sm"
          value={smtpCredentialIndex}
          onChange={e => setSMTPCredentialIndex(parseInt(e.target.value, 10))}
        >
          <option value="">-- Select credentials --</option>
          {smtpCredentials.map((credential, index) => (
            <option value={index}>{credential.email}</option>
          ))}
        </Form.Select>
        <Button size="sm" onClick={addSMTPCredentials}>
          <MdAdd />
        </Button>
      </InputGroup>

      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Email"
          value={getActiveCredentialEmail()}
          onChange={e => updateActiveCredentialEmail(e.target.value)}
        />
        <Form.Control
          placeholder="Password"
          value={getActiveCredentialPassword()}
          onChange={e => updateActiveCredentialPassword(e.target.value)}
        />
      </InputGroup>

      <Button
        variant="link"
        href="https://support.google.com/mail/answer/185833?hl=en"
        target="_blank"
      >
        Enable App Password on Google Account
      </Button>

      <ButtonGroup className="mt-3 float-end">
        <Button variant="dark" onClick={saveSMTPCredentials}>
          Save
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default SMTPManager;
