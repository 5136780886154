import axios from 'axios';

export const postToApp = async (ip, body) => {
  return await axios
    .create({
      headers: {
        'Content-Type': 'application/json',
        'auth-domain': 'dre',
      },
    })
    .post(ip, body);
};
