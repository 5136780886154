import React, { useEffect, useState } from 'react';
import { getAuthUser } from 'store/authentication/authentication.selector';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ActiveTemplateManager from '../ActivateTemplateManager';
import { CheckSquare, Square } from 'react-bootstrap-icons';
import {
  getOneOrganization,
  updateOrganization,
} from 'api/organization.service';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const OptionsModal = ({
  canAccessMOADBQuery,
  onHide,
  options,
  retrieveTemplatesAndSMTP,
  saveOptions,
  setOptions,
  setShowResetSettingsModal,
  setSMTPFormEnabled,
  setViewMode,
  show,
  smtpCredentials,
  smtpFormEnabled,
  templates,
  viewMode,
}) => {
  const userLogged = useSelector(getAuthUser);
  const [petitionAccountToken, setPetitionAccountToken] = useState<any>();

  const updateSMTPFormEnabled = newSMTPFormEnabled => {
    setSMTPFormEnabled(newSMTPFormEnabled);
    saveOptions(null, null, newSMTPFormEnabled);
  };

  const updatePetitionTokenID = async () => {
    await updateOrganization(
      { petitionAccountToken },
      { Organization_ID: userLogged?.organizationId },
    );
    toast.success('Peition Account Token Saved!');
  };

  useEffect(() => {
    if (userLogged?.organizationId)
      getOneOrganization({ Organization_ID: userLogged?.organizationId }).then(
        response => setPetitionAccountToken(response.petitionAccountToken),
      );
  }, [userLogged?.organizationId]);

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="general">
          <Tab eventKey="general" title="General">
            <ButtonGroup className="my-3">
              <Button
                variant={viewMode === 'desktop' ? 'primary' : 'light'}
                onClick={() => {
                  setViewMode('desktop');
                  if (viewMode !== 'desktop') onHide();
                }}
              >
                Plan View
              </Button>
              <Button
                variant={viewMode === 'mobile' ? 'primary' : 'light'}
                onClick={() => {
                  setViewMode('mobile');
                  if (viewMode !== 'mobile') onHide();
                }}
              >
                Contact View
              </Button>
            </ButtonGroup>
            <ActiveTemplateManager
              canAccessMOADBQuery={canAccessMOADBQuery}
              onHide={onHide}
              options={options}
              retrieveTemplatesAndSMTP={retrieveTemplatesAndSMTP}
              saveOptions={saveOptions}
              setOptions={setOptions}
              smtpCredentials={smtpCredentials}
              templates={templates}
            />
          </Tab>
          {canAccessMOADBQuery && (
            <Tab eventKey="advanced" title="Advanced">
              <Row className="mt-3">
                <Col>
                  <Button
                    className="w-100"
                    variant="outline-danger"
                    onClick={() => setShowResetSettingsModal(true)}
                  >
                    Reset Settings
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="w-100"
                    variant={
                      smtpFormEnabled ? 'outline-primary' : 'outline-secondary'
                    }
                    onClick={() => updateSMTPFormEnabled(!smtpFormEnabled)}
                  >
                    {smtpFormEnabled ? <CheckSquare /> : <Square />} SMTP Form{' '}
                    {smtpFormEnabled ? 'Enabled' : 'Disabled'}
                  </Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <InputGroup>
                    <FloatingLabel label="Petition Account Token">
                      <Form.Control
                        size="sm"
                        value={petitionAccountToken}
                        onChange={e => setPetitionAccountToken(e.target.value)}
                      />
                    </FloatingLabel>
                    <Button onClick={updatePetitionTokenID}>Save</Button>
                  </InputGroup>
                </Col>
              </Row>
            </Tab>
          )}
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default OptionsModal;
