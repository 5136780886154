import { Button } from '@mui/material';
import { Card, Grid } from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import { resetPassword } from 'api/auth.service';
import FormTextField from 'app/components/Form/FormTextField';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';

interface FormData {
  password: string;
  repeatPassword: string;
}
const initialValues: FormData = {
  password: '',
  repeatPassword: '',
};
const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const navigate = useNavigate();

  const onSubmit = async ({ password }: FormData) => {
    if (email) {
      resetPassword(email, password)
        .then(response => {
          if (response) window.location.href = '/login';
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  };

  async function validate(values: FormData) {
    const { password, repeatPassword } = values;

    if (!password) {
      return { password: 'Password is required.' };
    }
    if (!repeatPassword) {
      return { repeatPassword: 'Repeat Password is required.' };
    }
    if (password !== repeatPassword) {
      return { repeatPassword: 'Repeat Password does not match.' };
    }
    return;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        alignItems: 'center',
        background: '#0a3042',
        backgroundImage: 'radial-gradient(circle at 0%, #0a3042 62%, #155d81)',
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <Container maxWidth="sm">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', height: '85vh' }}
          >
            <Card
              variant="elevation"
              sx={{ width: '100%' }}
              className="form-container"
            >
              <h2>Reset your password</h2>
              <Form
                initialValues={initialValues}
                validate={validate}
                onSubmit={onSubmit}
                render={({ handleSubmit, form, submitting, pristine }) => (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    style={{ width: '100%' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'baseline',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                      }}
                    >
                      <Field name="password" type="password">
                        {props => (
                          <FormTextField {...props} placeholder="Password" />
                        )}
                      </Field>
                      <Field name="repeatPassword" type="password">
                        {props => (
                          <FormTextField
                            {...props}
                            placeholder="Repeat Password"
                          />
                        )}
                      </Field>
                    </Box>

                    <Grid container justifyContent="flex-end" mt={'0.6rem'}>
                      <Button
                        sx={{ mx: 1, textTransform: 'none' }}
                        type="submit"
                        variant="contained"
                        disabled={submitting}
                        className="form-button"
                      >
                        Reset
                      </Button>
                    </Grid>
                  </form>
                )}
              />
            </Card>
          </Stack>
        </Container>
      </motion.div>
    </Box>
  );
};

export default ResetPasswordPage;
