import React, { useEffect, useState } from 'react';
import { getMOADBCustomers, insertMOADBCustomer } from 'api/moadb.service';
import { Select } from '../Select';
import {
  Alert,
  Button,
  ButtonGroup,
  FloatingLabel,
  Form,
  Modal,
} from 'react-bootstrap';
import { MOADBDropdownStates } from '../MOADBDropdownStates';
import { toast } from 'react-toastify';
import { getOneOrganization } from 'api/organization.service';
import LoadingOverlay from '../LoadingOverlay';

export const MOADBDropdownCustomers = ({ className = '', value, onChange }) => {
  const [customers, setCustomers] = useState<any[]>([]);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newCustomerDistrict, setNewCustomerDistrict] = useState<any>(null);
  const [newCustomerElectionType, setNewCustomerElectionType] =
    useState<any>(null);
  const [newCustomerName, setNewCustomerName] = useState<any>(null);
  const [newCustomerState, setNewCustomerState] = useState<any>(null);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const addNewCustomer = () => {
    if (!newCustomerName) setError('Name required');
    else if (!newCustomerElectionType) setError('Election type required');
    else if (!newCustomerState) setError('State required');
    else if (!newCustomerDistrict) setError('District required');
    else {
      setError(null);
      setIsLoading(true);

      getOneOrganization({ OrganizationName: newCustomerName }).then(
        response => {
          if (!response?._id) {
            insertMOADBCustomer({
              district: newCustomerDistrict,
              election_type: newCustomerElectionType,
              customer_name: newCustomerName,
              state_abbr: newCustomerState,
            }).then((response: any) => {
              setIsLoading(false);

              if (!!response?.data?.customer_id) {
                setNewCustomerDistrict(null);
                setNewCustomerElectionType(null);
                setNewCustomerName(null);
                setNewCustomerState(null);
                setShowAddModal(false);
                retrieveCustomers();
                onChange({ target: { value: response.data.customer_id } });
                toast.info(`"${newCustomerName}" saved successfully`);
              }
            });
          } else {
            setIsLoading(false);
            toast.error(`"${newCustomerName}" already exists in DRE`);
          }
        },
      );
    }
  };

  const retrieveCustomers = () => {
    getMOADBCustomers().then(response => setCustomers(response.data));
  };

  useEffect(() => retrieveCustomers(), []);

  return (
    <>
      <LoadingOverlay show={isLoading} />

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel className="mb-3" label="Name">
            <Form.Control
              value={newCustomerName}
              onChange={e => setNewCustomerName(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel className="mb-3" label="Election Type">
            <Select
              placeholder="Select Election Type"
              value={newCustomerElectionType}
              onChange={e => setNewCustomerElectionType(e.target.value)}
            >
              <option value="Attorney General">Attorney General</option>
              <option value="Other">Other</option>
              <option value="Presidential">Presidential</option>
              <option value="Non-Profit">Non-Profit</option>
              <option value="State House">State House</option>
              <option value="US Congress">US Congress</option>
              <option value="US Senate">US Senate</option>
            </Select>
          </FloatingLabel>
          <FloatingLabel className="mb-3" label="State">
            <MOADBDropdownStates
              multiSelect={false}
              value={newCustomerState}
              onChange={e => setNewCustomerState(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel label="District">
            <Form.Control
              value={newCustomerDistrict}
              onChange={e => setNewCustomerDistrict(e.target.value)}
            />
          </FloatingLabel>
          {!!error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup className="float-end">
            <Button variant="secondary" onClick={() => setShowAddModal(false)}>
              Cancel
            </Button>
            <Button onClick={addNewCustomer}>Save</Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>

      <Select
        addLabel="Add New Customer"
        className={className}
        value={value ?? ''}
        onAdd={() => setShowAddModal(true)}
        onChange={onChange}
        placeholder="Select a Customer"
      >
        {(customers ?? []).map(customer => (
          <option key={customer.customer_id} value={customer.customer_id}>
            {customer.customer_name} ({customer.state_abbr})
          </option>
        ))}
      </Select>
    </>
  );
};
