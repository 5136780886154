import React, { useEffect, useRef, useState } from 'react';
import { getAuthUser } from 'store/authentication/authentication.selector';
import {
  getScraperScriptList,
  insertScraperScriptRecords,
} from 'api/scraper.script.service';
import { useSelector } from 'react-redux';
import {
  Button,
  ButtonGroup,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  ListGroup,
} from 'react-bootstrap';
import { Pencil, Play, PlusCircle } from 'react-bootstrap-icons';
import Editor from '@monaco-editor/react';
import { v4 as uuidv4 } from 'uuid';
import LoadingOverlay from '../LoadingOverlay';
import GeneralModal from '../GeneralModal/GeneralModal';

const ScraperScript = () => {
  const userLogged = useSelector(getAuthUser);
  const [activeScript, setActiveScript] = useState(0);
  const [edit, setEdit] = useState(false);
  const [editorScript, setEditorScript] = useState<any>(null);
  const editorRef = useRef<any>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(false);
  const loadingRequestId = useRef<any>(null);
  const [loadingStatus, setLoadingStatus] = useState<any>(null);
  const [newScript, setNewScript] = useState(false);
  const [scripts, setScripts] = useState<any>(null);
  const [showExtensionModal, setShowExtensionModal] = useState(false);

  const activateEditor = isNew => {
    if (isNew)
      setEditorScript({
        Name: null,
        Description: null,
        Script: null,
        Tags: null,
        orgId: userLogged?.organizationId,
        Vars: null,
      });
    else
      setEditorScript({
        ...scripts[activeScript],
        Vars: JSON.stringify(scripts[activeScript].Vars),
      });

    setNewScript(isNew);
    setEdit(true);
  };

  const executeScript = async (script = null, vars = null) => {
    // @ts-ignore:next-line
    if (typeof dreRun === 'undefined') setShowExtensionModal(true);
    else {
      try {
        loadingRequestId.current = uuidv4();
        setLoading(true);
        loadingRef.current = true;

        window['_DRE'].statusCallback = status =>
          setLoadingStatus(
            `${status?.state ?? ''} ${
              !status?.percentage
                ? ''
                : `${parseFloat(status?.percentage).toFixed(2)}%`
            }`,
          );

        // @ts-ignore:next-line
        const result = await dreRun(
          script ?? scripts[activeScript].Script,
          vars ?? scripts[activeScript].Vars,
        );
        if (!!result?.[0]) generateCSV(result);
        else if (!!result?.error) setError(result.error);
        setLoading(false);
        loadingRef.current = false;
      } catch (e: any) {
        setError(e?.message);
        setLoading(false);
        loadingRef.current = false;
      }
    }
  };

  const generateCSV = data => {
    const first = data?.[0] ?? {};

    const lines = [
      `"${Object.keys(first).join('","')}"\r\n`,
      ...data.map(
        row =>
          `"${Object.keys(first)
            .map(key => row[key])
            .join('","')}"\r\n`,
      ),
    ];

    const blob = new Blob(lines, { type: 'text/csv;charset=utf-8' });
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL, '_blank');
  };

  const updateActiveScript = () => {
    editorRef.current
      .getAction('editor.action.formatDocument')
      .run()
      .then(() => {
        let newVars = JSON.parse(editorScript.Vars);

        if (newScript) {
          scripts.push({
            ...editorScript,
            orgId: userLogged?.organizationId,
            Script: editorRef.current.getValue(),
            Vars: newVars,
          });
        } else {
          scripts[activeScript] = {
            ...editorScript,
            orgId: userLogged?.organizationId,
            Script: editorRef.current.getValue(),
            Vars: newVars,
          };
        }

        insertScraperScriptRecords({
          email: userLogged!.email,
          scripts,
        }).then(() => {
          setScripts([...scripts]);
          setEdit(false);
        });
      });
  };

  useEffect(() => {
    getScraperScriptList({
      email: userLogged!.email,
    }).then(response => {
      setScripts(response.data.scripts);
      setActiveScript(0);
    });
  }, [userLogged]);

  if (!scripts) return null;
  else
    return (
      <>
        <GeneralModal
          isError
          title={'Error'}
          message={error}
          show={!!error}
          onHide={() => setError(null)}
        />

        <GeneralModal
          title={'DRE Chrome Extension'}
          message={
            <ListGroup as="ol" variant="flush" numbered>
              <ListGroup.Item as="li">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://drive.google.com/file/d/1mj0tCIEb7evU4fe_AkmdNr5CrLqv0nVh/view?usp=drive_link"
                >
                  Download & unzip the DRE Chrome Extension Zip
                </a>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Open extension manager in Chrome
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Ensure "Developer Mode" is enabled
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Click "Load unpacked" within the Chrome extension manager
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Select the chome-extension folder resulting from step #1
              </ListGroup.Item>
              <ListGroup.Item as="li">Refresh this page</ListGroup.Item>
            </ListGroup>
          }
          show={showExtensionModal}
          onHide={() => setShowExtensionModal(false)}
        />

        <LoadingOverlay show={loading} status={loadingStatus} />

        {!edit ? (
          <Container className="mt-3">
            <div className="mb-3">
              <InputGroup>
                <Form.Select
                  value={activeScript}
                  onChange={e => setActiveScript(parseInt(e.target.value, 10))}
                >
                  {scripts.map((script, index) => (
                    <option
                      value={index}
                    >{`${script.Name}: ${script.Description}`}</option>
                  ))}
                </Form.Select>
                <Button variant="dark" onClick={() => activateEditor(true)}>
                  <PlusCircle />
                </Button>
                <Button variant="dark" onClick={() => activateEditor(false)}>
                  <Pencil />
                </Button>
              </InputGroup>
            </div>

            <div className="mb-3">
              {Object.keys(scripts[activeScript]?.Vars ?? []).map(key => {
                const scriptVar = scripts[activeScript].Vars[key];

                return (
                  <FloatingLabel label={key}>
                    <Form.Control
                      className="mb-3"
                      value={scriptVar}
                      onChange={e => {
                        scripts[activeScript].Vars[key] = e.target.value;
                        setScripts(scripts.map(script => ({ ...script })));
                      }}
                    ></Form.Control>
                  </FloatingLabel>
                );
              })}
            </div>

            <div className="mb-3 text-end">
              <Button onClick={() => executeScript()}>
                <Play />
              </Button>
            </div>
          </Container>
        ) : (
          <Container className="mt-3">
            <div className="mb-3">
              <FloatingLabel label="Name">
                <Form.Control
                  value={editorScript.Name ?? ''}
                  onChange={e =>
                    setEditorScript({ ...editorScript, Name: e.target.value })
                  }
                />
              </FloatingLabel>
            </div>

            <div className="mb-3">
              <FloatingLabel label="Description">
                <Form.Control
                  value={editorScript.Description ?? ''}
                  onChange={e =>
                    setEditorScript({
                      ...editorScript,
                      Description: e.target.value,
                    })
                  }
                />
              </FloatingLabel>
            </div>

            <div className="mb-3">
              <FloatingLabel label="Tag">
                <Form.Control
                  value={editorScript.Tag ?? ''}
                  onChange={e =>
                    setEditorScript({
                      ...editorScript,
                      Tag: e.target.value,
                    })
                  }
                />
              </FloatingLabel>
            </div>

            <div className="mb-3">
              <FloatingLabel label="Variables">
                <Form.Control
                  value={editorScript.Vars ?? ''}
                  onChange={e =>
                    setEditorScript({ ...editorScript, Vars: e.target.value })
                  }
                />
              </FloatingLabel>
            </div>

            <Editor
              className="mb-3"
              height="90vh"
              defaultValue={editorScript.Script ?? ''}
              defaultLanguage="javascript"
              theme="vs-dark"
              onMount={editor => (editorRef.current = editor)}
              onChange={v => (editorScript.Script = v)}
            />

            <ButtonGroup className="mb-3 float-end">
              <Button variant="secondary" onClick={() => setEdit(false)}>
                Cancel
              </Button>
              <Button onClick={updateActiveScript}>Save</Button>
              <Button
                onClick={() =>
                  executeScript(
                    editorScript.Script,
                    JSON.parse(editorScript.Vars),
                  )
                }
              >
                <Play />
              </Button>
            </ButtonGroup>
          </Container>
        )}
      </>
    );
};

export default ScraperScript;
