import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const RenameSegmentModal = ({ onHide, onSave, segment, show }) => {
  const [error, setError] = useState<any>(null);
  const [name, setName] = useState<any>(null);

  const validate = () => {
    if (!name) setError('Please enter a name');
    else {
      setError(null);
      setName(null);
      onSave(name);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Rename Segment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FloatingLabel label="Name">
          <Form.Control
            value={name ?? ''}
            onChange={e => setName(e.target.value)}
          />
        </FloatingLabel>
        {!!error && (
          <Alert className="mt-3" variant="danger">
            {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="light" onClick={onHide}>
            Cancel
          </Button>
          <Button onClick={validate}>Rename</Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default RenameSegmentModal;
