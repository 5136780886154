import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/esm/Row';
import viewCustomFieldContentHandler from '../viewCustomFieldContentHandler';

const ContactViewModal = ({
  cellTemplateList,
  columnOptionList,
  donors,
  editableFields,
  idField,
  onHide,
  indexList,
  row,
  setDonors,
  show,
  templates,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            {columnOptionList.map(columnOptions => {
              const cellTemplate = cellTemplateList.filter(
                a => a.field === columnOptions.field,
              )?.[0];

              return (
                columnOptions?.isVisible &&
                cellTemplate?.showInDesktop &&
                !cellTemplate?.hideInMobile &&
                (!editableFields ||
                  editableFields.includes(cellTemplate.field)) && (
                  <Col
                    key={`modal-${row?.[idField]}-${cellTemplate.field}`}
                    className="my-2"
                    xs={6}
                    style={{ overflow: 'hidden' }}
                  >
                    <Form.Group>
                      <Form.Label style={{ whiteSpace: 'nowrap' }}>
                        {cellTemplate.header}
                      </Form.Label>
                      <div>
                        {!!cellTemplate?.custom
                          ? viewCustomFieldContentHandler(
                              row,
                              cellTemplate,
                              { ...columnOptions, width: '100%' },
                              indexList,
                              donors,
                              setDonors,
                            )
                          : cellTemplate.contentHandler(
                              row,
                              templates,
                              cellTemplate,
                              { ...columnOptions, width: '100%' },
                              indexList,
                              donors,
                              setDonors,
                            )}
                      </div>
                    </Form.Group>
                  </Col>
                )
              );
            })}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactViewModal;
