import React from 'react';
import { Card } from 'react-bootstrap';
import { Circle, InfoCircle } from 'react-bootstrap-icons';

const GuideSection = ({
  bgColor = 'white',
  children,
  className = '',
  fgColor = 'black',
  Icon = InfoCircle,
  showIcon = false,
  title = '',
}) => {
  return (
    <Card className={`${className} h-100`}>
      <Card.Body className={`position-relative`}>
        {!!title && <Card.Title className="text-center">{title}</Card.Title>}

        {showIcon && (
          <Circle
            className={`position-absolute bg-${bgColor}`}
            style={{
              borderRadius: '18px',
              left: '-18px',
              height: '36px',
              top: '-18px',
              width: '36px',
            }}
          />
        )}

        {showIcon && (
          <Icon
            className={`position-absolute text-${fgColor}`}
            style={{
              left: '-16px',
              height: '32px',
              top: '-16px',
              width: '32px',
            }}
          />
        )}
        {children}
      </Card.Body>
    </Card>
  );
};

export default GuideSection;
