import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/authentication/authentication.selector';
import moment from 'moment';
import { Card, Container, ProgressBar } from 'react-bootstrap';
import { getTasksCompletedByUser } from 'api/process.ingestion.service';

const ProcessMonitor = () => {
  const userLogged = useSelector(getAuthUser);
  const [taskCompleted, setTaskCompleted] = useState<any>({});
  const retrieveIntervalRef = useRef<any>([]);

  const singleStepPercentage = 14.3;

  const percentage = {
    INITIALIZED: singleStepPercentage * 0,
    MATERIALIZED: singleStepPercentage * 1,
    SENDING: singleStepPercentage * 2,
    IMPORTED: singleStepPercentage * 3,
    MATCHED: singleStepPercentage * 4,
    RETRIEVED: singleStepPercentage * 5,
    INGESTED: singleStepPercentage * 6,
    COMPLETE: 100,
    ZERO: 100,
  };

  const text = {
    INITIALIZED:
      'Prospects have been imported but not yet materialized. So, are not yet available in the tracker.',
    MATERIALIZED:
      'Prospects (not including MOADB matches) should be available within the tracker.',
    SENDING: 'Prospects are being sent to MOADB for matching.',
    IMPORTED: 'Prospects have been imported by MOADB.',
    MATCHED: 'MOADB has attempted to match prospects.',
    RETRIEVED: 'MOADB matches have been retrieved but not yet imported.',
    INGESTED: 'MOADB matches have been imported but not yet materialized.',
    COMPLETE:
      'MOADB matches have been materialized and should be available with the tracker.',
    ZERO: 'ZERO MOADB matches.',
  };

  const retrieveTasks = useCallback(() => {
    getTasksCompletedByUser(userLogged?.email).then(response => {
      const newTasks = {};

      response.data.orgTasksCompleted.forEach(task => {
        if (task.batchId in newTasks) newTasks[task.batchId].push(task);
        else newTasks[task.batchId] = [task];
      });

      setTaskCompleted(newTasks);
    });
  }, [userLogged?.email]);

  useEffect(() => {
    retrieveTasks();
  }, [retrieveTasks]);

  useEffect(() => {
    retrieveIntervalRef.current = setInterval(retrieveTasks, 10000);
    return () => clearInterval(retrieveIntervalRef.current);
  });

  return (
    <Container>
      {Object.keys(taskCompleted).map(key => {
        const batch = taskCompleted[key];
        const task = batch[batch?.length - 1];

        return (
          <Card className="mt-3">
            <Card.Header>
              Batch created {moment(task.createdAt).fromNow()}
            </Card.Header>
            <Card.Body>
              <Card.Title>{task.task.replace(/_/g, ' ')}</Card.Title>
              <Card.Text>{text[task.task]}</Card.Text>
              <ProgressBar
                now={percentage[task.task]}
                variant={
                  percentage[task.task] < 25
                    ? 'warning'
                    : percentage[task.task] < 75
                    ? 'info'
                    : 'primary'
                }
              />
            </Card.Body>
          </Card>
        );
      })}
    </Container>
  );
};

export default ProcessMonitor;
