const content = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
    <style type="text/css">
      @import url(https://themes.googleusercontent.com/fonts/css?kit=dpiI8CyVsrzWsJLBFKehGpLhv3qFjX7dUn1mYxfCXhI);
      ol.lst-kix_268r56udnzju-3 {
        list-style-type: none;
      }
      ol.lst-kix_268r56udnzju-2 {
        list-style-type: none;
      }
      ol.lst-kix_268r56udnzju-5 {
        list-style-type: none;
      }
      ol.lst-kix_268r56udnzju-4 {
        list-style-type: none;
      }
      ol.lst-kix_268r56udnzju-7 {
        list-style-type: none;
      }
      .lst-kix_gpdybvh95fpz-3 > li {
        counter-increment: lst-ctn-kix_gpdybvh95fpz-3;
      }
      ol.lst-kix_268r56udnzju-6 {
        list-style-type: none;
      }
      ol.lst-kix_268r56udnzju-8 {
        list-style-type: none;
      }
      .lst-kix_hsxyf72adno-8 > li:before {
        content: '' counter(lst-ctn-kix_hsxyf72adno-8, lower-roman) '. ';
      }
      .lst-kix_kdfsnef6zzf6-0 > li {
        counter-increment: lst-ctn-kix_kdfsnef6zzf6-0;
      }
      .lst-kix_iuizipucx4mz-7 > li {
        counter-increment: lst-ctn-kix_iuizipucx4mz-7;
      }
      ol.lst-kix_mi687oiexhpn-8.start {
        counter-reset: lst-ctn-kix_mi687oiexhpn-8 0;
      }
      ol.lst-kix_44m267aygyei-8.start {
        counter-reset: lst-ctn-kix_44m267aygyei-8 0;
      }
      ol.lst-kix_iuizipucx4mz-3.start {
        counter-reset: lst-ctn-kix_iuizipucx4mz-3 0;
      }
      .lst-kix_mnerpilip97z-5 > li {
        counter-increment: lst-ctn-kix_mnerpilip97z-5;
      }
      .lst-kix_268r56udnzju-6 > li {
        counter-increment: lst-ctn-kix_268r56udnzju-6;
      }
      ol.lst-kix_nr4q6mtpzzce-2.start {
        counter-reset: lst-ctn-kix_nr4q6mtpzzce-2 0;
      }
      ol.lst-kix_2mz6pb1umrg-0.start {
        counter-reset: lst-ctn-kix_2mz6pb1umrg-0 0;
      }
      .lst-kix_6z9uvcn6pwsj-3 > li {
        counter-increment: lst-ctn-kix_6z9uvcn6pwsj-3;
      }
      ol.lst-kix_268r56udnzju-1 {
        list-style-type: none;
      }
      ol.lst-kix_268r56udnzju-0 {
        list-style-type: none;
      }
      .lst-kix_nr4q6mtpzzce-3 > li:before {
        content: '' counter(lst-ctn-kix_nr4q6mtpzzce-3, decimal) '. ';
      }
      .lst-kix_xukvprgjom46-7 > li {
        counter-increment: lst-ctn-kix_xukvprgjom46-7;
      }
      .lst-kix_sbf0cuma88ql-3 > li {
        counter-increment: lst-ctn-kix_sbf0cuma88ql-3;
      }
      .lst-kix_nr4q6mtpzzce-1 > li:before {
        content: '' counter(lst-ctn-kix_nr4q6mtpzzce-1, decimal) '. ';
      }
      .lst-kix_nr4q6mtpzzce-5 > li:before {
        content: '' counter(lst-ctn-kix_nr4q6mtpzzce-5, lower-roman) '. ';
      }
      .lst-kix_hsxyf72adno-7 > li {
        counter-increment: lst-ctn-kix_hsxyf72adno-7;
      }
      .lst-kix_nr4q6mtpzzce-0 > li:before {
        content: '' counter(lst-ctn-kix_nr4q6mtpzzce-0, decimal) '. ';
      }
      .lst-kix_nr4q6mtpzzce-4 > li:before {
        content: '' counter(lst-ctn-kix_nr4q6mtpzzce-4, lower-latin) '. ';
      }
      .lst-kix_nr4q6mtpzzce-2 > li:before {
        content: '' counter(lst-ctn-kix_nr4q6mtpzzce-2, lower-roman) '. ';
      }
      ol.lst-kix_w6v1rx36eaud-7 {
        list-style-type: none;
      }
      ol.lst-kix_w6v1rx36eaud-8 {
        list-style-type: none;
      }
      ol.lst-kix_w6v1rx36eaud-5 {
        list-style-type: none;
      }
      ol.lst-kix_w6v1rx36eaud-6 {
        list-style-type: none;
      }
      ol.lst-kix_w6v1rx36eaud-3 {
        list-style-type: none;
      }
      ol.lst-kix_w6v1rx36eaud-4 {
        list-style-type: none;
      }
      .lst-kix_nr4q6mtpzzce-8 > li:before {
        content: '' counter(lst-ctn-kix_nr4q6mtpzzce-8, lower-roman) '. ';
      }
      ol.lst-kix_w6v1rx36eaud-1 {
        list-style-type: none;
      }
      ol.lst-kix_w6v1rx36eaud-2 {
        list-style-type: none;
      }
      .lst-kix_nr4q6mtpzzce-7 > li:before {
        content: '' counter(lst-ctn-kix_nr4q6mtpzzce-7, lower-latin) '. ';
      }
      .lst-kix_xukvprgjom46-0 > li {
        counter-increment: lst-ctn-kix_xukvprgjom46-0;
      }
      ol.lst-kix_w6v1rx36eaud-0 {
        list-style-type: none;
      }
      .lst-kix_z8mruz1jap1n-3 > li {
        counter-increment: lst-ctn-kix_z8mruz1jap1n-3;
      }
      .lst-kix_nr4q6mtpzzce-6 > li:before {
        content: '' counter(lst-ctn-kix_nr4q6mtpzzce-6, decimal) '. ';
      }
      ol.lst-kix_mi687oiexhpn-3.start {
        counter-reset: lst-ctn-kix_mi687oiexhpn-3 0;
      }
      .lst-kix_mi687oiexhpn-8 > li {
        counter-increment: lst-ctn-kix_mi687oiexhpn-8;
      }
      ol.lst-kix_nr4q6mtpzzce-7.start {
        counter-reset: lst-ctn-kix_nr4q6mtpzzce-7 0;
      }
      ol.lst-kix_sbf0cuma88ql-6.start {
        counter-reset: lst-ctn-kix_sbf0cuma88ql-6 0;
      }
      .lst-kix_noz0fld27yvx-0 > li {
        counter-increment: lst-ctn-kix_noz0fld27yvx-0;
      }
      .lst-kix_suvp661h8f4e-7 > li {
        counter-increment: lst-ctn-kix_suvp661h8f4e-7;
      }
      .lst-kix_l1ljta7yxsg8-1 > li {
        counter-increment: lst-ctn-kix_l1ljta7yxsg8-1;
      }
      ol.lst-kix_kdfsnef6zzf6-8.start {
        counter-reset: lst-ctn-kix_kdfsnef6zzf6-8 0;
      }
      ol.lst-kix_noz0fld27yvx-5.start {
        counter-reset: lst-ctn-kix_noz0fld27yvx-5 0;
      }
      ol.lst-kix_r29j7u2ve7fh-3.start {
        counter-reset: lst-ctn-kix_r29j7u2ve7fh-3 0;
      }
      ol.lst-kix_gpdybvh95fpz-5.start {
        counter-reset: lst-ctn-kix_gpdybvh95fpz-5 0;
      }
      ol.lst-kix_268r56udnzju-3.start {
        counter-reset: lst-ctn-kix_268r56udnzju-3 0;
      }
      .lst-kix_l1ljta7yxsg8-2 > li {
        counter-increment: lst-ctn-kix_l1ljta7yxsg8-2;
      }
      .lst-kix_suvp661h8f4e-0 > li {
        counter-increment: lst-ctn-kix_suvp661h8f4e-0;
      }
      .lst-kix_mi687oiexhpn-1 > li {
        counter-increment: lst-ctn-kix_mi687oiexhpn-1;
      }
      ol.lst-kix_z8mruz1jap1n-7.start {
        counter-reset: lst-ctn-kix_z8mruz1jap1n-7 0;
      }
      ol.lst-kix_k7ak3da8nwm1-5.start {
        counter-reset: lst-ctn-kix_k7ak3da8nwm1-5 0;
      }
      ol.lst-kix_xukvprgjom46-4.start {
        counter-reset: lst-ctn-kix_xukvprgjom46-4 0;
      }
      .lst-kix_nr4q6mtpzzce-1 > li {
        counter-increment: lst-ctn-kix_nr4q6mtpzzce-1;
      }
      .lst-kix_mi687oiexhpn-0 > li {
        counter-increment: lst-ctn-kix_mi687oiexhpn-0;
      }
      .lst-kix_k7ak3da8nwm1-3 > li {
        counter-increment: lst-ctn-kix_k7ak3da8nwm1-3;
      }
      ol.lst-kix_hr7lu2216wfv-2.start {
        counter-reset: lst-ctn-kix_hr7lu2216wfv-2 0;
      }
      .lst-kix_r29j7u2ve7fh-5 > li {
        counter-increment: lst-ctn-kix_r29j7u2ve7fh-5;
      }
      ol.lst-kix_hsxyf72adno-4.start {
        counter-reset: lst-ctn-kix_hsxyf72adno-4 0;
      }
      ol.lst-kix_rkq69uujdjcq-4.start {
        counter-reset: lst-ctn-kix_rkq69uujdjcq-4 0;
      }
      .lst-kix_r29j7u2ve7fh-4 > li {
        counter-increment: lst-ctn-kix_r29j7u2ve7fh-4;
      }
      .lst-kix_r29j7u2ve7fh-8 > li:before {
        content: '' counter(lst-ctn-kix_r29j7u2ve7fh-8, lower-roman) '. ';
      }
      .lst-kix_gpdybvh95fpz-6 > li:before {
        content: '' counter(lst-ctn-kix_gpdybvh95fpz-6, decimal) '. ';
      }
      .lst-kix_z8mruz1jap1n-4 > li:before {
        content: '' counter(lst-ctn-kix_z8mruz1jap1n-4, lower-latin) '. ';
      }
      .lst-kix_z8mruz1jap1n-6 > li:before {
        content: '' counter(lst-ctn-kix_z8mruz1jap1n-6, decimal) '. ';
      }
      .lst-kix_nr4q6mtpzzce-2 > li {
        counter-increment: lst-ctn-kix_nr4q6mtpzzce-2;
      }
      .lst-kix_z8mruz1jap1n-8 > li:before {
        content: '' counter(lst-ctn-kix_z8mruz1jap1n-8, lower-roman) '. ';
      }
      .lst-kix_hsxyf72adno-1 > li:before {
        content: '' counter(lst-ctn-kix_hsxyf72adno-1, lower-latin) '. ';
      }
      .lst-kix_gpdybvh95fpz-0 > li:before {
        content: '' counter(lst-ctn-kix_gpdybvh95fpz-0, decimal) '. ';
      }
      .lst-kix_gpdybvh95fpz-8 > li:before {
        content: '' counter(lst-ctn-kix_gpdybvh95fpz-8, lower-roman) '. ';
      }
      ol.lst-kix_l1ljta7yxsg8-4.start {
        counter-reset: lst-ctn-kix_l1ljta7yxsg8-4 0;
      }
      .lst-kix_hsxyf72adno-0 > li {
        counter-increment: lst-ctn-kix_hsxyf72adno-0;
      }
      ol.lst-kix_iuizipucx4mz-8.start {
        counter-reset: lst-ctn-kix_iuizipucx4mz-8 0;
      }
      .lst-kix_hsxyf72adno-5 > li:before {
        content: '' counter(lst-ctn-kix_hsxyf72adno-5, lower-roman) '. ';
      }
      .lst-kix_hsxyf72adno-3 > li:before {
        content: '' counter(lst-ctn-kix_hsxyf72adno-3, decimal) '. ';
      }
      .lst-kix_hsxyf72adno-7 > li:before {
        content: '' counter(lst-ctn-kix_hsxyf72adno-7, lower-latin) '. ';
      }
      ol.lst-kix_w6v1rx36eaud-8.start {
        counter-reset: lst-ctn-kix_w6v1rx36eaud-8 0;
      }
      .lst-kix_2mz6pb1umrg-5 > li {
        counter-increment: lst-ctn-kix_2mz6pb1umrg-5;
      }
      .lst-kix_gpdybvh95fpz-2 > li:before {
        content: '' counter(lst-ctn-kix_gpdybvh95fpz-2, lower-roman) '. ';
      }
      ol.lst-kix_2mz6pb1umrg-5.start {
        counter-reset: lst-ctn-kix_2mz6pb1umrg-5 0;
      }
      .lst-kix_gpdybvh95fpz-4 > li:before {
        content: '' counter(lst-ctn-kix_gpdybvh95fpz-4, lower-latin) '. ';
      }
      ol.lst-kix_suvp661h8f4e-3.start {
        counter-reset: lst-ctn-kix_suvp661h8f4e-3 0;
      }
      ol.lst-kix_mi687oiexhpn-7 {
        list-style-type: none;
      }
      .lst-kix_iuizipucx4mz-6 > li {
        counter-increment: lst-ctn-kix_iuizipucx4mz-6;
      }
      ol.lst-kix_mi687oiexhpn-8 {
        list-style-type: none;
      }
      .lst-kix_6z9uvcn6pwsj-7 > li:before {
        content: '' counter(lst-ctn-kix_6z9uvcn6pwsj-7, lower-latin) '. ';
      }
      ol.lst-kix_xukvprgjom46-5.start {
        counter-reset: lst-ctn-kix_xukvprgjom46-5 0;
      }
      ol.lst-kix_k7ak3da8nwm1-1.start {
        counter-reset: lst-ctn-kix_k7ak3da8nwm1-1 0;
      }
      .lst-kix_noz0fld27yvx-1 > li {
        counter-increment: lst-ctn-kix_noz0fld27yvx-1;
      }
      .lst-kix_268r56udnzju-6 > li:before {
        content: '' counter(lst-ctn-kix_268r56udnzju-6, decimal) '. ';
      }
      .lst-kix_mi687oiexhpn-7 > li {
        counter-increment: lst-ctn-kix_mi687oiexhpn-7;
      }
      ol.lst-kix_rkq69uujdjcq-5.start {
        counter-reset: lst-ctn-kix_rkq69uujdjcq-5 0;
      }
      .lst-kix_z8mruz1jap1n-3 > li:before {
        content: '' counter(lst-ctn-kix_z8mruz1jap1n-3, decimal) '. ';
      }
      .lst-kix_kdfsnef6zzf6-1 > li {
        counter-increment: lst-ctn-kix_kdfsnef6zzf6-1;
      }
      .lst-kix_268r56udnzju-5 > li:before {
        content: '' counter(lst-ctn-kix_268r56udnzju-5, lower-roman) '. ';
      }
      .lst-kix_r29j7u2ve7fh-6 > li:before {
        content: '' counter(lst-ctn-kix_r29j7u2ve7fh-6, decimal) '. ';
      }
      .lst-kix_mnerpilip97z-6 > li:before {
        content: '' counter(lst-ctn-kix_mnerpilip97z-6, decimal) '. ';
      }
      .lst-kix_r29j7u2ve7fh-5 > li:before {
        content: '' counter(lst-ctn-kix_r29j7u2ve7fh-5, lower-roman) '. ';
      }
      .lst-kix_z8mruz1jap1n-0 > li:before {
        content: '' counter(lst-ctn-kix_z8mruz1jap1n-0, decimal) '. ';
      }
      .lst-kix_268r56udnzju-2 > li:before {
        content: '' counter(lst-ctn-kix_268r56udnzju-2, lower-roman) '. ';
      }
      .lst-kix_r29j7u2ve7fh-2 > li:before {
        content: '' counter(lst-ctn-kix_r29j7u2ve7fh-2, lower-roman) '. ';
      }
      .lst-kix_hsxyf72adno-8 > li {
        counter-increment: lst-ctn-kix_hsxyf72adno-8;
      }
      ol.lst-kix_hr7lu2216wfv-1.start {
        counter-reset: lst-ctn-kix_hr7lu2216wfv-1 0;
      }
      .lst-kix_268r56udnzju-1 > li:before {
        content: '' counter(lst-ctn-kix_268r56udnzju-1, lower-latin) '. ';
      }
      .lst-kix_6z9uvcn6pwsj-3 > li:before {
        content: '' counter(lst-ctn-kix_6z9uvcn6pwsj-3, decimal) '. ';
      }
      .lst-kix_iuizipucx4mz-8 > li {
        counter-increment: lst-ctn-kix_iuizipucx4mz-8;
      }
      ol.lst-kix_noz0fld27yvx-6.start {
        counter-reset: lst-ctn-kix_noz0fld27yvx-6 0;
      }
      .lst-kix_6z9uvcn6pwsj-2 > li:before {
        content: '' counter(lst-ctn-kix_6z9uvcn6pwsj-2, decimal) '. ';
      }
      .lst-kix_6z9uvcn6pwsj-6 > li:before {
        content: '' counter(lst-ctn-kix_6z9uvcn6pwsj-6, decimal) '. ';
      }
      .lst-kix_r29j7u2ve7fh-1 > li:before {
        content: '' counter(lst-ctn-kix_r29j7u2ve7fh-1, lower-latin) '. ';
      }
      ol.lst-kix_gpdybvh95fpz-6.start {
        counter-reset: lst-ctn-kix_gpdybvh95fpz-6 0;
      }
      ol.lst-kix_mi687oiexhpn-1 {
        list-style-type: none;
      }
      ol.lst-kix_mi687oiexhpn-2 {
        list-style-type: none;
      }
      ol.lst-kix_mi687oiexhpn-0 {
        list-style-type: none;
      }
      ol.lst-kix_mi687oiexhpn-5 {
        list-style-type: none;
      }
      ol.lst-kix_mi687oiexhpn-6 {
        list-style-type: none;
      }
      ol.lst-kix_mi687oiexhpn-3 {
        list-style-type: none;
      }
      .lst-kix_gpdybvh95fpz-2 > li {
        counter-increment: lst-ctn-kix_gpdybvh95fpz-2;
      }
      ol.lst-kix_mi687oiexhpn-4 {
        list-style-type: none;
      }
      ol.lst-kix_suvp661h8f4e-8.start {
        counter-reset: lst-ctn-kix_suvp661h8f4e-8 0;
      }
      ol.lst-kix_mnerpilip97z-6.start {
        counter-reset: lst-ctn-kix_mnerpilip97z-6 0;
      }
      .lst-kix_268r56udnzju-0 > li {
        counter-increment: lst-ctn-kix_268r56udnzju-0;
      }
      ol.lst-kix_mnerpilip97z-2.start {
        counter-reset: lst-ctn-kix_mnerpilip97z-2 0;
      }
      .lst-kix_mnerpilip97z-1 > li:before {
        content: '' counter(lst-ctn-kix_mnerpilip97z-1, lower-latin) '. ';
      }
      .lst-kix_44m267aygyei-4 > li {
        counter-increment: lst-ctn-kix_44m267aygyei-4;
      }
      .lst-kix_sbf0cuma88ql-4 > li {
        counter-increment: lst-ctn-kix_sbf0cuma88ql-4;
      }
      .lst-kix_mnerpilip97z-2 > li:before {
        content: '' counter(lst-ctn-kix_mnerpilip97z-2, lower-roman) '. ';
      }
      .lst-kix_2mz6pb1umrg-6 > li {
        counter-increment: lst-ctn-kix_2mz6pb1umrg-6;
      }
      .lst-kix_mnerpilip97z-5 > li:before {
        content: '' counter(lst-ctn-kix_mnerpilip97z-5, lower-roman) '. ';
      }
      .lst-kix_hsxyf72adno-1 > li {
        counter-increment: lst-ctn-kix_hsxyf72adno-1;
      }
      .lst-kix_268r56udnzju-7 > li {
        counter-increment: lst-ctn-kix_268r56udnzju-7;
      }
      ol.lst-kix_l1ljta7yxsg8-8.start {
        counter-reset: lst-ctn-kix_l1ljta7yxsg8-8 0;
      }
      ol.lst-kix_mnerpilip97z-1.start {
        counter-reset: lst-ctn-kix_mnerpilip97z-1 0;
      }
      ol.lst-kix_mnerpilip97z-7.start {
        counter-reset: lst-ctn-kix_mnerpilip97z-7 0;
      }
      ol.lst-kix_k7ak3da8nwm1-0.start {
        counter-reset: lst-ctn-kix_k7ak3da8nwm1-0 0;
      }
      .lst-kix_44m267aygyei-2 > li {
        counter-increment: lst-ctn-kix_44m267aygyei-2;
      }
      .lst-kix_hr7lu2216wfv-5 > li:before {
        content: '' counter(lst-ctn-kix_hr7lu2216wfv-5, lower-roman) '. ';
      }
      .lst-kix_noz0fld27yvx-8 > li {
        counter-increment: lst-ctn-kix_noz0fld27yvx-8;
      }
      ol.lst-kix_44m267aygyei-4.start {
        counter-reset: lst-ctn-kix_44m267aygyei-4 0;
      }
      .lst-kix_hr7lu2216wfv-7 > li {
        counter-increment: lst-ctn-kix_hr7lu2216wfv-7;
      }
      .lst-kix_rkq69uujdjcq-5 > li {
        counter-increment: lst-ctn-kix_rkq69uujdjcq-5;
      }
      .lst-kix_kdfsnef6zzf6-8 > li {
        counter-increment: lst-ctn-kix_kdfsnef6zzf6-8;
      }
      .lst-kix_44m267aygyei-3 > li:before {
        content: '' counter(lst-ctn-kix_44m267aygyei-3, decimal) '. ';
      }
      .lst-kix_rkq69uujdjcq-7 > li {
        counter-increment: lst-ctn-kix_rkq69uujdjcq-7;
      }
      .lst-kix_hr7lu2216wfv-5 > li {
        counter-increment: lst-ctn-kix_hr7lu2216wfv-5;
      }
      .lst-kix_44m267aygyei-7 > li:before {
        content: '' counter(lst-ctn-kix_44m267aygyei-7, lower-latin) '. ';
      }
      ol.lst-kix_w6v1rx36eaud-3.start {
        counter-reset: lst-ctn-kix_w6v1rx36eaud-3 0;
      }
      ol.lst-kix_mnerpilip97z-4 {
        list-style-type: none;
      }
      ol.lst-kix_mnerpilip97z-3 {
        list-style-type: none;
      }
      ol.lst-kix_mnerpilip97z-6 {
        list-style-type: none;
      }
      ol.lst-kix_mnerpilip97z-5 {
        list-style-type: none;
      }
      ol.lst-kix_mnerpilip97z-0 {
        list-style-type: none;
      }
      .lst-kix_sbf0cuma88ql-1 > li:before {
        content: '' counter(lst-ctn-kix_sbf0cuma88ql-1, lower-latin) '. ';
      }
      ol.lst-kix_mnerpilip97z-2 {
        list-style-type: none;
      }
      ol.lst-kix_hsxyf72adno-8.start {
        counter-reset: lst-ctn-kix_hsxyf72adno-8 0;
      }
      ol.lst-kix_mnerpilip97z-1 {
        list-style-type: none;
      }
      ol.lst-kix_mnerpilip97z-8 {
        list-style-type: none;
      }
      ol.lst-kix_mnerpilip97z-7 {
        list-style-type: none;
      }
      .lst-kix_sbf0cuma88ql-5 > li:before {
        content: '' counter(lst-ctn-kix_sbf0cuma88ql-5, lower-roman) '. ';
      }
      .lst-kix_gpdybvh95fpz-7 > li:before {
        content: '' counter(lst-ctn-kix_gpdybvh95fpz-7, lower-latin) '. ';
      }
      .lst-kix_w6v1rx36eaud-3 > li {
        counter-increment: lst-ctn-kix_w6v1rx36eaud-3;
      }
      ol.lst-kix_kdfsnef6zzf6-0 {
        list-style-type: none;
      }
      .lst-kix_hr7lu2216wfv-0 > li {
        counter-increment: lst-ctn-kix_hr7lu2216wfv-0;
      }
      ol.lst-kix_44m267aygyei-3.start {
        counter-reset: lst-ctn-kix_44m267aygyei-3 0;
      }
      ol.lst-kix_kdfsnef6zzf6-1 {
        list-style-type: none;
      }
      .lst-kix_gpdybvh95fpz-4 > li {
        counter-increment: lst-ctn-kix_gpdybvh95fpz-4;
      }
      .lst-kix_k7ak3da8nwm1-1 > li {
        counter-increment: lst-ctn-kix_k7ak3da8nwm1-1;
      }
      ol.lst-kix_kdfsnef6zzf6-2 {
        list-style-type: none;
      }
      ol.lst-kix_kdfsnef6zzf6-3 {
        list-style-type: none;
      }
      ol.lst-kix_suvp661h8f4e-4.start {
        counter-reset: lst-ctn-kix_suvp661h8f4e-4 0;
      }
      .lst-kix_hsxyf72adno-2 > li:before {
        content: '' counter(lst-ctn-kix_hsxyf72adno-2, lower-roman) '. ';
      }
      ol.lst-kix_kdfsnef6zzf6-4 {
        list-style-type: none;
      }
      ol.lst-kix_kdfsnef6zzf6-5 {
        list-style-type: none;
      }
      ol.lst-kix_kdfsnef6zzf6-6 {
        list-style-type: none;
      }
      ol.lst-kix_kdfsnef6zzf6-7 {
        list-style-type: none;
      }
      .lst-kix_z8mruz1jap1n-7 > li:before {
        content: '' counter(lst-ctn-kix_z8mruz1jap1n-7, lower-latin) '. ';
      }
      ol.lst-kix_kdfsnef6zzf6-8 {
        list-style-type: none;
      }
      .lst-kix_iuizipucx4mz-1 > li {
        counter-increment: lst-ctn-kix_iuizipucx4mz-1;
      }
      ol.lst-kix_w6v1rx36eaud-2.start {
        counter-reset: lst-ctn-kix_w6v1rx36eaud-2 0;
      }
      ol.lst-kix_iuizipucx4mz-2.start {
        counter-reset: lst-ctn-kix_iuizipucx4mz-2 0;
      }
      .lst-kix_hsxyf72adno-6 > li:before {
        content: '' counter(lst-ctn-kix_hsxyf72adno-6, decimal) '. ';
      }
      .lst-kix_rkq69uujdjcq-0 > li {
        counter-increment: lst-ctn-kix_rkq69uujdjcq-0;
      }
      .lst-kix_gpdybvh95fpz-3 > li:before {
        content: '' counter(lst-ctn-kix_gpdybvh95fpz-3, decimal) '. ';
      }
      .lst-kix_sbf0cuma88ql-2 > li {
        counter-increment: lst-ctn-kix_sbf0cuma88ql-2;
      }
      .lst-kix_z8mruz1jap1n-1 > li {
        counter-increment: lst-ctn-kix_z8mruz1jap1n-1;
      }
      .lst-kix_mi687oiexhpn-3 > li:before {
        content: '' counter(lst-ctn-kix_mi687oiexhpn-3, decimal) '. ';
      }
      .lst-kix_mi687oiexhpn-5 > li:before {
        content: '' counter(lst-ctn-kix_mi687oiexhpn-5, lower-roman) '. ';
      }
      .lst-kix_mi687oiexhpn-8 > li:before {
        content: '' counter(lst-ctn-kix_mi687oiexhpn-8, lower-roman) '. ';
      }
      .lst-kix_noz0fld27yvx-3 > li:before {
        content: '' counter(lst-ctn-kix_noz0fld27yvx-3, decimal) '. ';
      }
      .lst-kix_mi687oiexhpn-6 > li:before {
        content: '' counter(lst-ctn-kix_mi687oiexhpn-6, decimal) '. ';
      }
      .lst-kix_sbf0cuma88ql-1 > li {
        counter-increment: lst-ctn-kix_sbf0cuma88ql-1;
      }
      .lst-kix_noz0fld27yvx-6 > li:before {
        content: '' counter(lst-ctn-kix_noz0fld27yvx-6, decimal) '. ';
      }
      .lst-kix_noz0fld27yvx-5 > li:before {
        content: '' counter(lst-ctn-kix_noz0fld27yvx-5, lower-roman) '. ';
      }
      .lst-kix_2mz6pb1umrg-3 > li {
        counter-increment: lst-ctn-kix_2mz6pb1umrg-3;
      }
      ol.lst-kix_2mz6pb1umrg-6.start {
        counter-reset: lst-ctn-kix_2mz6pb1umrg-6 0;
      }
      .lst-kix_mi687oiexhpn-0 > li:before {
        content: '' counter(lst-ctn-kix_mi687oiexhpn-0, decimal) '. ';
      }
      ol.lst-kix_l1ljta7yxsg8-5.start {
        counter-reset: lst-ctn-kix_l1ljta7yxsg8-5 0;
      }
      ol.lst-kix_k7ak3da8nwm1-4.start {
        counter-reset: lst-ctn-kix_k7ak3da8nwm1-4 0;
      }
      .lst-kix_noz0fld27yvx-8 > li:before {
        content: '' counter(lst-ctn-kix_noz0fld27yvx-8, lower-roman) '. ';
      }
      .lst-kix_kdfsnef6zzf6-2 > li {
        counter-increment: lst-ctn-kix_kdfsnef6zzf6-2;
      }
      .lst-kix_suvp661h8f4e-5 > li {
        counter-increment: lst-ctn-kix_suvp661h8f4e-5;
      }
      ol.lst-kix_k7ak3da8nwm1-8 {
        list-style-type: none;
      }
      ol.lst-kix_k7ak3da8nwm1-6 {
        list-style-type: none;
      }
      ol.lst-kix_k7ak3da8nwm1-7 {
        list-style-type: none;
      }
      ol.lst-kix_k7ak3da8nwm1-4 {
        list-style-type: none;
      }
      ol.lst-kix_r29j7u2ve7fh-2.start {
        counter-reset: lst-ctn-kix_r29j7u2ve7fh-2 0;
      }
      ol.lst-kix_k7ak3da8nwm1-5 {
        list-style-type: none;
      }
      ol.lst-kix_k7ak3da8nwm1-2 {
        list-style-type: none;
      }
      ol.lst-kix_k7ak3da8nwm1-3 {
        list-style-type: none;
      }
      ol.lst-kix_k7ak3da8nwm1-0 {
        list-style-type: none;
      }
      ol.lst-kix_k7ak3da8nwm1-1 {
        list-style-type: none;
      }
      ol.lst-kix_268r56udnzju-4.start {
        counter-reset: lst-ctn-kix_268r56udnzju-4 0;
      }
      .lst-kix_noz0fld27yvx-0 > li:before {
        content: '' counter(lst-ctn-kix_noz0fld27yvx-0, decimal) '. ';
      }
      ol.lst-kix_l1ljta7yxsg8-0.start {
        counter-reset: lst-ctn-kix_l1ljta7yxsg8-0 0;
      }
      ol.lst-kix_6z9uvcn6pwsj-0.start {
        counter-reset: lst-ctn-kix_6z9uvcn6pwsj-0 0;
      }
      .lst-kix_2mz6pb1umrg-7 > li {
        counter-increment: lst-ctn-kix_2mz6pb1umrg-7;
      }
      .lst-kix_w6v1rx36eaud-1 > li {
        counter-increment: lst-ctn-kix_w6v1rx36eaud-1;
      }
      ol.lst-kix_6z9uvcn6pwsj-7.start {
        counter-reset: lst-ctn-kix_6z9uvcn6pwsj-7 0;
      }
      ol.lst-kix_nr4q6mtpzzce-1.start {
        counter-reset: lst-ctn-kix_nr4q6mtpzzce-1 0;
      }
      .lst-kix_6z9uvcn6pwsj-1 > li {
        counter-increment: lst-ctn-kix_6z9uvcn6pwsj-1;
      }
      .lst-kix_suvp661h8f4e-0 > li:before {
        content: '' counter(lst-ctn-kix_suvp661h8f4e-0, decimal) '. ';
      }
      .lst-kix_suvp661h8f4e-2 > li:before {
        content: '' counter(lst-ctn-kix_suvp661h8f4e-2, lower-roman) '. ';
      }
      ol.lst-kix_hr7lu2216wfv-5.start {
        counter-reset: lst-ctn-kix_hr7lu2216wfv-5 0;
      }
      .lst-kix_suvp661h8f4e-5 > li:before {
        content: '' counter(lst-ctn-kix_suvp661h8f4e-5, lower-roman) '. ';
      }
      .lst-kix_44m267aygyei-0 > li:before {
        content: '' counter(lst-ctn-kix_44m267aygyei-0, decimal) '. ';
      }
      ol.lst-kix_nr4q6mtpzzce-8.start {
        counter-reset: lst-ctn-kix_nr4q6mtpzzce-8 0;
      }
      ol.lst-kix_2mz6pb1umrg-1.start {
        counter-reset: lst-ctn-kix_2mz6pb1umrg-1 0;
      }
      .lst-kix_suvp661h8f4e-8 > li:before {
        content: '' counter(lst-ctn-kix_suvp661h8f4e-8, lower-roman) '. ';
      }
      .lst-kix_r29j7u2ve7fh-6 > li {
        counter-increment: lst-ctn-kix_r29j7u2ve7fh-6;
      }
      .lst-kix_suvp661h8f4e-7 > li:before {
        content: '' counter(lst-ctn-kix_suvp661h8f4e-7, lower-latin) '. ';
      }
      .lst-kix_mi687oiexhpn-2 > li {
        counter-increment: lst-ctn-kix_mi687oiexhpn-2;
      }
      .lst-kix_suvp661h8f4e-1 > li {
        counter-increment: lst-ctn-kix_suvp661h8f4e-1;
      }
      ol.lst-kix_44m267aygyei-7.start {
        counter-reset: lst-ctn-kix_44m267aygyei-7 0;
      }
      .lst-kix_hr7lu2216wfv-2 > li:before {
        content: '' counter(lst-ctn-kix_hr7lu2216wfv-2, lower-roman) '. ';
      }
      .lst-kix_kdfsnef6zzf6-6 > li {
        counter-increment: lst-ctn-kix_kdfsnef6zzf6-6;
      }
      .lst-kix_hr7lu2216wfv-1 > li:before {
        content: '' counter(lst-ctn-kix_hr7lu2216wfv-1, decimal) '. ';
      }
      ol.lst-kix_gpdybvh95fpz-2.start {
        counter-reset: lst-ctn-kix_gpdybvh95fpz-2 0;
      }
      ol.lst-kix_r29j7u2ve7fh-0.start {
        counter-reset: lst-ctn-kix_r29j7u2ve7fh-0 0;
      }
      .lst-kix_hr7lu2216wfv-4 > li:before {
        content: '' counter(lst-ctn-kix_hr7lu2216wfv-4, lower-latin) '. ';
      }
      ol.lst-kix_mnerpilip97z-8.start {
        counter-reset: lst-ctn-kix_mnerpilip97z-8 0;
      }
      ol.lst-kix_hr7lu2216wfv-0.start {
        counter-reset: lst-ctn-kix_hr7lu2216wfv-0 0;
      }
      .lst-kix_nr4q6mtpzzce-3 > li {
        counter-increment: lst-ctn-kix_nr4q6mtpzzce-3;
      }
      ol.lst-kix_hr7lu2216wfv-3.start {
        counter-reset: lst-ctn-kix_hr7lu2216wfv-3 0;
      }
      ol.lst-kix_k7ak3da8nwm1-2.start {
        counter-reset: lst-ctn-kix_k7ak3da8nwm1-2 0;
      }
      ol.lst-kix_xukvprgjom46-1.start {
        counter-reset: lst-ctn-kix_xukvprgjom46-1 0;
      }
      .lst-kix_44m267aygyei-2 > li:before {
        content: '' counter(lst-ctn-kix_44m267aygyei-2, lower-roman) '. ';
      }
      ol.lst-kix_2mz6pb1umrg-4.start {
        counter-reset: lst-ctn-kix_2mz6pb1umrg-4 0;
      }
      .lst-kix_kdfsnef6zzf6-5 > li {
        counter-increment: lst-ctn-kix_kdfsnef6zzf6-5;
      }
      ol.lst-kix_l1ljta7yxsg8-3.start {
        counter-reset: lst-ctn-kix_l1ljta7yxsg8-3 0;
      }
      .lst-kix_z8mruz1jap1n-8 > li {
        counter-increment: lst-ctn-kix_z8mruz1jap1n-8;
      }
      .lst-kix_44m267aygyei-8 > li:before {
        content: '' counter(lst-ctn-kix_44m267aygyei-8, lower-roman) '. ';
      }
      .lst-kix_2mz6pb1umrg-2 > li:before {
        content: '' counter(lst-ctn-kix_2mz6pb1umrg-2, lower-roman) '. ';
      }
      .lst-kix_2mz6pb1umrg-0 > li:before {
        content: '' counter(lst-ctn-kix_2mz6pb1umrg-0, decimal) '. ';
      }
      .lst-kix_sbf0cuma88ql-6 > li:before {
        content: '' counter(lst-ctn-kix_sbf0cuma88ql-6, decimal) '. ';
      }
      .lst-kix_sbf0cuma88ql-0 > li:before {
        content: '' counter(lst-ctn-kix_sbf0cuma88ql-0, decimal) '. ';
      }
      .lst-kix_sbf0cuma88ql-8 > li:before {
        content: '' counter(lst-ctn-kix_sbf0cuma88ql-8, lower-roman) '. ';
      }
      ol.lst-kix_268r56udnzju-2.start {
        counter-reset: lst-ctn-kix_268r56udnzju-2 0;
      }
      ol.lst-kix_z8mruz1jap1n-3 {
        list-style-type: none;
      }
      ol.lst-kix_2mz6pb1umrg-5 {
        list-style-type: none;
      }
      ol.lst-kix_z8mruz1jap1n-2 {
        list-style-type: none;
      }
      ol.lst-kix_2mz6pb1umrg-4 {
        list-style-type: none;
      }
      ol.lst-kix_z8mruz1jap1n-5 {
        list-style-type: none;
      }
      ol.lst-kix_2mz6pb1umrg-3 {
        list-style-type: none;
      }
      ol.lst-kix_z8mruz1jap1n-4 {
        list-style-type: none;
      }
      ol.lst-kix_2mz6pb1umrg-2 {
        list-style-type: none;
      }
      .lst-kix_2mz6pb1umrg-8 > li:before {
        content: '' counter(lst-ctn-kix_2mz6pb1umrg-8, lower-roman) '. ';
      }
      ol.lst-kix_z8mruz1jap1n-7 {
        list-style-type: none;
      }
      ol.lst-kix_2mz6pb1umrg-1 {
        list-style-type: none;
      }
      ol.lst-kix_z8mruz1jap1n-6 {
        list-style-type: none;
      }
      ol.lst-kix_2mz6pb1umrg-0 {
        list-style-type: none;
      }
      ol.lst-kix_gpdybvh95fpz-4.start {
        counter-reset: lst-ctn-kix_gpdybvh95fpz-4 0;
      }
      ol.lst-kix_z8mruz1jap1n-8 {
        list-style-type: none;
      }
      ol.lst-kix_xukvprgjom46-3.start {
        counter-reset: lst-ctn-kix_xukvprgjom46-3 0;
      }
      ol.lst-kix_2mz6pb1umrg-8 {
        list-style-type: none;
      }
      ol.lst-kix_z8mruz1jap1n-1 {
        list-style-type: none;
      }
      ol.lst-kix_2mz6pb1umrg-7 {
        list-style-type: none;
      }
      ol.lst-kix_z8mruz1jap1n-0 {
        list-style-type: none;
      }
      ol.lst-kix_2mz6pb1umrg-6 {
        list-style-type: none;
      }
      .lst-kix_hr7lu2216wfv-3 > li {
        counter-increment: lst-ctn-kix_hr7lu2216wfv-3;
      }
      .lst-kix_k7ak3da8nwm1-4 > li {
        counter-increment: lst-ctn-kix_k7ak3da8nwm1-4;
      }
      .lst-kix_mnerpilip97z-6 > li {
        counter-increment: lst-ctn-kix_mnerpilip97z-6;
      }
      ol.lst-kix_noz0fld27yvx-4.start {
        counter-reset: lst-ctn-kix_noz0fld27yvx-4 0;
      }
      .lst-kix_sbf0cuma88ql-5 > li {
        counter-increment: lst-ctn-kix_sbf0cuma88ql-5;
      }
      .lst-kix_rkq69uujdjcq-3 > li {
        counter-increment: lst-ctn-kix_rkq69uujdjcq-3;
      }
      ol.lst-kix_268r56udnzju-7.start {
        counter-reset: lst-ctn-kix_268r56udnzju-7 0;
      }
      ol.lst-kix_noz0fld27yvx-1.start {
        counter-reset: lst-ctn-kix_noz0fld27yvx-1 0;
      }
      .lst-kix_w6v1rx36eaud-4 > li {
        counter-increment: lst-ctn-kix_w6v1rx36eaud-4;
      }
      .lst-kix_2mz6pb1umrg-4 > li {
        counter-increment: lst-ctn-kix_2mz6pb1umrg-4;
      }
      .lst-kix_6z9uvcn6pwsj-4 > li {
        counter-increment: lst-ctn-kix_6z9uvcn6pwsj-4;
      }
      .lst-kix_268r56udnzju-8 > li:before {
        content: '' counter(lst-ctn-kix_268r56udnzju-8, lower-roman) '. ';
      }
      .lst-kix_l1ljta7yxsg8-8 > li {
        counter-increment: lst-ctn-kix_l1ljta7yxsg8-8;
      }
      ol.lst-kix_l1ljta7yxsg8-2.start {
        counter-reset: lst-ctn-kix_l1ljta7yxsg8-2 0;
      }
      .lst-kix_44m267aygyei-6 > li {
        counter-increment: lst-ctn-kix_44m267aygyei-6;
      }
      .lst-kix_268r56udnzju-0 > li:before {
        content: '' counter(lst-ctn-kix_268r56udnzju-0, decimal) '. ';
      }
      .lst-kix_r29j7u2ve7fh-4 > li:before {
        content: '' counter(lst-ctn-kix_r29j7u2ve7fh-4, lower-latin) '. ';
      }
      .lst-kix_mnerpilip97z-7 > li:before {
        content: '' counter(lst-ctn-kix_mnerpilip97z-7, lower-latin) '. ';
      }
      .lst-kix_z8mruz1jap1n-2 > li:before {
        content: '' counter(lst-ctn-kix_z8mruz1jap1n-2, lower-roman) '. ';
      }
      .lst-kix_268r56udnzju-3 > li:before {
        content: '' counter(lst-ctn-kix_268r56udnzju-3, decimal) '. ';
      }
      ol.lst-kix_nr4q6mtpzzce-0 {
        list-style-type: none;
      }
      ol.lst-kix_nr4q6mtpzzce-5.start {
        counter-reset: lst-ctn-kix_nr4q6mtpzzce-5 0;
      }
      .lst-kix_xukvprgjom46-7 > li:before {
        content: '' counter(lst-ctn-kix_xukvprgjom46-7, lower-latin) '. ';
      }
      ol.lst-kix_nr4q6mtpzzce-5 {
        list-style-type: none;
      }
      ol.lst-kix_nr4q6mtpzzce-6 {
        list-style-type: none;
      }
      .lst-kix_mnerpilip97z-2 > li {
        counter-increment: lst-ctn-kix_mnerpilip97z-2;
      }
      ol.lst-kix_nr4q6mtpzzce-7 {
        list-style-type: none;
      }
      ol.lst-kix_nr4q6mtpzzce-8 {
        list-style-type: none;
      }
      .lst-kix_6z9uvcn6pwsj-1 > li:before {
        content: '' counter(lst-ctn-kix_6z9uvcn6pwsj-1, decimal) '. ';
      }
      ol.lst-kix_nr4q6mtpzzce-1 {
        list-style-type: none;
      }
      ol.lst-kix_nr4q6mtpzzce-2 {
        list-style-type: none;
      }
      ol.lst-kix_6z9uvcn6pwsj-3.start {
        counter-reset: lst-ctn-kix_6z9uvcn6pwsj-3 0;
      }
      ol.lst-kix_nr4q6mtpzzce-3 {
        list-style-type: none;
      }
      ol.lst-kix_nr4q6mtpzzce-4 {
        list-style-type: none;
      }
      ol.lst-kix_hr7lu2216wfv-7.start {
        counter-reset: lst-ctn-kix_hr7lu2216wfv-7 0;
      }
      .lst-kix_k7ak3da8nwm1-2 > li:before {
        content: '' counter(lst-ctn-kix_k7ak3da8nwm1-2, lower-roman) '. ';
      }
      .lst-kix_suvp661h8f4e-8 > li {
        counter-increment: lst-ctn-kix_suvp661h8f4e-8;
      }
      .lst-kix_6z9uvcn6pwsj-4 > li:before {
        content: '' counter(lst-ctn-kix_6z9uvcn6pwsj-4, lower-latin) '. ';
      }
      .lst-kix_nr4q6mtpzzce-6 > li {
        counter-increment: lst-ctn-kix_nr4q6mtpzzce-6;
      }
      .lst-kix_gpdybvh95fpz-0 > li {
        counter-increment: lst-ctn-kix_gpdybvh95fpz-0;
      }
      ol.lst-kix_xukvprgjom46-0.start {
        counter-reset: lst-ctn-kix_xukvprgjom46-0 0;
      }
      ol.lst-kix_2mz6pb1umrg-8.start {
        counter-reset: lst-ctn-kix_2mz6pb1umrg-8 0;
      }
      ol.lst-kix_gpdybvh95fpz-0.start {
        counter-reset: lst-ctn-kix_gpdybvh95fpz-0 0;
      }
      ol.lst-kix_r29j7u2ve7fh-5.start {
        counter-reset: lst-ctn-kix_r29j7u2ve7fh-5 0;
      }
      ol.lst-kix_k7ak3da8nwm1-6.start {
        counter-reset: lst-ctn-kix_k7ak3da8nwm1-6 0;
      }
      .lst-kix_xukvprgjom46-6 > li {
        counter-increment: lst-ctn-kix_xukvprgjom46-6;
      }
      .lst-kix_k7ak3da8nwm1-7 > li:before {
        content: '' counter(lst-ctn-kix_k7ak3da8nwm1-7, lower-latin) '. ';
      }
      .lst-kix_mnerpilip97z-4 > li:before {
        content: '' counter(lst-ctn-kix_mnerpilip97z-4, lower-latin) '. ';
      }
      .lst-kix_2mz6pb1umrg-0 > li {
        counter-increment: lst-ctn-kix_2mz6pb1umrg-0;
      }
      ol.lst-kix_noz0fld27yvx-2.start {
        counter-reset: lst-ctn-kix_noz0fld27yvx-2 0;
      }
      ol.lst-kix_xukvprgjom46-6 {
        list-style-type: none;
      }
      ol.lst-kix_xukvprgjom46-7 {
        list-style-type: none;
      }
      ol.lst-kix_xukvprgjom46-8 {
        list-style-type: none;
      }
      ol.lst-kix_l1ljta7yxsg8-1.start {
        counter-reset: lst-ctn-kix_l1ljta7yxsg8-1 0;
      }
      .lst-kix_mi687oiexhpn-5 > li {
        counter-increment: lst-ctn-kix_mi687oiexhpn-5;
      }
      ol.lst-kix_xukvprgjom46-2 {
        list-style-type: none;
      }
      ol.lst-kix_xukvprgjom46-3 {
        list-style-type: none;
      }
      ol.lst-kix_xukvprgjom46-4 {
        list-style-type: none;
      }
      ol.lst-kix_k7ak3da8nwm1-7.start {
        counter-reset: lst-ctn-kix_k7ak3da8nwm1-7 0;
      }
      ol.lst-kix_nr4q6mtpzzce-0.start {
        counter-reset: lst-ctn-kix_nr4q6mtpzzce-0 0;
      }
      ol.lst-kix_xukvprgjom46-5 {
        list-style-type: none;
      }
      ol.lst-kix_xukvprgjom46-0 {
        list-style-type: none;
      }
      ol.lst-kix_hr7lu2216wfv-6.start {
        counter-reset: lst-ctn-kix_hr7lu2216wfv-6 0;
      }
      ol.lst-kix_xukvprgjom46-1 {
        list-style-type: none;
      }
      ol.lst-kix_gpdybvh95fpz-1.start {
        counter-reset: lst-ctn-kix_gpdybvh95fpz-1 0;
      }
      .lst-kix_r29j7u2ve7fh-3 > li {
        counter-increment: lst-ctn-kix_r29j7u2ve7fh-3;
      }
      ol.lst-kix_268r56udnzju-6.start {
        counter-reset: lst-ctn-kix_268r56udnzju-6 0;
      }
      .lst-kix_kdfsnef6zzf6-5 > li:before {
        content: '' counter(lst-ctn-kix_kdfsnef6zzf6-5, lower-roman) '. ';
      }
      ol.lst-kix_r29j7u2ve7fh-4.start {
        counter-reset: lst-ctn-kix_r29j7u2ve7fh-4 0;
      }
      ol.lst-kix_6z9uvcn6pwsj-8.start {
        counter-reset: lst-ctn-kix_6z9uvcn6pwsj-8 0;
      }
      ol.lst-kix_nr4q6mtpzzce-6.start {
        counter-reset: lst-ctn-kix_nr4q6mtpzzce-6 0;
      }
      .lst-kix_iuizipucx4mz-0 > li {
        counter-increment: lst-ctn-kix_iuizipucx4mz-0;
      }
      .lst-kix_iuizipucx4mz-2 > li:before {
        content: '' counter(lst-ctn-kix_iuizipucx4mz-2, lower-roman) '. ';
      }
      ol.lst-kix_6z9uvcn6pwsj-5.start {
        counter-reset: lst-ctn-kix_6z9uvcn6pwsj-5 0;
      }
      .lst-kix_268r56udnzju-3 > li {
        counter-increment: lst-ctn-kix_268r56udnzju-3;
      }
      ol.lst-kix_6z9uvcn6pwsj-2.start {
        counter-reset: lst-ctn-kix_6z9uvcn6pwsj-2 0;
      }
      ol.lst-kix_nr4q6mtpzzce-3.start {
        counter-reset: lst-ctn-kix_nr4q6mtpzzce-3 0;
      }
      .lst-kix_hr7lu2216wfv-7 > li:before {
        content: '' counter(lst-ctn-kix_hr7lu2216wfv-7, lower-latin) '. ';
      }
      .lst-kix_kdfsnef6zzf6-2 > li:before {
        content: '' counter(lst-ctn-kix_kdfsnef6zzf6-2, lower-roman) '. ';
      }
      .lst-kix_noz0fld27yvx-7 > li {
        counter-increment: lst-ctn-kix_noz0fld27yvx-7;
      }
      .lst-kix_xukvprgjom46-3 > li {
        counter-increment: lst-ctn-kix_xukvprgjom46-3;
      }
      .lst-kix_l1ljta7yxsg8-6 > li:before {
        content: '' counter(lst-ctn-kix_l1ljta7yxsg8-6, decimal) '. ';
      }
      .lst-kix_hsxyf72adno-4 > li {
        counter-increment: lst-ctn-kix_hsxyf72adno-4;
      }
      ol.lst-kix_r29j7u2ve7fh-6.start {
        counter-reset: lst-ctn-kix_r29j7u2ve7fh-6 0;
      }
      .lst-kix_44m267aygyei-3 > li {
        counter-increment: lst-ctn-kix_44m267aygyei-3;
      }
      .lst-kix_44m267aygyei-5 > li:before {
        content: '' counter(lst-ctn-kix_44m267aygyei-5, lower-roman) '. ';
      }
      ol.lst-kix_noz0fld27yvx-0.start {
        counter-reset: lst-ctn-kix_noz0fld27yvx-0 0;
      }
      .lst-kix_k7ak3da8nwm1-0 > li {
        counter-increment: lst-ctn-kix_k7ak3da8nwm1-0;
      }
      ol.lst-kix_k7ak3da8nwm1-8.start {
        counter-reset: lst-ctn-kix_k7ak3da8nwm1-8 0;
      }
      ol.lst-kix_l1ljta7yxsg8-2 {
        list-style-type: none;
      }
      .lst-kix_sbf0cuma88ql-3 > li:before {
        content: '' counter(lst-ctn-kix_sbf0cuma88ql-3, decimal) '. ';
      }
      ol.lst-kix_l1ljta7yxsg8-1 {
        list-style-type: none;
      }
      .lst-kix_z8mruz1jap1n-4 > li {
        counter-increment: lst-ctn-kix_z8mruz1jap1n-4;
      }
      ol.lst-kix_l1ljta7yxsg8-4 {
        list-style-type: none;
      }
      ol.lst-kix_l1ljta7yxsg8-3 {
        list-style-type: none;
      }
      ol.lst-kix_l1ljta7yxsg8-6 {
        list-style-type: none;
      }
      ol.lst-kix_gpdybvh95fpz-0 {
        list-style-type: none;
      }
      ol.lst-kix_l1ljta7yxsg8-5 {
        list-style-type: none;
      }
      ol.lst-kix_l1ljta7yxsg8-8 {
        list-style-type: none;
      }
      ol.lst-kix_l1ljta7yxsg8-7 {
        list-style-type: none;
      }
      ol.lst-kix_gpdybvh95fpz-4 {
        list-style-type: none;
      }
      ol.lst-kix_gpdybvh95fpz-3 {
        list-style-type: none;
      }
      ol.lst-kix_gpdybvh95fpz-2 {
        list-style-type: none;
      }
      ol.lst-kix_gpdybvh95fpz-1 {
        list-style-type: none;
      }
      ol.lst-kix_gpdybvh95fpz-8 {
        list-style-type: none;
      }
      ol.lst-kix_gpdybvh95fpz-7 {
        list-style-type: none;
      }
      ol.lst-kix_l1ljta7yxsg8-0 {
        list-style-type: none;
      }
      ol.lst-kix_gpdybvh95fpz-6 {
        list-style-type: none;
      }
      .lst-kix_2mz6pb1umrg-5 > li:before {
        content: '' counter(lst-ctn-kix_2mz6pb1umrg-5, lower-roman) '. ';
      }
      ol.lst-kix_gpdybvh95fpz-5 {
        list-style-type: none;
      }
      ol.lst-kix_6z9uvcn6pwsj-4.start {
        counter-reset: lst-ctn-kix_6z9uvcn6pwsj-4 0;
      }
      ol.lst-kix_268r56udnzju-8.start {
        counter-reset: lst-ctn-kix_268r56udnzju-8 0;
      }
      ol.lst-kix_r29j7u2ve7fh-8.start {
        counter-reset: lst-ctn-kix_r29j7u2ve7fh-8 0;
      }
      .lst-kix_rkq69uujdjcq-2 > li:before {
        content: '' counter(lst-ctn-kix_rkq69uujdjcq-2, lower-roman) '. ';
      }
      .lst-kix_r29j7u2ve7fh-7 > li:before {
        content: '' counter(lst-ctn-kix_r29j7u2ve7fh-7, lower-latin) '. ';
      }
      .lst-kix_hr7lu2216wfv-6 > li {
        counter-increment: lst-ctn-kix_hr7lu2216wfv-6;
      }
      .lst-kix_k7ak3da8nwm1-7 > li {
        counter-increment: lst-ctn-kix_k7ak3da8nwm1-7;
      }
      ol.lst-kix_hr7lu2216wfv-8.start {
        counter-reset: lst-ctn-kix_hr7lu2216wfv-8 0;
      }
      .lst-kix_z8mruz1jap1n-5 > li:before {
        content: '' counter(lst-ctn-kix_z8mruz1jap1n-5, lower-roman) '. ';
      }
      .lst-kix_gpdybvh95fpz-5 > li:before {
        content: '' counter(lst-ctn-kix_gpdybvh95fpz-5, lower-roman) '. ';
      }
      .lst-kix_hsxyf72adno-0 > li:before {
        content: '' counter(lst-ctn-kix_hsxyf72adno-0, decimal) '. ';
      }
      li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt;
      }
      .lst-kix_sbf0cuma88ql-8 > li {
        counter-increment: lst-ctn-kix_sbf0cuma88ql-8;
      }
      ol.lst-kix_nr4q6mtpzzce-4.start {
        counter-reset: lst-ctn-kix_nr4q6mtpzzce-4 0;
      }
      ol.lst-kix_r29j7u2ve7fh-7.start {
        counter-reset: lst-ctn-kix_r29j7u2ve7fh-7 0;
      }
      .lst-kix_rkq69uujdjcq-6 > li {
        counter-increment: lst-ctn-kix_rkq69uujdjcq-6;
      }
      .lst-kix_r29j7u2ve7fh-8 > li {
        counter-increment: lst-ctn-kix_r29j7u2ve7fh-8;
      }
      ol.lst-kix_xukvprgjom46-2.start {
        counter-reset: lst-ctn-kix_xukvprgjom46-2 0;
      }
      ol.lst-kix_kdfsnef6zzf6-1.start {
        counter-reset: lst-ctn-kix_kdfsnef6zzf6-1 0;
      }
      .lst-kix_44m267aygyei-7 > li {
        counter-increment: lst-ctn-kix_44m267aygyei-7;
      }
      ol.lst-kix_rkq69uujdjcq-2.start {
        counter-reset: lst-ctn-kix_rkq69uujdjcq-2 0;
      }
      .lst-kix_rkq69uujdjcq-2 > li {
        counter-increment: lst-ctn-kix_rkq69uujdjcq-2;
      }
      ol.lst-kix_6z9uvcn6pwsj-6.start {
        counter-reset: lst-ctn-kix_6z9uvcn6pwsj-6 0;
      }
      ol.lst-kix_suvp661h8f4e-6.start {
        counter-reset: lst-ctn-kix_suvp661h8f4e-6 0;
      }
      ol.lst-kix_hr7lu2216wfv-4.start {
        counter-reset: lst-ctn-kix_hr7lu2216wfv-4 0;
      }
      ol.lst-kix_gpdybvh95fpz-3.start {
        counter-reset: lst-ctn-kix_gpdybvh95fpz-3 0;
      }
      .lst-kix_k7ak3da8nwm1-6 > li {
        counter-increment: lst-ctn-kix_k7ak3da8nwm1-6;
      }
      ol.lst-kix_xukvprgjom46-7.start {
        counter-reset: lst-ctn-kix_xukvprgjom46-7 0;
      }
      .lst-kix_hr7lu2216wfv-2 > li {
        counter-increment: lst-ctn-kix_hr7lu2216wfv-2;
      }
      .lst-kix_2mz6pb1umrg-1 > li {
        counter-increment: lst-ctn-kix_2mz6pb1umrg-1;
      }
      ol.lst-kix_44m267aygyei-0 {
        list-style-type: none;
      }
      ol.lst-kix_44m267aygyei-1 {
        list-style-type: none;
      }
      ol.lst-kix_268r56udnzju-5.start {
        counter-reset: lst-ctn-kix_268r56udnzju-5 0;
      }
      ol.lst-kix_44m267aygyei-2 {
        list-style-type: none;
      }
      .lst-kix_iuizipucx4mz-3 > li {
        counter-increment: lst-ctn-kix_iuizipucx4mz-3;
      }
      ol.lst-kix_suvp661h8f4e-4 {
        list-style-type: none;
      }
      .lst-kix_mnerpilip97z-1 > li {
        counter-increment: lst-ctn-kix_mnerpilip97z-1;
      }
      ol.lst-kix_suvp661h8f4e-3 {
        list-style-type: none;
      }
      .lst-kix_w6v1rx36eaud-2 > li:before {
        content: '' counter(lst-ctn-kix_w6v1rx36eaud-2, lower-roman) '. ';
      }
      ol.lst-kix_suvp661h8f4e-2 {
        list-style-type: none;
      }
      ol.lst-kix_suvp661h8f4e-1 {
        list-style-type: none;
      }
      ol.lst-kix_suvp661h8f4e-0 {
        list-style-type: none;
      }
      .lst-kix_w6v1rx36eaud-3 > li:before {
        content: '' counter(lst-ctn-kix_w6v1rx36eaud-3, decimal) '. ';
      }
      .lst-kix_w6v1rx36eaud-4 > li:before {
        content: '' counter(lst-ctn-kix_w6v1rx36eaud-4, lower-latin) '. ';
      }
      .lst-kix_6z9uvcn6pwsj-7 > li {
        counter-increment: lst-ctn-kix_6z9uvcn6pwsj-7;
      }
      .lst-kix_l1ljta7yxsg8-5 > li {
        counter-increment: lst-ctn-kix_l1ljta7yxsg8-5;
      }
      .lst-kix_w6v1rx36eaud-5 > li:before {
        content: '' counter(lst-ctn-kix_w6v1rx36eaud-5, lower-roman) '. ';
      }
      .lst-kix_w6v1rx36eaud-6 > li:before {
        content: '' counter(lst-ctn-kix_w6v1rx36eaud-6, decimal) '. ';
      }
      ol.lst-kix_suvp661h8f4e-8 {
        list-style-type: none;
      }
      ol.lst-kix_suvp661h8f4e-7 {
        list-style-type: none;
      }
      ol.lst-kix_suvp661h8f4e-6 {
        list-style-type: none;
      }
      ol.lst-kix_suvp661h8f4e-5 {
        list-style-type: none;
      }
      ol.lst-kix_44m267aygyei-3 {
        list-style-type: none;
      }
      ol.lst-kix_44m267aygyei-4 {
        list-style-type: none;
      }
      ol.lst-kix_44m267aygyei-5 {
        list-style-type: none;
      }
      ol.lst-kix_rkq69uujdjcq-7.start {
        counter-reset: lst-ctn-kix_rkq69uujdjcq-7 0;
      }
      ol.lst-kix_44m267aygyei-6 {
        list-style-type: none;
      }
      .lst-kix_w6v1rx36eaud-7 > li {
        counter-increment: lst-ctn-kix_w6v1rx36eaud-7;
      }
      ol.lst-kix_44m267aygyei-7 {
        list-style-type: none;
      }
      ol.lst-kix_44m267aygyei-8 {
        list-style-type: none;
      }
      .lst-kix_w6v1rx36eaud-7 > li:before {
        content: '' counter(lst-ctn-kix_w6v1rx36eaud-7, lower-latin) '. ';
      }
      .lst-kix_w6v1rx36eaud-8 > li:before {
        content: '' counter(lst-ctn-kix_w6v1rx36eaud-8, lower-roman) '. ';
      }
      ol.lst-kix_gpdybvh95fpz-8.start {
        counter-reset: lst-ctn-kix_gpdybvh95fpz-8 0;
      }
      ol.lst-kix_z8mruz1jap1n-0.start {
        counter-reset: lst-ctn-kix_z8mruz1jap1n-0 0;
      }
      ol.lst-kix_6z9uvcn6pwsj-1.start {
        counter-reset: lst-ctn-kix_6z9uvcn6pwsj-1 0;
      }
      ol.lst-kix_hsxyf72adno-4 {
        list-style-type: none;
      }
      ol.lst-kix_hsxyf72adno-5 {
        list-style-type: none;
      }
      ol.lst-kix_hsxyf72adno-2 {
        list-style-type: none;
      }
      ol.lst-kix_hsxyf72adno-3 {
        list-style-type: none;
      }
      ol.lst-kix_hsxyf72adno-8 {
        list-style-type: none;
      }
      ol.lst-kix_hsxyf72adno-6 {
        list-style-type: none;
      }
      ol.lst-kix_suvp661h8f4e-1.start {
        counter-reset: lst-ctn-kix_suvp661h8f4e-1 0;
      }
      ol.lst-kix_hsxyf72adno-7 {
        list-style-type: none;
      }
      .lst-kix_w6v1rx36eaud-1 > li:before {
        content: '' counter(lst-ctn-kix_w6v1rx36eaud-1, decimal) '. ';
      }
      ol.lst-kix_hsxyf72adno-0 {
        list-style-type: none;
      }
      ol.lst-kix_hsxyf72adno-1 {
        list-style-type: none;
      }
      .lst-kix_w6v1rx36eaud-0 > li:before {
        content: '' counter(lst-ctn-kix_w6v1rx36eaud-0, decimal) '. ';
      }
      .lst-kix_iuizipucx4mz-1 > li:before {
        content: '' counter(lst-ctn-kix_iuizipucx4mz-1, lower-latin) '. ';
      }
      .lst-kix_xukvprgjom46-4 > li {
        counter-increment: lst-ctn-kix_xukvprgjom46-4;
      }
      ol.lst-kix_268r56udnzju-0.start {
        counter-reset: lst-ctn-kix_268r56udnzju-0 0;
      }
      ol.lst-kix_w6v1rx36eaud-5.start {
        counter-reset: lst-ctn-kix_w6v1rx36eaud-5 0;
      }
      .lst-kix_iuizipucx4mz-5 > li:before {
        content: '' counter(lst-ctn-kix_iuizipucx4mz-5, lower-roman) '. ';
      }
      .lst-kix_z8mruz1jap1n-7 > li {
        counter-increment: lst-ctn-kix_z8mruz1jap1n-7;
      }
      .lst-kix_iuizipucx4mz-7 > li:before {
        content: '' counter(lst-ctn-kix_iuizipucx4mz-7, lower-latin) '. ';
      }
      .lst-kix_kdfsnef6zzf6-3 > li:before {
        content: '' counter(lst-ctn-kix_kdfsnef6zzf6-3, decimal) '. ';
      }
      .lst-kix_sbf0cuma88ql-6 > li {
        counter-increment: lst-ctn-kix_sbf0cuma88ql-6;
      }
      .lst-kix_l1ljta7yxsg8-3 > li:before {
        content: '' counter(lst-ctn-kix_l1ljta7yxsg8-3, decimal) '. ';
      }
      .lst-kix_z8mruz1jap1n-6 > li {
        counter-increment: lst-ctn-kix_z8mruz1jap1n-6;
      }
      .lst-kix_l1ljta7yxsg8-5 > li:before {
        content: '' counter(lst-ctn-kix_l1ljta7yxsg8-5, lower-roman) '. ';
      }
      .lst-kix_l1ljta7yxsg8-7 > li:before {
        content: '' counter(lst-ctn-kix_l1ljta7yxsg8-7, lower-latin) '. ';
      }
      .lst-kix_kdfsnef6zzf6-1 > li:before {
        content: '' counter(lst-ctn-kix_kdfsnef6zzf6-1, decimal) '. ';
      }
      .lst-kix_iuizipucx4mz-3 > li:before {
        content: '' counter(lst-ctn-kix_iuizipucx4mz-3, decimal) '. ';
      }
      .lst-kix_44m267aygyei-0 > li {
        counter-increment: lst-ctn-kix_44m267aygyei-0;
      }
      ol.lst-kix_2mz6pb1umrg-7.start {
        counter-reset: lst-ctn-kix_2mz6pb1umrg-7 0;
      }
      .lst-kix_l1ljta7yxsg8-1 > li:before {
        content: '' counter(lst-ctn-kix_l1ljta7yxsg8-1, decimal) '. ';
      }
      .lst-kix_6z9uvcn6pwsj-0 > li {
        counter-increment: lst-ctn-kix_6z9uvcn6pwsj-0;
      }
      ol.lst-kix_noz0fld27yvx-3.start {
        counter-reset: lst-ctn-kix_noz0fld27yvx-3 0;
      }
      ol.lst-kix_hsxyf72adno-1.start {
        counter-reset: lst-ctn-kix_hsxyf72adno-1 0;
      }
      ol.lst-kix_l1ljta7yxsg8-6.start {
        counter-reset: lst-ctn-kix_l1ljta7yxsg8-6 0;
      }
      .lst-kix_rkq69uujdjcq-5 > li:before {
        content: '' counter(lst-ctn-kix_rkq69uujdjcq-5, lower-roman) '. ';
      }
      .lst-kix_rkq69uujdjcq-7 > li:before {
        content: '' counter(lst-ctn-kix_rkq69uujdjcq-7, lower-latin) '. ';
      }
      .lst-kix_rkq69uujdjcq-1 > li:before {
        content: '' counter(lst-ctn-kix_rkq69uujdjcq-1, decimal) '. ';
      }
      ol.lst-kix_mi687oiexhpn-5.start {
        counter-reset: lst-ctn-kix_mi687oiexhpn-5 0;
      }
      .lst-kix_gpdybvh95fpz-7 > li {
        counter-increment: lst-ctn-kix_gpdybvh95fpz-7;
      }
      .lst-kix_kdfsnef6zzf6-4 > li {
        counter-increment: lst-ctn-kix_kdfsnef6zzf6-4;
      }
      ol.lst-kix_kdfsnef6zzf6-6.start {
        counter-reset: lst-ctn-kix_kdfsnef6zzf6-6 0;
      }
      .lst-kix_rkq69uujdjcq-3 > li:before {
        content: '' counter(lst-ctn-kix_rkq69uujdjcq-3, decimal) '. ';
      }
      .lst-kix_noz0fld27yvx-4 > li {
        counter-increment: lst-ctn-kix_noz0fld27yvx-4;
      }
      .lst-kix_xukvprgjom46-2 > li:before {
        content: '' counter(lst-ctn-kix_xukvprgjom46-2, lower-roman) '. ';
      }
      .lst-kix_xukvprgjom46-0 > li:before {
        content: '' counter(lst-ctn-kix_xukvprgjom46-0, decimal) '. ';
      }
      .lst-kix_w6v1rx36eaud-0 > li {
        counter-increment: lst-ctn-kix_w6v1rx36eaud-0;
      }
      .lst-kix_iuizipucx4mz-4 > li {
        counter-increment: lst-ctn-kix_iuizipucx4mz-4;
      }
      ol.lst-kix_r29j7u2ve7fh-1.start {
        counter-reset: lst-ctn-kix_r29j7u2ve7fh-1 0;
      }
      .lst-kix_w6v1rx36eaud-6 > li {
        counter-increment: lst-ctn-kix_w6v1rx36eaud-6;
      }
      ol.lst-kix_mi687oiexhpn-6.start {
        counter-reset: lst-ctn-kix_mi687oiexhpn-6 0;
      }
      ol.lst-kix_k7ak3da8nwm1-3.start {
        counter-reset: lst-ctn-kix_k7ak3da8nwm1-3 0;
      }
      .lst-kix_sbf0cuma88ql-0 > li {
        counter-increment: lst-ctn-kix_sbf0cuma88ql-0;
      }
      ol.lst-kix_2mz6pb1umrg-3.start {
        counter-reset: lst-ctn-kix_2mz6pb1umrg-3 0;
      }
      ol.lst-kix_mi687oiexhpn-0.start {
        counter-reset: lst-ctn-kix_mi687oiexhpn-0 0;
      }
      ol.lst-kix_w6v1rx36eaud-1.start {
        counter-reset: lst-ctn-kix_w6v1rx36eaud-1 0;
      }
      .lst-kix_z8mruz1jap1n-0 > li {
        counter-increment: lst-ctn-kix_z8mruz1jap1n-0;
      }
      ol.lst-kix_iuizipucx4mz-6.start {
        counter-reset: lst-ctn-kix_iuizipucx4mz-6 0;
      }
      .lst-kix_r29j7u2ve7fh-7 > li {
        counter-increment: lst-ctn-kix_r29j7u2ve7fh-7;
      }
      ol.lst-kix_268r56udnzju-1.start {
        counter-reset: lst-ctn-kix_268r56udnzju-1 0;
      }
      .lst-kix_xukvprgjom46-4 > li:before {
        content: '' counter(lst-ctn-kix_xukvprgjom46-4, lower-latin) '. ';
      }
      ol.lst-kix_iuizipucx4mz-0.start {
        counter-reset: lst-ctn-kix_iuizipucx4mz-0 0;
      }
      ol.lst-kix_hsxyf72adno-7.start {
        counter-reset: lst-ctn-kix_hsxyf72adno-7 0;
      }
      .lst-kix_xukvprgjom46-5 > li:before {
        content: '' counter(lst-ctn-kix_xukvprgjom46-5, lower-roman) '. ';
      }
      ol.lst-kix_w6v1rx36eaud-6.start {
        counter-reset: lst-ctn-kix_w6v1rx36eaud-6 0;
      }
      .lst-kix_l1ljta7yxsg8-6 > li {
        counter-increment: lst-ctn-kix_l1ljta7yxsg8-6;
      }
      .lst-kix_k7ak3da8nwm1-4 > li:before {
        content: '' counter(lst-ctn-kix_k7ak3da8nwm1-4, lower-latin) '. ';
      }
      ol.lst-kix_44m267aygyei-5.start {
        counter-reset: lst-ctn-kix_44m267aygyei-5 0;
      }
      .lst-kix_xukvprgjom46-8 > li:before {
        content: '' counter(lst-ctn-kix_xukvprgjom46-8, lower-roman) '. ';
      }
      .lst-kix_k7ak3da8nwm1-1 > li:before {
        content: '' counter(lst-ctn-kix_k7ak3da8nwm1-1, lower-latin) '. ';
      }
      .lst-kix_k7ak3da8nwm1-0 > li:before {
        content: '' counter(lst-ctn-kix_k7ak3da8nwm1-0, decimal) '. ';
      }
      .lst-kix_6z9uvcn6pwsj-6 > li {
        counter-increment: lst-ctn-kix_6z9uvcn6pwsj-6;
      }
      ol.lst-kix_l1ljta7yxsg8-7.start {
        counter-reset: lst-ctn-kix_l1ljta7yxsg8-7 0;
      }
      .lst-kix_gpdybvh95fpz-6 > li {
        counter-increment: lst-ctn-kix_gpdybvh95fpz-6;
      }
      ol.lst-kix_44m267aygyei-6.start {
        counter-reset: lst-ctn-kix_44m267aygyei-6 0;
      }
      .lst-kix_r29j7u2ve7fh-0 > li {
        counter-increment: lst-ctn-kix_r29j7u2ve7fh-0;
      }
      .lst-kix_k7ak3da8nwm1-5 > li:before {
        content: '' counter(lst-ctn-kix_k7ak3da8nwm1-5, lower-roman) '. ';
      }
      .lst-kix_k7ak3da8nwm1-8 > li:before {
        content: '' counter(lst-ctn-kix_k7ak3da8nwm1-8, lower-roman) '. ';
      }
      ol.lst-kix_suvp661h8f4e-2.start {
        counter-reset: lst-ctn-kix_suvp661h8f4e-2 0;
      }
      ol.lst-kix_noz0fld27yvx-7.start {
        counter-reset: lst-ctn-kix_noz0fld27yvx-7 0;
      }
      ol.lst-kix_gpdybvh95fpz-7.start {
        counter-reset: lst-ctn-kix_gpdybvh95fpz-7 0;
      }
      ol.lst-kix_iuizipucx4mz-5.start {
        counter-reset: lst-ctn-kix_iuizipucx4mz-5 0;
      }
      .lst-kix_noz0fld27yvx-3 > li {
        counter-increment: lst-ctn-kix_noz0fld27yvx-3;
      }
      ol.lst-kix_44m267aygyei-0.start {
        counter-reset: lst-ctn-kix_44m267aygyei-0 0;
      }
      .lst-kix_suvp661h8f4e-4 > li {
        counter-increment: lst-ctn-kix_suvp661h8f4e-4;
      }
      .lst-kix_iuizipucx4mz-8 > li:before {
        content: '' counter(lst-ctn-kix_iuizipucx4mz-8, lower-roman) '. ';
      }
      .lst-kix_kdfsnef6zzf6-3 > li {
        counter-increment: lst-ctn-kix_kdfsnef6zzf6-3;
      }
      ol.lst-kix_2mz6pb1umrg-2.start {
        counter-reset: lst-ctn-kix_2mz6pb1umrg-2 0;
      }
      .lst-kix_kdfsnef6zzf6-7 > li:before {
        content: '' counter(lst-ctn-kix_kdfsnef6zzf6-7, lower-latin) '. ';
      }
      .lst-kix_kdfsnef6zzf6-8 > li:before {
        content: '' counter(lst-ctn-kix_kdfsnef6zzf6-8, lower-roman) '. ';
      }
      ol.lst-kix_noz0fld27yvx-8.start {
        counter-reset: lst-ctn-kix_noz0fld27yvx-8 0;
      }
      ol.lst-kix_hsxyf72adno-6.start {
        counter-reset: lst-ctn-kix_hsxyf72adno-6 0;
      }
      .lst-kix_iuizipucx4mz-0 > li:before {
        content: '' counter(lst-ctn-kix_iuizipucx4mz-0, decimal) '. ';
      }
      .lst-kix_l1ljta7yxsg8-8 > li:before {
        content: '' counter(lst-ctn-kix_l1ljta7yxsg8-8, lower-roman) '. ';
      }
      .lst-kix_kdfsnef6zzf6-4 > li:before {
        content: '' counter(lst-ctn-kix_kdfsnef6zzf6-4, lower-latin) '. ';
      }
      .lst-kix_sbf0cuma88ql-7 > li {
        counter-increment: lst-ctn-kix_sbf0cuma88ql-7;
      }
      ol.lst-kix_44m267aygyei-1.start {
        counter-reset: lst-ctn-kix_44m267aygyei-1 0;
      }
      .lst-kix_l1ljta7yxsg8-4 > li:before {
        content: '' counter(lst-ctn-kix_l1ljta7yxsg8-4, lower-latin) '. ';
      }
      ol.lst-kix_xukvprgjom46-6.start {
        counter-reset: lst-ctn-kix_xukvprgjom46-6 0;
      }
      ol.lst-kix_suvp661h8f4e-7.start {
        counter-reset: lst-ctn-kix_suvp661h8f4e-7 0;
      }
      .lst-kix_iuizipucx4mz-4 > li:before {
        content: '' counter(lst-ctn-kix_iuizipucx4mz-4, lower-latin) '. ';
      }
      .lst-kix_kdfsnef6zzf6-0 > li:before {
        content: '' counter(lst-ctn-kix_kdfsnef6zzf6-0, decimal) '. ';
      }
      .lst-kix_suvp661h8f4e-2 > li {
        counter-increment: lst-ctn-kix_suvp661h8f4e-2;
      }
      ol.lst-kix_mnerpilip97z-5.start {
        counter-reset: lst-ctn-kix_mnerpilip97z-5 0;
      }
      ol.lst-kix_mi687oiexhpn-1.start {
        counter-reset: lst-ctn-kix_mi687oiexhpn-1 0;
      }
      .lst-kix_mnerpilip97z-8 > li {
        counter-increment: lst-ctn-kix_mnerpilip97z-8;
      }
      .lst-kix_l1ljta7yxsg8-0 > li:before {
        content: '' counter(lst-ctn-kix_l1ljta7yxsg8-0, decimal) '. ';
      }
      .lst-kix_w6v1rx36eaud-8 > li {
        counter-increment: lst-ctn-kix_w6v1rx36eaud-8;
      }
      .lst-kix_z8mruz1jap1n-5 > li {
        counter-increment: lst-ctn-kix_z8mruz1jap1n-5;
      }
      .lst-kix_r29j7u2ve7fh-2 > li {
        counter-increment: lst-ctn-kix_r29j7u2ve7fh-2;
      }
      ol.lst-kix_w6v1rx36eaud-0.start {
        counter-reset: lst-ctn-kix_w6v1rx36eaud-0 0;
      }
      .lst-kix_rkq69uujdjcq-8 > li:before {
        content: '' counter(lst-ctn-kix_rkq69uujdjcq-8, lower-roman) '. ';
      }
      .lst-kix_l1ljta7yxsg8-4 > li {
        counter-increment: lst-ctn-kix_l1ljta7yxsg8-4;
      }
      .lst-kix_2mz6pb1umrg-3 > li:before {
        content: '' counter(lst-ctn-kix_2mz6pb1umrg-3, decimal) '. ';
      }
      .lst-kix_rkq69uujdjcq-0 > li:before {
        content: '' counter(lst-ctn-kix_rkq69uujdjcq-0, decimal) '. ';
      }
      ol.lst-kix_suvp661h8f4e-5.start {
        counter-reset: lst-ctn-kix_suvp661h8f4e-5 0;
      }
      .lst-kix_2mz6pb1umrg-7 > li:before {
        content: '' counter(lst-ctn-kix_2mz6pb1umrg-7, lower-latin) '. ';
      }
      .lst-kix_mi687oiexhpn-4 > li {
        counter-increment: lst-ctn-kix_mi687oiexhpn-4;
      }
      .lst-kix_6z9uvcn6pwsj-8 > li {
        counter-increment: lst-ctn-kix_6z9uvcn6pwsj-8;
      }
      ol.lst-kix_mnerpilip97z-3.start {
        counter-reset: lst-ctn-kix_mnerpilip97z-3 0;
      }
      .lst-kix_rkq69uujdjcq-4 > li:before {
        content: '' counter(lst-ctn-kix_rkq69uujdjcq-4, lower-latin) '. ';
      }
      ol.lst-kix_iuizipucx4mz-1.start {
        counter-reset: lst-ctn-kix_iuizipucx4mz-1 0;
      }
      .lst-kix_xukvprgjom46-1 > li:before {
        content: '' counter(lst-ctn-kix_xukvprgjom46-1, lower-latin) '. ';
      }
      .lst-kix_hsxyf72adno-3 > li {
        counter-increment: lst-ctn-kix_hsxyf72adno-3;
      }
      ol.lst-kix_xukvprgjom46-8.start {
        counter-reset: lst-ctn-kix_xukvprgjom46-8 0;
      }
      .lst-kix_mnerpilip97z-3 > li {
        counter-increment: lst-ctn-kix_mnerpilip97z-3;
      }
      .lst-kix_268r56udnzju-2 > li {
        counter-increment: lst-ctn-kix_268r56udnzju-2;
      }
      .lst-kix_xukvprgjom46-2 > li {
        counter-increment: lst-ctn-kix_xukvprgjom46-2;
      }
      .lst-kix_2mz6pb1umrg-8 > li {
        counter-increment: lst-ctn-kix_2mz6pb1umrg-8;
      }
      .lst-kix_nr4q6mtpzzce-5 > li {
        counter-increment: lst-ctn-kix_nr4q6mtpzzce-5;
      }
      ol.lst-kix_mnerpilip97z-4.start {
        counter-reset: lst-ctn-kix_mnerpilip97z-4 0;
      }
      ol.lst-kix_sbf0cuma88ql-0.start {
        counter-reset: lst-ctn-kix_sbf0cuma88ql-0 0;
      }
      .lst-kix_noz0fld27yvx-1 > li:before {
        content: '' counter(lst-ctn-kix_noz0fld27yvx-1, lower-latin) '. ';
      }
      .lst-kix_l1ljta7yxsg8-7 > li {
        counter-increment: lst-ctn-kix_l1ljta7yxsg8-7;
      }
      .lst-kix_6z9uvcn6pwsj-5 > li {
        counter-increment: lst-ctn-kix_6z9uvcn6pwsj-5;
      }
      .lst-kix_noz0fld27yvx-2 > li:before {
        content: '' counter(lst-ctn-kix_noz0fld27yvx-2, lower-roman) '. ';
      }
      .lst-kix_mi687oiexhpn-4 > li:before {
        content: '' counter(lst-ctn-kix_mi687oiexhpn-4, lower-latin) '. ';
      }
      .lst-kix_mi687oiexhpn-7 > li:before {
        content: '' counter(lst-ctn-kix_mi687oiexhpn-7, lower-latin) '. ';
      }
      .lst-kix_noz0fld27yvx-4 > li:before {
        content: '' counter(lst-ctn-kix_noz0fld27yvx-4, lower-latin) '. ';
      }
      .lst-kix_268r56udnzju-8 > li {
        counter-increment: lst-ctn-kix_268r56udnzju-8;
      }
      .lst-kix_noz0fld27yvx-7 > li:before {
        content: '' counter(lst-ctn-kix_noz0fld27yvx-7, lower-latin) '. ';
      }
      .lst-kix_k7ak3da8nwm1-8 > li {
        counter-increment: lst-ctn-kix_k7ak3da8nwm1-8;
      }
      .lst-kix_mi687oiexhpn-1 > li:before {
        content: '' counter(lst-ctn-kix_mi687oiexhpn-1, lower-latin) '. ';
      }
      .lst-kix_mi687oiexhpn-2 > li:before {
        content: '' counter(lst-ctn-kix_mi687oiexhpn-2, lower-roman) '. ';
      }
      ol.lst-kix_w6v1rx36eaud-4.start {
        counter-reset: lst-ctn-kix_w6v1rx36eaud-4 0;
      }
      ol.lst-kix_hsxyf72adno-5.start {
        counter-reset: lst-ctn-kix_hsxyf72adno-5 0;
      }
      ol.lst-kix_suvp661h8f4e-0.start {
        counter-reset: lst-ctn-kix_suvp661h8f4e-0 0;
      }
      ol.lst-kix_hr7lu2216wfv-0 {
        list-style-type: none;
      }
      ol.lst-kix_hr7lu2216wfv-1 {
        list-style-type: none;
      }
      ol.lst-kix_hr7lu2216wfv-2 {
        list-style-type: none;
      }
      .lst-kix_w6v1rx36eaud-5 > li {
        counter-increment: lst-ctn-kix_w6v1rx36eaud-5;
      }
      .lst-kix_gpdybvh95fpz-5 > li {
        counter-increment: lst-ctn-kix_gpdybvh95fpz-5;
      }
      ol.lst-kix_hr7lu2216wfv-7 {
        list-style-type: none;
      }
      .lst-kix_noz0fld27yvx-2 > li {
        counter-increment: lst-ctn-kix_noz0fld27yvx-2;
      }
      ol.lst-kix_hr7lu2216wfv-8 {
        list-style-type: none;
      }
      ol.lst-kix_hr7lu2216wfv-3 {
        list-style-type: none;
      }
      ol.lst-kix_hr7lu2216wfv-4 {
        list-style-type: none;
      }
      ol.lst-kix_hr7lu2216wfv-5 {
        list-style-type: none;
      }
      ol.lst-kix_44m267aygyei-2.start {
        counter-reset: lst-ctn-kix_44m267aygyei-2 0;
      }
      ol.lst-kix_hr7lu2216wfv-6 {
        list-style-type: none;
      }
      .lst-kix_iuizipucx4mz-5 > li {
        counter-increment: lst-ctn-kix_iuizipucx4mz-5;
      }
      .lst-kix_mi687oiexhpn-6 > li {
        counter-increment: lst-ctn-kix_mi687oiexhpn-6;
      }
      ol.lst-kix_mi687oiexhpn-2.start {
        counter-reset: lst-ctn-kix_mi687oiexhpn-2 0;
      }
      ol.lst-kix_z8mruz1jap1n-1.start {
        counter-reset: lst-ctn-kix_z8mruz1jap1n-1 0;
      }
      ol.lst-kix_sbf0cuma88ql-5.start {
        counter-reset: lst-ctn-kix_sbf0cuma88ql-5 0;
      }
      ol.lst-kix_iuizipucx4mz-4.start {
        counter-reset: lst-ctn-kix_iuizipucx4mz-4 0;
      }
      .lst-kix_suvp661h8f4e-1 > li:before {
        content: '' counter(lst-ctn-kix_suvp661h8f4e-1, decimal) '. ';
      }
      .lst-kix_nr4q6mtpzzce-7 > li {
        counter-increment: lst-ctn-kix_nr4q6mtpzzce-7;
      }
      .lst-kix_rkq69uujdjcq-4 > li {
        counter-increment: lst-ctn-kix_rkq69uujdjcq-4;
      }
      .lst-kix_hr7lu2216wfv-4 > li {
        counter-increment: lst-ctn-kix_hr7lu2216wfv-4;
      }
      .lst-kix_suvp661h8f4e-4 > li:before {
        content: '' counter(lst-ctn-kix_suvp661h8f4e-4, lower-latin) '. ';
      }
      .lst-kix_mnerpilip97z-7 > li {
        counter-increment: lst-ctn-kix_mnerpilip97z-7;
      }
      .lst-kix_suvp661h8f4e-3 > li:before {
        content: '' counter(lst-ctn-kix_suvp661h8f4e-3, decimal) '. ';
      }
      .lst-kix_nr4q6mtpzzce-0 > li {
        counter-increment: lst-ctn-kix_nr4q6mtpzzce-0;
      }
      ol.lst-kix_sbf0cuma88ql-2 {
        list-style-type: none;
      }
      ol.lst-kix_hsxyf72adno-0.start {
        counter-reset: lst-ctn-kix_hsxyf72adno-0 0;
      }
      ol.lst-kix_sbf0cuma88ql-1 {
        list-style-type: none;
      }
      ol.lst-kix_sbf0cuma88ql-4 {
        list-style-type: none;
      }
      ol.lst-kix_sbf0cuma88ql-3 {
        list-style-type: none;
      }
      ol.lst-kix_sbf0cuma88ql-0 {
        list-style-type: none;
      }
      .lst-kix_noz0fld27yvx-6 > li {
        counter-increment: lst-ctn-kix_noz0fld27yvx-6;
      }
      .lst-kix_suvp661h8f4e-6 > li:before {
        content: '' counter(lst-ctn-kix_suvp661h8f4e-6, decimal) '. ';
      }
      ol.lst-kix_rkq69uujdjcq-8.start {
        counter-reset: lst-ctn-kix_rkq69uujdjcq-8 0;
      }
      ol.lst-kix_sbf0cuma88ql-6 {
        list-style-type: none;
      }
      ol.lst-kix_sbf0cuma88ql-5 {
        list-style-type: none;
      }
      ol.lst-kix_sbf0cuma88ql-8 {
        list-style-type: none;
      }
      ol.lst-kix_sbf0cuma88ql-7 {
        list-style-type: none;
      }
      .lst-kix_hr7lu2216wfv-3 > li:before {
        content: '' counter(lst-ctn-kix_hr7lu2216wfv-3, decimal) '. ';
      }
      ol.lst-kix_rkq69uujdjcq-1.start {
        counter-reset: lst-ctn-kix_rkq69uujdjcq-1 0;
      }
      .lst-kix_hr7lu2216wfv-0 > li:before {
        content: '' counter(lst-ctn-kix_hr7lu2216wfv-0, decimal) '. ';
      }
      ol.lst-kix_z8mruz1jap1n-6.start {
        counter-reset: lst-ctn-kix_z8mruz1jap1n-6 0;
      }
      ol.lst-kix_mi687oiexhpn-4.start {
        counter-reset: lst-ctn-kix_mi687oiexhpn-4 0;
      }
      .lst-kix_hr7lu2216wfv-6 > li:before {
        content: '' counter(lst-ctn-kix_hr7lu2216wfv-6, decimal) '. ';
      }
      ol.lst-kix_mi687oiexhpn-7.start {
        counter-reset: lst-ctn-kix_mi687oiexhpn-7 0;
      }
      ol.lst-kix_mnerpilip97z-0.start {
        counter-reset: lst-ctn-kix_mnerpilip97z-0 0;
      }
      .lst-kix_hr7lu2216wfv-8 > li:before {
        content: '' counter(lst-ctn-kix_hr7lu2216wfv-8, lower-roman) '. ';
      }
      ol.lst-kix_sbf0cuma88ql-7.start {
        counter-reset: lst-ctn-kix_sbf0cuma88ql-7 0;
      }
      .lst-kix_268r56udnzju-4 > li {
        counter-increment: lst-ctn-kix_268r56udnzju-4;
      }
      .lst-kix_44m267aygyei-4 > li:before {
        content: '' counter(lst-ctn-kix_44m267aygyei-4, lower-latin) '. ';
      }
      .lst-kix_l1ljta7yxsg8-0 > li {
        counter-increment: lst-ctn-kix_l1ljta7yxsg8-0;
      }
      ol.lst-kix_rkq69uujdjcq-3.start {
        counter-reset: lst-ctn-kix_rkq69uujdjcq-3 0;
      }
      ol.lst-kix_kdfsnef6zzf6-5.start {
        counter-reset: lst-ctn-kix_kdfsnef6zzf6-5 0;
      }
      .lst-kix_hsxyf72adno-5 > li {
        counter-increment: lst-ctn-kix_hsxyf72adno-5;
      }
      ol.lst-kix_rkq69uujdjcq-6.start {
        counter-reset: lst-ctn-kix_rkq69uujdjcq-6 0;
      }
      .lst-kix_hr7lu2216wfv-8 > li {
        counter-increment: lst-ctn-kix_hr7lu2216wfv-8;
      }
      ol.lst-kix_iuizipucx4mz-7.start {
        counter-reset: lst-ctn-kix_iuizipucx4mz-7 0;
      }
      .lst-kix_gpdybvh95fpz-8 > li {
        counter-increment: lst-ctn-kix_gpdybvh95fpz-8;
      }
      .lst-kix_44m267aygyei-6 > li:before {
        content: '' counter(lst-ctn-kix_44m267aygyei-6, decimal) '. ';
      }
      .lst-kix_sbf0cuma88ql-4 > li:before {
        content: '' counter(lst-ctn-kix_sbf0cuma88ql-4, lower-latin) '. ';
      }
      .lst-kix_sbf0cuma88ql-2 > li:before {
        content: '' counter(lst-ctn-kix_sbf0cuma88ql-2, lower-roman) '. ';
      }
      ol.lst-kix_z8mruz1jap1n-8.start {
        counter-reset: lst-ctn-kix_z8mruz1jap1n-8 0;
      }
      .lst-kix_268r56udnzju-5 > li {
        counter-increment: lst-ctn-kix_268r56udnzju-5;
      }
      .lst-kix_44m267aygyei-5 > li {
        counter-increment: lst-ctn-kix_44m267aygyei-5;
      }
      .lst-kix_noz0fld27yvx-5 > li {
        counter-increment: lst-ctn-kix_noz0fld27yvx-5;
      }
      .lst-kix_2mz6pb1umrg-4 > li:before {
        content: '' counter(lst-ctn-kix_2mz6pb1umrg-4, lower-latin) '. ';
      }
      .lst-kix_2mz6pb1umrg-6 > li:before {
        content: '' counter(lst-ctn-kix_2mz6pb1umrg-6, decimal) '. ';
      }
      ol.lst-kix_w6v1rx36eaud-7.start {
        counter-reset: lst-ctn-kix_w6v1rx36eaud-7 0;
      }
      ol.lst-kix_kdfsnef6zzf6-7.start {
        counter-reset: lst-ctn-kix_kdfsnef6zzf6-7 0;
      }
      .lst-kix_mnerpilip97z-0 > li {
        counter-increment: lst-ctn-kix_mnerpilip97z-0;
      }
      .lst-kix_hsxyf72adno-6 > li {
        counter-increment: lst-ctn-kix_hsxyf72adno-6;
      }
      .lst-kix_gpdybvh95fpz-1 > li {
        counter-increment: lst-ctn-kix_gpdybvh95fpz-1;
      }
      ol.lst-kix_r29j7u2ve7fh-1 {
        list-style-type: none;
      }
      ol.lst-kix_hsxyf72adno-3.start {
        counter-reset: lst-ctn-kix_hsxyf72adno-3 0;
      }
      ol.lst-kix_r29j7u2ve7fh-0 {
        list-style-type: none;
      }
      ol.lst-kix_r29j7u2ve7fh-3 {
        list-style-type: none;
      }
      ol.lst-kix_r29j7u2ve7fh-2 {
        list-style-type: none;
      }
      ol.lst-kix_r29j7u2ve7fh-5 {
        list-style-type: none;
      }
      ol.lst-kix_r29j7u2ve7fh-4 {
        list-style-type: none;
      }
      ol.lst-kix_r29j7u2ve7fh-7 {
        list-style-type: none;
      }
      ol.lst-kix_r29j7u2ve7fh-6 {
        list-style-type: none;
      }
      .lst-kix_nr4q6mtpzzce-8 > li {
        counter-increment: lst-ctn-kix_nr4q6mtpzzce-8;
      }
      ol.lst-kix_r29j7u2ve7fh-8 {
        list-style-type: none;
      }
      .lst-kix_xukvprgjom46-5 > li {
        counter-increment: lst-ctn-kix_xukvprgjom46-5;
      }
      .lst-kix_6z9uvcn6pwsj-8 > li:before {
        content: '' counter(lst-ctn-kix_6z9uvcn6pwsj-8, lower-roman) '. ';
      }
      ol.lst-kix_kdfsnef6zzf6-4.start {
        counter-reset: lst-ctn-kix_kdfsnef6zzf6-4 0;
      }
      .lst-kix_hr7lu2216wfv-1 > li {
        counter-increment: lst-ctn-kix_hr7lu2216wfv-1;
      }
      .lst-kix_44m267aygyei-8 > li {
        counter-increment: lst-ctn-kix_44m267aygyei-8;
      }
      .lst-kix_268r56udnzju-7 > li:before {
        content: '' counter(lst-ctn-kix_268r56udnzju-7, lower-latin) '. ';
      }
      .lst-kix_rkq69uujdjcq-1 > li {
        counter-increment: lst-ctn-kix_rkq69uujdjcq-1;
      }
      .lst-kix_mnerpilip97z-4 > li {
        counter-increment: lst-ctn-kix_mnerpilip97z-4;
      }
      .lst-kix_suvp661h8f4e-6 > li {
        counter-increment: lst-ctn-kix_suvp661h8f4e-6;
      }
      .lst-kix_268r56udnzju-4 > li:before {
        content: '' counter(lst-ctn-kix_268r56udnzju-4, lower-latin) '. ';
      }
      ol.lst-kix_hsxyf72adno-2.start {
        counter-reset: lst-ctn-kix_hsxyf72adno-2 0;
      }
      .lst-kix_xukvprgjom46-3 > li:before {
        content: '' counter(lst-ctn-kix_xukvprgjom46-3, decimal) '. ';
      }
      .lst-kix_z8mruz1jap1n-1 > li:before {
        content: '' counter(lst-ctn-kix_z8mruz1jap1n-1, decimal) '. ';
      }
      .lst-kix_z8mruz1jap1n-2 > li {
        counter-increment: lst-ctn-kix_z8mruz1jap1n-2;
      }
      .lst-kix_6z9uvcn6pwsj-0 > li:before {
        content: '' counter(lst-ctn-kix_6z9uvcn6pwsj-0, decimal) '. ';
      }
      .lst-kix_mnerpilip97z-8 > li:before {
        content: '' counter(lst-ctn-kix_mnerpilip97z-8, lower-roman) '. ';
      }
      .lst-kix_r29j7u2ve7fh-3 > li:before {
        content: '' counter(lst-ctn-kix_r29j7u2ve7fh-3, decimal) '. ';
      }
      .lst-kix_xukvprgjom46-6 > li:before {
        content: '' counter(lst-ctn-kix_xukvprgjom46-6, decimal) '. ';
      }
      .lst-kix_6z9uvcn6pwsj-5 > li:before {
        content: '' counter(lst-ctn-kix_6z9uvcn6pwsj-5, lower-roman) '. ';
      }
      .lst-kix_k7ak3da8nwm1-3 > li:before {
        content: '' counter(lst-ctn-kix_k7ak3da8nwm1-3, decimal) '. ';
      }
      ol.lst-kix_sbf0cuma88ql-8.start {
        counter-reset: lst-ctn-kix_sbf0cuma88ql-8 0;
      }
      .lst-kix_r29j7u2ve7fh-0 > li:before {
        content: '' counter(lst-ctn-kix_r29j7u2ve7fh-0, decimal) '. ';
      }
      .lst-kix_w6v1rx36eaud-2 > li {
        counter-increment: lst-ctn-kix_w6v1rx36eaud-2;
      }
      ol.lst-kix_noz0fld27yvx-7 {
        list-style-type: none;
      }
      ol.lst-kix_noz0fld27yvx-6 {
        list-style-type: none;
      }
      .lst-kix_xukvprgjom46-1 > li {
        counter-increment: lst-ctn-kix_xukvprgjom46-1;
      }
      ol.lst-kix_noz0fld27yvx-5 {
        list-style-type: none;
      }
      ol.lst-kix_noz0fld27yvx-4 {
        list-style-type: none;
      }
      ol.lst-kix_noz0fld27yvx-3 {
        list-style-type: none;
      }
      ol.lst-kix_noz0fld27yvx-2 {
        list-style-type: none;
      }
      ol.lst-kix_noz0fld27yvx-1 {
        list-style-type: none;
      }
      ol.lst-kix_noz0fld27yvx-0 {
        list-style-type: none;
      }
      .lst-kix_mnerpilip97z-0 > li:before {
        content: '' counter(lst-ctn-kix_mnerpilip97z-0, decimal) '. ';
      }
      ol.lst-kix_rkq69uujdjcq-0.start {
        counter-reset: lst-ctn-kix_rkq69uujdjcq-0 0;
      }
      .lst-kix_mnerpilip97z-3 > li:before {
        content: '' counter(lst-ctn-kix_mnerpilip97z-3, decimal) '. ';
      }
      .lst-kix_k7ak3da8nwm1-5 > li {
        counter-increment: lst-ctn-kix_k7ak3da8nwm1-5;
      }
      .lst-kix_k7ak3da8nwm1-6 > li:before {
        content: '' counter(lst-ctn-kix_k7ak3da8nwm1-6, decimal) '. ';
      }
      ol.lst-kix_kdfsnef6zzf6-3.start {
        counter-reset: lst-ctn-kix_kdfsnef6zzf6-3 0;
      }
      .lst-kix_rkq69uujdjcq-8 > li {
        counter-increment: lst-ctn-kix_rkq69uujdjcq-8;
      }
      ol.lst-kix_noz0fld27yvx-8 {
        list-style-type: none;
      }
      .lst-kix_kdfsnef6zzf6-6 > li:before {
        content: '' counter(lst-ctn-kix_kdfsnef6zzf6-6, decimal) '. ';
      }
      .lst-kix_iuizipucx4mz-6 > li:before {
        content: '' counter(lst-ctn-kix_iuizipucx4mz-6, decimal) '. ';
      }
      .lst-kix_44m267aygyei-1 > li {
        counter-increment: lst-ctn-kix_44m267aygyei-1;
      }
      .lst-kix_k7ak3da8nwm1-2 > li {
        counter-increment: lst-ctn-kix_k7ak3da8nwm1-2;
      }
      ol.lst-kix_sbf0cuma88ql-4.start {
        counter-reset: lst-ctn-kix_sbf0cuma88ql-4 0;
      }
      .lst-kix_kdfsnef6zzf6-7 > li {
        counter-increment: lst-ctn-kix_kdfsnef6zzf6-7;
      }
      ol.lst-kix_z8mruz1jap1n-2.start {
        counter-reset: lst-ctn-kix_z8mruz1jap1n-2 0;
      }
      ol.lst-kix_6z9uvcn6pwsj-4 {
        list-style-type: none;
      }
      ol.lst-kix_6z9uvcn6pwsj-5 {
        list-style-type: none;
      }
      ol.lst-kix_6z9uvcn6pwsj-6 {
        list-style-type: none;
      }
      .lst-kix_268r56udnzju-1 > li {
        counter-increment: lst-ctn-kix_268r56udnzju-1;
      }
      ol.lst-kix_6z9uvcn6pwsj-7 {
        list-style-type: none;
      }
      ol.lst-kix_6z9uvcn6pwsj-8 {
        list-style-type: none;
      }
      ol.lst-kix_sbf0cuma88ql-1.start {
        counter-reset: lst-ctn-kix_sbf0cuma88ql-1 0;
      }
      .lst-kix_l1ljta7yxsg8-3 > li {
        counter-increment: lst-ctn-kix_l1ljta7yxsg8-3;
      }
      ol.lst-kix_z8mruz1jap1n-5.start {
        counter-reset: lst-ctn-kix_z8mruz1jap1n-5 0;
      }
      .lst-kix_44m267aygyei-1 > li:before {
        content: '' counter(lst-ctn-kix_44m267aygyei-1, lower-latin) '. ';
      }
      .lst-kix_l1ljta7yxsg8-2 > li:before {
        content: '' counter(lst-ctn-kix_l1ljta7yxsg8-2, lower-roman) '. ';
      }
      .lst-kix_nr4q6mtpzzce-4 > li {
        counter-increment: lst-ctn-kix_nr4q6mtpzzce-4;
      }
      ol.lst-kix_iuizipucx4mz-6 {
        list-style-type: none;
      }
      ol.lst-kix_iuizipucx4mz-7 {
        list-style-type: none;
      }
      ol.lst-kix_iuizipucx4mz-4 {
        list-style-type: none;
      }
      ol.lst-kix_iuizipucx4mz-5 {
        list-style-type: none;
      }
      ol.lst-kix_iuizipucx4mz-8 {
        list-style-type: none;
      }
      .lst-kix_hsxyf72adno-2 > li {
        counter-increment: lst-ctn-kix_hsxyf72adno-2;
      }
      ol.lst-kix_kdfsnef6zzf6-2.start {
        counter-reset: lst-ctn-kix_kdfsnef6zzf6-2 0;
      }
      .lst-kix_iuizipucx4mz-2 > li {
        counter-increment: lst-ctn-kix_iuizipucx4mz-2;
      }
      .lst-kix_mi687oiexhpn-3 > li {
        counter-increment: lst-ctn-kix_mi687oiexhpn-3;
      }
      ol.lst-kix_6z9uvcn6pwsj-0 {
        list-style-type: none;
      }
      ol.lst-kix_iuizipucx4mz-2 {
        list-style-type: none;
      }
      ol.lst-kix_6z9uvcn6pwsj-1 {
        list-style-type: none;
      }
      ol.lst-kix_iuizipucx4mz-3 {
        list-style-type: none;
      }
      ol.lst-kix_6z9uvcn6pwsj-2 {
        list-style-type: none;
      }
      ol.lst-kix_iuizipucx4mz-0 {
        list-style-type: none;
      }
      ol.lst-kix_6z9uvcn6pwsj-3 {
        list-style-type: none;
      }
      ol.lst-kix_iuizipucx4mz-1 {
        list-style-type: none;
      }
      ol.lst-kix_rkq69uujdjcq-1 {
        list-style-type: none;
      }
      .lst-kix_sbf0cuma88ql-7 > li:before {
        content: '' counter(lst-ctn-kix_sbf0cuma88ql-7, lower-latin) '. ';
      }
      ol.lst-kix_rkq69uujdjcq-0 {
        list-style-type: none;
      }
      ol.lst-kix_rkq69uujdjcq-3 {
        list-style-type: none;
      }
      ol.lst-kix_rkq69uujdjcq-2 {
        list-style-type: none;
      }
      ol.lst-kix_z8mruz1jap1n-4.start {
        counter-reset: lst-ctn-kix_z8mruz1jap1n-4 0;
      }
      .lst-kix_6z9uvcn6pwsj-2 > li {
        counter-increment: lst-ctn-kix_6z9uvcn6pwsj-2;
      }
      .lst-kix_rkq69uujdjcq-6 > li:before {
        content: '' counter(lst-ctn-kix_rkq69uujdjcq-6, decimal) '. ';
      }
      .lst-kix_suvp661h8f4e-3 > li {
        counter-increment: lst-ctn-kix_suvp661h8f4e-3;
      }
      ol.lst-kix_sbf0cuma88ql-2.start {
        counter-reset: lst-ctn-kix_sbf0cuma88ql-2 0;
      }
      ol.lst-kix_kdfsnef6zzf6-0.start {
        counter-reset: lst-ctn-kix_kdfsnef6zzf6-0 0;
      }
      .lst-kix_r29j7u2ve7fh-1 > li {
        counter-increment: lst-ctn-kix_r29j7u2ve7fh-1;
      }
      .lst-kix_gpdybvh95fpz-1 > li:before {
        content: '' counter(lst-ctn-kix_gpdybvh95fpz-1, lower-latin) '. ';
      }
      ol.lst-kix_rkq69uujdjcq-8 {
        list-style-type: none;
      }
      ol.lst-kix_sbf0cuma88ql-3.start {
        counter-reset: lst-ctn-kix_sbf0cuma88ql-3 0;
      }
      .lst-kix_hsxyf72adno-4 > li:before {
        content: '' counter(lst-ctn-kix_hsxyf72adno-4, lower-latin) '. ';
      }
      ol.lst-kix_rkq69uujdjcq-5 {
        list-style-type: none;
      }
      ol.lst-kix_rkq69uujdjcq-4 {
        list-style-type: none;
      }
      ol.lst-kix_z8mruz1jap1n-3.start {
        counter-reset: lst-ctn-kix_z8mruz1jap1n-3 0;
      }
      .lst-kix_2mz6pb1umrg-2 > li {
        counter-increment: lst-ctn-kix_2mz6pb1umrg-2;
      }
      ol.lst-kix_rkq69uujdjcq-7 {
        list-style-type: none;
      }
      ol.lst-kix_rkq69uujdjcq-6 {
        list-style-type: none;
      }
      .lst-kix_xukvprgjom46-8 > li {
        counter-increment: lst-ctn-kix_xukvprgjom46-8;
      }
      .lst-kix_2mz6pb1umrg-1 > li:before {
        content: '' counter(lst-ctn-kix_2mz6pb1umrg-1, lower-latin) '. ';
      }
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c4 {
        padding-top: 0pt;
        border-left-style: solid;
        padding-left: 30pt;
        padding-bottom: 12pt;
        line-height: 1.15;
        border-left-width: 0pt;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c19 {
        margin-left: 108pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 12pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c7 {
        margin-left: 72pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 12pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c5 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 12pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c3 {
        color: #151417;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 17pt;
        font-family: 'Roboto';
        font-style: normal;
      }
      .c1 {
        color: #151417;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: 'Roboto';
        font-style: normal;
      }
      .c13 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 16pt;
        font-family: 'Arial';
        font-style: normal;
      }
      .c17 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: 'Arial';
        font-style: normal;
      }
      .c21 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt;
      }
      .c20 {
        padding-top: 0pt;
        padding-bottom: 12pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c2 {
        padding-top: 0pt;
        padding-bottom: 4pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c22 {
        padding-top: 0pt;
        padding-bottom: 12pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: center;
      }
      .c14 {
        font-size: 17pt;
        font-family: 'Roboto';
        color: #151417;
        font-weight: 700;
      }
      .c8 {
        font-size: 12pt;
        font-family: 'Roboto';
        color: #151417;
        font-weight: 400;
      }
      .c6 {
        font-size: 15pt;
        font-family: 'Roboto';
        color: #151417;
        font-weight: 700;
      }
      .c9 {
        font-size: 12pt;
        font-family: 'Roboto';
        color: #151417;
        font-weight: 700;
      }
      .c15 {
        background-color: #ffffff;
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt;
      }
      .c12 {
        font-size: 12pt;
        font-family: 'Roboto';
        font-weight: 400;
      }
      .c0 {
        padding: 0;
        margin: 0;
      }
      .c10 {
        color: inherit;
        text-decoration: inherit;
      }
      .c18 {
        color: #600dff;
      }
      .c11 {
        background-color: #ffff00;
      }
      .c16 {
        height: 16pt;
      }
      .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: 'Arial';
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: 'Arial';
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        color: #000000;
        font-size: 11pt;
        font-family: 'Arial';
      }
      p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: 'Arial';
      }
      h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: 'Arial';
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: 'Arial';
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: 'Arial';
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: 'Arial';
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: 'Arial';
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: 'Arial';
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
    </style>
  </head>
  <body class="c15 doc-content">
    <p class="c22">
      <span class="c6"
        >DRE User Agreement, Purchase Agreement, Terms &amp; Conditions</span
      >
    </p>
    <p class="c20">
      <span class="c8"
        >This User Agreement, Purchase Agreement, and Terms and Conditions
        (collectively, &ldquo;</span
      ><span class="c9">Terms</span
      ><span class="c8"
        >&rdquo;) along with the chosen plan and/or document incorporating such
        Terms (&ldquo;</span
      ><span class="c9">Plan</span
      ><span class="c8"
        >&rdquo;) shall govern the relationship, and constitute a legally
        binding agreement, between DRE Data Services, LLC (&ldquo;</span
      ><span class="c9">DRE</span><span class="c8">&rdquo;, &ldquo;the </span
      ><span class="c9">Company</span><span class="c8">&rdquo;, &ldquo;</span
      ><span class="c9">we</span><span class="c8">&rdquo; or &ldquo;</span
      ><span class="c9">us</span
      ><span class="c8">&rdquo;) and you or your company (&ldquo;</span
      ><span class="c9">Customer</span
      ><span class="c8">&rdquo;) (collectively referred to as the &ldquo;</span
      ><span class="c9">parties</span
      ><span class="c8"
        >&rdquo;). By accessing, using, downloading, or installing the DRE
        Platform and/or Browser Extension, Customer acknowledges that Customer
        has read, will be bound by and will comply with these Terms and the </span
      ><span class="c8"><a href = "https://www.dredata.ai/privacy" target = "_blank">Privacy Policy</a></span
      ><span class="c1"
        >&nbsp;(which is incorporated herein by reference), both as may be
        amended at the sole discretion of DRE.
      </span>
    </p>
    <h2 class="c2" id="h.yh71wwy5daov">
      <span class="c14">1. Platform and/or Browser Extensions</span>
    </h2>
    <ol class="c0 lst-kix_k7ak3da8nwm1-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c8"
          >Subject to the Terms, DRE agrees to license access to its proprietary
          online Platform and/or Browser Extensions (collectively the
          &ldquo;</span
        ><span class="c9">Platform</span
        ><span class="c8"
          >&rdquo;) to the Customer, which Platform enables Customer to utilize
          DRE&rsquo;s service(s) (as set out in the relevant Plan) to gain
          access to predicted data points, intelligence and insights relating to
          individuals and business professionals (each predictive model, a
          &ldquo;</span
        ><span class="c9">Prediction Profile</span
        ><span class="c8"
          >&rdquo;), derived using Artificial Intelligence (&ldquo;</span
        ><span class="c9">A.I.</span
        ><span class="c8"
          >&rdquo;) using public and open-source Data from multiple sources
          (collectively, the &ldquo;</span
        ><span class="c9">Prediction Profiles and Data</span
        ><span class="c8"
          >&rdquo;) retained in DRE&rsquo;s Prediction Profiles and Database
          (&ldquo;</span
        ><span class="c9">Prediction Profiles and Database</span
        ><span class="c1">&rdquo;). </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Customer hereby acknowledges that the Prediction Profiles and Data
          available on DRE are A.I.-generated predictive models ONLY and are NOT
          statements of fact or truth about the underlying person or business
          professional that is the object of the Prediction Profile and Data.
          Rather such Prediction Profiles and Data are analytical predictions
          about the potential reality of the individual and business
          professional created using publicly available and governmental data.
          Prediction Profiles and Data should not be treated as statements of
          fact or truth. Prediction Profiles and Data are not certain, nor 100%
          accurate.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c8"
          >Customer wishes to use and access the Platform and obtain Prediction
          Profiles and Data for Customer&rsquo;s internal business uses and
          operations for one or more of the following objectives: B2B
          sales/marketing to prospective and current customers, recruitment,
          business intelligence or fraud prevention purposes (&ldquo;</span
        ><span class="c9">Purpose</span
        ><span class="c1"
          >&rdquo;). Therefore, the parties agree as follows.
        </span>
      </li>
    </ol>
    <h2 class="c2" id="h.r5l8sqhdmxq2">
      <span class="c3">2. Eligibility</span>
    </h2>
    <ol class="c0 lst-kix_suvp661h8f4e-0 start" start="1">
      <li class="c5 li-bullet-0"><span class="c1">End Users:</span></li>
    </ol>
    <ol class="c0 lst-kix_suvp661h8f4e-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c8">As used in these Terms, an &ldquo;</span
        ><span class="c9">End User</span
        ><span class="c1"
          >&rdquo; means an individual user designated by Customer as a user of
          the Platform (up to the number of End Users provided for in the
          applicable Plan). Customer is solely responsible for granting End
          Users access to the Platform, including adding and removing access
          rights of End Users. Customer shall be responsible for compliance with
          these Terms by all End Users.
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c8"
          >If you are using DRE as a member of an organization or using your
          organization&rsquo;s email domain (thereby representing yourself as a
          member of the organization) (&ldquo;</span
        ><span class="c9">You</span
        ><span class="c1"
          >&rdquo;), You represent, warrant, and covenant that You are
          authorized to enter this agreement on behalf of such business
          organization, notwithstanding whether You or Your company make payment
          of the Fees, and such business organization shall be a party to and
          bound by the terms hereof, and will thereby be the Customer, and that
          DRE may share Your email address and plan information with an
          authorized agent of the company upon request in order for them to
          administer the account for the company. For the avoidance of doubt,
          use of the Platform is not permitted for anyone under the age of 18.
          To the extent that You or Your employer have entered into another
          written agreement with DRE, in respect of Your account, that contains
          terms that directly conflict with any terms of these Terms, then the
          conflicted terms set forth in such other agreement will control.</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >If Customer designates additional persons as End Users beyond the
          number subscribed for in the applicable Plan, such designation may be
          deemed by DRE as Customer&rsquo;s subscription to such additional
          number of End Users. In the event of such, DRE may charge Customer a
          corresponding additional Fee equal to the prevailing per-End User rate
          multiplied by the period from the date of designation until the end of
          the then-current Term.
        </span>
      </li>
    </ol>
    <ol class="c0 lst-kix_suvp661h8f4e-0" start="2">
      <li class="c5 li-bullet-0"><span class="c1">Account:</span></li>
    </ol>
    <ol class="c0 lst-kix_suvp661h8f4e-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c8"
          >Each End User will gain access to the Platform by creating an account
          and providing a unique username and password (&ldquo;</span
        ><span class="c9">Login Credentials</span
        ><span class="c1"
          >&rdquo;). To obtain Login Credentials, Customer acknowledges that End
          Users must provide DRE with certain identifying information (including
          names and business/sole proprietorship email addresses), which
          information must be accurate and complete. Customer also agrees to
          inform us immediately of any unauthorized use of Customer&rsquo;s or
          any End Users&rsquo; account or any change in anny Login Credentials.
          By accepting the Terms, Customer declares that it is responsible for
          all activities taken under its account.</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >Such Login Credentials may not be shared, must be kept secret and
          secure, and may not under any circumstances be used by anyone who is
          not an End User. An End User is not permitted to re-assign, transfer,
          or sublicense their account to any third party. If any End
          User&rsquo;s Login Credentials are disclosed to any person who is not
          an End User but who is an employee of Customer, this disclosure will
          constitute Customer&rsquo;s subscription as of the time of such
          disclosure to the number of additional End Users equal to the number
          of persons to whom such credentials were disclosed. DRE will not be
          liable for any damages or losses caused by someone using
          Customer&rsquo;s account without permission.
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >Once Customer creates an account, each End User, will automatically
          join our mailing list. Each End User can choose to remove their email
          addresses from that mailing list by choosing the
          &ldquo;unsubscribe&rdquo; link at the bottom of any email
          communication DRE sends.
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >If the employment of any End User that was in effect as of the date
          such person was designated as an End User terminates, such
          person&rsquo;s authorization to access the Platform shall be revoked
          automatically without any further action by DRE. Customer may either
          elect to transfer the End User license to a different user and
          corresponding email address with the domain held by the Customer, or
          terminate the End User&rsquo;s account. Please note that there will be
          no refunds in the event of the aforementioned.</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_suvp661h8f4e-0" start="3">
      <li class="c5 li-bullet-0">
        <span class="c8"
          >Any individual who accesses the Platform under these Terms must be
          provisioned as an End User. As used in these Terms, &ldquo;</span
        ><span class="c9">Credit</span
        ><span class="c1"
          >&rdquo; means a non-exclusive, non-sublicensable, non-transferable,
          worldwide, revocable and limited right to access one (1) Prediction
          Profile throughout the Term. For the avoidance of doubt, Credits
          purchased by Customer and not used by the end of the applicable Term
          shall expire at the end of such Term and not roll over to any renewal
          term. Any additional Credits may be purchased on an ad hoc basis for
          an additional fee. The content of any particular Prediction Profile
          (including the available Prediction Profiles and Data points or the
          accuracy of any particular Prediction Profiles and Data point) is
          provided on an as-is basis, and once a Credit is used, the use cannot
          be revoked, and no replacement Credits or refunds will be provided
          based on the amount or quality of a particular record.</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.2x50qk9q1ykq">
      <span class="c3">3. Software as a Service. </span>
    </h2>
    <ol class="c0 lst-kix_iuizipucx4mz-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c8"
          >Subject to these Terms, DRE grants to Customer a non-exclusive,
          non-sublicensable, non-transferable, worldwide, revocable and limited
          right throughout the applicable subscription period (&ldquo;</span
        ><span class="c9">Term</span
        ><span class="c1"
          >&rdquo;) to use the Platform for the Purpose to such number of End
          Users and limited to such number of Credits (as defined below) by
          which the Customer can receive Prediction Profiles and Data through
          the use of the Platform.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Following termination of these Terms subject to the restrictions
          outlined in these Terms, Customer may only continue to use Prediction
          Profiles and Data that was legally obtained from DRE under these Terms
          and that was stored on Customer&rsquo;s systems during the Term.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >The Platform is provided subject to certain usage limits outlined in
          the Supplementary Terms and Acceptable Use Policy incorporated
          therein.
        </span>
      </li>
    </ol>
    <h2 class="c2" id="h.sgt24amrqvj"><span class="c14">4. Support. </span></h2>
    <ol class="c0 lst-kix_2mz6pb1umrg-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >DRE will provide different tools to help users address frequently
          asked questions and additional technical and general support issues.
          In addition, DRE tests frequent updates, maintenance, error shooting
          and additional means in order to improve the Platform. DRE does not
          undertake to keep operating any of the above, and reserves the right
          to change, reduce, limit or terminate its maintenance and support
          efforts.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c8"
          >DRE may access, collect, and use any information from or relating to
          Customer and Customer&rsquo;s use of the Platform (&ldquo;</span
        ><span class="c9">Related Information</span
        ><span class="c1"
          >&rdquo;) for customer and technical support, for regulatory and
          third-party compliance purposes, to protect and enforce DRE&rsquo;s
          rights, to monitor compliance with and investigate potential breaches
          of these Terms, and to recommend additional products or services. DRE
          may share this information with its partners or affiliates for the
          same purposes. Customer grants DRE and DRE affiliates the perpetual
          right to use Related Information and any feedback provided by Customer
          for purposes such as to test, develop, improve, and enhance
          DRE&rsquo;s products and services, and to create and own derivative
          works based on Related Information and feedback, so long as neither
          Customer, any End User nor any other individual is identifiable as the
          source of such information.</span
        >
      </li>
    </ol>
    <h2 class="c2 c16" id="h.yqkdm934wl9e"><span class="c3"></span></h2>
    <h2 class="c2" id="h.r95wlmit6znm">
      <span class="c3">5. Changes to the Terms. </span>
    </h2>
    <ol class="c0 lst-kix_gpdybvh95fpz-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c8"
          >We reserve the right to modify, change to, suspend or discontinue,
          temporarily or permanently the Platform or any Features (the
          &ldquo;</span
        ><span class="c9">Changes</span
        ><span class="c1"
          >&rdquo;) with or without notice with no liability, at any time and
          for any reason, including without limitation any Changes which may be
          done automatically for the purpose of improving, enhancing or
          de-bugging versions of the Platform or other aspects thereof. We will
          notify Customer of any adverse material changes via the Site or
          Platform (including without limitation by sending an email
          notification or by way of on-screen pop-ups) by any other form prior
          to those Changes becoming effective. Otherwise, any other,
          non-material change, will be effective upon the &ldquo;last
          updated&rdquo; date stated at the top of these Terms.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Customer&rsquo;s continued use of the Platform, following any such
          Changes, constitutes complete and irrevocable acceptance of such
          Changes and the revised Terms. If Customer does not agree with the
          new/modified Terms, Customer&rsquo;s sole remedy is to discontinue
          using the Platform. For the avoidance of doubt, the then-current
          version of these Terms will supersede all earlier versions.
        </span>
      </li>
    </ol>
    <h2 class="c2" id="h.dimuua6pj36v">
      <span class="c3">6. Intellectual Property Rights. </span>
    </h2>
    <ol class="c0 lst-kix_r29j7u2ve7fh-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >As between DRE and Customer, all intellectual property rights in the
          Prediction Profiles, underlying Prediction Profiles and Data, the
          Prediction Profiles and Database, the Platform, and any part thereof,
          and any and all derivatives, changes, and improvements thereof lie
          exclusively with DRE. DRE owns the layout, user interfaces,
          appearance, trademarks, and other intellectual property comprising the
          Platform.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c8"
          >If Customer provides DRE with suggestions or feedback, which may
          concern, but are not limited to Prediction Profiles and Data, the
          Prediction Profiles and Database or Platform and any customizations,
          features, improvements, modifications, corrections, enhancements,
          derivatives or extensions thereto (&ldquo;</span
        ><span class="c9">Customer Suggestions</span
        ><span class="c1"
          >&rdquo;), such Customer Suggestions shall be deemed the sole property
          of DRE (except to the extent that such Customer Suggestions include
          Customer Prediction Profiles and Data, which shall remain
          Customer&rsquo;s property), and Customer hereby irrevocably transfers
          and assigns to DRE all intellectual property or proprietary rights in
          and to such Customer Suggestions and, to the maximum extent permitted
          by law, waives any and all derivative rights and copyrights to
          Customer suggestions and hereby irrevocably waives the right to claim
          or bring proceedings in connection with such rights.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Customer shall not use any trade name, trademark, service mark,
          brand, or logo of DRE or CommonGround, or any link to a DRE website,
          for any purpose other than in connection herewith, including, without
          limitation, in any communications using Prediction Profiles and Data
          from DRE, without DRE&rsquo;s prior written consent.</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.aqsv1z9akwui">
      <span class="c3">7. Customer Obligations. </span>
    </h2>
    <ol class="c0 lst-kix_nr4q6mtpzzce-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Customer shall not, and shall not permit any third party to:
        </span>
      </li>
    </ol>
    <ol class="c0 lst-kix_nr4q6mtpzzce-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c1"
          >interfere or attempt to interfere with the Platform, infiltrate,
          hack, reverse engineer, decompile, or disassemble the Platform or
          Prediction Profiles and Database or use the Prediction Profiles and
          Data to compile similar Prediction Profiles and Databases, Platforms,
          or services or compete with DRE;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >use a robot or other automated means to access or scrape the Platform
          or extract Prediction Profiles and Data other than such tools as are
          explicitly contemplated and provided via the Platform (e.g.,
          integrations allowing the export of Prediction Profiles and Data into
          Customer&rsquo;s CRM);</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >override or circumvent, or attempt to override or circumvent, any
          security feature, control, or use limits of the Platform;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >use the Prediction Profiles, or underlying Prediction Profiles and
          Data for any reason or in any way that is prohibited by the laws and
          regulations applicable to the Customer;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c9"
          >publish, distribute, share, sell, lease, transfer, or otherwise make
          the Prediction Profiles, Prediction Profiles and Data available to any
          third person or entity.</span
        ><span class="c1"
          >&nbsp;Customer will use its best efforts to prevent the misuse or
          unauthorized use of the Prediction Profiles and Prediction Profiles
          and Data by any third person or entity;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >purport to or factually sub-license its right to access and use the
          Platform or provide remote access to the Platform or Platform to or
          for the benefit of any third party or any unauthorized person;</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >permit the use of a single End User license by more than one
          individual;</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >use the Platform to determine a consumer&rsquo;s eligibility for
          credit or insurance for personal, family, or household purposes,
          employment or governmental license or benefit, or any purpose covered
          by the Fair Credit Reporting Act or similar legislation in any other
          relevant jurisdiction;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >violate third parties&rsquo; rights to privacy and other rights;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >use the Platform in any way, or use the Prediction Profiles and
          Prediction Profiles and Data for any purpose, that can be deemed to be
          stalking, offensive, abusive, defamatory, fraudulent, or deceptive,
          threatening, advocating harassment or intimidation;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >disparage or misrepresent the capabilities or reputation of DRE; and
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >disclose the source of Prediction Profiles and Prediction Profiles
          and Data provided by DRE unless obligated to by law.
        </span>
      </li>
    </ol>
    <ol class="c0 lst-kix_nr4q6mtpzzce-0" start="2">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >DRE requires that Customers agree to and comply with all regulations
          potentially applicable to personal or private data, data protection,
          security, marketing, or privacy-related laws, statutes, directives, or
          regulations, including but not limited to:
        </span>
      </li>
    </ol>
    <ol class="c0 lst-kix_nr4q6mtpzzce-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c8"
          >California Consumer Privacy Act of 2018 and the California Consumer
          Privacy Act Regulations together with any amending or replacement
          legislation (&ldquo;</span
        ><span class="c9">CCPA</span><span class="c1">&rdquo;);</span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c8">the U.S. Federal CAN-SPAM Act of 2003 (&ldquo;</span
        ><span class="c9">CAN-SPAM</span
        ><span class="c1"
          >&rdquo;) together with any amending or replacement legislation; and
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >all other equivalent laws and regulations in any relevant
          jurisdiction relating to Personal Data and privacy (together with
          CCPA, CAN-SPAM, and others)</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_nr4q6mtpzzce-0" start="3">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >In the case of any violation of the obligations in this Section by
          Customer, DRE may immediately suspend Customer&rsquo;s access to the
          Platform. In addition to any other damages it may be entitled to under
          the law, should Customer or any person using the Platform through
          Customer&rsquo;s account intentionally breach any material term of
          these Terms, DRE shall have a right to seek injunctive relief,
          including attorneys&rsquo; fees and court costs.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c8"
          >If DRE informs Customer that an individual has made a request that
          their Personal Prediction Profiles and Data be removed from
          DRE&rsquo;s Platform, Customer shall remove such Prediction Profiles
          and Data from their possession without undue delay, unless the
          Customer has another valid legal basis under applicable law to process
          such Prediction Profiles and Data. Customer confirms that it will at
          all times maintain an active End User of &lsquo;administrator&rsquo;
          status (the &ldquo;</span
        ><span class="c9">Admin</span
        ><span class="c1"
          >&rdquo;) whose responsibility it will be to ensure compliance with
          the aforementioned requirement.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >DRE is not a &ldquo;Data Processor&rdquo; or &ldquo;Data
          Controller&rdquo; as defined by law for the Prediction Profiles and
          Data provided by DRE.</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.uo3adbbfbpq">
      <span class="c3">8. Confidentiality. </span>
    </h2>
    <ol class="c0 lst-kix_hr7lu2216wfv-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c8">Each party (each a &ldquo;</span
        ><span class="c9">receiving party</span
        ><span class="c8"
          >&rdquo;) may have access to certain non-public proprietary,
          confidential information or Prediction Profiles and Data of the other
          party (each a &ldquo;</span
        ><span class="c9">disclosing party</span
        ><span class="c8"
          >&rdquo;), regardless of how it is furnished, which a reasonable
          person or entity should reasonably believe is proprietary,
          confidential, or competitively sensitive (together, the &ldquo;</span
        ><span class="c9">Confidential Information</span
        ><span class="c1"
          >&ldquo;). The fact that the parties entered into these Terms, and the
          contents of these Terms, shall be deemed Confidential Information.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Confidential Information shall exclude any information that (i) is
          now or subsequently becomes generally available in the public domain
          through no fault or breach on the part of receiving party; (ii) the
          receiving party can demonstrate in its records to have had rightfully
          in its possession before disclosure of the Confidential Information by
          the disclosing party; (iii) receiving party rightfully obtains from a
          third party who has the right to transfer or disclose it, without
          default or breach of these Terms; or (iv) the receiving party can
          demonstrate in its records to have independently developed, without
          breach of these Terms or any use of or reference to the Confidential
          Information.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1">The receiving party agrees: </span>
      </li>
    </ol>
    <ol class="c0 lst-kix_hr7lu2216wfv-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c1"
          >not to disclose the disclosing party&rsquo;s Confidential Information
          to any third parties other than to its directors, officers, employees,
          advisors, or consultants (collectively, the
          &ldquo;Representatives&rdquo;) on a strict &ldquo;need to know&rdquo;
          basis provided that such Representatives are bound by written
          agreements to comply with confidentiality obligations as protective as
          those herein;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >inform the disclosing party immediately on becoming aware or
          suspecting that an unauthorized party has become aware of or gained
          access to the Confidential Information;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >not to use or reproduce, or knowingly allow anyone else to reproduce,
          directly or indirectly, any of the disclosing party&rsquo;s
          Confidential Information for any purposes except to carry out its
          rights and responsibilities under these Terms, unless otherwise
          provided for in these Terms; and
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >to keep the disclosing party&rsquo;s Confidential Information
          confidential using at least the same degree of care it uses to protect
          its own confidential information, which shall in any event not be less
          than a reasonable degree of care.
        </span>
      </li>
    </ol>
    <ol class="c0 lst-kix_hr7lu2216wfv-0" start="4">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Notwithstanding the foregoing, if the receiving party is required by
          any applicable law, rule, or regulation, to disclose the disclosing
          party&rsquo;s Confidential Information, then before such disclosure,
          the receiving party will give written notice (to the extent
          permissible by applicable law) to the disclosing party so that it may
          seek appropriate relief and will disclose Confidential Information to
          the minimum extent required.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >The Customer shall not ridicule, defame, mock, disparage, stalk,
          intimidate, threaten, harass, harm, advocate, incite harassment, or
          abuse another person, group, DRE employees, including DRE&rsquo;s
          customer service representatives, hatefully, racially, religiously,
          ethnically or in any other manner.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c8"
          >Customer acknowledges that the Prediction Profiles and Data obtained
          using Credits are for its own use only and that the disclosure to a
          third party of Prediction Profiles, permitting a third party to access
          any Prediction Profile records through use of Login Credentials or the
          use of the Prediction Profiles for the benefit of or on behalf of any
          third party (&ldquo;</span
        ><span class="c9">Covered Breach</span
        ><span class="c8"
          >&rdquo;), will cause damage to DRE in an amount that is difficult to
          quantify. In order to avoid the time and expense of quantifying the
          direct damages caused by a Covered Breach, </span
        ><span class="c9"
          >if Customer, negligently or intentionally, commits a Covered Breach,
          then DRE shall be entitled to liquidated damages from Customer in the
          amount equal to $2.00 per Prediction Profile record that is so
          disclosed, used, or made available per third party recipient or
          beneficiary, as applicable</span
        ><span class="c1"
          >. For the avoidance of doubt, such liquidated damages are cumulative
          with any other damages that may be caused by such Covered
          Breach.</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.3aydbepks80v">
      <span class="c3">9. Warranties. </span>
    </h2>
    <ol class="c0 lst-kix_kdfsnef6zzf6-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Each party represents and warrants that (i) these Terms, constitute a
          legal, valid and binding obligation, enforceable against it in
          accordance with these Terms; and (ii) it is validly existing and in
          good standing, and is qualified to do business; (iii) the performance
          of these Terms, the purchase of the Plan, and the use of the Platform
          have been properly authorized..
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1">Customer represents and warrants to DRE that:</span>
      </li>
    </ol>
    <ol class="c0 lst-kix_kdfsnef6zzf6-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c1"
          >it is not a Data broker and is not entering into these Terms in order
          to resell any Data to third parties;</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >it shall not engage in any conduct that brings or is likely to bring
          the reputation of DRE into disrepute;</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >it shall ensure that all information to be provided by it to DRE in
          connection with these Terms and the performance of Customer&rsquo;s
          obligations hereunder is and shall remain true and correct in all
          respects;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >it is not named on any U.S. or other government restricted-party
          list, and will not permit any End-User to access or use the Platform
          for any prohibited end use;</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >it shall not take any action (or, as the case may be, omit to take
          any action) that would directly or indirectly infringe upon or
          misappropriate the intellectual property of DRE or its affiliates;
          and</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >its execution of these Terms and its use of DRE Platform and/or
          Prediction Profiles and Data will not violate any applicable law, rule
          or regulation.</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.6yzxbu92r77a">
      <span class="c3">10. Indemnification. </span>
    </h2>
    <ol class="c0 lst-kix_z8mruz1jap1n-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Customer agrees to indemnify and hold harmless DRE and its
          affiliates, suppliers, partners, officers, agents, and employees from
          and against any claim, costs, demand, losses, damages or expenses
          (including reasonable attorney&rsquo;s fees) arising from:</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_z8mruz1jap1n-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c1"
          >Customer&rsquo;s sending of any information, messages, or materials
          to any Prediction Profile (including, but not limited to, through
          e-mail, mail, text, in person solicitation, or other means) in
          violation of any law or the rights of any third party;</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1">Customer&rsquo;s violation of these Terms;</span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >any Prediction Profiles and Data provided by Customer to DRE;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >the undue or unauthorized interference by Customer or any of its
          representatives with the Platform, Prediction Profiles and Data, or
          Prediction Profiles and Database in any respect;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >use of any Prediction Profiles and Data or access to the Platform in
          violation of any law or by any third party to whom Customer has
          granted access (including access obtained by such third party through
          the use of the login credentials assigned to each End User);</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >the negligence, misconduct, or any fraudulent act or omission of
          Customer and/or its representative in carrying out or failing to carry
          out its obligations under these Terms.</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_z8mruz1jap1n-0" start="2">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >In order to claim indemnification under these Terms, the indemnified
          party shall provide the indemnifying party with: (i) prompt written
          notice of the claim subject to the indemnification; (ii) the right to
          control and direct the investigation, defense, and settlement of such
          claim (except that the indemnifying party may not settle any claim or
          proceeding unless it unconditionally releases the indemnified party of
          all liability); and (iii) reasonable cooperation in connection with
          any related investigation, defense, and settlement.</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.qp8phvwcuezm">
      <span class="c3">11. Disclaimer of Warranties. </span>
    </h2>
    <ol class="c0 lst-kix_l1ljta7yxsg8-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c8">DRE</span
        ><span class="c1"
          >&nbsp;PROVIDES ACCESS TO THE PLATFORM AND PREDICTION PROFILES AND
          DATA TO CUSTOMER ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS
          AVAILABLE&rdquo; BASIS, WITHOUT WARRANTIES OF ANY KIND, WHETHER
          EXPRESS OR IMPLIED, INCLUDING THE WARRANTY OF TITLE, MERCHANTABILITY,
          NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE OR ACCURACY.
          CUSTOMER DOES NOT HAVE THE RIGHT TO MAKE OR PASS ON ANY REPRESENTATION
          OR WARRANTY ON BEHALF OF THE OTHER PARTY TO ANY THIRD PARTY.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >CUSTOMER UNDERSTANDS THAT PREDICTION PROFILES ARE MERELY MODELED
          PREDICTIONS ABOUT AN INDIVIDUAL OR BUSINESS PERSON. PREDICTION
          PROFILES CAN PROVIDE CONTEXT BASED ON ANY LIKELIHOOD OR RELATION TO
          REALITY ASSIGNED SUBJECTIVELY TO THE PREDICTION PROFILE BY THE USER,
          BUT PREDICTION PROFILES SHOULD BE RELIED ON AS STATEMENTS OF ACTUAL
          FACT OR TRUTH.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN, DRE DOES NOT WARRANT
          THAT THE PLATFORM, PREDICTION PROFILES, AND DATA, OR ANY SERVICE
          RELATED THERETO (i) WILL BE DELIVERED OR PERFORMED ERROR-FREE OR
          WITHOUT INTERRUPTION; OR (ii) WILL MEET CUSTOMER&rsquo;S REQUIREMENTS;
          OR (iii) MAY BE USED OR RELIED UPON BY THE CUSTOMER OR ANY OTHER PARTY
          TO COMPLY WITH ANY LAW, RULE, REGULATION, INDUSTRY STANDARD OR POLICY,
          NOR THAT THE Platform WILL RENDER CUSTOMER NOT ANY OTHER PARTY
          COMPLIANT WITH ANY LAW, RULE, REGULATION, INDUSTRY STANDARD OR POLICY.
          IF AND TO THE EXTENT THAT CUSTOMER USES THE Platform WITH THE
          INTENTION OF OR FOR THE PURPOSE OF COMPLYING WITH ANY LAW, RULE,
          REGULATION, INDUSTRY STANDARD, OR POLICY, CUSTOMER ACKNOWLEDGES AND
          AGREES THAT THE PLATFORM IS, IN THAT REGARD, PROVIDED &ldquo;AS
          IS,&rdquo; AND CUSTOMER ASSUMES FULL RESPONSIBILITY FOR ITS
          COMPLIANCE. CUSTOMER AGREES THAT DRE SHALL HAVE NO LIABILITY TO
          CUSTOMER FOR CUSTOMER&rsquo;S USE OF OR RELIANCE ON THE Platform FOR
          SUCH PURPOSES. ANY PREDICTION PROFILE AND DATA OBTAINED USING THE
          Platform ARE OBTAINED AT CUSTOMER&rsquo;S OWN RISK AND DISCRETION AND
          DRE SHALL NOT BE RESPONSIBLE FOR ANY DAMAGE CAUSED TO CUSTOMER&rsquo;S
          COMPUTER OR TO CUSTOMER BY ANY BUGS, VIRUSES, TROJAN HORSES, OR OTHER
          DESTRUCTIVE CODE RESULTING FROM USE OF THE PLATFORM OR ANY PREDICTION
          PROFILES AND DATA OBTAINED THEREFROM.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >DRE OPERATES AS AN INTERACTIVE COMPUTER SERVICE. MOST OF THE
          INFORMATION IT PROVIDES IS NOT CREATED DIRECTLY BY DRE BUT IS
          RETRIEVED FROM THE WEB OR THE CONTRIBUTION OF RELEVANT INFORMATION
          FROM OTHER USERS AND BUSINESS PARTNERS AND AS SUCH, DRE SHALL NOT HAVE
          ANY LEGAL LIABILITY OR RESPONSIBILITY FOR THE ACCURACY OR COMPLETENESS
          OF ANY INFORMATION PROVIDED THROUGH USE OF THE PLAFORM except as
          expressly specified herein and only to the extent so specified.
          CUSTOMER ASSUMES ALL RESPONSIBILITY AND RISK FOR CUSTOMER&rsquo;S USE
          OF THE Platform, AND PREDICTION PROFILES AND DATA.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Although DRE will do its best efforts to provide accurate Prediction
          Profiles and Data, DRE, and its sub-processors providers shall have no
          legal liability or responsibility for the accuracy or completeness of
          any information used or disclosed on the Platform, including
          information that pertains to an individual&rsquo;s geographic
          location, and Customer acknowledges that such information could
          pertain to individuals in jurisdictions regarding which it expressed
          no interest.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >For the avoidance of doubt, DRE will not be responsible for instances
          that are outside of its control, which include:</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_l1ljta7yxsg8-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c1"
          >any actions or inactions of Customer that result in a loss of or
          interruption to the Platform; and</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >any events or outages affecting the provision of the Platform by DRE
          to Customer that are outside of the control and/or responsibility of
          DRE.</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_l1ljta7yxsg8-0" start="7">
      <li class="c5 li-bullet-0">
        <span class="c8"
          >DRE&rsquo;s profiles may reference links to other websites (the
          &ldquo;</span
        ><span class="c9">Linked Sites</span
        ><span class="c1"
          >&rdquo;). DRE neither endorses nor is affiliated with the Linked
          Sites and is not responsible for any of the content on the Linked
          Sites or the use thereof.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c8">Used herein, a &ldquo;</span
        ><span class="c9">Third Party Product</span
        ><span class="c1"
          >&rdquo; is any third-party product, application, service, software,
          network, system, directory, website, Prediction Profiles and Database
          and/or information obtained separately which links to the Platform, or
          which Customer may connect to or enable in conjunction with the
          Platform, including, without limitation, Third Party Products which
          may be integrated directly into the Platform or any Features (if
          applicable) by Customer or at Customer&rsquo;s direction. If Customer
          chooses to share Customer Prediction Profiles and Data in any manner,
          including through a Third Party Product or integration Customer is
          solely responsible for what that third party may do with Customer
          Prediction Profiles and Data, and Customer&rsquo;s relationship with
          that third party. Likewise, Customer is solely responsible for any of
          the effects a Third Party Product may have on Customer Prediction
          Profiles and Data, including deleting or corrupting Customer
          Prediction Profiles and Data. Except for these sub-processors,
          Customer acknowledges that DRE is not responsible for the disclosure
          of Customer Prediction Profiles and Data by Customer or
          Customer&rsquo;s agents (including Customer&rsquo;s End Users) to any
          third parties or the effects of any Third Party Product on Customer
          Prediction Profiles and Data.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Certain AI Features may provide output based on Customer Prediction
          Profiles and Data input by Customer itself. Customer acknowledges and
          agrees that such AI Features may provide output that is inaccurate or
          false, and it is Customer&rsquo;s responsibility to validate such
          Prediction Profiles and Data.</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.1ltut35hvu53">
      <span class="c3">12. Limitation of Liability. </span>
    </h2>
    <ol class="c0 lst-kix_mi687oiexhpn-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >IN NO EVENT WILL DRE BE LIABLE FOR ANY PUNITIVE, MULTIPLE LOST
          PROFITS, LOST BUSINESS, LOSS OF USE, LOSS OF DATA, LOST OR CORRUPTED
          DATA DAMAGES, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR
          FOR SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES, HOWEVER
          CAUSED, AND ON ANY THEORY OF LIABILITY, WHETHER FOR BREACH OF THESE
          TERMS, THE DPA, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), OR
          OTHERWISE, WHETHER OR NOT DRE HAS BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
          DRE&rsquo;S LIABILITY AND THE LIABILITY OF EACH OF ITS OFFICERS,
          DIRECTORS, INVESTORS, EMPLOYEES, AGENTS, ADVERTISERS, LICENSORS,
          SUPPLIERS, SERVICE PROVIDERS AND OTHER CONTRACTORS TO CUSTOMER OR ANY
          THIRD PARTIES UNDER ANY CIRCUMSTANCE IS LIMITED TO A MAXIMUM AMOUNT OF
          USD100.</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.pk65k814vdwg">
      <span class="c3">13. Fees; Tax. </span>
    </h2>
    <ol class="c0 lst-kix_w6v1rx36eaud-0 start" start="1">
      <li class="c5 li-bullet-0"><span class="c1">Fees</span></li>
    </ol>
    <ol class="c0 lst-kix_w6v1rx36eaud-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c8"
          >Certain Plans and Features may be subject to payment of particular
          fees (&ldquo;</span
        ><span class="c9">Fee(s)</span
        ><span class="c8"
          >&rdquo;), as determined by DRE in its sole discretion</span
        ><span class="c8">&nbsp;(&ldquo;</span><span class="c9">Paid Plan</span
        ><span class="c8">&rdquo;). </span
        ><span class="c1"
          >DRE will provide notice of such Fees then in effect in relation to
          such Plan on sign-up therefore. All Fees are due and payable in
          advance and shall be paid by credit card in accordance with
          DRE&rsquo;s provided instructions. All Fees shall be deemed to be in
          U.S. Dollars.</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >DRE reserves the right to change its Fees at any time, upon notice to
          Customer if such change may affect Customer&rsquo;s existing Plan. If
          Customer received a discount or other promotional offer, DRE shall
          have the right to automatically and without notice renew
          Customer&rsquo;s Plan at the full, and then-applicable Fee.
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >For the avoidance of doubt, all payments under these Terms are
          non-refundable.</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_w6v1rx36eaud-0" start="2">
      <li class="c5 li-bullet-0"><span class="c1">Taxes</span></li>
    </ol>
    <ol class="c0 lst-kix_w6v1rx36eaud-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c8"
          >To the extent permitted by law (and unless specified otherwise by DRE
          in writing), all Fees are exclusive of all taxes (including any value
          added tax, sales tax, goods and services tax, etc.), levies or duties
          imposed by taxing authorities, or other similar governmental chargers,
          howsoever designated, except for taxes based on the net income of DRE
          (&ldquo;</span
        ><span class="c9">Taxes</span
        ><span class="c1"
          >&rdquo;), and Customer shall be responsible for payment of all
          applicable Taxes relating to Customer&rsquo;s use of the Platform, or
          to any payments or purchases made by Customer. DRE is not responsible
          for any such additional fees or costs. Unless otherwise prior agreed
          to in writing between the parties, if under applicable law taxes are
          required to be withheld, Customer shall pay DRE an amount such that
          the net amount after withholding of taxes shall equal the amount that
          would have been otherwise payable under these Terms.</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_w6v1rx36eaud-0" start="3">
      <li class="c5 li-bullet-0"><span class="c1">Late Payment </span></li>
    </ol>
    <ol class="c0 lst-kix_w6v1rx36eaud-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c1"
          >If Customer fails to pay any Fees due to DRE by their due date
          (according to the chosen Plan) DRE has the right, without prejudice to
          any other rights that it has in law, to implement, at its sole
          discretion and on notice to Customer, a Suspension or Limitation
          process as set below. Any amounts payable to DRE by Customer that
          remain unpaid after the due date shall be subject to a late charge
          equal to 3% of the invoice amount per month from the due date until
          such amount is paid. Customer shall pay all collection fees, including
          legal expenses, with respect to the collection of late payments.</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_w6v1rx36eaud-0" start="4">
      <li class="c5 li-bullet-0"><span class="c1">Chargebacks</span></li>
    </ol>
    <ol class="c0 lst-kix_w6v1rx36eaud-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c1"
          >DRE reserves its right to dispute any chargeback received from
          Customer, including by providing the relevant credit card company or
          financial institution with any information and documentation proving
          that Customer is responsible for authorizing such chargeback and
          continued to make use of and access the Platform thereafter.
          Customer&rsquo;s use of the Platform will not resume until Customer
          re-subscribes for a new Plan, and pay any applicable Fees in full,
          including any fees and expenses incurred by DRE and/or any Third Party
          Services for each chargeback , if applicable (including Fees for the
          Platform provided prior to the chargeback, handling and processing
          charges and fees incurred by the payment processor).</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.sbdhmhtop085">
      <span class="c3">14. Prediction Profiles and Data Cleansing.</span>
    </h2>
    <ol class="c0 lst-kix_sbf0cuma88ql-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Customer acknowledges that through the use of Platform or otherwise,
          Customer may have the opportunity to transmit Prediction Profile
          information to DRE for purposes of matching, cleansing, or updating
          records with information from DRE&rsquo;s Prediction Profiles and
          Database. In the event such information is transmitted to DRE, DRE
          will make commercially reasonable efforts consistent with its research
          protocols and priorities, to respond to match and clean and append
          requests by researching and/or verifying Prediction Profile
          information so submitted and supplementing the Prediction Profiles and
          Database with information DRE is able to verify. DRE may also use
          email deliverability Prediction Profiles and Data (such as email
          &ldquo;bounce&rdquo; Prediction Profiles and Data) accessible through
          Customer&rsquo;s use of the Platform to improve the Prediction
          Profiles and Database by, for example, eliminating invalid email
          addresses from the Prediction Profiles and Data.</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.e9p23httg1mp">
      <span class="c3">15. Suspension and Limitation Processes.</span>
    </h2>
    <ol class="c0 lst-kix_6z9uvcn6pwsj-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >If Customer commits any act or omission which, in the opinion of DRE,
          is or could be prejudicial to its interests or subject DRE to
          liability; uses the Platform in a manner that poses a security risk to
          or may adversely affect the Platform; or engages in or is suspected of
          engaging in bribery, fraud, or otherwise corrupt or illegal actions or
          omissions, DRE has the right, without prejudice to any other rights
          that it has in law, to implement the following Suspension
          process:</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_6z9uvcn6pwsj-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c8"
          >unilaterally suspend or discontinue the provision of the Platform to
          Customer (&ldquo;</span
        ><span class="c9">Suspension</span
        ><span class="c8">&rdquo;) for 14 (fourteen) days (&ldquo;</span
        ><span class="c9">Suspension Period</span
        ><span class="c1"
          >&rdquo;) or such other reasonable time as agreed between the
          parties;</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_6z9uvcn6pwsj-2 start" start="1">
      <li class="c19 li-bullet-0">
        <span class="c1"
          >If Customer remedies the cause of the Suspension during the
          Suspension Period, the Suspension will immediately, or as soon as
          practicably possible thereafter, be lifted;</span
        >
      </li>
      <li class="c19 li-bullet-0">
        <span class="c1"
          >If Customer fails to remedy the cause of the Suspension within the
          Suspension Period, DRE reserves the right to terminate these Terms
          with immediate effect; or</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_6z9uvcn6pwsj-1" start="2">
      <li class="c7 li-bullet-0">
        <span class="c1"
          >immediately terminate these Terms, and in either case, be entitled to
          claim from Customer whatever direct damages or loss it has
          su&#64256;ered.</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_6z9uvcn6pwsj-0" start="2">
      <li class="c5 li-bullet-0">
        <span class="c8"
          >DRE may, in its sole discretion and based on its internal compliance
          processes, use technological means to place reasonable use limits on
          Customer&rsquo;s daily or monthly consumption of Credits or access to
          the Platform as it deems appropriate (&ldquo;</span
        ><span class="c9">Limitation</span
        ><span class="c1"
          >&rdquo;) to protect against any potential or suspected fraud, hacking
          attacks, misuse of the Prediction Profiles and Data, excessive use,
          including excessive downloads or screen views that indicate a
          violation of these Terms (such as sharing with third parties or
          attempting to circumvent limitations to Credits (if applicable), and
          the like. Customer may, on written notice to DRE with justification
          therefor, request that the Limitation be removed. DRE, in its sole
          discretion, may then decide to remove, modify, or retain the
          Limitation if it determines that the justification and use-case
          provided by Customer is in good faith and are otherwise in accordance
          with these Terms. DRE may also make removal of the Limitation subject
          to receipt of written and signed representations and warranties as DRE
          may deem reasonably appropriate in order to ensure the justification
          for the removal of the Limitation.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Customer hereby acknowledges that if DRE determines through its
          compliance verification processes that the Customer is in violation of
          any of the representations and/or warranties set forth herein, DRE
          retains the right to unilaterally terminate these Terms on 7
          days&rsquo; prior written notice to Customer, unless Customer provides
          assurances to DRE that guarantee its compliance, which guarantees DRE
          may, in its sole discretion, may or may not accept.</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.d1x8fjxgvbyd">
      <span class="c3">16. Term; Termination. </span>
    </h2>
    <ol class="c0 lst-kix_rkq69uujdjcq-0 start" start="1">
      <li class="c5 li-bullet-0"><span class="c1">Term</span></li>
    </ol>
    <ol class="c0 lst-kix_rkq69uujdjcq-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c8"
          >These Terms commence on the first date the Customer uses the Platform
          and/or agrees to these Terms, unless otherwise agreed in writing
          between the parties, and shall continue in effect for the subscription
          period applicable to the specific purchased Plan (the &ldquo;</span
        ><span class="c9">Initial Term</span
        ><span class="c8"
          >&rdquo;) or any Renewal Term thereof (collectively, the &ldquo;</span
        ><span class="c9">Term</span><span class="c1">&rdquo;). &nbsp;</span>
      </li>
    </ol>
    <ol class="c0 lst-kix_rkq69uujdjcq-0" start="2">
      <li class="c5 li-bullet-0"><span class="c1">Auto-renewal</span></li>
    </ol>
    <ol class="c0 lst-kix_rkq69uujdjcq-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c8"
          >In order to ensure that Customer does not experience any interruption
          or loss of services, at the end of the Initial Term (or each Renewal
          Term, as applicable), the Plan will automatically renew (&ldquo;</span
        ><span class="c9">Renewal Date</span
        ><span class="c8"
          >&rdquo;) by default for a renewal period equal in time to the
          original subscription period (excluding extended periods)
          (&ldquo;</span
        ><span class="c9">Renewal Term</span
        ><span class="c8"
          >&rdquo;) at the then-current pricing structure for the current Plan
          (subject to applicable Taxes changes and excluding any discount or
          other promotional offer provided for the Initial Term) (&ldquo;</span
        ><span class="c9">Renewing Paid Plan</span
        ><span class="c1">&rdquo;). </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c8"
          >Accordingly, on the Renewal Date, DRE will automatically charge
          Customer the applicable Fees for the Renewing Paid Plan, using the
          same means of payment (&ldquo;</span
        ><span class="c9">Renewal Charge</span
        ><span class="c1"
          >&rdquo;). If the Renewal Charge is unsuccessful, DRE may, in its sole
          discretion (but shall not be obligated to), retry to collect such
          Renewal Charge for a period of up to two (2) weeks, during which time,
          Customer&rsquo;s DRE account might be suspended. In the event of
          failure to collect the Renewal Charge, DRE may, in its sole
          discretion, suspend or cancel Customer&rsquo;s Plan, without further
          notice.</span
        >
      </li>
    </ol>
    <ol class="c0 lst-kix_rkq69uujdjcq-0" start="3">
      <li class="c5 li-bullet-0"><span class="c1">Termination</span></li>
    </ol>
    <ol class="c0 lst-kix_rkq69uujdjcq-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c1"
          >Customer may request to terminate these Terms (and thereby the Plan)
          at any time, in accordance with the instructions available on the
          Platform. The effective date for cancellation of the Plan shall be at
          the end of the current Term.
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >Notwithstanding anything to the contrary in the foregoing, with
          respect to subscriptions to Renewing Paid Plans, such subscriptions
          will be discontinued only upon the expiration of the respective period
          for which payment has been received.. Please note that as the
          cancellation process may take a few days, in order to avoid the next
          automatic renewal and respective charge the cancellation request
          should be made at least fourteen (14) days prior to the expiration of
          the then-current service period.</span
        >
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >DRE may terminate these Terms with immediate effect by giving written
          notice to Customer if: (i) the Customer materially breaches these
          Terms and fails to cure the breach within seven (7) days after being
          given written notice thereof; or (ii) the Customer is declared
          bankrupt or insolvent, makes a general assignment for the benefit of
          its creditors, a trustee or receiver is appointed for such party or
          any petition by or on behalf of such party is filed under any
          bankruptcy or similar laws; or (iii) the Plan is deemed to be a
          deprecated Plan by DRE, in which case DRE shall provide 30 days prior
          written notice.
        </span>
      </li>
    </ol>
    <ol class="c0 lst-kix_rkq69uujdjcq-0" start="4">
      <li class="c5 li-bullet-0">
        <span class="c1">Effects of Termination</span>
      </li>
    </ol>
    <ol class="c0 lst-kix_rkq69uujdjcq-1 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c1"
          >On termination of these Terms for any reason, Customer will (i)
          immediately cease use of the Platform and any related services (except
          that the Admin may continue to access the Platform for a reasonable
          time following the termination of these Terms solely for the purpose
          of complying with Customer&rsquo;s obligations hereunder); and (ii)
          all payments will become due and payable. Notwithstanding the
          foregoing, as of termination. Customer is solely responsible for any
          communications between Customer or any End User and any Prediction
          Profile.
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c1"
          >On termination resulting from Customer&rsquo;s breach of these Terms,
          Customer shall: (i) immediately provide DRE a complete list of all
          third parties to which Customer disclosed the Prediction Profiles and
          Data; and (ii) irrevocably delete, and cause any third party that
          Customer has provided any Prediction Profiles and Data to delete any
          and all Prediction Profiles and Data, except for Prediction Profiles
          and Data that was already known to Customer or that was already in
          Customer&rsquo;s possession prior to obtaining it through the use of
          the Platform. On request, Customer will provide DRE with a signed
          notice confirming that Customer complied with the provisions of this
          paragraph.
        </span>
      </li>
    </ol>
    <h2 class="c2" id="h.tuvpkhob7xzb">
      <span class="c3">17. Governing Law.</span>
    </h2>
    <ol class="c0 lst-kix_hsxyf72adno-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c8"
          >If Customer&rsquo;s country of incorporation is the United States,
          these Terms, including the arbitration agreement referred to in
          Section 18, will be interpreted in accordance with the laws of the
          State of Delaware, without regard to the principles of conflict of
          laws. Subject to Section 18, any disputes arising out of or in
          connection with these Terms shall be brought exclusively before the
          competent courts of t</span
        ><span class="c12">h</span
        ><span class="c12">e Commonwealth of Virginia.</span
        ><span class="c12">&nbsp;</span
        ><span class="c1"
          >The parties hereby submit to the personal jurisdiction of such courts
          and waive any argument that such courts are inconvenient.</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.8bv6vflnrin4">
      <span class="c3">18. Arbitration. </span>
    </h2>
    <ol class="c0 lst-kix_xukvprgjom46-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Both parties agree to resolve any dispute (other than Litigation
          Claims (as defined below)) through final and binding arbitration as
          detailed herein. Before filing a claim, both parties agree to try to
          resolve the dispute informally and undertake reasonable efforts to
          contact each other to resolve any claim before taking any formal
          action. If a dispute is not resolved within 15 days after the first
          notification of the dispute is sent, either party may initiate an
          arbitration proceeding as described below. The parties hereby agree to
          pursue an arbitration proceeding to resolve the dispute quickly and
          efficiently and to reduce the costs imposed on the parties.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c8"
          >If Customer&rsquo;s jurisdiction of incorporation is in the United
          States, the parties agree that any dispute arising out of or in
          connection with these Terms, including any question regarding its
          existence, validity, or termination, shall be referred to and finally
          resolved by arbitration under the Commercial Arbitration Rules and the
          Supplementary Procedures for Consumer-Related Disputes, administered
          by the American Arbitration Association (&ldquo;</span
        ><span class="c9">AAA</span
        ><span class="c8"
          >&rdquo;), as such rules are in effect at the time arbitration is
          sought. Those rules are available at </span
        ><span class="c12 c18"
          ><a
            class="c10"
            href="https://www.google.com/url?q=http://www.adr.org/&amp;sa=D&amp;source=editors&amp;ust=1724856642165524&amp;usg=AOvVaw09O89TxfZ5rZ9nDZdbTOvo"
            >www.adr.org</a
          ></span
        ><span class="c1"
          >. Arbitration will proceed on an individual basis and will be handled
          by a sole arbitrator under those rules. Both parties further agree
          that the arbitration will be held in New York, New York, or, at
          Customer election, will be conducted telephonically or via other
          remote electronic means. The AAA rules will govern the payment of all
          arbitration fees.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >If Customer&rsquo;s jurisdiction of incorporation is anywhere other
          than the United States, the parties agree that any dispute arising out
          of or in connection with these Terms, including any question regarding
          its existence, validity, or termination, shall be referred to and
          finally resolved by arbitration under the LCIA Rules, which Rules are
          deemed to be incorporated by reference into this Section. The number
          of arbitrators shall be one. The seat or legal place of arbitration
          shall be London, England. The language to be used in the arbitral
          proceedings shall be English. The governing law of the contract shall
          be the substantive law of England and Wales.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c8">The following claims (&ldquo;</span
        ><span class="c9">Litigation Claims</span
        ><span class="c1"
          >&rdquo;) shall not be subject to the arbitration agreement as set out
          below and shall be litigated in the courts of competent jurisdiction
          set forth in these Terms: (a) claims by the disclosing party for the
          unauthorized disclosure, or the misuse, by the receiving party of
          disclosing party&rsquo;s Confidential Information; (b) claims by DRE
          to collect Fees; and (c) claims for mandatory or prohibitory
          injunctive relief, except for temporary relief in aid of arbitration
          or to secure the payment of an arbitration award under these Terms.
          The Litigation Claims are not subject to arbitration and are expressly
          excluded by the parties from arbitration unless otherwise agreed in
          writing.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >No party shall commence or seek to prosecute or defend any dispute,
          controversy, or claim based on any legal theory arising out of or
          relating to these Terms, or the breach thereof, other than on an
          individual, non-class, non-collective action basis. No party shall
          seek to prosecute or defend any dispute, controversy, or claim arising
          out of or relating to these Terms, or the breach thereof, in a
          representative or private attorney general capacity. The arbitrator
          shall not have the power to consolidate any arbitration under these
          Terms with any other arbitration, absent agreement of all parties
          involved, or otherwise to deal with any matter on a non-individual,
          class, collective, representative, or private attorney general
          basis.</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.q3g7b6lqz7gd">
      <span class="c3">19. Force Majeure.</span>
    </h2>
    <ol class="c0 lst-kix_mnerpilip97z-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c8"
          >Neither party shall be liable to the other for any performance delay
          or failure to perform hereunder, due to any act, war, omission,
          epidemic, pandemic, or condition beyond the reasonable control of the
          affected party (&ldquo;</span
        ><span class="c9">Force Majeure Event</span
        ><span class="c1"
          >&rdquo;), provided the affected party gives notice to the other and
          makes reasonable efforts to resume performance as soon as possible.
          Neither a Force Majeure Event nor termination of these Terms in
          connection therewith shall relieve either party from its obligation to
          pay the other any outstanding payments due under these Terms.
        </span>
      </li>
    </ol>
    <h2 class="c2" id="h.r50m20wari6j">
      <span class="c3">20. Compliance with Legal Requests. </span>
    </h2>
    <ol class="c0 lst-kix_noz0fld27yvx-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Without limiting the foregoing, DRE retains the right to fully
          cooperate with any valid legal process from a law enforcement
          authority with jurisdiction that requests or directs us to disclose
          Customer Prediction Profiles and Data or other information on the
          Platform. CUSTOMER WAIVES AND HOLDS HARMLESS DRE AND ITS AFFILIATES,
          LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY
          ACTION TAKEN BY DRE OR ANY OF THE FOREGOING PARTIES DURING OR AS A
          RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A
          CONSEQUENCE OF INVESTIGATIONS BY EITHER US, SUCH PARTIES, OR LAW
          ENFORCEMENT AUTHORITIES.</span
        >
      </li>
    </ol>
    <h2 class="c2" id="h.t3twcfnw1tvx">
      <span class="c3">21. Miscellaneous. </span>
    </h2>
    <ol class="c0 lst-kix_268r56udnzju-0 start" start="1">
      <li class="c5 li-bullet-0">
        <span class="c1"
          >These Terms (as amended) set forth the entire understanding between
          the parties concerning the subject matter herein and supersedes all
          prior and contemporaneous written agreements and discussions between
          the parties relating thereto. In the case of any conflict of terms,
          these Terms shall prevail.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >No amendment, modification, consensual cancellation, waiver,
          relaxation, or suspension of any of the provisions hereof shall be
          binding unless reduced to writing and signed by the parties.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Customer agrees that DRE may disclose the fact that Customer is a
          client of DRE. While these Terms are in effect, the Customer grants
          DRE the right to reference Customer&rsquo;s company name and logo in
          marketing materials and on DRE&rsquo;s website until Customer&rsquo;s
          use of the Platform is discontinued.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >If any provision of these Terms is determined to be void or
          unenforceable by a court or arbitral body of competent jurisdiction,
          such section shall be interpreted as necessary to give maximum force
          to the provisions thereof, and the validity and enforceability of the
          remainder of the Terms shall not be affected.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >The failure of either party to enforce at any time the provisions of
          these Terms shall not be interpreted to be a waiver of such provisions
          or of the right of such party to enforce each and every such
          provision.
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >All notices given under these Terms shall be in writing (electronic
          or otherwise) and shall be deemed to have been duly given: (i) when
          delivered to the address duly provided by such party, if delivered by
          messenger during normal business hours of the recipient; (ii) the
          third business day following posting to the address set forth under
          the party&rsquo;s signature below, if posted by international airmail
          or reputable international carrier service; or (iii) when sent to the
          party&rsquo;s provided email address if sent via email, provided that
          no notification of failure to send, out of office message, or similar
          automatic reply is received by the sender, in which case the notice
          shall not be deemed to have been duly given under this subsection
          (iii) and notice shall be provided in accordance with either (i) or
          (ii).
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Customer may not transfer or assign its rights or obligations under
          these Terms to any third party. Any purported assignment contrary to
          this section shall be void. Notwithstanding, each party shall be
          entitled to assign its rights and obligations under these Terms, in
          whole or in part, to any related entity or upon a merger, acquisition
          or sale of all or substantially all its business, without the need to
          obtain the consent of the other party, provided, in the case of
          Customer, that such assignee is not a Prediction Profiles and Data
          broker or other entity that competes with DRE.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >The parties acknowledge that in entering into these Terms, they do
          not do so on the basis of, and do not rely on, any representations,
          warranties or other provisions except as expressly provided herein and
          all conditions, warranties and other terms implied by statute or
          common law are hereby excluded to the fullest extent permitted by
          law.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c1"
          >Nothing contained in these Terms is intended or is to be construed to
          create a partnership, joint venture, agency, or employment
          relationship between the parties.
        </span>
      </li>
    </ol>
    <h2 class="c2" id="h.2fzmfailq1w9">
      <span class="c3">22. Contact Us</span>
    </h2>
    <p class="c4">
      <span class="c8"
        >If you have any additional questions, please feel free to contact us at </span
      ><span class="c12 c18"><a href = "mailto: support@dredata.ai">support@dredata.ai</a>.</span>
    </p>
    <p class="c4"><span class="c1">DRE Data Services, LLC</span></p>
    <p class="c4">
      <span class="c1"
        >1900 Campus Commons Drive, Suite 100, Reston VA 20191</span
      >
    </p>
    <p class="c21"><span class="c17"></span></p>
  </body>
</html>
`;

export default content;
