import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getPaymentIntent } from 'api/stripe.service';
import CheckoutForm from './CheckoutForm';
import { useSearchParams } from 'react-router-dom';
import constants from 'app/config/constants';
import { CPaymentIntent } from 'api/interfaces';

const StripePaymentPage = () => {
  const [paymentIntent, setPaymentIntent] = useState<CPaymentIntent>();
  const [searchParams] = useSearchParams();
  const paymentIntentId = searchParams.get('pi');
  const promise = loadStripe(constants.STRIPE_PUBLIC_KEY()!);

  const startConfiguration = async (paymentIntentId: string) => {
    const paymentIntentResponse = await getPaymentIntent(paymentIntentId);
    setPaymentIntent(paymentIntentResponse as CPaymentIntent);
  };
  useEffect(() => {
    paymentIntentId && startConfiguration(paymentIntentId);
  }, [paymentIntentId]);

  return (
    <div className="App-Container is-noBackground flex-container justify-content-center">
      {paymentIntent?.client_secret && (
        <Elements
          stripe={promise}
          options={{ clientSecret: paymentIntent.client_secret }}
        >
          <CheckoutForm paymentIntent={paymentIntent} />
        </Elements>
      )}
    </div>
  );
};

export default StripePaymentPage;
