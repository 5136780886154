import { useState } from 'react';
import { deepMergeObjects } from 'app/components/MOADBFacetedSearch/utils';
import { maskObject } from 'utils/maskObject';

export const useHistory = (value: any = {}, mask: any = null) => {
  const [index, setIndex] = useState<number>(0);
  const [state, setState] = useState<any[]>([maskObject(value ?? {}, mask)]);
  const current = state[index];

  const mergeIfNecessary = (priorValue, newValue) => {
    if (typeof priorValue === 'object' && typeof newValue === 'object')
      return deepMergeObjects(priorValue, newValue);
    else return newValue;
  };

  const history = {
    current,
    isFirst: index === 0,
    isLast: index === state.length - 1,

    goBack: () => {
      const newIndex = Math.max(0, index - 1);
      setIndex(newIndex);
    },

    goForward: () => {
      const newIndex = Math.min(state.length - 1, index + 1);
      setIndex(newIndex);
    },

    update: value => {
      const stringCurrent = JSON.stringify(maskObject(current, mask));
      const stringValue = JSON.stringify(
        maskObject(mergeIfNecessary(current, value), mask),
      );

      if (stringCurrent !== stringValue) {
        const newState = [
          ...state.slice(0, index + 1),
          mergeIfNecessary(current, value),
        ];
        setState(newState);
        setIndex(index + 1);
      }
    },
  };

  return history;
};
