const objectDelta = (a: any, b: any) => {
  if (!a) return [];

  const unique: string[] = [];

  Object.keys(a).forEach(key => {
    const matched = !!b.filter(
      b2 =>
        a !== b2 &&
        (!a[key] || JSON.stringify(a[key]) === JSON.stringify(b2[key])),
    )?.[0];

    if (!matched) unique.push(key);
  });

  return unique;
};

export default objectDelta;
