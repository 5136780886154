import * as React from 'react';
import { Box, Container, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import DRE_logo from '../../assets/Color logo - no background.svg';
import useWindowSize from 'utils/useWindowSize';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthSliceSlice } from 'store/authentication/authentication';
import { selectCart } from 'app/pages/SearchPage/slice/selectors';
import cartIcon from '../../assets/carticon.png';
import ToolTip from '../ToolTip';
import { NavBarPurchaseButtons } from '../NavBarPurchaseButtons';
import { useCookies } from 'react-cookie';

const NavBarWrapper = styled.div`
  padding: 30px 0px;
  width: 100%;
  background: #0a3042;
  background-image: radial-gradient(circle at 0%, #0a3042 62%, #155d81);
  box-shadow: 1px 0 3px rgba(20, 20, 43, 0.09);
`;

const Logo = styled.img`
  height: 45px;
  cursor: pointer;
  // border-radius: 7px;
`;

export default function NavBar() {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies();
  const {
    actions: { makeLogout },
  } = useAuthSliceSlice();
  const cart = useSelector(selectCart);
  const navigate = useNavigate();
  const size = useWindowSize();
  const [, setBreakpoint] = React.useState('');

  React.useEffect(() => {
    if (size.width < 600) {
      setBreakpoint('small');
    } else if (size.width < 900) {
      setBreakpoint('medium');
    } else if (size.width < 1200) {
      setBreakpoint('large');
    }
  }, [size]);

  return (
    <NavBarWrapper
      style={{
        display: 'inline-block',
        left: 0,
        position: 'sticky',
        width: '100vw',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <Logo src={DRE_logo} onClick={() => navigate(`/`)} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {!!cart.length && (
              <ToolTip title="View Cart" placement="bottom">
                <IconButton
                  onClick={() => navigate(`/cart`)}
                  sx={{ cursor: 'pointer' }}
                  aria-label="cart-nav-button"
                >
                  <Typography
                    sx={{
                      color: '#36e961',
                      fontSize: '14px',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 700,
                    }}
                  >
                    {cart.length}&nbsp;
                  </Typography>
                  <img
                    src={cartIcon}
                    style={{ width: '25px' }}
                    alt="cart-icon"
                  />
                </IconButton>
              </ToolTip>
            )}

            <NavBarPurchaseButtons />

            <Box
              onClick={() => {
                removeCookie('user');
                dispatch(makeLogout());
              }}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  boxShadow: '0px 0px 4px #3898ec',
                  transform: 'scale(1.015)',
                },
                padding: '8px 16px',
                border: '1px solid #36e961',
                borderRadius: '76px',
                backgroundImage: 'linear-gradient(#36e961, #64ee85)',
                color: '#1f2d54',
                fontSize: '14px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: 500,
                lineHeight: '1.286em',
                textAlign: 'center',
                boxShadow: '0 1px 4px rgba(74, 58, 255, .26)',
                transition: 'all .3s ease',
              }}
            >
              Logout
            </Box>
          </Box>
        </Box>
      </Container>
    </NavBarWrapper>
  );
}
