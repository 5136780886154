import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { MdClose, MdSearch } from 'react-icons/md';

const FilterDropdown = ({
  cellTemplate,
  columnOptions,
  customFilter,
  data,
  query,
  setCustomFilter,
  setQuery,
}) => {
  const dropdownRef = useRef<HTMLElement>();
  const [maxWidth, setMaxWidth] = useState(0);
  const field = `${!!cellTemplate?.custom ? 'prospectTracker.custom.' : ''}${
    cellTemplate.field
  }`;

  const getCurrentValues = React.useCallback(() => {
    let currentValues = [...(query[field] ?? [])];
    return currentValues
      .filter((value, index) => currentValues.indexOf(value) === index)
      .sort();
  }, [field, query]);

  const addFilterValue = React.useMemo(
    () =>
      (value, toggle = true) => {
        let currentValues = getCurrentValues();

        if (toggle && currentValues?.indexOf(value) >= 0) {
          delete currentValues[currentValues.indexOf(value)];
          if (value === customFilter) setCustomFilter('');
        } else if (currentValues.indexOf(value) < 0) currentValues.push(value);

        setQuery({
          ...query,
          [field]: currentValues.filter(a => !!a),
        });
      },
    [customFilter, field, getCurrentValues, query, setCustomFilter, setQuery],
  );

  const clearFilterValue = React.useMemo(
    () => () => {
      let currentValues = getCurrentValues();

      if (currentValues?.indexOf(customFilter) >= 0)
        delete currentValues[currentValues.indexOf(customFilter)];

      setQuery({
        ...query,
        [field]: currentValues.filter(a => !!a),
      });

      setCustomFilter('');
    },
    [customFilter, field, getCurrentValues, query, setCustomFilter, setQuery],
  );

  useEffect(() => {
    const currentWidth = dropdownRef.current?.clientWidth ?? 0;
    if (currentWidth > maxWidth) setMaxWidth(currentWidth);
  }, [data, maxWidth]);

  return (
    <Dropdown
      ref={dropdownRef}
      className="w-100"
      as={ButtonGroup}
      autoClose="outside"
      style={{
        minWidth: columnOptions?.width ?? cellTemplate.width ?? `${maxWidth}px`,
      }}
    >
      <Dropdown.Toggle split size="sm" variant="light" className="text-end">
        <small>
          {(!!cellTemplate.headerValueHandler
            ? cellTemplate.headerValueHandler(getCurrentValues(), data)
            : getCurrentValues()
          )
            .join(',')
            .substring(0, 25)}
        </small>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ maxHeight: '200px', overflow: 'auto' }}>
        <InputGroup size="sm" className="p-1">
          <Form.Control
            type="text"
            value={customFilter}
            onChange={e => setCustomFilter(e.target.value)}
          />
          <Button variant="light" onClick={() => clearFilterValue()}>
            <MdClose />
          </Button>
          <Button
            variant="light"
            onClick={() => addFilterValue(customFilter, false)}
          >
            <MdSearch />
          </Button>
        </InputGroup>
        {(!!cellTemplate.filterValueHandler
          ? cellTemplate.filterValueHandler(data)
          : data
        ).map(item => (
          <Dropdown.Item
            key={`${field}-${item?.value ?? item}`}
            className={
              query?.[field]?.indexOf(item?.value ?? item) >= 0
                ? 'bg-primary text-light'
                : ''
            }
            onClick={() => addFilterValue(item?.value ?? item)}
          >
            <small>{item?.label ?? item}</small>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FilterDropdown;
