import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';

const QNA = ({ defaultActiveKey = '0' }) => {
  const [activeQuestionKey, setActiveQuestionKey] = useState<any>(
    defaultActiveKey ?? '0',
  );

  useEffect(() => {
    setActiveQuestionKey(defaultActiveKey);
  }, [defaultActiveKey]);

  return (
    <>
      <h1 className="my-3 text-center">Questions you might have.</h1>

      <Accordion
        className="mb-5"
        activeKey={activeQuestionKey}
        defaultActiveKey={activeQuestionKey}
        flush
        onSelect={eventKey => setActiveQuestionKey(eventKey)}
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>What is DRE?</Accordion.Header>
          <Accordion.Body>
            DRE is a customer intelligence tool that helps Business Users find
            new customers and clients. The DRE Platform contains a web browser
            extension (we call “Sidekick”) and a Software-as-a-Service (SaaS)
            platform. DRE Users can see A.I.-modeled insights into prospective
            customers or clients, search for prospects nation-wide, and license
            the contact information for prospects.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            What are Prediction Profiles and how are they created?{' '}
          </Accordion.Header>
          <Accordion.Body>
            Prediction Profiles are profiles on individual U.S. adults derived
            using Artificial Intelligence analyzing open-source data.
            Importantly, Prediction Profiles are just that: predictions. They
            are not verified statements of confirmed fact. Viewing a Prediction
            Profile is most analogous to receiving the A.I.-results of an
            instantaneous, open-source internet search on an individual. DRE’s
            A.I. scours all data available on an individual and then
            mathematically models the most likely attributes about that
            individual that would help a commercial enterprise outreach to that
            person (e.g., that individual’s wealth, ideology, or interests). DRE
            has created Prediction Profiles on over 200 million U.S. adults.
            Prediction Profiles come in two different levels of detail depending
            on a User’s Membership Plan.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>What is the Sidekick?</Accordion.Header>
          <Accordion.Body>
            DRE’s Browser Sidekick is a web browser extension (currently
            available on Chrome and Edge) that allows Users to passively see
            Prediction Profiles while browsing the web. It works like this:
            while a User browses the web, the Sidekick identifies text on
            screens that represents human names. Sidekick then automatically
            accesses the Prediction Profile on the person represented by that
            name, and allows the User to hover over the name to see the
            Prediction Profile. Users with paid subscriptions can then see
            additional details and license contact information for that
            individual.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" id="browseHoverSee">
          <Accordion.Header>
            What is meant by Browse, Hover, and See?
          </Accordion.Header>
          <Accordion.Body>
            This is how Sidekick works. A User Browses the internet using
            Sidekick, Hovers over any highlighted name on the screen, and Sees
            the Prediction Profile on that person.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            How do DRE Credits work? Do they roll-over?
          </Accordion.Header>
          <Accordion.Body>
            DRE credits are automatically included in paid membership Plans. 1
            credit = $1 dollar. Credits are used to license the contact
            information on prospects so Users can outreach to that prospect.
            Credits are also used to search the Prediction Profiles on over 200
            million U.S. adults. Don’t worry, DRE Credits always roll-over into
            the next month if not used.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>How does billing work?</Accordion.Header>
          <Accordion.Body>
            After a DRE User chooses the right plan, the User is prompted to
            provide a credit card or other digital means of payment. DRE’s
            billing occurs through a Stripe payment integration. User’s are
            billed monthly on a recurring basis via the payment means provided.
            See DRE’s User Agreement, Purchase Agreement, Terms & Conditions for
            more details.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>How do I cancel my subscription?</Accordion.Header>
          <Accordion.Body>
            DRE customers may cancel at any time. The effective date for
            cancellation of the Plan is the end of the current term (usually
            monthly billing cycle). We ask that you provide us with 14 days
            notice in order to allow us to process the cancellation before the
            next monthly invoice. See DRE’s User Agreement, Purchase Agreement,
            Terms & Conditions for more details.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            What about privacy laws? What are my rights?
          </Accordion.Header>
          <Accordion.Body>
            DRE takes privacy seriously. We have a 3-part{' '}
            <a
              href="https://www.dredata.ai/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            available here. PART 1 applies to information we collect from Users
            of the DRE Platform. PART 2 sets forth DRE’s policies and practices
            for handling information DRE may possess or process about any
            individual, whether or not they have interacted with the Platform.
            And PART 3 provide general privacy obligations that apply to both
            PART 1 and PART 2. Regulations around data privacy change rapidly,
            so DRE’s{' '}
            <a
              href="https://www.dredata.ai/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            is subject to change. Please review the{' '}
            <a
              href="https://www.dredata.ai/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            periodically.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default QNA;
