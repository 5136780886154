import {
  /*take, call,*/ put,
  /*select,*/ takeLatest,
} from 'redux-saga/effects';
// import { searchSliceActions as actions } from '.';

import { singleSearchDataAsync, bulkSearchDataAsync } from '.';

// function* doSomething() {}

function* singleDonorSearchSaga(
  action: ReturnType<typeof singleSearchDataAsync.pending>,
) {
  yield put(action);
}
function* bulkDonorSearchSaga(
  action: ReturnType<typeof bulkSearchDataAsync.pending>,
) {
  yield put(action);
}

export function* singleDonorSaga() {
  yield takeLatest(singleSearchDataAsync.pending.type, singleDonorSearchSaga);
}
export function* bulkDonorSaga() {
  yield takeLatest(bulkSearchDataAsync.pending.type, bulkDonorSearchSaga);
}

export function* searchSliceSaga() {
  // yield takeLatest(actions.someAction.type, doSomething);
}
