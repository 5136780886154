import constants from 'app/config/constants';
import moment from 'moment';
import { orgPaymentHistory } from './orgPaymentHistory';

export const orgHasBasicAccess = organization => {
  return (
    orgPaymentHistory(organization).filter(
      payment =>
        [constants.STRIPE_PRICE_BASIC_ACCESS()].includes(payment.price) &&
        moment(Date.now()).isBetween(
          moment.unix(payment.period.start),
          moment.unix(payment.period.end),
        ),
    ).length >= 1
  );
};
