import React from 'react';
import TOS from './TOS';
import { updateUser } from 'api/user.service';
import { useCookies } from 'react-cookie';

const AcceptToS = ({ user }) => {
  const [cookies, setCookie] = useCookies();

  const onAgreement = () => {
    updateUser({ acceptedToS: true }, { _id: user._id }).then(() => {
      setCookie('user', { ...cookies?.user, acceptedToS: true });
      window.location.href = '/settings';
    });
  };

  return <TOS onAgreement={onAgreement} />;
};

export default AcceptToS;
