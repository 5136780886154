import {
  InputBaseComponentProps,
  SxProps,
  TextField,
  TextFieldVariants,
  Theme,
} from '@mui/material';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';

const defaultSX: SxProps<Theme> = { width: '100%' };
function FormTextField<
  FieldValue = any,
  T extends HTMLElement = HTMLElement,
  InputValue = FieldValue,
>(
  props: FieldRenderProps<FieldValue, T, InputValue> & {
    variant?: TextFieldVariants;
    sx?: SxProps<Theme> | undefined;
  },
) {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    ...rest
  } = props;
  return (
    <TextField
      {...rest}
      sx={props.sx || defaultSX}
      name={name}
      helperText={meta.touched ? meta.error : undefined}
      error={meta.error && meta.touched}
      inputProps={restInput as unknown as InputBaseComponentProps}
      onChange={onChange}
      value={value}
      variant={rest.variant || 'outlined'}
    />
  );
}
export default FormTextField;
