import React from 'react';
import { PlusCircle, PlusCircleFill, XCircle } from 'react-bootstrap-icons';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import ToolTip from '../ToolTip';
import Fields, { getFieldByName } from './Fields';
import { getElementByName, getIsBlock } from './Elements';

export const SQLElement = ({
  activeSQLElementId,
  children,
  deleteActiveSQLElement,
  field,
  id,
  isLast,
  moadbQueries,
  openReplaceModal,
  referenceId,
  type,
  setActiveSQLElementId,
  setAddWithin,
  setShowAddModal,
  updateSingleSQLElement,
  updateSQLElements,
  value,
}: {
  activeSQLElementId?: any;
  children?: any;
  deleteActiveSQLElement: any;
  field: string;
  id: string;
  isLast: boolean;
  moadbQueries: any;
  openReplaceModal: any;
  referenceId: string;
  type: string;
  setActiveSQLElementId: any;
  setAddWithin: any;
  setShowAddModal: any;
  updateSingleSQLElement: any;
  updateSQLElements: any;
  value: any;
}) => {
  const isBlock = getIsBlock(type);
  const isRef = type === 'ref';
  const fieldType = getFieldByName(field)?.type;
  const element = getElementByName(type);
  const inputType = element?.textOnly ? 'text' : fieldType ?? 'text';

  const addElement = addWithin => {
    setAddWithin(addWithin);
    setShowAddModal(true);
  };

  const onDragOver = e => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target.closest('[id]').querySelector('.list-group-item');
    target.style.boxShadow = 'inset 0px 0px 0px 4px #00ff00';
  };

  const onDragOverPrePost = e => {
    e.preventDefault();
    e.stopPropagation();

    e.target.style.boxShadow = 'inset 0px 0px 0px 4px #00ff00';
  };

  const onDragStart = e => {
    e.dataTransfer.setData('subjectID', e.target.id);
  };

  const onDrop = e => {
    e.preventDefault();
    e.stopPropagation();

    const subjectID = e.dataTransfer.getData('subjectID');
    const subject = document.getElementById(subjectID);
    const target = e.target.closest('[id]');

    if (subject !== target) {
      target.append(subject);
      updateSQLElements();
    }
  };

  const onDropPost = e => {
    e.preventDefault();
    e.stopPropagation();

    const subjectID = e.dataTransfer.getData('subjectID');
    const subject = document.getElementById(subjectID);
    const parent = e.target.closest('[id]');
    const listGroup = parent.closest('.list-group');

    if (parent !== subject) {
      listGroup.insertBefore(subject, parent.nextSibling);
      updateSQLElements();
    }
  };

  const onDropPre = e => {
    e.preventDefault();
    e.stopPropagation();

    const subjectID = e.dataTransfer.getData('subjectID');
    const subject = document.getElementById(subjectID);
    const parent = e.target.closest('[id]');
    const listGroup = parent.closest('.list-group');

    if (parent !== subject) {
      listGroup.insertBefore(subject, parent);
      updateSQLElements();
    }
  };

  const onMouseDown = e => {
    e.stopPropagation();

    setActiveSQLElementId(id);
  };

  const onMouseLeave = e => {
    const target = e.target.closest('[id]').querySelector('.list-group-item');
    if (!!target) target.style.boxShadow = 'none';
  };

  const onMouseLeavePrePost = e => {
    e.stopPropagation();
    e.preventDefault();
    e.target.style.boxShadow = 'none';
  };

  return (
    <div
      id={id}
      className={id === activeSQLElementId ? 'activeSQLElement' : ''}
      data-type={type}
      data-referenceid={referenceId}
      data-field={field}
      data-value={value}
      draggable
      onDragLeave={isBlock ? onMouseLeave : undefined}
      onDragOver={isBlock ? onDragOver : undefined}
      onDragStart={onDragStart}
      onMouseDown={onMouseDown}
      onMouseLeave={isBlock ? onMouseLeave : undefined}
    >
      <div
        className="pre"
        onDragLeave={onMouseLeavePrePost}
        onDragOver={onDragOverPrePost}
        onDrop={onDropPre}
        onMouseLeave={onMouseLeavePrePost}
      />

      <ListGroup.Item
        onDragOver={isBlock ? onDragOver : undefined}
        onDrop={isBlock ? onDrop : undefined}
      >
        <Row>
          {!isBlock && !isRef && (
            <Col xs={{ order: 'last' }} lg={{ order: 'first', span: 11 }}>
              <Row>
                <Col xs={3}>
                  <Form.Select
                    value={field}
                    onChange={e =>
                      updateSingleSQLElement(id, e.target.value, value)
                    }
                  >
                    <option>-- Select a field --</option>
                    {Object.keys(Fields).map(section => (
                      <optgroup label={section}>
                        {Fields[section]
                          .sort((a, b) => a.label.localeCompare(b.label))
                          .map(element => (
                            <option value={element.name}>
                              {element.label}
                            </option>
                          ))}
                      </optgroup>
                    ))}
                  </Form.Select>
                </Col>
                <Col xs={2} className="text-center">
                  <Button
                    variant={'dark'}
                    className="fs-5 w-100"
                    onClick={() => openReplaceModal(activeSQLElementId, type)}
                  >
                    {type}
                  </Button>
                </Col>
                <Col xs={7}>
                  {fieldType !== 'yesno' && !element?.noValue && (
                    <Form.Control
                      type={inputType}
                      value={value}
                      placeholder={element?.placeholder[inputType]}
                      onChange={e => {
                        if (inputType === 'text') {
                          const value = e.target.value.replace(
                            /("|'|`)(.*?)(\1)/g,
                            "'$2'",
                          );
                          updateSingleSQLElement(id, field, value);
                        } else
                          updateSingleSQLElement(id, field, e.target.value);
                      }}
                    />
                  )}
                  {fieldType === 'yesno' && (
                    <>
                      <Button
                        variant={value === "'Yes'" ? 'primary' : 'light'}
                        onClick={e =>
                          updateSingleSQLElement(id, field, "'Yes'")
                        }
                      >
                        Yes
                      </Button>
                      <Button
                        variant={!value ? 'primary' : 'light'}
                        onClick={e => updateSingleSQLElement(id, field, null)}
                      >
                        Either
                      </Button>
                      <Button
                        variant={value === "'No'" ? 'primary' : 'light'}
                        onClick={e => updateSingleSQLElement(id, field, "'No'")}
                      >
                        No
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          )}

          {isBlock && (
            <Col xs lg={11}>
              <Row>
                <Col xs lg={1}>
                  <Button
                    variant="primary"
                    className="fs-5 w-100"
                    onClick={() => openReplaceModal(activeSQLElementId, type)}
                  >
                    {type}
                  </Button>
                </Col>
              </Row>
            </Col>
          )}

          {isRef && (
            <Col xs lg={11}>
              <Row>
                <Col lg={1}>
                  <Button
                    variant="success"
                    className="fs-5 w-100"
                    onClick={() => openReplaceModal(activeSQLElementId, type)}
                  >
                    ref
                  </Button>
                </Col>
                <Col lg={11}>
                  {moadbQueries.queries
                    .filter(query => query._id === referenceId)
                    .map(query => (
                      <>
                        <div className="fs-5 fw-bold text-start">
                          {query.Name}
                        </div>
                        <div className="text-start">{query.Description}</div>
                      </>
                    ))}
                </Col>
              </Row>
            </Col>
          )}

          <Col xs lg={1}>
            <ButtonGroup className="float-end">
              {isBlock && (
                <ToolTip title="Add new element with block">
                  <Button
                    variant="link"
                    className="fs-5 text-dark"
                    onClick={() => addElement(true)}
                  >
                    <PlusCircleFill />
                  </Button>
                </ToolTip>
              )}

              <ToolTip title="Add new element below this one">
                <Button
                  variant="link"
                  className="fs-5 text-dark"
                  onClick={() => addElement(false)}
                >
                  <PlusCircle />
                </Button>
              </ToolTip>

              <ToolTip title="Remove this element">
                <Button
                  variant="link"
                  className="fs-5 text-dark"
                  onClick={deleteActiveSQLElement}
                >
                  <XCircle />
                </Button>
              </ToolTip>
            </ButtonGroup>
          </Col>
        </Row>

        {!!children?.[0] && (
          <ListGroup className="w-100">
            {children.map((element, index) => (
              <SQLElement
                activeSQLElementId={activeSQLElementId}
                deleteActiveSQLElement={deleteActiveSQLElement}
                isLast={index === children.length - 1}
                key={element.id}
                moadbQueries={moadbQueries}
                openReplaceModal={openReplaceModal}
                setShowAddModal={setShowAddModal}
                setActiveSQLElementId={setActiveSQLElementId}
                setAddWithin={setAddWithin}
                updateSingleSQLElement={updateSingleSQLElement}
                updateSQLElements={updateSQLElements}
                {...element}
              />
            ))}
          </ListGroup>
        )}
      </ListGroup.Item>

      {isLast && (
        <div
          className="post"
          onDragLeave={onMouseLeavePrePost}
          onDragOver={onDragOverPrePost}
          onDrop={onDropPost}
          onMouseLeave={onMouseLeavePrePost}
        />
      )}
    </div>
  );
};
