// App.tsx
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import PlanViewHeader from './PlanViewHeader';
import { MdOutlineMenu } from 'react-icons/md';
import { isGapInRange } from 'app/components/Prospect-Tracker/utility';
import PlanViewPage from './PlanViewPage';
import RangeGapModal from '../../RangeGapModal';

const PlanView = ({
  appEnabled,
  cellTemplateList,
  columnOptionList,
  count,
  donors,
  getPageInsertIndex,
  idField,
  options,
  order,
  pageHeight,
  pagesLoaded,
  pageVisibility,
  query,
  range,
  retrieveTemplatesAndSMTP,
  rowHeight,
  rowVisibility,
  saveOptions,
  setColumnOptionList,
  setDonors,
  setFormsMedium,
  setFormsRow,
  setShowOptionsModal,
  setOrder,
  setQuery,
  setRange,
  setRemoveRow,
  setRemoveRowList,
  setShiftDown,
  setShowFormsModal,
  setSort,
  shiftDown,
  smtpCredentials,
  smtpFormEnabled,
  sort,
  templates,
  userLogged,
  workspacePaddingRef,
}) => {
  const [activeColumnOptions, setActiveColumnOptions] = React.useState<any>({});
  const [columnResizerMouseDown, setColumnResizerMouseDown] =
    React.useState(false);
  const [mouseDown, setMouseDown] = React.useState(false);
  const [mouseDownRowIndex, setMouseDownRowIndex] = React.useState(0);
  const [showRangeGapModal, setShowRangeGapModal] = React.useState(false);
  let columnHeaderRed = React.useRef<HTMLElement>();

  const hideRangeGapModal = () => {
    setShowRangeGapModal(false);
    setMouseDown(false);
    setShiftDown(false);
  };

  const resetRange = index => {
    if (!shiftDown) {
      setMouseDown(true);
      setMouseDownRowIndex(index);
      setRange({ min: index, max: index });
    }
  };

  const updateRange = (index, isMouseDown) => {
    if (mouseDown || shiftDown) {
      const newMin = mouseDownRowIndex <= index ? mouseDownRowIndex : index;
      const newMax = mouseDownRowIndex >= index ? mouseDownRowIndex : index;

      if (!isMouseDown) {
        setMouseDown(isMouseDown);

        if (isGapInRange(donors, idField, newMin, newMax)) {
          setRange({ min: newMin, max: newMin });
          setShowRangeGapModal(true);
        } else setRange({ min: newMin, max: newMax });
      } else setRange({ min: newMin, max: newMax });
    }
  };

  const updateSort = field => {
    if (field === sort) setOrder(order === -1 ? 1 : -1);
    else {
      setSort(field);
      setOrder(-1);
    }
  };

  const onDraggerDrop = (e, columnOptions) => {
    const index = columnOptionList
      .map((a, index) => (a === columnOptions ? index : null))
      .filter(a => a)?.[0];

    const indexDelta = activeColumnOptions.index - index;

    if (indexDelta) {
      columnOptionList.splice(activeColumnOptions.index, 1);

      columnOptionList = [
        ...columnOptionList.slice(0, index),
        activeColumnOptions,
        ...columnOptionList.slice(index),
      ].map((a, index) => ({ ...a, index }));

      setColumnOptionList([...columnOptionList]);

      saveOptions(columnOptionList);
    }
  };

  const onDraggerMouseDown = (e, columnOptions, thRef) => {
    columnHeaderRed.current = thRef.current;
    setActiveColumnOptions(columnOptions);
  };

  const onResizerDblClick = columnOptions => {
    delete columnOptions.width;
    setColumnOptionList([...columnOptionList]);
    setColumnResizerMouseDown(false);
    saveOptions(columnOptionList);
  };

  const onResizerMouseDown = (e, columnOptions, thRef) => {
    columnHeaderRed.current = thRef.current;
    setActiveColumnOptions(columnOptions);
    setColumnResizerMouseDown(true);
  };

  const onMouseMove = e => {
    if (columnResizerMouseDown && columnHeaderRed?.current) {
      const rect = columnHeaderRed.current.getBoundingClientRect();
      const delta = e.clientX - rect.left;

      columnHeaderRed.current.style.minWidth = `${delta}px`;
      columnHeaderRed.current.style.maxWidth = `${delta}px`;

      activeColumnOptions.width = delta;
      setColumnOptionList([...columnOptionList]);
    }
  };

  const onMouseUp = () => {
    if (columnResizerMouseDown) saveOptions(columnOptionList);
    setColumnResizerMouseDown(false);
  };

  return (
    <>
      <RangeGapModal onHide={hideRangeGapModal} show={showRangeGapModal} />

      <Table
        bordered
        hover
        size="sm"
        style={{
          WebkitUserSelect: 'none',
          msUserSelect: 'none',
          userSelect: 'none',
        }}
        tabIndex={0}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >
        <thead className="sticky-top top-0">
          <tr>
            <th>
              <Button variant="dark" onClick={() => setShowOptionsModal(true)}>
                <MdOutlineMenu />
              </Button>
            </th>
            {columnOptionList.map(columnOptions => {
              const cellTemplate = cellTemplateList.filter(
                a => a.field === columnOptions.field,
              )?.[0];

              return (
                columnOptions?.isVisible &&
                cellTemplate?.showInDesktop && (
                  <PlanViewHeader
                    activeColumnOptions={activeColumnOptions}
                    key={`${cellTemplate.field}`}
                    cellTemplate={cellTemplate}
                    columnOptions={columnOptions}
                    donors={donors}
                    onDraggerDrop={onDraggerDrop}
                    onDraggerMouseDown={onDraggerMouseDown}
                    onResizerDblClick={onResizerDblClick}
                    onResizerMouseDown={onResizerMouseDown}
                    order={order}
                    query={query}
                    setQuery={setQuery}
                    sort={sort}
                    updateSort={updateSort}
                  />
                )
              );
            })}
          </tr>
        </thead>
        <tbody ref={workspacePaddingRef} />
        {Object.keys(pageVisibility).map(pageIndex => (
          <PlanViewPage
            appEnabled={appEnabled}
            cellTemplateList={cellTemplateList}
            columnOptionList={columnOptionList}
            donors={donors}
            getPageInsertIndex={getPageInsertIndex}
            idField={idField}
            options={options}
            pageHeight={pageHeight}
            pageIndex={pageIndex}
            pagesLoaded={pagesLoaded}
            pageVisibility={pageVisibility}
            range={range}
            retrieveTemplatesAndSMTP={retrieveTemplatesAndSMTP}
            rowHeight={rowHeight}
            rowVisibility={rowVisibility}
            resetRange={resetRange}
            setDonors={setDonors}
            setFormsMedium={setFormsMedium}
            setFormsRow={setFormsRow}
            setRemoveRow={setRemoveRow}
            setRemoveRowList={setRemoveRowList}
            setShowFormsModal={setShowFormsModal}
            setShowOptionsModal={setShowOptionsModal}
            smtpCredentials={smtpCredentials}
            smtpFormEnabled={smtpFormEnabled}
            templates={templates}
            userLogged={userLogged}
            updateRange={updateRange}
          />
        ))}
      </Table>
    </>
  );
};

export default PlanView;
