import { FormControl } from '@mui/base';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { Box } from '@mui/system';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  StripePaymentElementChangeEvent,
  PaymentMethod,
} from '@stripe/stripe-js';
import { CPaymentIntent } from 'api/interfaces';
import {
  confirmPaymentIntent,
  getPaymentIntent,
  listPaymentMethods,
} from 'api/stripe.service';
import { markDonorRecordsPurchased } from 'api/subscription.service';
import { useSearchSlice } from 'app/pages/SearchPage/slice';
import { selectCart } from 'app/pages/SearchPage/slice/selectors';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuthUser } from 'store/authentication/authentication.selector';
import FooterStripe from '../FooterStripe';
import HeaderStripe from '../HeaderStripe';

import './styles.css';

interface CheckoutFormProps {
  paymentIntent: CPaymentIntent;
}

const CheckoutForm: FC<CheckoutFormProps> = ({ paymentIntent }) => {
  const [savePaymentMethod, setSavePaymentMethod] = useState<boolean>(false);
  const userLogged = useSelector(getAuthUser);
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const { actions } = useSearchSlice();

  const dispatch = useDispatch();
  const stripe = useStripe()!;
  const elements = useElements()!;

  const cart = useSelector(selectCart);

  const [dreIds, setDreIds] = useState<number[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const [pMethod, setPMethod] = React.useState('');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPMethod((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    const dreIdsSS = JSON.parse(
      sessionStorage.getItem('LAST_PURCHASED') || '[]',
    ) as number[];
    const dreIdsState = cart.map(cartItem => cartItem.DRE_ID);
    setDreIds(dreIdsState.length ? dreIdsState : dreIdsSS);
  }, [cart]);

  useEffect(() => {
    const succeeded = paymentIntent.status === 'succeeded';
    setSucceeded(succeeded);
    listPaymentMethods(paymentIntent.customer, { type: 'card' }).then(
      listResponse => {
        setPaymentMethods(listResponse.data);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIntent]);

  useEffect(() => {
    if (succeeded) {
      dispatch(actions.resetSearchSlice(null));
      if (dreIds.length) {
        sessionStorage.setItem('LAST_PURCHASED', JSON.stringify(dreIds));
      }
      navigate('/thank-you');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [succeeded]);

  const afterSucceeded = async () => {
    await markDonorRecordsPurchased(dreIds, userLogged!.email);
    setError('');
    setProcessing(false);
    setSucceeded(true);
  };
  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement> | undefined,
  ) => {
    event?.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setProcessing(true);
    setError('');
    elements.submit();
    try {
      const payload = await stripe.confirmPayment({
        elements,
        clientSecret: paymentIntent.client_secret || '',
        redirect: 'if_required',
        confirmParams: {
          save_payment_method: savePaymentMethod,
          receipt_email: userLogged?.email || '',
        },
      });
      if (payload.error) {
        const paymentIntentResponse = await getPaymentIntent(paymentIntent.id);
        if (paymentIntentResponse.status === 'succeeded') {
          await afterSucceeded();
        } else {
          // eslint-disable-next-line no-throw-literal
          throw new Error(`Payment failed ${payload?.error?.message}`);
        }
      } else {
        await afterSucceeded();
      }
    } catch (error) {
      setProcessing(false);
      setSucceeded(false);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(`Unexpected error ${error}`);
      }
    }
  };

  const payWithPaymentSelected = async () => {
    setProcessing(true);
    try {
      const result = await confirmPaymentIntent(paymentIntent.id, pMethod);
      if (result.status === 'succeeded') {
        await afterSucceeded();
      } else {
        throw new Error(`Payment failed`);
      }
    } catch (error) {
      setSucceeded(false);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(`Unexpected error ${error}`);
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleChange = (event: StripePaymentElementChangeEvent) => {
    setDisabled(event.empty);
  };

  return (
    <div className="App App--multiItem">
      <div className="App-Overview">
        <HeaderStripe />
        <div className="App-productSummaryContainer">
          <div className="ProductSummary is-clickable">
            <span className="ProductSummary-name Text Text-color--gray500 Text-fontSize--16 Text-fontWeight--500">
              <b>Standard</b>
              <br />
              <br />
              <ul className="OrderDetails-items">
                <li className="OrderDetails-item">
                  <div className="LineItem flex-container align-items-flex-start">
                    <small>Standard Donor Record</small>
                  </div>
                </li>
              </ul>
            </span>
            <div className="ProductSummary-amountsContainer">
              <div className="ProductSummary-totalsRead" style={{ opacity: 1 }}>
                <div
                  className="ProductSummary-totalAmountContainer is-clickable"
                  style={{ opacity: 1 }}
                >
                  <span
                    className="ProductSummary-totalAmount Text Text-color--default Text-fontWeight--600 Text--tabularNumbers"
                    id="ProductSummary-totalAmount"
                    data-testid="product-summary-total-amount"
                  >
                    <span>${paymentIntent.amount / 100}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {succeeded ? (
        <div className="App-Payment is-noBackground">
          <div className="d-flex flex-column align-items-center mt-4">
            <Typography sx={{ color: 'success.main' }}>
              Payment success
            </Typography>
          </div>
        </div>
      ) : (
        <div className="App-Payment is-noBackground">
          {!!paymentMethods.length && (
            <Box sx={{ marginBottom: '3rem' }}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Saved Payment Methods
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={pMethod}
                  onChange={handleRadioChange}
                >
                  {paymentMethods.map(paymentMethod => (
                    <div key={paymentMethod.id}>
                      <FormControlLabel
                        value={paymentMethod.id}
                        control={<Radio />}
                        label={
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: 2,
                            }}
                          >
                            <Typography>
                              ********{paymentMethod.card?.last4}{' '}
                              {paymentMethod.card?.brand}{' '}
                              <strong>
                                Exp {paymentMethod.card?.exp_month}/
                                {paymentMethod.card?.exp_year}
                              </strong>
                            </Typography>
                          </Box>
                        }
                      />
                      {pMethod === paymentMethod.id && (
                        <>
                          <button
                            disabled={processing}
                            onClick={payWithPaymentSelected}
                            id="submit"
                            className={`SubmitButton `}
                            style={{
                              width: '80px',
                              height: '30px',
                              backgroundColor: 'rgb(25, 37, 82)',
                              color: 'rgb(255, 255, 255)',
                            }}
                          >
                            Pay
                          </button>
                        </>
                      )}
                    </div>
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          )}

          <form onSubmit={handleSubmit}>
            <PaymentElement
              options={{
                defaultValues: {
                  billingDetails: {
                    email: userLogged?.email,
                  },
                },
              }}
              className="Custom-PaymentElement"
              onChange={handleChange}
            />
            {processing ? (
              <div className="text-center mt-3">Processing...</div>
            ) : (
              <>
                <button
                  disabled={disabled}
                  id="submit"
                  className={`SubmitButton mt-2 ${
                    disabled ? 'text-muted' : ''
                  }`}
                  style={{
                    backgroundColor: 'rgb(25, 37, 82)',
                    color: 'rgb(255, 255, 255)',
                  }}
                >
                  Pay
                </button>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          setSavePaymentMethod(!savePaymentMethod)
                        }
                        checked={savePaymentMethod}
                        defaultChecked
                        color="primary"
                        sx={{
                          color: blue[800],
                          '&.Mui-checked': {
                            color: blue[600],
                          },
                        }}
                      />
                    }
                    label="Save Payment Method"
                  />
                </FormGroup>
              </>
            )}
            {error && (
              <Typography component="div" variant="body1">
                <Box sx={{ color: 'error.main' }}>{error}</Box>
              </Typography>
            )}
          </form>
        </div>
      )}
      <FooterStripe />
    </div>
  );
};

export default CheckoutForm;
