// App.tsx
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.min.css';
import { enumerate } from 'app/components/Prospect-Tracker/utility';
import constants from 'app/config/constants';
import {
  MdHelp,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from 'react-icons/md';
import HelpModal from 'app/components/Prospect-Tracker/components/HelpModal';
import { Coin, Download } from 'react-bootstrap-icons';
import ScrapeModal from '../../ScrapeModal';

const PlanViewStatusBar = ({
  appEnabled,
  activePage,
  canAccessMOADBQuery,
  count,
  downloadCSV,
  getQueryAndSortBasedOnViewMode,
  goToPage,
  includeUploads,
  setAppEnabled,
  setIncludeUploads,
  userLogged,
}) => {
  const [showHelpModal, setShowHelpModal] = React.useState(false);
  const [showScrapeModal, setShowScrapeModal] = React.useState(false);
  const pageCount = Math.ceil(count / constants.DPT_LOAD_LIMIT());
  const _DRE = React.useMemo(() => (window as any)._DRE, []);

  return (
    <div
      className="w-100 bg-light position-fixed"
      style={{ bottom: '0px', zIndex: 20 }}
    >
      <HelpModal show={showHelpModal} onHide={() => setShowHelpModal(false)} />

      <ScrapeModal
        getQueryAndSortBasedOnViewMode={getQueryAndSortBasedOnViewMode}
        onHide={() => setShowScrapeModal(false)}
        show={showScrapeModal}
        userLogged={userLogged}
      />

      <Row>
        <Col xs={12} md={4} lg={9}>
          <ButtonGroup>
            <Button variant="dark" onClick={() => setShowHelpModal(true)}>
              <MdHelp />
            </Button>
            <Button variant="dark" onClick={() => downloadCSV()}>
              <Download />
            </Button>
            {canAccessMOADBQuery && (
              <Button
                variant={appEnabled ? 'primary' : 'dark'}
                onClick={() => setAppEnabled(!appEnabled)}
              >
                App {appEnabled ? 'Enabled' : 'Disabled'}
              </Button>
            )}
            <Button
              variant={includeUploads ? 'dark' : 'primary'}
              onClick={() => setIncludeUploads(!includeUploads)}
            >
              {includeUploads ? 'All' : 'Licensed'}
            </Button>
            {!!_DRE && (
              <Button variant="dark" onClick={() => setShowScrapeModal(true)}>
                <Coin />
              </Button>
            )}
          </ButtonGroup>
        </Col>
        <Col xs={12} md={8} lg={3}>
          {pageCount > 1 && (
            <InputGroup>
              <Button
                variant="dark"
                disabled={activePage === 0}
                onClick={() => goToPage(0)}
              >
                <MdKeyboardDoubleArrowLeft />
              </Button>
              <Button
                variant="dark"
                disabled={activePage === 0}
                onClick={() => goToPage(activePage)}
              >
                <MdKeyboardArrowLeft />
              </Button>
              <Form.Select
                value={activePage}
                onChange={e => goToPage(e.target.value)}
              >
                {enumerate(pageCount).map(pageIndex => (
                  <option value={pageIndex}>
                    Page {pageIndex + 1} of {pageCount}
                  </option>
                ))}
              </Form.Select>
              <Button
                variant="dark"
                disabled={activePage === pageCount - 1}
                onClick={() => goToPage(activePage + 1)}
              >
                <MdKeyboardArrowRight />
              </Button>
              <Button
                variant="dark"
                disabled={activePage === pageCount - 1}
                onClick={() => goToPage(pageCount - 1)}
              >
                <MdKeyboardDoubleArrowRight />
              </Button>
            </InputGroup>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PlanViewStatusBar;
