import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import './styles.css';
import {
  CaretRight,
  CheckSquare,
  ChevronDown,
  Plus,
  Square,
} from 'react-bootstrap-icons';

export const MultiSelect = ({
  addLabel = 'Add',
  children,
  className = '',
  disabled = false,
  onAdd = null,
  onChange = v => {},
  placeholder = 'Select one or more',
  value = [],
}: {
  addLabel?: string;
  children?: any;
  className?: string;
  disabled?: boolean;
  onAdd?: any;
  onChange?: Function;
  placeholder?: string;
  value?: any[];
}) => {
  const [addValue] = useState(`add-${Date.now()}`);
  const [placeholderValue] = useState('');
  const [selectAllValue] = useState(`select-all-${Date.now()}`);
  const valueArray = (Array.isArray(value) ? value : [value]).filter(a => a);

  const allChildren = (Array.isArray(children) ? children : [children])
    .map(child => (Array.isArray(child) ? child : [child]))
    .reduce((p, c) => [...p, ...c], []);

  const allSelected =
    allChildren.filter(child => valueArray.includes(child?.props?.value))
      .length === allChildren.length;

  const joinedLabel =
    value?.[0] && !!value?.[0]
      ? `${allChildren
          .filter(child => valueArray.includes(child?.props?.value))
          .map(child => child?.props?.children)
          .join(', ')}`
      : value;

  const joinedValue = value?.[0] && !!value?.[0] ? `${joinedLabel}` : value;

  const hasJoinedValue =
    (Array.isArray(joinedValue) && !!joinedValue?.[0]) ||
    (!Array.isArray(joinedValue) && !!joinedValue);

  const handleOnChange = newValue => {
    if (newValue === placeholderValue)
      onChange({
        target: {
          value: [],
        },
      });
    else if (newValue === selectAllValue)
      onChange({
        target: {
          value: allSelected
            ? []
            : allChildren.map(child => child?.props?.value),
        },
      });
    else
      onChange({
        target: {
          value: valueArray.includes(newValue)
            ? value.filter(v => v !== newValue)
            : [...valueArray, newValue],
        },
      });
  };

  return (
    <Dropdown className={`multSelect w-100 ${className}`}>
      <Dropdown.Toggle
        disabled={disabled}
        className={`${
          disabled ? 'bg-body-secondary' : 'bg-white'
        } text-dark text-start w-100`}
      >
        <div>
          {hasJoinedValue ? (
            joinedValue
          ) : (
            <>
              <CaretRight /> {placeholder}
            </>
          )}
        </div>
        <ChevronDown className="expand" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item disabled={disabled} onClick={() => handleOnChange('')}>
          <CaretRight /> {placeholder}
        </Dropdown.Item>

        <Dropdown.Item
          disabled={disabled}
          onClick={() => handleOnChange(selectAllValue)}
        >
          {allSelected ? (
            <>
              <Square /> Deselect All
            </>
          ) : (
            <>
              <CheckSquare /> Select All
            </>
          )}
        </Dropdown.Item>

        {!!onAdd && (
          <Dropdown.Item
            disabled={disabled}
            onClick={() => handleOnChange(addValue)}
          >
            <Plus /> {addLabel}
          </Dropdown.Item>
        )}
        <Dropdown.Divider />

        {allChildren.map(child => {
          const selected = valueArray.includes(child.props?.value);

          return (
            <Dropdown.Item
              disabled={disabled}
              key={child.key}
              onClick={() => handleOnChange(child.props.value)}
            >
              {selected ? <CheckSquare /> : <Square />} {child.props.children}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
