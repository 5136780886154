import React, { useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';

const FeedbackModal = ({ onHide, onSend, show }) => {
  const [feedback, setFeedback] = useState<any>(null);

  const send = () => {
    onSend(feedback);
    setFeedback(null);
  };

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Real-Time Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup controlId="formFile">
            <Form.Control
              rows={3}
              as="textarea"
              value={feedback ?? ''}
              onChange={e => setFeedback(e.target.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button variant="light" onClick={onHide}>
              Cancel
            </Button>
            <Button onClick={send} disabled={!feedback}>
              Send
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FeedbackModal;
