import constants from 'app/config/constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import store from 'app/config/store';

const INTERNAL_SERVER_ERROR = 500;

const axiosInstance = sessionToken => {
  const finalSessionToken =
    sessionToken ?? store.getState()?.authentication?.user?.sessionToken;

  const instance = axios.create({
    baseURL: `${constants.BASE_ENDPOINT()}/api`,
    headers: {
      'Session-Token': finalSessionToken,
      'Content-Type': 'application/json',
      'auth-domain': 'dre',
      timeout: 1000,
    },
    // .. other options
  });

  instance.interceptors.response.use(
    response => response,
    async error => {
      let errorMsg = {};

      if (axios.isAxiosError(error)) {
        if (error.response?.data.message) {
          toast.error(error.response?.data.message);
        } else {
          toast.error(error.response?.data.error);
        }
      }
      if (error.response.status === INTERNAL_SERVER_ERROR) {
        errorMsg = {
          status: error.response.status,
          message: error.response.statusText,
        };
      }
      return errorMsg;
    },
  );

  instance.interceptors.request.use(
    function (config) {
      config.headers['Session-Token'] = finalSessionToken;
      config.headers.authDomain = 'dre';
      return config;
    },
    async error => {
      let errorMsg = {};
      if (error.response.status === INTERNAL_SERVER_ERROR) {
        errorMsg = {
          status: error.response.status,
          message: error.response.statusText,
        };
      }
      return errorMsg;
    },
  );

  return instance;
};

const addURLparams = reqObj => {
  let newUrl = ``;
  Object.keys(reqObj).forEach((key, i) => {
    if (reqObj[key]) {
      if (i === 0) {
        newUrl += `?${encodeURIComponent(key)}=${encodeURIComponent(
          reqObj[key],
        )}`;
      } else {
        newUrl += `&${encodeURIComponent(key)}=${encodeURIComponent(
          reqObj[key],
        )}`;
      }
    }
  });
  return newUrl;
};

const postUtil = (url, data) =>
  axios({
    method: 'post',
    url,
    data,
  });

const putUtil = (url, data) =>
  axios({
    method: `put`,
    url,
    data,
  });

const patchUtil = (url, data) =>
  axios({
    method: `patch`,
    url,
    data,
  });

const getUtil = (url, data = null) =>
  axios({
    method: `get`,
    url,
    params: data,
  });

const deleteUtil = (url, data) =>
  axios({
    method: `delete`,
    url,
    data,
  });

const fetchData = async (key, urlParams, endpoint, method = `GET`, payload) => {
  const requestURL = `${endpoint}${addURLparams(urlParams)}`;
  try {
    const data =
      method === `GET`
        ? await getUtil(requestURL)
        : await postUtil(requestURL, payload);
    return data;
  } catch (error) {
    throw new Error(error, key);
  }
};

export { postUtil, getUtil, putUtil, patchUtil, deleteUtil, fetchData };

export default axiosInstance;
