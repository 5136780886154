import { Box, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  createOrganization,
  getOneOrganization,
  updateOrganization,
} from 'api/organization.service';
import FormTextField from 'app/components/Form/FormTextField';
import { CustomButton } from 'app/components/LeftSidebar';
import axios from 'axios';
import React, { FC, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';
import { OrganizationFormModalProps, OrgFormData } from './types';
const initialValues: OrgFormData = {
  OrganizationName: '',
  Organization_ID: undefined,
  donors_purchased: [],
};
const OrganizationFormModal: FC<OrganizationFormModalProps> = ({
  organization,
  handleClose,
  afterSave,
}) => {
  const editMode = !!organization;
  const [saving, setSaving] = useState<boolean>(false);

  const onSubmit = async ({
    OrganizationName,
    Organization_ID = 0,
    donors_purchased,
  }: OrgFormData) => {
    const orgPayload = {
      OrganizationName,
      Organization_ID,
      donors_purchased,
    };

    setSaving(true);

    try {
      if (!editMode) {
        // check if exists Organization_ID
        const orgResponse = await getOneOrganization({ Organization_ID });
        if (orgResponse) {
          throw new Error(
            `An organization already exists with the Organization_ID ${Organization_ID}`,
          );
        }
      }
      const query = { _id: organization?._id };
      const fetchOperation = editMode ? updateOrganization : createOrganization;
      await fetchOperation(orgPayload, query);
      afterSave && afterSave();
      toast.success(
        `${OrganizationName} was ${
          editMode ? 'updated' : 'created'
        } successfully`,
      );
    } catch (error) {
      let message = '';
      if (error instanceof Error) {
        message = error?.message;
      }
      if (axios.isAxiosError(error)) {
        message = error.response?.data.error || error?.response;
      }
      toast.error(message);
    } finally {
      setSaving(false);
    }
    // fetchOperation(orgPayload, query)
    //   .then(updated => {})
    //   .catch(error => {
    //     let message = error?.response;
    //     if (axios.isAxiosError(error)) {
    //       message = error.response?.data.error;
    //     }
    //     toast.error(message);
    //   })
    //   .finally(() => setSaving(false));
  };
  async function validate(values: OrgFormData) {
    const { OrganizationName, Organization_ID } = values;

    if (!OrganizationName) {
      return { OrganizationName: 'Organization Name is required.' };
    }
    if (!Organization_ID || Organization_ID === 0) {
      const errorMessage =
        Organization_ID === 0
          ? 'Organization Id must be greater than 0.'
          : 'Organization Id is required.';
      return { Organization_ID: errorMessage };
    }
  }
  return (
    <Form
      initialValues={{
        OrganizationName:
          organization?.OrganizationName || initialValues.OrganizationName,
        Organization_ID:
          organization?.Organization_ID || initialValues.Organization_ID,
      }}
      validate={validate}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '100%',
            }}
          >
            {/* OrganizationName */}
            <Field name="OrganizationName" type="text">
              {props => (
                <FormTextField {...props} placeholder="Organization Name" />
              )}
            </Field>
            {/* Organization_ID */}
            <Field name="Organization_ID" type="number" disabled={editMode}>
              {props => (
                <FormTextField {...props} placeholder="Organization ID" />
              )}
            </Field>

            <Box sx={{ display: 'flex', gap: 4 }}>
              <CustomButton
                type="submit"
                variant="contained"
                disabled={submitting || saving}
                sx={{ width: '100%' }}
              >
                {saving ? 'SAVING...' : 'SAVE'}
              </CustomButton>
              <Button
                onClick={handleClose}
                sx={{
                  color: grey[500],
                  border: '1px solid ',
                  borderColor: grey[500],
                  borderRadius: '76px',
                  width: '100%',
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      )}
    />
  );
};

export default OrganizationFormModal;
