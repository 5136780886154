import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import React, { FC } from 'react';
import { EnhancedTableProps } from './types';
import { visuallyHidden } from '@mui/utils';

const EnhancedTableHead: FC<EnhancedTableProps> = ({
  onRequestSort,
  order,
  orderBy,
  headers,
}) => {
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead>
      <TableRow
        sx={{
          '& > *': {
            paddingX: '24px',
          },
        }}
      >
        {headers.map(headCell => (
          <TableCell
            sx={{
              backgroundColor: '#f7f8fc',
              fontFamily: 'Inter, sans-serif',
              color: '#454e66',
              fontSize: '12px',
              fontWeight: 700,
              lineHeight: '16px',
              paddingY: '12px',
              minWidth: headCell.minWidth,
              maxWidth: headCell.maxWidth,
            }}
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
