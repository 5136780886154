import React from 'react';
import DreLogo from '../../../assets/dre-logo-stripe.png';
const HeaderStripe = () => {
  return (
    <>
      <header
        className="Header"
        style={{ backgroundColor: 'rgb(255, 255, 255)' }}
      >
        <div className="Header-content flex-container justify-content-space-between align-items-stretch">
          <div className="Header-business flex-item width-grow flex-container align-items-center">
            <a
              className="Link Header-businessLink Link--primary"
              aria-label="Back to Powdur"
              title="Powdur"
              target="_self"
              href="#0"
            >
              <div style={{ position: 'relative' }}>
                <div className="flex-container align-items-center">
                  <div
                    className="Header-merchantLogoContainer"
                    style={{ transform: 'none' }}
                  >
                    <div className="Header-merchantLogoWithLabel flex-item width-grow">
                      <div
                        className="Tooltip-Context "
                        style={{ padding: '0px' }}
                      >
                        <div className="HeaderImage HeaderImage--icon flex-item width-fixed flex-container justify-content-center align-items-center width-fixed">
                          <img
                            className="HeaderImage-imageIcon"
                            alt="Powdur icon"
                            src={DreLogo}
                          />
                        </div>
                      </div>
                      <span className="Header-businessLink-label Header-businessLink-label--noPointerEvents Text Text-color--gray800 Text-fontSize--14 Text-fontWeight--500">
                        DRE Data Services, LLC
                      </span>
                      <h1 className="Header-businessLink-name Text Text-color--gray800 Text-fontSize--14 Text-fontWeight--500 Text--truncate">
                        DRE Data Services, LLC
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          {/* <div className='Header-actions flex-item width-fixed'>
            <button
              className='Button Header-viewDetailsButton Button--link Button--sm'
              type='button'
            >
              <div className='flex-container justify-content-flex-end align-items-center'>
                <svg
                  className='InlineSVG Icon Button-Icon Button-Icon--right Icon--sm Icon--square'
                  focusable='false'
                  viewBox='0 0 12 12'
                >
                  <path
                    d='M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z'
                    fill-rule='evenodd'
                  ></path>
                </svg>
                <div
                  className='Header-detailsLabel'
                  style={{ position: 'unset', opacity: 1, transform: 'none' }}
                >
                  <span className='Text Text-color--default Text-fontSize--13 Text-fontWeight--400 Text--truncate'>
                    Details
                  </span>
                </div>
              </div>
            </button>
          </div> */}
        </div>
      </header>
    </>
  );
};

export default HeaderStripe;
