import constants from 'app/config/constants';
import { reduceObject } from './reduceObject';

export const updateAnalytics = (eventName, eventParams) => {
  if (!!constants?.ANALYTICS_ENV())
    fetch(
      `https://www.google-analytics.com/mp/collect?measurement_id=${constants.ANALYTICS_MEASUREMENT_ID()}&api_secret=${constants.ANALYTICS_SECRET()}`,
      {
        method: 'POST',
        body: JSON.stringify({
          client_id: constants.ANALYTICS_CLIENT_ID(),
          events: [
            {
              name: eventName,
              params: {
                env: constants.ANALYTICS_ENV(),
                ...reduceObject(eventParams),
              },
            },
          ],
        }),
      },
    );
};
