import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { PlusCircle } from 'react-bootstrap-icons';

const CustomFieldModal = ({
  cellTemplateList,
  columnOptionList,
  onHide,
  saveOptions,
  setCellTemplateList,
  setColumnOptionList,
  show,
}) => {
  const [error, setError] = useState<any>(null);
  const [updatedCellTemplatelist, setUpdatedCellTemplateList] =
    useState<any>(null);
  const [updatedColumnOptionList, setUpdatedColumnOptionList] =
    useState<any>(null);

  const createNewField = () => {
    setUpdatedCellTemplateList([
      ...updatedCellTemplatelist,
      {
        custom: true,
        field: 'New Field',
        header: 'New Field',
        showInDesktop: true,
        type: 'text',
      },
    ]);

    setUpdatedColumnOptionList([
      ...(updatedColumnOptionList ?? []),
      {
        field: 'New Field',
        index: updatedColumnOptionList.length,
        isVisible: true,
      },
    ]);
  };

  const save = async () => {
    let newError: any = null;

    updatedCellTemplatelist
      .filter(a => !!a.custom)
      .forEach(field => {
        if (!field.field) newError = 'Please, ensure all fields have names.';
        if (field.type === 'dropdown' && !field.options)
          newError = 'Please, ensure all dropdowns have options.';
      });

    if (newError) setError(newError);
    else {
      setError(null);
      setCellTemplateList(updatedCellTemplatelist);
      setColumnOptionList(updatedColumnOptionList);
      await saveOptions(updatedColumnOptionList);
      await saveOptions(
        updatedColumnOptionList,
        updatedCellTemplatelist.filter(a => !!a.custom),
      );
      onHide();
    }
  };

  const updateField = (field, index, attribute, value) => {
    field[attribute] = value;

    if (attribute === 'field') {
      field['header'] = value;
      updatedColumnOptionList[index].field = value;
      setUpdatedColumnOptionList([...updatedColumnOptionList]);
    }

    setUpdatedCellTemplateList([...updatedCellTemplatelist]);
  };

  useEffect(() => {
    setUpdatedColumnOptionList(columnOptionList.map(a => ({ ...a })));
  }, [columnOptionList]);

  useEffect(() => {
    setUpdatedCellTemplateList(cellTemplateList.map(a => ({ ...a })));
  }, [cellTemplateList]);

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Custom Fields</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-end">
          <Button
            variant="link"
            className="text-decoration-none"
            onClick={createNewField}
          >
            <PlusCircle /> Add New Custom Field
          </Button>
        </div>

        {updatedCellTemplatelist?.map(
          (field, index) =>
            !!field.custom && (
              <div
                className={
                  index < updatedCellTemplatelist.length - 1 ? 'mb-3' : ''
                }
              >
                <InputGroup className="mb-1">
                  <FloatingLabel label="Name">
                    <Form.Control
                      value={field.field}
                      onChange={e =>
                        updateField(field, index, 'field', e.target.value)
                      }
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Type">
                    <Form.Select
                      value={field.type}
                      onChange={e =>
                        updateField(field, index, 'type', e.target.value)
                      }
                    >
                      <option value="boolean">Boolean</option>
                      <option value="date">Date</option>
                      <option value="dropdown">Dropdown</option>
                      <option value="number">Number</option>
                      <option value="text">Text</option>
                    </Form.Select>
                  </FloatingLabel>
                </InputGroup>
                {field.type === 'dropdown' && (
                  <FloatingLabel label="Options (comma-delimited)">
                    <Form.Control
                      value={field.options}
                      onChange={e =>
                        updateField(field, index, 'options', e.target.value)
                      }
                    />
                  </FloatingLabel>
                )}
              </div>
            ),
        )}
        {!!error && (
          <Alert className="mt-3" variant="danger">
            {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button onClick={save}>Save</Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomFieldModal;
