import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getObjectValue } from '../../utility';
import { viewStandardOnChange } from './viewStandardContentHandlers';
import { CheckSquare, Square } from 'react-bootstrap-icons';

const viewCustomFieldContentHandlerCSV = (row, cellTemplate) => {
  const field = `prospectTracker.custom.${cellTemplate.field}`;
  return getObjectValue(row, field) ?? '';
};

const viewCustomFieldContentHandler = (
  row,
  cellTemplate,
  columnOptions,
  indexList,
  data,
  setData,
) => {
  const field = `prospectTracker.custom.${cellTemplate.field}`;

  if (cellTemplate.type === 'boolean') {
    const value = (getObjectValue(row, field) ?? 'false') === 'true';

    return (
      <Button
        className="text-dark"
        variant="link"
        value={!value}
        onClick={viewStandardOnChange(cellTemplate, indexList, data, setData)}
      >
        {!!value ? <CheckSquare /> : <Square />}
      </Button>
    );
  } else if (cellTemplate.type === 'dropdown')
    return (
      <Form.Select
        size="sm"
        value={getObjectValue(row, field) ?? ''}
        onChange={viewStandardOnChange(cellTemplate, indexList, data, setData)}
        style={{
          maxWidth: columnOptions?.width ?? 'fit-content',
          minWidth: columnOptions?.width ?? 'fit-content',
          width: columnOptions?.width ?? 'fit-content',
        }}
      >
        <option>-- Select one --</option>
        {(cellTemplate.options ?? '').split(',').map(option => {
          const optionPurged = option.trim();
          return <option value={optionPurged}>{optionPurged}</option>;
        })}
      </Form.Select>
    );
  else if (
    cellTemplate.type === 'date' ||
    cellTemplate.type === 'number' ||
    cellTemplate.type === 'text'
  )
    return (
      <Form.Control
        size="sm"
        value={getObjectValue(row, field) ?? ''}
        onChange={viewStandardOnChange(cellTemplate, indexList, data, setData)}
        style={{
          maxWidth: columnOptions?.width ?? 'fit-content',
          minWidth: columnOptions?.width ?? '250px',
          width: columnOptions?.width ?? 'fit-content',
        }}
      />
    );
  else return <></>;
};

export default viewCustomFieldContentHandler;
export { viewCustomFieldContentHandlerCSV };
