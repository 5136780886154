// import { RootState } from 'store/configureStore';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './org-pagination';

const selectSlice = (state: RootState) =>
  state?.orgPaginationSlice || initialState;

export const getOrgPaginationState = createSelector(
  [selectSlice],
  state => state,
);

export const getIsLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);

// export const getIsAuthenticationError = createSelector(
//   [selectSlice],
//   state => state.isAuthenticationError,
// );

// export const getAuthUser = createSelector([selectSlice], state => state.user);
